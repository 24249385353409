import * as React from 'react';
import styled, { css } from 'styled-components';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { Error } from '@progress/kendo-react-labels';

const Container = styled.fieldset`
  ${props => props.$width && css`
    flex-grow: 0;
    flex-basis: auto;
    width: ${props.$width};
    margin: ${props.margin} !important;
  `}
`;

const NumericBox = (props) => {
  const { valid, validationMessage, width, margin } = props;
  return (
    <Container $width={width} className={'k-form-fieldset'} margin={margin}>
      <NumericTextBox
        {...props}
        valid={valid}
      />
      {!valid && <Error>{validationMessage}</Error>}
    </Container>
  );
};

export default NumericBox;