import React from 'react';
import { connect } from 'react-redux';
import { GridToolbar } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { GridSize, Grid } from '../../shared/components';
import {
  loadSubcompetencyList,
  openSubcompetencyConfirmDialog,
  closeSubcompetencyConfirmDialog,
  removeSubcompetencyConfirmDialog,
  sortSubcompetencies
} from '../actions/subcompetency-list-page-actions';
import { canWriteStudyProgram } from '../../common/actions';
import { Text } from '../../../ui';
import { DialogActionsBar, Dialog } from '@progress/kendo-react-dialogs';
import { withRouter } from '../../../components/withRouter';
import { pagingSettings } from '../../../utils/GridPagingUtils';

class SubcompetencyList extends React.PureComponent {

  constructor() {
    super();
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleAddClicked = this.handleAddClicked.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleConfirmDialogClose = this.handleConfirmDialogClose.bind(this);
    this.handleRemoveConfirm = this.handleRemoveConfirm.bind(this);
    this.renderGroup = this.renderGroup.bind(this);
  }

  async componentDidMount() {
    if (this.props.renderForListPage) {
      await this.props.loadSubcompetencyList({
        competencyId: this.props.competencyId,
        skip: pagingSettings.paging.skip,
        take: pagingSettings.paging.take
      });
    } else {
      await this.props.loadSubcompetencyList({ competencyId: this.props.competencyId });
    }
  }

  render() {
    const { loading, subcompetencies, canWrite } = this.props;
    const localizationService = provideLocalizationService(this);
    return (
      <>
        <Grid
          loading={loading}
          data={subcompetencies}
          isPageable={this.props.renderForListPage ?? false}
          skip={this.props.skip}
          take={this.props.take}
          total={this.props.total}
          sort={this.props.sorting}
          onPageChange={this.handlePageChange}
          onSortChange={this.handleSort}
          columns={this.resolveColumns(localizationService)}
          actions={
            (canWrite || (this.props.renderForListPage)) &&
              this.resolveActions()
          }
        >
          {(canWrite || (this.props.renderForListPage)) &&
            <GridToolbar>
              <Button themeColor='primary' onClick={this.handleAddClicked}>
                {localizationService.toLanguageString('custom.add')}
              </Button>
            </GridToolbar>
          }
        </Grid>
        {this.props.isConfirmDialogVisible &&
          <Dialog title={localizationService.toLanguageString('custom.confirm')} onClose={this.handleConfirmDialogClose}>
            <Text>{localizationService.toLanguageString('subcompetency.subcompetencyDialogMessage')}</Text>
            <DialogActionsBar>
              <Button themeColor={'primary'} onClick={this.handleRemoveConfirm}>{localizationService.toLanguageString('buttons.delete')}</Button>
              <Button onClick={this.handleConfirmDialogClose}>{localizationService.toLanguageString('buttons.cancel')}</Button>
            </DialogActionsBar>
          </Dialog>
        }
      </>
    );
  }

  resolveColumns(localizationService) {
    const columns = [
      {
        field: 'code',
        className: 'align-left',
        headerClassName: 'align-left',
        title: localizationService.toLanguageString('subcompetency.code'),
        minGridWidth: GridSize.small
      }, {
        field: 'name',
        className: 'align-left',
        headerClassName: 'align-left',
        title: localizationService.toLanguageString('subcompetency.name')
      }, {
        field: 'groupName',
        className: 'align-left',
        headerClassName: 'align-left',
        title: localizationService.toLanguageString('subcompetency.group'),
        minGridWidth: GridSize.medium,
        cell: this.renderGroup,
        sortable: false
      }
    ];

    if (this.props.renderForListPage) {
      columns.push({
        field: 'studyProgramName',
        className: 'align-left',
        headerClassName: 'align-left',
        title: localizationService.toLanguageString('subcompetency.studyProgram'),
        sortable: false
      });
    }

    return columns;
  }

  resolveActions() {
    const actions = [
      {
        icon: 'delete',
        onClick: this.handleRemoveClick,
        disabledOn: (e) => {
          return !e.dataItem.canBeDeleted || (!canWriteStudyProgram(e.dataItem.studyProgramId, this.props.app));
        }
      }
    ];

    if (this.props.renderForListPage) {
      actions.splice(0, 0,
        {
          icon: 'edit',
          onClick: this.handleEditClick,
          disabledOn: (e) => {
            return !canWriteStudyProgram(e.dataItem.studyProgramId, this.props.app);
          }
        }
      );
    }

    return actions;
  }

  renderGroup(e) {
    const { dataItem, className } = e;
    return (
      <td className={className}>
        {`${dataItem.groupName} (${dataItem.groupAbbreviation})`}
      </td>
    );
  }

  handleSort(e) {
    this.props.sortSubcompetencies({ sorting: e.sort, competencyId: this.props.competencyId });
  }

  async handlePageChange(e) {
    await this.props.loadSubcompetencyList({ competencyId: this.props.competencyId, skip: e.page.skip, take: e.page.take });
  }

  handleEditClick(e) {
    if (this.props.canWrite || this.props.renderForListPage) {
      this.props.navigate(`/subcompetencies/${e.dataItem.id}`);
    }
  }

  handleAddClicked() {
    if (this.props.renderForListPage) {
      this.props.navigate(`/subcompetencies/create`);
    } else {
      this.props.navigate(`/competencies/${this.props.competencyId}/subcompetencies/subcompetency_select`);
    }
  }

  handleRemoveClick(row) {
    this.props.openSubcompetencyConfirmDialog(row.dataItem);
  }

  handleConfirmDialogClose() {
    this.props.closeSubcompetencyConfirmDialog();
  }

  handleRemoveConfirm() {
    this.props.removeSubcompetencyConfirmDialog({ competencyId: this.props.competencyId });
  }
}

const mapStateToProps = state => ({
  loading: state.subcompetencyListPage.loading,
  subcompetencies: state.subcompetencyListPage.subcompetencies,
  isConfirmDialogVisible: state.subcompetencyListPage.isConfirmDialogVisible,
  skip: state.subcompetencyListPage.skip,
  take: state.subcompetencyListPage.take,
  total: state.subcompetencyListPage.total,
  sorting: state.subcompetencyListPage.sorting,
  app: state.app
});

const mapDispatchToProps = dispatch => ({
  loadSubcompetencyList: (payload) => dispatch(loadSubcompetencyList(payload)),
  openSubcompetencyConfirmDialog: (payload) => dispatch(openSubcompetencyConfirmDialog(payload)),
  closeSubcompetencyConfirmDialog: () => dispatch(closeSubcompetencyConfirmDialog()),
  removeSubcompetencyConfirmDialog: (payload) => dispatch(removeSubcompetencyConfirmDialog(payload)),
  sortSubcompetencies: (payload) => dispatch(sortSubcompetencies(payload))
});

registerForLocalization(SubcompetencyList);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SubcompetencyList));