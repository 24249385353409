export default {
  LOAD_STUDY_PROGRAM_FORM_PAGE_START: 'LoadStudyProgramFormPageStart',
  LOAD_STUDY_PROGRAM_FORM_PAGE_END: 'LoadStudyProgramFormPageEnd',

  LOAD_STUDY_PROGRAM_AVAILABLE_DIVISIONS_START: 'LoadStudyProgramAvailableDivisionsStart',
  LOAD_STUDY_PROGRAM_AVAILABLE_DIVISIONS_END: 'LoadStudyProgramAvailableDivisionsEnd',
  LOAD_STUDY_PROGRAM_AVAILABLE_COORDINATORS_START: 'LoadStudyProgramAvailableCoordinatorsStart',
  LOAD_STUDY_PROGRAM_AVAILABLE_COORDINATORS_END: 'LoadStudyProgramAvailableCoordinatorsEnd',
  LOAD_STUDY_PROGRAM_AVAILABLE_ADMINISTRATORS_START: 'LoadStudyProgramAvailableAdministratorsStart',
  LOAD_STUDY_PROGRAM_AVAILABLE_ADMINISTRATORS_END: 'LoadStudyProgramAvailableAdministratorsEnd',
  LOAD_STUDY_PROGRAM_AVAILABLE_COMMITTEE_MEMBERS_START: 'LoadStudyProgramAvailableCommitteeMembersStart',
  LOAD_STUDY_PROGRAM_AVAILABLE_COMMITTEE_MEMBERS_END: 'LoadStudyProgramAvailableCommitteeMembersEnd',
  LOAD_STUDY_PROGRAM_AVAILABLE_FACULTIES_START: 'LoadStudyProgramAvailableFacultiesStart',
  LOAD_STUDY_PROGRAM_AVAILABLE_FACULTIES_END: 'LoadStudyProgramAvailableFacultiesEnd'
};