export const enMessages = {
  validation: {
    Required: 'Required',
    negativeValue: 'Negative value',
    impossibleSequence: 'Impossible sequence',
    userNotSelected: 'User not selected',
    studyPlanCycleDepartmentCredits: 'Department cannot consist of more credits than the cycle',
    studyPlanCycleDepartmentResidents: 'Minimum amount of residents cannot exceed the maximum amount',
    cycleNotSelected: 'Cycle not selected',
    departmentNotSelected: 'Department not selected',
    invalidEmailFormat: 'Incorrect email format',
    exceedsMaximumLength: 'Exceeded maximum number of characters',
    selectOrFillBelow: 'Select or fill in fields below',
    invalidLinkFormat: 'Invalid link format',
    atLeastOneRequired: 'At least one option is required',
    dateIsRequiredWithTime: 'When specifying the time, the date must also be specified',
    timeIsRequiredWithDate: 'When specifying the date, the time must also be specified',
    wrongFormat: 'Wrong format'
  },
  common: {
    somethingWentWrong: 'Something went wrong',
    yes: 'Yes',
    no: 'No',
    total: 'Total'
  },
  monthsShort: {
    jan: 'jan',
    feb: 'feb',
    mar: 'mar',
    apr: 'apr',
    may: 'may',
    jun: 'jun',
    jul: 'jul',
    aug: 'aug',
    sep: 'sep',
    oct: 'oct',
    nov: 'nov',
    dec: 'dec'
  },
  authorization: {
    logout: 'Logout',
    userNotFound: 'Such user was not found. Please login again.',
    loginAgain: 'Login again',
    forbiddenTitle: 'Access denied',
    forbiddenMessage: 'You have no access to this address.',
    returnHome: 'Return home'
  },
  studyPlanCycleDepartmentStudentStatus: {
    Started: 'Started',
    NotStarted: 'Not started',
    Submitted: 'Submitted',
    Returned: 'Returned',
    Finished: 'Finished'
  },
  competencyStatus: {
    NotStarted: 'Not started',
    InProgress: 'In progress',
    Completed: 'Completed',
    Submitted: 'Submitted',
    Acquired: 'Acquired',
    Rejected: 'Rejected'
  },
  studentCompetencyStatus: {
    NotStarted: 'Not started',
    Started: 'Started',
    ToBeApproved: 'Awaiting approval',
    NotApproved: 'Not approved',
    Returned: 'Returned',
    ToBeSigned: 'Awaiting signature',
    Acquired: 'Acquired'
  },
  cycleTheoreticalPartType: {
    Lecture: 'Lecture',
    Seminar: 'Seminar'
  },
  cycleType: {
    Optional: 'Optional',
    Required: 'Required'
  },
  surveyQuestionType: {
    Text: 'Text',
    NumericZeroThroughFive: 'Numeric [0:5]',
    NumericOneThroughFive: 'Numeric [1:5]',
    NumericOneThroughTen: 'Numeric [1:10]'
  },
  roleType: {
    Administrator: 'Administrator',
    Manager: 'Manager',
    Student: 'Student',
    Mentor: 'Mentor'
  },
  surveyType: {
    ResidentEvaluationSurvey: 'Resident evaluation survey',
    CycleEvaluationSurvey: 'Cycle evaluation survey',
    ManagerEvaluationSurvey: 'Manager evaluation survey',
    ClinicalCaseSurvey: 'Clinical case survey',
    TechnicalSkillSurvey: 'Technical skill survey',
    Survey360: '360 survey'
  },
  surveyTypeAbbreviation: {
    ResidentEvaluationSurvey: 'Resident\'s',
    CycleEvaluationSurvey: 'Cycle\'s',
    ManagerEvaluationSurvey: 'Managers\'s',
    ClinicalCaseSurvey: 'C.C',
    TechnicalSkillSurvey: 'T.S.',
    Survey360: '360'
  },
  competencySurveyStudentStatus: {
    NotSubmitted: 'Not submitted',
    Submitted: 'Submitted',
    Evaluated: 'Evaluated'
  },
  evaluatorRoles: {
    Resident: 'Resident',
    Manager: 'Manager',
    Nurse: 'Nurse',
    Pacient: 'Pacient',
    Lecturer: 'Lecturer'
  },
  userStatus: {
    unapproved: 'Unapproved',
    active: 'Active',
    deactivated: 'Deactivated'
  },
  cycleTeachingType: {
    Local: 'Taught in coordinated program',
    External: 'Taught in other programs',
    LocalAndExternal: 'Taught in coordinated program and others'
  },
  studentStatus: {
    Studying: 'Studying',
    TemporarilyNotStudying: 'Temporarily not studying',
    RemovedFromList: 'Removed from list',
    Graduated: 'Graduated'
  },
  studyPlanCycleStudentApprovingUserStatus: {
    Submitted: 'Submitted',
    Unapproved: 'Unapproved',
    Returned: 'Returned',
    Supplemented: 'Supplemented',
    Approved: 'Approved'
  },
  buttons: {
    save: 'Save',
    cancel: 'Cancel',
    reset: 'Reset',
    close: 'Close',
    edit: 'Edit',
    delete: 'Delete',
    saveAndCreateNew: 'Save and create new',
    submit: 'Submit',
    register: 'Register',
    add: 'Add',
    return: 'Return',
    request: 'Request',
    confirm: 'Confirm',
    evaluate: 'Evaluate',
    yes: 'Yes',
    no: 'No',
    download: 'Download',
    preview: 'Preview'
  },
  conjunctions: {
    outOf: 'out of'
  },
  studyProgram: {
    formTitle: 'Study program',
    studyProgram: 'Program',
    studyPrograms: 'Study programs',
    coordinator: 'Prog. coordinator',
    coordinators: 'Program coordinators',
    administrators: 'Studies administrators',
    administrator: 'Studies administrator',
    committeeMembers: 'Committee members',
    committeeMember: 'Committee member',
    studyPlans: 'Study plans',
    years: 'Duration in years',
    name: 'Name',
    code: 'National code',
    credits: 'Duration in credits',
    validFrom: 'Valid from',
    validTo: 'Valid to',
    cycleCode: 'Code',
    cycleCredits: 'Credits',
    gridDetailsError: 'Failed to load study program information',
    gridError: 'Failed to load study programs list',
    loadError: 'Failed to load study program information',
    saveError: 'Failed to save study program information',
    saveSuccess: 'Study program saved successfuly',
    deleteError: 'Failed to delete study program',
    addStudyprogram: 'Add study program',
    tabstripCyclesTitle: 'Cycles',
    tabstripCasesTitle: 'Cases',
    tabstripAbilitiesTitle: 'Abilities',
    tabstripTheoreticalPartsTitle: 'Theoretical parts',
    studyProgramCycleDialogMessage: 'Are you sure you want to remove cycle from study program?',
    studyProgramCaseDialogMessage: 'Are you sure you want to remove case from study program? Count of assigned cycle cases: ',
    studyProgramSkillAbilityDialogMessage: 'Are you sure you want to remove ability from study program? Count of assigned cycle skill abilities: ',
    studyProgramTheoreticalPartDialogMessage: 'Are you sure you want to remove theoretical part from study program? Count of assigned cycle theoretical parts: ',
    studyProgramCase: 'Study program case',
    studyProgramSkillAbility: 'Study program skill ability',
    studyProgramTheoreticalPart: 'Study program theoretical part',
    assignedCycleCaseCount: 'Assigned cycle cases',
    assignedCycleSkillAbilityCount: 'Assigned cycle skill abilities',
    assignedCycleTheoreticalPartCount: 'Assigned cycle theoretical parts',
    cycleCount: 'Cycle count',
    descriptionLink: 'Description link',
    division: 'Division',
    faculty: 'Faculty'
  },
  department: {
    name: 'Name',
    formTitle: 'Department',
    department: 'Department',
    departments: 'Departments',
    code: 'Code',
    accreditationFrom: 'Accreditation from',
    accreditationTo: 'Accreditated to',
    accreditedFrom: 'Accreditated from',
    accreditedTo: 'Accredited to',
    assignedResidents: 'Assigned residents',
    cycles: 'Cycles',
    address: 'Address',
    manager: 'Manager',
    phone: 'Managers cellphone',
    email: 'Managers e-mail',
    gridDetailsError: 'Failed to load department information',
    gridError: 'Failed to load depertments list',
    loadError: 'Failed to load department information',
    saveError: 'Failed to save department information',
    saveSuccess: 'Department save successfuly',
    deleteSuccess: 'Department deleted successfully',
    deleteError: 'Failed to delete department',
    showInvalid: 'Show invalid',
    addDepartmetn: 'Add department',
    createNewDepartment: 'Save and create new department',
    departmentCycles: 'Department cycles',
    isForeign: 'Foreign department',
    departmentDialogMessage: 'Are you sure you want to remove department?',
    isExternal: 'External department'
  },
  DepartmentCycle: {
    gridError: 'Failed to load department cycles list',
    studyProgram: 'Program',
    cycle: 'Cycle',
    department: 'Department',
    studyYear: 'Study year',
    accreditationTo: 'Accreditation to',
    minResidentCount: 'Min. resident count',
    maxResidentCount: 'Max. resident count',
    cycleCredits: 'Cycle credits',
    departmentCredits: 'Department credits',
    addCycle: 'Add cycle'
  },
  cycle: {
    formTitle: 'Cycle',
    name: 'Name',
    englishName: 'English name',
    studyProgram: 'Program',
    coordinatingStudyProgram: 'Coordinating program',
    code: 'Code',
    cycle: 'Cycle',
    cycles: 'Cycles',
    credits: 'Credits',
    compulsory: 'Compulsory',
    studyYears: 'Study Years',
    department: 'Department',
    departmentCountGrid: 'Departments',
    departmentCount: 'Assigned departments',
    cycleDepartments: 'Cycle departments',
    showInvalid: 'Show invalid',
    abilityCount: 'Count of Ability',
    caseCount: 'Count of Case',
    theoreticalPartCount: 'Theoretical part',
    years: 'Study years',
    gridDetailsError: 'Failed to load cycle information',
    gridError: 'Failed to load cycles list',
    loadError: 'Failed to load cycle information',
    saveError: 'Failed to save cycle information',
    saveSuccess: 'Cycle saved successfully',
    deleteError: 'Failed to delete cycle',
    type: 'Cycle type',
    description: 'Description',
    departments: 'Cycle departments',
    validFrom: 'Valid from',
    validTo: 'Valid to',
    firstYear: '1st year',
    secondYear: '2nd year',
    thirdYear: '3rd year',
    fourthYear: '4th year',
    fifthYear: '5th year',
    sixthYear: '6th year',
    seventhYear: '7th year',
    cycleCase: 'Cycle case',
    cases: 'Cycle cases',
    case: 'Case',
    caseDiagnoses: 'Diagnoses',
    caseDiagnosis: 'Diagnosis',
    caseDiagnosisCode: 'Code',
    minCaseRequiredNumber: 'Min. number',
    cycleSkill: 'Cycle skill',
    skills: 'Cycle skills',
    skill: 'Skill',
    minSkillRequiredLevelA: 'A level',
    minSkillRequiredLevelB: 'B level',
    minSkillRequiredLevelC: 'C level',
    minSkillRequiredLevelD: 'D level',
    minSkillRequiredLevelE: 'E level',
    minSkillRequiredLevelATitle: 'A',
    minSkillRequiredLevelBTitle: 'B',
    minSkillRequiredLevelCTitle: 'C',
    cycleTheoreticalPart: 'Cycle theoretical part',
    theoreticalPart: 'Theoretical part',
    theoreticalParts: 'Cycle theoretical parts',
    theoreticalPartType: 'Type',
    theoreticalPartName: 'Name',
    theoreticalPartTeacher: 'Teacher',
    theoreticalPartTeachers: 'Teachers',
    validationYears: 'You must select at least one study year.',
    validationEmptyCase: 'Please fill or remove empty case entry before proceeding.',
    validationMinSkillsLevel: 'Combined value of A, B and C levels must be greater than 0.',
    validationEmptySkillAbility: 'Please fill or remove empty skill ability before proceeding.',
    validationEmptyTheoreticalPart: 'Please fill or remove empty skill ability before proceeding.',
    cycleCaseDialogMessage: 'Are you sure you want to remove case from cycle?',
    cycleCaseDiagnosisDialogMessage: 'Are you sure you want to remove diagnose form case?',
    cycleSkillAbilityDialogMessage: 'Are you sure you want to remove ability from cycle?',
    cycleTheoreticalPartDialogMessage: 'Are you sure you want to remove theoretical part from cycle?',
    tabstripCasesTitle: 'Cases',
    tabstripAbilitiesTitle: 'Abilities',
    tabstripTheoreticalPartsTitle: 'Theoeretical parts',
    cycleDialogMessage: 'Are you sure you want to remove cycle?',
    capitalCaseError: 'Cycle name cannot be composed out of capital letters only',
    abbreviation: 'Abbreviation',
    studyProgramCode: 'Program code',
    cycleNumber: 'Cycle queue no.',
    allowedSymbols: 'Allowed symbols are digits and .',
    cannotBeginWithDot: 'Code cannot begin with a dot',
    cannotEndWithDot: 'Code cannot end with a dot',
    cannotHaveMultipleDots: 'Code cannot contain multiple dots',
    assignedToCompetency: 'Cycle is assigned to competency',
    assignedToStudyPlan: 'Cycle is assigned to study plan',
    cycleChildren: 'Taught in study programs',
    teachingType: 'Taught',
    childStudyProgramValidationDuplicate: 'Study program is selected multiple times',
    taughtIn: 'How many programs are included',
    cycleIsBeingCopied: 'Cycle is being copied',
    requiresManagersConfirmation: 'Requires approval of skills and cases',
    cycleAdministrator: 'Cycle administrator',
    administrator: 'Administrator'
  },
  residentcycles: {
    cycle: 'Cycle',
    cycleType: 'Type',
    cycleCredits: 'Cycle credits',
    studentCredits: 'Department credits/To do',
    departmentName: 'Residency department',
    assignedCredits: 'assigned credits',
    finishedCredits: 'finished credits',
    dateFromTo: 'From - To',
    status: 'Status',
    studyYear: 'Study year',
    saveError: 'Failed to save resident cycle information',
    saveSuccess: 'Resident cycle saved successfuly',
    credits: 'Credits',
    department: 'Department',
    departmentCredits: 'Department credits',
    creditsWillBeExecuted: 'Student credits',
    formTitle: 'Resident cycles',
    student: 'Resident',
    studyPlanYear: 'Study plan year',
    notSpecify: 'Unspecified ',
    loadError: 'Failed to load resident cycles list',
    badDateFromInterval: 'Date from isn\'t in study plan',
    yearFirstLetter: ' y. ',
    NotStarted: 'Not started',
    Started: 'Started',
    Submitted: 'Submitted',
    Evaluating: 'Evaluating',
    Finished: 'Finished',
    deleteSuccess: 'Resident cycle deleted successfully',
    deleteError: 'Failed to delete resident cycle'
  },
  student: {
    studyProgram: 'Program',
    resident: 'Resident',
    year: 'Study year',
    progress: 'Progress',
    firstName: 'First name',
    lastName: 'Last name',
    gridError: 'Error! Could not load students list.',
    studentNumber: 'Number',
    activeCycles: 'Active cycles',
    credits: 'Credits',
    studyProgramLabel: 'Study program',
    number: 'Number',
    cycles: 'Cycles',
    cycle: 'Cycle',
    residents: 'Resident',
    status: 'Status',
    competencies: 'Competencies'
  },
  StudyPlan: {
    studyProgram: 'Program',
    studyPlan: 'Study plan',
    studyPlans: 'Study plans',
    isApproved: 'Status',
    credits: 'Credits',
    studyYear: 'Study year',
    areCreditsMatching: 'Only display with non-matching credits',
    confirmed: 'Confirmed',
    notConfirmed: 'Not confirmed',
    shorthandYear: 'yr.',
    gridError: 'Error! Could not load cycles list.',
    cycles: 'Cycles',
    year: 'Year of study start',
    optionalCredits: 'Optional credits'
  },
  StudyPlanCycle: {
    gridTitle: 'Study plan cycles',
    cycle: 'Cycle',
    cycles: 'Cycles',
    code: 'Code',
    status: 'Status',
    year: 'Year',
    studyYear: 'Study year',
    credits: 'Credits',
    studyDuration: 'Study duration',
    studyProgram: 'Program',
    assignedResidents: 'Assigned residents',
    departments: 'Departments',
    type: 'Type',
    gridError: 'Error!. Could not load cycles list.',
    gridDetailsError: 'Error!. Could not load cycle departments',
    assignSuccess: 'Cycle assigned succcessfuly.',
    assignError: 'Error! Could not assign cycle.',
    approve: 'Approve',
    approveError: 'Error! Could not approve study plan.',
    studyPlan: 'Study plan',
    studyPlans: 'Study plans',
    studyPlanCycle: 'Study plan cycles',
    studyPlanCycleStudent: 'Resident',
    name: 'Name',
    students: 'Residents',
    minMaxStudents: 'Min.-Max. residents',
    cycleAssignation: 'Cycle assignation',
    studyPlanCycleRemoveDialogMessage: 'Are you sure you want to remove cycle from study plan?',
    findCycles: 'Find cycles',
    addSelectedCycles: 'Add selected cycles',
    selectedStudents: ' students selected.',
    theoreticalParts: 'Theoretical parts',
    removeTheoreticalPartDialogMessage: 'Are you sure you want to remove theoretical part from study plan cycle',
    plannedDate: 'Planned date',
    plannedTime: 'Planned time',
    link: 'Link',
    cycleManagers: 'Cycle managers',
    cycleManager: 'Cycle manager',
    cycleManagerAlreadyExists: 'Such cycle manager is already assigned',
    firstName: 'First name',
    lastName: 'Last name',
    addCycleManager: 'Add cycle manager',
    confirmDeleteManager: 'Are you sure you want to remove selected cycle manager?'
  },
  StudyPlanCycleStudent: {
    firstName: 'First name',
    lastName: 'Last name',
    department: 'Department',
    dateFrom: 'Date from',
    dateTo: 'Date to',
    credits: 'Credits',
    year: 'Study year',
    isAlreadyAdded: 'Already assigned',
    status: 'Status',
    student: 'Student',
    students: 'Students',
    addWithManager: 'Add with manager',
    addWithoutManager: 'Add without manager',
    exceedingDepartmentCreditsValidation: 'Entered credits are exceeding the selected department credits',
    exceedingDepartmentStudentCountValidation: 'Selected cycle department cannot fit that many students. Reduce selected student count or increase maximum student count in cycle department',
    studentCyclePeriodCollision: 'The cycle period has collisions with other student cycles',
    failedToAddStudents: 'Failed to add students:',
    cycle: 'Cycle',
    saveDialogTitle: 'Select cycle manager',
    cycleManager: 'Cycle manager',
    failedToChangeManager: 'Failed To change cycle manager for students:'
  },
  StudyPlanCycleStudentOverviewPage: {
    status: 'Status',
    cycle: 'Cycle',
    credits: 'Credits',
    cycleManager: 'Cycle manager',
    studentDepartments: 'Departments',
    department: 'Department',
    dateFrom: 'Date from',
    dateTo: 'Date to',
    year: 'Year',
    deleteConfirmDialogTitle: 'Delete department',
    deleteConfirmDialogText: 'Are you sure you want to delete the selected student department?',
    addNew: 'New'
  },
  User: {
    gridError: 'Error. Could not load user\'s roles list.',
    user: 'User',
    users: 'Users',
    role: 'Role',
    notStudy: 'Show not studying',
    firstName: 'First name',
    lastName: 'Last name',
    department: 'Department',
    lastActionDate: 'Last action date',
    firstLogin: 'First login',
    loadError: 'Failed to load user information',
    saveError: 'Failed to save user information',
    saveSuccess: 'User saved successfully',
    formTitle: 'Users',
    search: 'Search',
    administrator: 'Administrator',
    manager: 'Manager',
    student: 'Student',
    description: 'Description',
    deleteError: 'Failed to delete user',
    userDialogMessage: 'Are you sure you want to remove user?',
    confirmImpersonateDialogMessage: 'Are you sure you want to log in as the selected user?',
    personalCode: 'Personal code',
    searchUser: 'Search in LSMUSIS',
    userAddedFrom: 'Added from LSMUSIS',
    key: 'key',
    hasActiveStudy: 'Has active studies',
    stampNumber: 'Stamp number',
    userHasNoUsername: 'User has no username',
    email: 'Email',
    type: 'Type',
    externalUser: 'External user',
    status: 'Status',
    showExternal: 'Show external',
    showMentor: 'Show mentor',
    showManager: 'show resident manager',
    person: 'person',
    externalUserAbbreviation: 'Ext. user',
    activate: 'Activate',
    deactivate: 'Deactivate',
    approve: 'Approve',
    logInAs: 'Log in as',
    requests: 'Request',
    invalidStampNumber: 'Invalid stamp number',
    employee: 'University employee',
    dean: 'Dean'
  },
  UserSearchDialog: {
    search: 'Search',
    add: 'Save',
    close: 'Close',
    title: 'Search in LSMUSIS'
  },
  TlkSearchDialog: {
    title: 'Search Tlk code',
    search: 'Search'
  },
  StudyPlanCycleDepartment: {
    formTitle: 'Study plan cycle department',
    studyPlanCycleDepartment: 'Cycle department',
    studyProgram: 'Program',
    cycle: 'Cycle',
    credits: 'Credits',
    studyYear: 'Study year',
    department: 'Department',
    cycleCredits: 'Cycle credits',
    departmentCredits: 'Department credits',
    cycleManager: 'Cycle manager',
    residentManagers: 'Resident managers',
    residentManager: 'Resident manager',
    minResidentCount: 'Min. residents',
    maxResidentCount: 'Max. residents',
    confirmDeleteDialog: 'Are you sure you want to delete this study plan cycle department?',
    validationMinMax: 'Min. residents count is higher than max. residents count',
    validationDepartmentCredits: 'Department credits should not be more than cycle credits',
    validationStudentManagerAlreadyExists: 'Such student manager is already assigned',
    parentDepartmentWarning: 'Please select a specific department or clinic'
  },
  theoreticalPart: {
    gridError: 'Error!. Could not load theoretical parts list.',
    approve: 'Approve',
    type: 'Type',
    name: 'Name',
    resident: 'Resident',
    lecturer: 'Lecturer',
    lecturers: 'Lecturers',
    studyProgram: 'Study program',
    year: 'Study year',
    status: 'Status',
    showUnapproved: 'Show unapproved',
    unknownType: 'Unknown type',
    unknownStatus: 'Unkown status',
    plannedDate: 'Planned date',
    plannedTime: 'Planned time',
    link: 'Link',
    cycle: 'Cycle',
    theoreticalParts: 'Theoretical parts',
    theoreticalPart: 'Theoretical part',
    tabstripStudentsTitle: 'Residents',
    forename: 'Name',
    surname: 'Surname',
    date: 'Date',
    completed: 'Completed',
    incomplete: 'Incomplete',
    location: 'Location',
    dateFrom: 'From',
    dateTo: 'To',
    locationUnknown: 'Not specified',
    linkToMoreInformation: 'Link to more detailed information',
    isPlanned: 'Is planned'
  },
  StudyPlanCycleResident: {
    gridError: 'Error!. Could not load study plan cycle residents list.',
    status: 'Status',
    resident: 'Resident',
    date: 'Date',
    dateFrom: 'From',
    dateTo: 'To',
    dateRange: 'From - To',
    year: 'Year',
    sudyYear: 'Study year',
    studyProgram: 'StudyProgram',
    department: 'Department',
    departmentCredits: 'Department credits',
    creditsForCompletion: 'Credits to be completed',
    gridStatus: 'Status (department)',
    loadError: 'Failed to load study plan cycle resident information',
    saveError: 'Failed to save study plan cycle resident information',
    saveSuccess: 'Study plan cycle resident saved successfully',
    formTitle: 'Study plan cycle resident',
    credits: 'Credits',
    dialogConfirmDeleteStudent: 'Are you sure you want to remove selected students from this study plan cycle?',
    assign: 'Assign',
    assigned: 'Assigned',
    notAssigned: 'Not assigned',
    cycleManager: 'Cycle manager',
    changeCycleManager: 'Change cycle manager',
    change: 'Change'
  },
  myStudies: {
    tabstripStudyProgramsTitle: 'Study programs',
    durationInYears: 'Duration in years',
    studyProgramShorthand: 'Stud. Program',
    studyYearShorthand: 'Stud. Year',
    number: 'Number',
    cycles: 'Rotations',
    assignedCredits: 'Assigned credits',
    finishedCredits: 'Finished credits',
    notSpecify: 'unspecified',
    studyYear: 'Study year',
    cycle: 'Rotation',
    cycleCredits: 'Cycle credits',
    dateFrom: 'From',
    dateTo: 'To',
    myCycles: 'My cycles',
    status: 'Status',
    myStudies: 'My curriculum ',
    studyPrograms: 'Study programs',
    studyProgram: 'Study program',
    studyStatus: 'Study status',
    credits: 'Credits',
    noStudiesHeader: 'No studies found',
    noStudiesMessage: 'You dont have any studies'
  },
  studentCycleCompletion: {
    manager: 'Resident manager',
    date: 'Date',
    evaluate: 'Evaluate',
    evaluation: 'Overall evaluation',
    managerEvaluations: 'Evaluation of mentor by resident',
    evaluateCycle: 'Evaluate cycle',
    cycleQualityEvaluation: 'Rotation quality evaluation form',
    cycleManagerEvaluation: 'Evaluation of resident by mentors',
    previewReport: 'Preview report',
    submitReport: 'Submit report',
    evaluatedOn: 'Evaluated on',
    surveyTooltipInfo: 'Upon finishing cycle, resident evaluates managers they worked with, cycle quality and cycle manager. These evaluations are shown as anonymous, to keep managers objective.',
    preview: 'Preview',
    notEvaluated: 'Not evaluated',
    finishCycle: 'Finish the cycle',
    submitCycle: 'Submit for evaluation',
    confirmSubmitCycleComplete: 'Are you sure you want to submit this cycle report?',
    confirmSubmitCycleIncomplete: 'You haven\'t registered all required skills and cases. Are you sure you want to submit this cycle report?',
    statusHistory: 'Status history',
    managerApprovals: 'Request manager for approval of skills and cases  that were acquired with them?',
    cycleManagerMissingWarningMessage: 'This cycle does not have a cycle manager assigned to it, contact program studies\' coordinator',
    requestSkillsAndCasesApprovalTitle: 'Confirm',
    requestSkillsAndCasesApprovalMessage: 'Request resident manager {0} to confirm skills and cases that were acquired with them?',
    requestApproval: 'Request approval',
    approvalRequestTooltipInfo: 'When completing a cycle resident may request manager to approve cycle skills and cases that were acquired with them.',
    cycleCompletionTooltip: 'To submit cycle for evaluation you must first acquire at least one approval of cycle skills and/or cases from resident\'s manager.',
    missingDepartmentError: 'There is no selected department, contact the study administrator'
  },
  studentCycleTheoreticalPart: {
    haveCompleted: 'Have completed',
    completed: 'Completed',
    incomplete: 'Incomplete',
    linkToMoreInformation: 'Link to more information',
    completedAfterCycleSubmission: 'Completed after cycle submission'
  },
  studentCycle: {
    dateFrom: 'Date from',
    dateTo: 'Date to',
    period: 'Period',
    department: 'Department',
    departments: 'Departments',
    cycleType: 'Type',
    cycleCredits: 'Cycle credits',
    cycleStatus: 'Cycle status',
    cycleStatusComment: 'Manager\'s note',
    cycle: 'Cycle',
    cycles: 'Cycles',
    skill: 'Skill',
    case: 'Case',
    tabSkills: 'Skills',
    tabCases: 'Clinical cases',
    tabTheoreticalParts: 'Theory',
    tabCycleCompletion: 'Completion',
    studentManager: 'Mentor',
    medicalHistoryNumber: 'Case No.',
    date: 'Date',
    skillConfirmDialogTitle: 'Selected skills registration',
    skillEditDialogTitle: 'Skill editing',
    skillDeleteDialogMessage: 'Are you sure you want to delete selected skill?',
    skillRegisterErrorMessage: 'Could not add {0} of {1} skills. Successfully added {2}',
    skillDeleteErrorMessage: 'Could not delete the selected skill',
    caseConfirmDialogTitle: 'Selected cases registration',
    caseEditDialogTitle: 'Case editing',
    caseDeleteDialogMessage: 'Are you sure you want to delete selected case?',
    caseRegisterErrorMessage: 'Could not add {0} of {1} cases. Successfully added {2}',
    caseDeleteErrorMessage: 'Could not delete the selected case',
    cycleSkillDeleteDialogMessage: 'Are you sure you want to delete selected additional skills?',
    cycleSkillDeleteErrorMessage: 'Could not delete selected additional skills',
    cycleCaseDeleteDialogMessage: 'Are you sure you want to delete selected additional cases?',
    cycleCaseDeleteErrorMessage: 'Could not delete selected additional cases',
    validationDateOutOfCycleRange: 'The date is out of cycle date range',
    validationNumberTooLong: 'The number is too long',
    addSkillFromAnotherCycle: 'Add skill from another cycle',
    addCaseFromAnotherCycle: 'Add case from another cycle',
    creditsShort: 'cr.',
    theoreticalPartRegisterDialogMessage: 'Are you sure you want to mark theoretical part as completed?',
    enterNewMentor: 'Enter new mentor',
    chooseExisting: 'Choose existing',
    stampNumber: 'Stamp number',
    name: 'Name',
    surname: 'Surname',
    cycleCode: 'Cycle code',
    competencies: 'Competencies',
    levelATooltip: 'A – resident can perform a procedure without supervision.',
    levelBTooltip: 'B – resident can perform a procedure with supervision.',
    levelCTooltip: 'C – resident has seen has seen or participated in a procedure.',
    noAssignedCasesMessage: 'There are no assigned cases in this cycle',
    email: 'Email',
    invalidStampNumber: 'Invalid stamp number',
    invalidString: 'Field filled incorrectly',
    credits: 'Credits',
    submitDepartmentDesignationDialogMessage: 'Are you sure you want to submit a letter of designation?'
  },
  managedStudents: {
    resident: 'Resident',
    status: 'Cycle status',
    cycle: 'Cycle',
    generalMark: 'Mark',
    year: 'year',
    creditsShort: 'cr.'
  },
  managedStudent: {
    myResidents: 'My residents',
    myResident: 'My resident',
    resident: 'Resident',
    dateFromTo: 'Date from - to',
    department: 'Department',
    departments: 'Departments',
    type: 'Type',
    cycleCredits: 'Cycle credits',
    studyProgram: 'Study program',
    year: 'Year',
    evaluation: 'Evaluation',
    cycleStatus: 'Cycle status',
    evaluate: 'Evaluate',
    tabstripSkillsTitle: 'Skills',
    tabstripCasesTitle: 'Cases',
    tabstripTheoreticalPartsTitle: 'Theoretical parts',
    residentPortfolio: 'Resident portfolio',
    returnForCorrection: 'Return for correction',
    comment: 'Comment',
    residentsCycle: 'Residents cycle',
    approve: 'Approve',
    return: 'Return',
    approvalRequests: 'Approval requests',
    skillsAndCasesStatuses: 'Skill and cases statuses',
    approveDialogMessage: 'Are you sure you want to approve skills and cases?'
  },
  studyPlanCycleTheoreticalPart: {
    formTitle: 'Study plan cycle theoretical part',
    studyPlans: 'Study plans',
    studyPlan: 'Study plan',
    studyPlanCycle: 'Study plan cycle',
    studyPlanCycleTheoreticalPart: 'Theoretical part',
    theoreticalPart: 'Theoretical part',
    plannedDate: 'Planned date',
    plannedTime: 'Planned time',
    link: 'Link',
    invalidLink: 'Invalid link',
    lecturer: 'Lecturer',
    tabstripStudentsTitle: 'Residents',
    name: 'Name',
    surname: 'Surname',
    status: 'Status',
    date: 'Date',
    location: 'Location',
    studyPrograms: 'Study programs',
    studyProgramChecklistInfo: 'If theoretical part is taught in other programs, select in which programs should that be represent'
  },
  survey: {
    manager: 'Manager',
    survey: 'Survey',
    surveys: 'Surveys',
    managerEvaluation: 'How does a resident evaluate manager?',
    cycleEvaluation: ' How does a resident evaluate cycle quality and practical work?',
    residentEvaluation: 'How does cycle manager evaluate completed resident cycle?',
    credits: 'Credits',
    code: 'Code',
    from: 'From',
    to: 'To',
    name: 'Name',
    resident: 'Resident',
    cycleCompletion: 'Cycle completion',
    reportTitle: 'Characteristics and practical work evaluation',
    grade: 'Grade',
    managerEvaluationSurvey: 'Resident manager\'s evaluation survey',
    completedCycleQualityEvaluation: 'Completed cycle\'s quality evaluation',
    residentEvaluationSurvey: 'Resident\'s evaluation survey',
    residentPortfolio: 'Resident portfolio',
    competencyAssignation: 'Competency assignation',
    minCount: 'Minimum count',
    competencies: 'Competencies',
    tieredCompetencies: 'Tiered competencies',
    competency: 'Competency',
    cycle: 'Cycle',
    myResidents: 'My residents',
    residentCycle: 'Resident cycle'
  },
  cyclePlan: {
    resident: 'Resident',
    department: 'Department',
    studyYear: 'Study year',
    studyProgram: 'Study program',
    emptyList: 'No entries found or no study program is selected.',
    creditsTotal: 'Credits total',
    cycle: 'Cycle'
  },
  subcompetency: {
    code: 'Code',
    name: 'Name',
    group: 'Competencies group',
    subcompetency: 'Subcompetency',
    subcompetencies: 'Subcompetencies',
    studyProgram: 'Study program',
    competencies: 'Competencies',
    description: 'Description',
    subcompetencyDialogMessage: 'Are you sure you want to remove subcompetency?'
  },
  competency: {
    abbreviation: 'Code',
    name: 'Name',
    studyProgram: 'Study program',
    competency: 'Competency',
    formTitle: 'Competency',
    competencies: 'Competencies',
    subcompetency: 'Subcompetency',
    subcompetencies: 'Subcompetenices',
    description: 'Description',
    survey: 'Survey',
    surveys: 'Surveys',
    note: 'Note',
    number: 'Number',
    question: 'Question',
    questions: 'Questions',
    status: 'Status',
    type: 'Type',
    minCount: 'Minimum amount',
    competencySurveyDialogMessage: 'Are you sure you want to remove survey from competency?',
    competencySurveyQuestionDialogMessage: 'Are you sure you want to remove a question from the survey?',
    competencyCycleDialogMessage: 'Are you sure you want to remove cycle from competency?',
    surveyQuestion: 'Question',
    cycle: 'Cycle',
    cycles: 'Cycles',
    credits: 'Credits',
    addSelectedCycles: 'Add selected cycles',
    addSelectedSubcompetencies: 'Add selected subcompetencies',
    code: 'Code',
    group: 'Group',
    subcompetencyAssignation: 'Subcompetency assignation',
    surveyDialogMessage: 'Are you sure you want to remove survey?',
    invalidSubcompetencyWarningMessage: 'Specified subcompetency is not assigned to survey\'s competency.',
    invalidSubcompetencyWarningMessage2: 'Specified subcompetency is not assigned to any of survey\'s competencies\'.',
    subcompetencyMismatch: 'This subcompetency is not assigned to competency',
    duplicateCompetenciesSelected: 'Competency is selected multiple times',
    competencyDialogMessage: 'Are you sure you want to remove competency?',
    tieredCompetency: 'Tiered competency',
    tieredCompetencies: 'Tiered competencies',
    tabstripQuestionsTitle: 'Questions',
    surveyQuestionDialogMessage: 'Are you sure you want to remove a question from the survey?',
    surveyType: 'Survey type',
    surveyCode: 'SurveyCode',
    minEvaluationCount: 'Min. evaluation count',
    greatestExistingQuestionNumber: 'Greatest existing question number',
    questionCount: 'Question count'
  },
  studentCompetency: {
    code: 'Code',
    name: 'Name',
    subcompetencies: 'Subcompetencies',
    cycles: 'Cycles',
    status: 'Status',
    progress: 'Progress',
    completedCyclesLabel: 'Cycles completed:',
    completedSubcompetenciesLabel: 'Subcompetencies completed:',
    overallProgress: 'Overall progress',
    myCompetencies: 'My competencies',
    myCompetency: 'My competency',
    tabstripCyclesTitle: 'Rotations',
    tabstripSubcompetenciesTitle: 'Milestones',
    tabstripSurveysTitle: 'Surveys',
    tabstripCertificateTitle: 'Certificate',
    abbreviation: 'Code',
    studyProgram: 'Study program',
    type: 'Type',
    credits: 'Credits',
    cycleStatus: 'Cycle status',
    group: 'Area',
    description: 'Description',
    generalMark: 'Mark',
    markDate: 'Mark date',
    isCompelted: 'Completed',
    requestEvaluationDialogTitle: 'Request evaluation',
    comment: 'Comment',
    manager: 'Manager',
    completion: 'Completion',
    submitted: 'Submitted',
    survey: 'Survey',
    surveys: 'Surveys',
    competency: 'Competency',
    minCount: 'Minimum count',
    request: 'Request',
    evaluation: 'Evaluation',
    evaluation2: 'Evaluation',
    requestDate: 'Requested on',
    submitForEvaluation: 'Submit for evaluation',
    required: 'Required',
    submittedEvaluated: 'Submitted/Evaluated',
    submitForEvaluationDialogMessage: 'Are you sure you want to submit a request to acquire competency?',
    tieredCompetencyAbbr: 'TC',
    myTieredCompetency: 'My TC',
    tieredCompetency: 'Tiered competency',
    completed: 'Completed',
    evaluations: 'Evaluations',
    evaluationDate: 'Evaluated on',
    submittedForEvaluation: 'Submitted for evaluation',
    firstName: 'Name',
    surname: 'Surname',
    email: 'E-mail',
    requestExternalUser: 'Request external user',
    requestInternalUser: 'Request internal user',
    evaluator: 'Evaluator',
    department: 'Department',
    role: 'Role',
    externalUser: 'External user',
    coordinator: 'Coordinator',
    commentFieldLengthExceeds: 'Field cannot exceed 300 symbols',
    qrCode: 'QR Code',
    shoqQrCode: 'Show QR code',
    competencyCertificate: 'Competency certificate',
    noCertificate: 'No certificate found',
    stampNumber: 'Stamp number',
    getReport: 'Report',
    invalidStampNumber: 'Invalid stamp number',
    parentDepartmentWarning: 'Please select a specific department or clinic'
  },
  mySurvey: {
    resident: 'Resident',
    surveyType: 'Type',
    requestDate: 'Requested on',
    comment: 'Comment',
    surveyStatus: 'Status',
    status: 'Status',
    mySurveys: 'My surveys',
    mySurvey: 'My survey',
    evaluation: 'Evaluation',
    surveyCode: 'Survey code',
    name: 'Name',
    code: 'Code',
    evaluationsDescription: 'Evaluations desciption',
    evaluation0Descrition: 'No answer',
    evaluation1Description: 'Observes the process',
    evaluation2Description: 'Performs with thorough residents\' managers\' help',
    evaluation3Description: 'Performs with minimal residents\' managers\' help',
    evaluation4Description: 'Performs on its own',
    evaluation5Description: 'Teaches junior residents',
    survey360evaluation0Description: 'Not assesable',
    survey360evaluation1Description: 'Completely disagree',
    survey360evaluation2Description: 'Disagree',
    survey360evaluation3Description: 'Partially agree',
    survey360evaluation4Description: 'Agree',
    survey360evaluation5Description: 'Completely agree',
    tieredCompetency: 'Competency',
    requestsEvaluation: 'Requests evaluation'
  },
  competencyRequest: {
    competency: 'Competency',
    studyProgram: 'Study program',
    requestDate: 'Request date',
    status: 'Status',
    competencyRequests: 'Competency requests',
    competencyRequest: 'Competency request',
    code: 'Code',
    name: 'Name',
    surveys: 'Surveys',
    cycles: 'Cycles',
    student: 'Student',
    type: 'Type',
    submitted: 'Submitted',
    evaluated: 'Evaluated',
    required: 'Required',
    completed: 'Completed',
    evaluatedPastTense: 'evaluated',
    register: 'Register',
    return: 'Return',
    deny: 'Deny',
    sign: 'Sign',
    evaluations: 'Evaluations',
    resident: 'Resident',
    showAcquired: 'Show acquired',
    comment: 'Comment',
    returnDialogTitle: 'Return competency',
    getReport: 'Report',
    competencyGrantMessage: 'Are you sure you want to sign students\' competency electronically?',
    competenciesGrantMessage: 'Are you sure you want to sign students\' competencies electronically?'
  },
  userRequest: {
    userRequestDialogMessage: 'Ar you sure you want to remove this request for evaluation?',
    survey: 'Survey',
    resident: 'Resident',
    role: 'Role',
    evaluated: 'evaluated'
  },
  approvalRequest: {
    status: 'Status',
    resident: 'Resident',
    cycle: 'Cycle',
    requestDate: 'Date of request'
  },
  systemMessages: {
    isSuccessfullyAdded: 'is successfully added',
    areSuccessfullyAdded: 'are successfully added',
    isNotAddedBecauseOfErrors: 'is not added because of errors.',
    areNotAddedBecauseOfErrors: 'are not added because of errors.',
    successfullyAddedRecords: 'Successfully added records:',
    successfullyAdded: 'Successfully added:'
  },
  drawer: {
    home: 'Home',
    departments: 'Departments',
    profile: 'Profile',
    settings: 'Settings',
    cycles: 'Curriculum',
    studyPrograms: 'Residency programs',
    students: 'Residents',
    studyPlans: 'Rotation planning',
    theoreticalParts: 'Theory',
    users: 'Users',
    myStudies: 'My curriculum',
    evaluateCycle: 'Rotation evaluation',
    requests: 'Assessment',
    cyclePlans: 'Rotating calendar',
    subcompetencies: 'Milestones',
    competencies: 'EPA’s',
    competenciesList: 'List of EPA’s',
    surveys: 'Questionnaires',
    myCompetencies: 'My EPA’s ',
    mySurveys: 'EPA’s assessment',
    competencyRequests: 'Certification of EPA’s',
    evaluators: 'Evaluators',
    approvalRequests: 'Skills evaluation',
    notifications: 'Notifications',
    tooltips: {
      home: 'Home',
      departments: 'Departments',
      profile: 'Profile',
      settings: 'Settings',
      cycles: 'Curriculum',
      studyPrograms: 'Residency programs',
      students: 'Residents',
      studyPlans: 'Rotation planning',
      theoreticalParts: 'Theory',
      users: 'Users',
      myStudies: 'My curriculum',
      evaluateCycle: 'Rotation evaluation',
      requests: 'Assessment',
      cyclePlans: 'Rotating calendar',
      subcompetencies: 'Milestones',
      competencies: 'EPA’s',
      competenciesList: 'List of EPA’s',
      surveys: 'Questionnaires',
      myCompetencies: 'My EPA’s ',
      mySurvey: 'EPA’s assessment',
      competencyRequests: 'Certification of EPA’s',
      evaluators: 'Evaluators',
      approvalRequests: 'Requests of evaluation of skills and cases',
      notifications: 'List of notifications'
    }
  },
  userDrawer: {
    studyPrograms: 'Study programs',
    logOut: 'Log out'
  },
  custom: {
    add: 'Add',
    close: 'Close',
    edit: 'Edit',
    delete: 'Delete',
    loading: 'Loading',
    teamEfficiency: 'Team Efficiency',
    teamMembers: 'Team Members',
    dashboard: 'Dashboard',
    planning: 'Planning',
    profile: 'Profile',
    info: 'Info',
    language: 'Language',
    trend: 'Trend',
    volume: 'Volume',
    myTeam: 'My Team',
    allTeams: 'All Teams',
    teamCalendar: 'Team Calendar',
    saveChanges: 'Save Changes',
    save: 'Save',
    cancel: 'Cancel',
    title: 'Resident Portfolio',
    startFreeTrial: 'Start Free Trial',
    buyNow: 'Buy Now',
    getSource: 'Get the source code on GitHub',
    demoInfo: 'This demo application is built using the following KendoReact components and the KendoReact Default Theme.',
    firstName: 'First Name',
    middleName: 'Middle Name',
    lastName: 'Last Name',
    email: 'Email Address',
    phoneNumber: 'Phone Number',
    country: 'Country',
    biography: 'Short Biography',
    public: 'Include in public directory',
    team: 'Team',
    reset: 'Reset',
    employee: 'Employee',
    contactName: 'Contact Name',
    jobTitle: 'Job Title',
    status: 'Status',
    settings: 'Settings',
    performance: 'Performance',
    rating: 'Rating',
    engagement: 'Engagement',
    budget: 'Budget',
    contacts: 'Contacts',
    address: 'Address',
    phone: 'Phone',
    gridSearch: 'Search in all columns...',
    exportExcel: 'Export to Excel',
    exportPdf: 'Export to PDF',
    copy: 'Copy',
    search: 'Search',
    logout: 'Log out',
    confirm: 'Confirm',
    clearFilter: 'Clear filter',
    approve: 'Approve',
    notSpecified: 'Not specified'
  },
  grid: {
    groupPanelEmpty: 'Drag a column header and drop it here to group by that column',
    pagerItemsPerPage: 'items per page',
    pagerInfo: '{0} - {1} of {2} items',
    pagerFirstPage: 'Go to the first page',
    pagerPreviousPage: 'Go to the previous page',
    pagerNextPage: 'Go to the next page',
    pagerLastPage: 'Go to the last page',
    pagerPage: 'Page',
    pagerOf: 'of',
    filterSubmitButton: 'Filter',
    filterClearButton: 'Clear filter',
    filterTitle: 'Filter',
    filterEqOperator: 'Is equal to',
    filterNotEqOperator: 'Is not equal to',
    filterIsNullOperator: 'Is null',
    filterIsNotNullOperator: 'Is not null',
    filterIsEmptyOperator: 'Is empty',
    filterIsNotEmptyOperator: 'Is not empty',
    filterStartsWithOperator: 'Starts with',
    filterContainsOperator: 'Contains',
    filterNotContainsOperator: 'Does not contain',
    filterEndsWithOperator: 'Ends with',
    filterGteOperator: 'Is greater than or equal to',
    filterGtOperator: 'Is greater than',
    filterLteOperator: 'Is less than or equal to',
    filterLtOperator: 'Is less than',
    filterIsTrue: 'Is true',
    filterIsFalse: 'Is false',
    filterBooleanAll: '(All)',
    filterAfterOrEqualOperator: 'Is after or equal to',
    filterAfterOperator: 'Is after',
    filterBeforeOperator: 'Is before',
    filterBeforeOrEqualOperator: 'Is before or equal to',
    noRecords: 'No records available.',
    view: 'View',
    edit: 'Edit',
    delete: 'Delete'
  },
  filter: {
    eqOperator: 'Is equal to',
    notEqOperator: 'Is not equal to',
    isNullOperator: 'Is null',
    isNotNullOperator: 'Is not null',
    isEmptyOperator: 'Is empty',
    isNotEmptyOperator: 'Is not empty',
    startsWithOperator: 'Starts with',
    containsOperator: 'Contains',
    notContainsOperator: 'Does not contain',
    endsWithOperator: 'Ends with',
    gteOperator: 'Is greater than or equal to',
    gtOperator: 'Is greater than',
    lteOperator: 'Is less than or equal to',
    ltOperator: 'Is less than',
    isTrue: 'Is true',
    isFalse: 'Is false',
    afterOrEqualOperator: 'Is after or equal to',
    afterOperator: 'Is after',
    beforeOperator: 'Is before',
    beforeOrEqualOperator: 'Is before or equal to',
    andLogic: 'And',
    orLogic: 'Or',
    addExpression: 'Add Expression',
    addGroup: 'Add Group',
    close: 'Close'
  },
  dateinput: {
    increment: 'Increase value',
    decrement: 'Decrease value'
  },
  calendar: {
    today: 'TODAY'
  },
  datepicker: {
    toggleCalendar: 'Toggle calendar'
  },
  daterangepicker: {
    swapStartEnd: 'Swap start and end values',
    start: 'Start',
    end: 'End',
    separator: ' '
  },
  datetimepicker: {
    date: 'Date',
    time: 'Time',
    toggleDateTimeSelector: 'Toggle date-time selector',
    cancel: 'Cancel',
    set: 'Set'
  },
  editor: {
    bold: 'Bold',
    italic: 'Italic',
    underline: 'Underline',
    strikethrough: 'Strikethrough',
    subscript: 'Subscript',
    superscript: 'Superscript',
    unlink: 'Remove hyperlink',
    undo: 'Undo',
    redo: 'Redo',
    fontSize: 'Font Size',
    fontName: 'Font Name',
    format: 'Format',
    alignLeft: 'Align text left',
    alignRight: 'Align text right',
    alignCenter: 'Center text',
    alignJustify: 'Justify',
    indent: 'Indent',
    outdent: 'Outdent',
    orderedList: 'Insert ordered list',
    bulletList: 'Insert unordered list',
    createTable: 'Create a table',
    insertTableHint: 'Create a {0} x {1} table',
    addRowBefore: 'Add row above',
    addRowAfter: 'Add row below',
    addColumnBefore: 'Add column on the left',
    addColumnAfter: 'Add column on the right',
    deleteRow: 'Delete row',
    deleteColumn: 'Delete column',
    deleteTable: 'Delete table',
    mergeCells: 'Merge cells',
    splitCell: 'Split cell',
    hyperlink: 'Insert hyperlink',
    'hyperlink-dialog-title': 'Insert hyperlink',
    'hyperlink-dialog-content-address': 'Web address',
    'hyperlink-dialog-content-title': 'Title',
    'hyperlink-dialog-content-newwindow': 'Open link in new window',
    'hyperlink-dialog-cancel': 'Cancel',
    'hyperlink-dialog-insert': 'Insert',
    image: 'Insert image',
    'image-dialog-title': 'Insert image',
    'image-address': 'Web address',
    'image-title': 'Title',
    'image-altText': 'Alternate text',
    'image-width': 'Width (px)',
    'image-height': 'Height (px)',
    'image-cancel': 'Cancel',
    'image-insert': 'Insert',
    viewHtml: 'View HTML',
    'viewHtml-dialog-title': 'View HTML',
    'viewHtml-cancel': 'Cancel',
    'viewHtml-update': 'Update'
  },
  timepicker: {
    now: 'NOW',
    selectNow: 'Select Now',
    cancel: 'Cancel',
    set: 'Set',
    toggleTimeSelector: 'Toggle TimeSelector',
    toggleClock: 'Toggle Clock'
  },
  dropdowns: {
    nodata: 'NO DATA FOUND.',
    clear: 'Clear'
  },
  numerictextbox: {
    increment: 'Increase value',
    decrement: 'Decrease value'
  },
  upload: {
    cancel: 'Cancel',
    clearSelectedFiles: 'Clear',
    dropFilesHere: 'Drop files here to upload',
    headerStatusUploaded: 'Done',
    headerStatusUploading: 'Uploading...',
    invalidFileExtension: 'File type not allowed.',
    invalidFiles: 'Invalid file(s). Please check file upload requirements.',
    invalidMaxFileSize: 'File size too large.',
    invalidMinFileSize: 'File size too small.',
    remove: 'Remove',
    retry: 'Retry',
    select: 'Select files...',
    uploadSelectedFiles: 'Upload',
    total: 'Total',
    files: 'files'
  },
  sortable: {
    noData: 'No Data'
  },
  pager: {
    itemsPerPage: 'items per page',
    info: '{0} - {1} of {2} items',
    firstPage: 'Go to the first page',
    previousPage: 'Go to the previous page',
    nextPage: 'Go to the next page',
    lastPage: 'Go to the last page',
    page: 'Page',
    of: 'of'
  },
  treelist: {
    filterClearButton: 'Clear',
    filterEqOperator: 'Is equal to',
    filterNotEqOperator: 'Is not equal to',
    filterIsNullOperator: 'Is null',
    filterIsNotNullOperator: 'Is not null',
    filterIsEmptyOperator: 'Is empty',
    filterIsNotEmptyOperator: 'Is not empty',
    filterStartsWithOperator: 'Starts with',
    filterContainsOperator: 'Contains',
    filterNotContainsOperator: 'Does not contain',
    filterEndsWithOperator: 'Ends with',
    filterGteOperator: 'Is greater than or equal to',
    filterGtOperator: 'Is greater than',
    filterLteOperator: 'Is less than or equal to',
    filterLtOperator: 'Is less than',
    filterIsTrue: 'Is true',
    filterIsFalse: 'Is false',
    filterBooleanAll: '(All)',
    filterAfterOrEqualOperator: 'Is after or equal to',
    filterAfterOperator: 'Is after',
    filterBeforeOperator: 'Is before',
    filterBeforeOrEqualOperator: 'Is before or equal to',
    noRecords: 'No records available'
  },
  scheduler: {
    editorValidationRequired: 'Field is required.',
    editorValidationStart: 'Start time must be be before End time.',
    editorValidationEnd: 'End time must be after Start time.',
    allEvents: 'All events',
    allDay: 'all day',
    today: 'Today',
    dayViewTitle: 'Day',
    monthViewTitle: 'Month',
    weekViewTitle: 'Week',
    workWeekViewTitle: 'Work Week',
    timelineViewTitle: 'Timeline',
    agendaViewTitle: 'Agenda',
    deleteTitle: 'Delete',
    previousTitle: 'Previous',
    nextTitle: 'Next',
    showFullDay: 'Show full day',
    showWorkDay: 'Show business hours',
    editorOccurrence: 'Edit current occurrence',
    editorSeries: 'Edit the series',
    editorRecurringConfirmation: 'Do you want to edit only this event occurrence or the whole series?',
    editorRecurringDialogTitle: 'Edit Recurring Item',
    editorSave: 'Save',
    editorCancel: 'Cancel',
    editorDelete: 'Delete',
    editorTitle: 'Event',
    editorEventTitle: 'Title',
    editorEventStart: 'Start',
    editorEventStartTimeZone: 'Start Time Zone',
    editorEventEnd: 'End',
    editorEventEndTimeZone: 'End Time Zone',
    editorEventAllDay: 'All Day Event',
    editorEventDescription: 'Description',
    editorEventSeparateTimeZones: 'End in different Time Zone',
    editorEventTimeZone: 'Specify Time Zone',
    recurrenceEditorRepeat: 'Repeat',
    recurrenceEditorDailyInterval: 'day(s)',
    recurrenceEditorDailyRepeatEvery: 'Repeat every',
    recurrenceEditorWeeklyInterval: 'week(s)',
    recurrenceEditorWeeklyRepeatEvery: 'Repeat every',
    recurrenceEditorWeeklyRepeatOn: 'Repeat on',
    recurrenceEditorMonthlyDay: 'Day',
    recurrenceEditorMonthlyInterval: 'month(s)',
    recurrenceEditorMonthlyRepeatEvery: 'Repeat every',
    recurrenceEditorMonthlyRepeatOn: 'Repeat on',
    recurrenceEditorYearlyOf: 'of',
    recurrenceEditorYearlyRepeatEvery: 'Repeat every',
    recurrenceEditorYearlyRepeatOn: 'Repeat on',
    recurrenceEditorYearlyInterval: 'year(s)',
    recurrenceEditorFrequenciesDaily: 'Daily',
    recurrenceEditorFrequenciesMonthly: 'Monthly',
    recurrenceEditorFrequenciesNever: 'Never',
    recurrenceEditorFrequenciesWeekly: 'Weekly',
    recurrenceEditorFrequenciesYearly: 'Yearly',
    recurrenceEditorOffsetPositionsFirst: 'First',
    recurrenceEditorOffsetPositionsSecond: 'Second',
    recurrenceEditorOffsetPositionsThird: 'Third',
    recurrenceEditorOffsetPositionsFourth: 'Fourth',
    recurrenceEditorOffsetPositionsLast: 'Last',
    recurrenceEditorWeekdaysDay: 'Day',
    recurrenceEditorWeekdaysWeekday: 'Weekday',
    recurrenceEditorWeekdaysWeekendday: 'Weekend Day',
    recurrenceEditorEndAfter: 'After',
    recurrenceEditorEndOccurrence: 'occurrence(s)',
    recurrenceEditorEndLabel: 'End',
    recurrenceEditorEndNever: 'Never',
    recurrenceEditorEndOn: 'On',
    deleteConfirmation: 'Are you sure you want to delete this event?',
    deleteRecurringConfirmation: 'Do you want to delete only this event occurrence or the whole series?',
    deleteOccurrence: 'Delete current occurrence',
    deleteSeries: 'Delete the series',
    deleteDialogTitle: 'Delete Event',
    deleteRecurringDialogTitle: 'Delete Recurring Item',
    dateTitle: 'Date',
    timeTitle: 'Time',
    eventTitle: 'Event',
    noEvents: 'no events'
  },
  notifications: {
    notifications: 'Notifications',
    notification: 'Notification',
    creationDate: 'Creation date',
    visibleFrom: 'Announce from',
    deleteNotification: 'Delete notification',
    deleteNotificationConfirmation: 'Are you sure you want to delete this notification?',
    faculties: 'Faculties',
    facultiesAll: 'Faculties',
    studyPrograms: 'Study programs',
    studyProgramsAll: 'Study programs',
    studyYears: 'Study years',
    studyYearsAll: 'Study years',
    studyStatuses: 'Study statuses',
    studyStatusesAll: 'Study statuses',
    title: 'Title',
    text: 'Text',
    deleted: 'Deleted'
  },
  myNotifications: {
    noRecords: 'Currently there are no notifications.'
  },
  designations: {
    designations: 'Designations',
    designation: 'Designation',
    date: 'Date',
    cycle: 'Cycle',
    credits: 'Credits',
    statusHistory: 'Status history'
  }
};
