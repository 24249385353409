export default {
  LOAD_COMPETENCY_REQUEST_SUMMARY_START: 'LoadCompetencyRequestSummaryStart',
  LOAD_COMPETENCY_REQUEST_SUMMARY_END: 'LoadCompetencyRequestSummaryEnd',

  SUBMIT_COMPETENCY_REQUEST_START: 'SubmitCompetencyRequestStart',
  SUBMIT_COMPETENCY_REQUEST_END: 'SubmitCompetencyRequestEnd',

  TOGGLE_COMPETENCY_REQUEST_RETURN_DIALOG: 'ToggleCompetencyReqeustReturnDialog',
  EXPAND_COMPETENCY_REQUEST_SUMMARY_SURVEY_QUESTION: 'ExpandCompetencyRequestSummarySurveyQuestion',
  CLEAR_COMPETENCY_REQUEST_SUMMARY_PAGE: 'ClearCompetencyRequestSummaryPage',

  GET_COMPETENCY_REPORT_START: 'GetCompetencyReportStart',
  GET_COMPETENCY_REPORT_END: 'GetCompetencyReportEnd',

  TOGGLE_COMPETENCY_GRANT_CONFIRM_DIALOG: 'ToggleCompetencyGrantConfirmDialog',

  INIT_SIGNING_START: 'InitSigningStart',
  INIT_SIGNING_END: 'InitSigningEnd'
};