import CycleOverviewPageActionTypes from './cycle-overview-page-action-types';
import axios from 'axios';

export const loadCycleOverviewPage = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: CycleOverviewPageActionTypes.LOAD_CYCLE_OVERVIEW_PAGE_START });
  const state = getState().cycleOverviewPage;
  try {
    state.cycleCancelToken?.cancel();
    state.cycleCancelToken = axios.CancelToken.source();
    const cycle = await api.get(`api/cycles/${payload.cycleId}`, null, state.cycleCancelToken.token);
    const usersStudyPrograms = await api.get(`api/study_programs`, state.cycleCancelToken.token);
    dispatch({
      type: CycleOverviewPageActionTypes.LOAD_CYCLE_OVERVIEW_PAGE_END,
      payload: {
        cycle: cycle,
        canWrite: usersStudyPrograms?.data?.some(element => element.id === cycle.studyProgramId)
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: CycleOverviewPageActionTypes.LOAD_CYCLE_OVERVIEW_PAGE_END });
      throw error;
    }
  }
};

export const selectCycleOverviewPageTab = (payload) => async(dispatch, getState, { api }) => {
  dispatch({
    type: CycleOverviewPageActionTypes.SELECT_CYCLE_OVERVIEW_PAGE_TAB,
    payload: {
      selected: payload.selected
    }
  });
};
