import { MENU_ITEMS } from '../menu-items';
import AppPageActionTypes from './app-page-action-types';
import { ROLE_TYPES } from '../../../resources/roleType';
import ForbiddenError from '../../../utils/forbidden-error';
import { STUDENT_STATUSES } from '../../../resources/studentStatus';
import { SCREEN_SIZES } from '../../../resources/screenSize';
import { ROLE_PERMISSIONS } from '../../../resources/rolePermissions';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { SIGNALR_METHODS } from '../../../resources/signalRMethods';
import axios from 'axios';

const loadUserDrawerItems = (dispatch, localizationService, user, userStudyPrograms, currentStudies) => {
  const studyProgramItems = userStudyPrograms?.map(item => ({
    studyProgramId: item.id,
    studyProgramName: item.studyProgram,
    studentId: item.studentId,
    status: localizationService.toLanguageString(`studentStatus.${item.status}`),
    selected: currentStudies?.studyProgramId === item.id && currentStudies?.status === item.status,
    isSubItem: true
  })) ?? [];
  const userDrawerItems = [];
  if (window.innerWidth < SCREEN_SIZES.XS && user.email) {
    userDrawerItems.push({ title: user.email, disabled: true, fontSize: '8pt' });
  }
  userDrawerItems.push(
    { title: localizationService.toLanguageString('userDrawer.studyPrograms'), disabled: true, iconSvg: 'studyProgram-icon' },
    { separator: true },
    ...studyProgramItems
  );
  userDrawerItems.push(
    { separator: true },
    { title: localizationService.toLanguageString('userDrawer.logOut'), actionMethod: () => dispatch(deauthorize()), isKendoIcon: true, iconSvg: 'k-i-logout' }
  );
  return userDrawerItems;
};

export const loadApplication = (payload) => async(dispatch, getState, { api, authorizationService, history }) => {
  dispatch({ type: AppPageActionTypes.START_APPLICATION_LOADING });
  await authorizationService.authorize();
  if (authorizationService.isAuthorized()) {
    dispatch(loadUserData(payload));
  } else {
    authorizationService.redirect();
  }
};

export const loadUserData = (payload) => async(dispatch, getState, { api }) => {
  const { localizationService, isUserImpersonated } = payload;
  try {
    let [user, permissions] = await Promise.all([
      api.get(`api/users/current`),
      api.get(`api/users/current/permissions`)
    ]);
    const userStudyPrograms = await api.get(`api/users/${user.id}/study_programs`);
    const currentStudies = userStudyPrograms?.find(element => element.status === STUDENT_STATUSES.STUDYING) || userStudyPrograms?.[0];
    const menuItems = MENU_ITEMS.reduce((menus, menu) => {
      if ((!user.roles.includes(ROLE_TYPES.ADMINISTRATOR) && permissions.permissions?.includes(menu.permission)) ||
        (user.roles.includes(ROLE_TYPES.ADMINISTRATOR) && ((menu.permission != ROLE_PERMISSIONS.MY_STUDIES_READ && menu.permission != ROLE_PERMISSIONS.MY_COMPETENCIES_READ) || permissions.isStudent))) {
        if (!menu.parentId) {
          menus.push({ separator: true });
        }
        menus.push(menu);
      }
      return menus;
    }, []);

    const mappedMenuItems = menuItems.map(element => {
      return element;
    });
    const userDrawerItems = loadUserDrawerItems(dispatch, localizationService, user, userStudyPrograms, currentStudies);
    dispatch({
      type: AppPageActionTypes.UPDATE_USER_INFO,
      payload: {
        userEmail: user.email,
        userInitials: user.initials,
        isUserImpersonated,
        userId: user.id,
        userPermissions: permissions.permissions,
        userMenuItems: mappedMenuItems,
        userDrawerItems,
        userStudyPrograms,
        currentStudies: currentStudies
          ? {
            studyProgramId: currentStudies.id,
            studyProgramName: currentStudies.studyProgram,
            studentId: currentStudies.studentId,
            status: currentStudies.status
          }
          : null,
        userIsAdministrator: permissions.isAdministrator,
        userAdministratedStudyPrograms: permissions.studyProgramIds
      }
    });
    dispatch(createRequestsHubConnection());
  } catch (error) {
    dispatch({
      type: AppPageActionTypes.ERROR,
      payload: {
        isError: true,
        errorMessage: null,
        errorResource: 'authorization.userNotFound',
        errorMessageButtonTextResource: 'authorization.loginAgain'
      }
    });
  }
  dispatch({ type: AppPageActionTypes.END_APPLICATION_LOADING });
};

export const updateUserMenuItems = (payload) => (dispatch) => {
  const { userMenuItems } = payload;
  dispatch({
    type: AppPageActionTypes.UPDATE_USER_MENU_ITEMS,
    payload: {
      userMenuItems
    }
  });
};

export const updateUserDrawerItems = (payload) => (dispatch) => {
  const { userDrawerItems } = payload;
  dispatch({
    type: AppPageActionTypes.UPDATE_USER_DRAWER_ITEMS,
    payload: {
      userDrawerItems
    }
  });
};

export const logoutFromImpersonate = (payload) => async(dispatch, getState, { api, history }) => {
  try {
    api.clearImpersonatedUserHeader();
    dispatch(loadApplication(payload));
    history.push('/');
  } catch (error) {
    throw error;
  }
};

export const canWriteStudyProgram = (payload, app) => {
  const { userAdministratedStudyPrograms, userIsAdministrator } = app;
  return userAdministratedStudyPrograms?.includes(payload) || userIsAdministrator;
};

export const deauthorize = () => (dispatch, getState, { authorizationService, history }) => {
  history.replace('/');
  authorizationService.logout();
};

export const showError = (payload) => (dispatch, getState, { history }) => {
  const { errorMessage, reason } = payload;
  if (reason instanceof ForbiddenError) {
    history.push('/forbidden');
  } else {
    dispatch({
      type: AppPageActionTypes.ERROR,
      payload: {
        isError: true,
        errorMessage,
        errorResource: null,
        errorMessageButtonText: null,
        errorMessageButtonTextResource: null
      }
    });
  }
};

export const handleStudyProgramChange = (payload) => (dispatch) => {
  dispatch({
    type: AppPageActionTypes.HANDLE_STUDY_PROGRAM_CHANGE,
    payload: { currentStudies: payload.studyProgram }
  });
};

export const createRequestsHubConnection = () => async(dispatch, getState) => {
  const connection = new HubConnectionBuilder().withUrl(`/request_count`).withAutomaticReconnect().build();
  await connection.start();
  connection.on(SIGNALR_METHODS.CLIENT.SET_CYCLE_EVALUATION_REQUEST_COUNT, message => {
    const state = getState().app;
    if (state.userId === message?.userId) {
      dispatch({
        type: AppPageActionTypes.UPDATE_REQUESTS_HUB_DATA,
        payload: {
          requestsHubData: {
            ...state.requestsHubData,
            cycleEvaluationRequestCount: message.requestCount
          }
        }
      });
    }
  });
  connection.on(SIGNALR_METHODS.CLIENT.SET_BATCHED_CYCLE_EVALUATION_REQUEST_COUNT, message => {
    const state = getState().app;
    const cycleEvaluationRequestCount = message?.find(element => state.userId === element.userId)?.requestCount;
    if (cycleEvaluationRequestCount >= 0) {
      dispatch({
        type: AppPageActionTypes.UPDATE_REQUESTS_HUB_DATA,
        payload: {
          requestsHubData: {
            ...state.requestsHubData,
            cycleEvaluationRequestCount
          }
        }
      });
    }
  });
  connection.on(SIGNALR_METHODS.CLIENT.SET_SURVEY_REQUEST_COUNT, message => {
    const state = getState().app;
    if (state.userId === message?.userId) {
      dispatch({
        type: AppPageActionTypes.UPDATE_REQUESTS_HUB_DATA,
        payload: {
          requestsHubData: {
            ...state.requestsHubData,
            surveyRequestCount: message.requestCount
          }
        }
      });
    }
  });
  connection.on(SIGNALR_METHODS.CLIENT.SET_COMPETENCY_REQUEST_COUNT, message => {
    const state = getState().app;
    if (state.userId === message?.userId) {
      dispatch({
        type: AppPageActionTypes.UPDATE_REQUESTS_HUB_DATA,
        payload: {
          requestsHubData: {
            ...state.requestsHubData,
            competencyRequestCount: message.requestCount
          }
        }
      });
    }
  });
  connection.on(SIGNALR_METHODS.CLIENT.SET_STUDENT_CYCLE_CASE_AND_SKILL_APPROVAL_REQUEST_COUNT, message => {
    const state = getState().app;
    if (state.userId === message?.userId) {
      dispatch({
        type: AppPageActionTypes.UPDATE_REQUESTS_HUB_DATA,
        payload: {
          requestsHubData: {
            ...state.requestsHubData,
            studentCycleCaseAndSkillApprovalRequestCount: message.requestCount
          }
        }
      });
    }
  });
  connection.on(SIGNALR_METHODS.CLIENT.SEVER_CONNECTION, () => {
    connection.stop();
  });
  connection.on(SIGNALR_METHODS.CLIENT.SET_UNREAD_NOTIFICATIONS_COUNT, message => {
    const state = getState().app;
    if (state.userId === message?.userId) {
      dispatch({
        type: AppPageActionTypes.UPDATE_REQUESTS_HUB_DATA,
        payload: {
          requestsHubData: {
            ...state.requestsHubData,
            unreadNotificationsCount: message.unreadNotificationsCount
          }
        }
      });
    }
  });
  connection.on(SIGNALR_METHODS.CLIENT.SET_UNREAD_NOTIFICATIONS_COUNT_INVALIDATED, () => {
    const state = getState().app;
    const { requestsHubConnection, userId } = state;
    if (requestsHubConnection) {
      requestsHubConnection.send(SIGNALR_METHODS.SERVER.REQUESTS_HUB.SEND_USER_UNREAD_NOTIFICATIONS_COUNT, userId, axios.CancelToken.source().token);
    }
  });
  const state = getState().app;
  connection.send(SIGNALR_METHODS.SERVER.REQUESTS_HUB.SEND_CYCLE_EVALUATION_REQUEST_COUNT, state.userId, axios.CancelToken.source().token);
  connection.send(SIGNALR_METHODS.SERVER.REQUESTS_HUB.SEND_SURVEY_REQUEST_COUNT, state.userId, axios.CancelToken.source().token);
  connection.send(SIGNALR_METHODS.SERVER.REQUESTS_HUB.SEND_COMPETENCY_REQUEST_COUNT, state.userId, axios.CancelToken.source().token);
  connection.send(SIGNALR_METHODS.SERVER.REQUESTS_HUB.SEND_STUDENT_CYCLE_CASE_AND_SKILL_APPROVAL_REQUEST_COUNT, state.userId, axios.CancelToken.source().token);
  connection.send(SIGNALR_METHODS.SERVER.REQUESTS_HUB.SEND_USER_UNREAD_NOTIFICATIONS_COUNT, state.userId, axios.CancelToken.source().token);
  dispatch({
    type: AppPageActionTypes.SET_REQUESTS_HUB_CONNECTION,
    payload: {
      requestsHubConnection: connection
    }
  });
};

export const fetchRequestsData = () => (dispatch, getState) => {
  const state = getState().app;
  const connection = state.requestHubConnection;
  if (connection) {
    connection.send(SIGNALR_METHODS.SERVER.REQUESTS_HUB.SEND_CYCLE_EVALUATION_REQUEST_COUNT, state.userId, axios.CancelToken.source().token);
    connection.send(SIGNALR_METHODS.SERVER.REQUESTS_HUB.SEND_SURVEY_REQUEST_COUNT, state.userId, axios.CancelToken.source().token);
    connection.send(SIGNALR_METHODS.SERVER.REQUESTS_HUB.SEND_COMPETENCY_REQUEST_COUNT, state.userId, axios.CancelToken.source().token);
    connection.send(SIGNALR_METHODS.SERVER.REQUESTS_HUB.SEND_STUDENT_CYCLE_CASE_AND_SKILL_APPROVAL_REQUEST_COUNT, state.userId, axios.CancelToken.source().token);
    connection.send(SIGNALR_METHODS.SERVER.REQUESTS_HUB.SEND_USER_UNREAD_NOTIFICATIONS_COUNT, state.userId, axios.CancelToken.source().token);
  }
};