import React from 'react';
import { connect } from 'react-redux';
import { provideLocalizationService, registerForIntl, registerForLocalization } from '@progress/kendo-react-intl';
import { Grid, GridSize } from '../../components';
import { loadStudentCompetencySurveyList } from '../../student-competency/actions/student-competency-survey-list-actions';
import { withRouter } from '../../../../components/withRouter';
import styled from 'styled-components';
import { Colors, Text } from '../../../../ui';
import { STUDENT_COMPETENCY_STATUS } from '../../../../resources/studentCompetencyStatus';

const CellEvaluationContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const EvaluationWrapper = styled.span`
  white-space: nowrap;
`;

class StudentCompetencySurveyList extends React.Component {
  constructor(props) {
    super(props);
    this.renderEvaluation = this.renderEvaluation.bind(this);
  }

  async componentDidMount() {
    if (this.props.competencyId > 0) {
      await this.props.loadStudentCompetencySurveyList({
        competencyId: this.props.competencyId,
        studyProgramId: this.props.studyProgramId,
        studentId: this.props.studentId
      });
    }
  }

  render() {
    const { studentCompetencySurveys, loading, handleRequestEvaluationClick, competencyStatus } = this.props;
    const localizationService = provideLocalizationService(this);
    const canResidentRequestEvaluation = competencyStatus === STUDENT_COMPETENCY_STATUS.ACQUIRED || competencyStatus === STUDENT_COMPETENCY_STATUS.NOT_APPROVED;
    return (
      <Grid
        loading={loading}
        data={studentCompetencySurveys}
        isPageable={false}
        onRowClick={this.props.onRowClick}
        columns={[
          {
            field: 'surveyCode',
            className: 'align-left',
            headerClassName: 'align-left',
            title: localizationService.toLanguageString('studentCompetency.code'),
            minGridWidth: GridSize.small
          }, {
            field: 'surveyName',
            className: 'align-left',
            headerClassName: 'align-left',
            title: localizationService.toLanguageString('studentCompetency.name')
          }, {
            field: 'evaluation',
            className: 'align-center',
            headerClassName: 'align-center',
            title: localizationService.toLanguageString('studentCompetency.evaluation2'),
            cell: this.renderEvaluation
          }, {
            field: 'surveyType',
            className: 'align-left',
            headerClassName: 'align-left',
            title: localizationService.toLanguageString('studentCompetency.type'),
            minGridWidth: GridSize.medium,
            cell: ({ className, dataItem }) => (
              <td className={className}>
                {localizationService.toLanguageString(`surveyTypeAbbreviation.${dataItem.surveyType}`)}
              </td>
            )
          }
        ]}
        actions={!this.props.preview && [
          { label: localizationService.toLanguageString('studentCompetency.request'), onClick: handleRequestEvaluationClick, disabledOn: (e) => canResidentRequestEvaluation || e.dataItem.questionCount === 0 }
        ]}
      />
    );
  }

  renderEvaluation({ className, dataItem: { completedEvaluationCount, minCount, pendingEvaluationCount } }) {
    const localizationService = provideLocalizationService(this);
    return (
      <td className={className}>
        <CellEvaluationContainer>
          <EvaluationWrapper>
            <Text variant='body2' textColor={minCount > 0 && completedEvaluationCount < minCount ? Colors.ERROR_40 : Colors.BLACK}>{completedEvaluationCount}</Text>
            {` ${localizationService.toLanguageString('conjunctions.outOf')} ${minCount}`}
          </EvaluationWrapper>
          <EvaluationWrapper>
            {`${localizationService.toLanguageString('studentCompetency.submitted')}: ${pendingEvaluationCount}`}
          </EvaluationWrapper>
        </CellEvaluationContainer>
      </td>
    );
  }
}

const mapStateToProps = (state) => ({
  studentCompetencySurveys: state.studentCompetencySurveyList.studentCompetencySurveys,
  loading: state.studentCompetencySurveyList.loading
});

const mapDispatchToProps = (dispatch) => ({
  loadStudentCompetencySurveyList: (payload) => dispatch(loadStudentCompetencySurveyList(payload))
});

registerForIntl(StudentCompetencySurveyList);
registerForLocalization(StudentCompetencySurveyList);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StudentCompetencySurveyList));
