import axios from 'axios';
import CompetencyFormPageActionTypes from './competency-form-page-action-types';
import ValidationError from '../../../utils/validation-error';

export const loadCompetencyFormPage = ({ competencyId }) => async(dispatch, getState, { api }) => {
  dispatch({ type: CompetencyFormPageActionTypes.LOAD_COMPETENCY_FORM_PAGE_START });
  try {
    if (competencyId > 0) {
      const competency = await api.get(`api/competencies/${competencyId}`);
      dispatch({
        type: CompetencyFormPageActionTypes.LOAD_COMPETENCY_FORM_PAGE_END,
        payload: {
          competency: {
            ...competency,
            studyProgram: competency.studyProgramId ? { id: competency.studyProgramId, name: competency.studyProgramName } : null
          }
        }
      });
    } else {
      dispatch({ type: CompetencyFormPageActionTypes.LOAD_COMPETENCY_FORM_PAGE_END });
    }
  } catch (error) {
    dispatch({ type: CompetencyFormPageActionTypes.LOAD_COMPETENCY_FORM_PAGE_END });
    throw error;
  }
  dispatch(loadStudyPrograms());
};

export const loadStudyPrograms = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: CompetencyFormPageActionTypes.LOAD_COMPETENCY_AVAILABLE_STUDY_PROGRAMS_START });
  try {
    const state = getState().competencyFormPage;
    state.availableStudyProgramsCancelToken?.cancel();
    state.availableStudyProgramsCancelToken = axios.CancelToken.source();
    const query = {
      skip: 0,
      limit: 50,
      keyword: payload?.keyword
    };
    const studyPrograms = await api.get(`api/study_programs`, query, state.availableStudyProgramsCancelToken.token);
    dispatch({
      type: CompetencyFormPageActionTypes.LOAD_COMPETENCY_AVAILABLE_STUDY_PROGRAMS_END,
      payload: {
        studyPrograms: studyPrograms ? studyPrograms.data : []
      }
    });
  } catch (error) {
    dispatch({
      type: CompetencyFormPageActionTypes.LOAD_COMPETENCY_AVAILABLE_STUDY_PROGRAMS_END
    });
    throw error;
  }
};

export const saveCompetency = (payload) => async(dispatch, getState, { api, history }) => {
  dispatch({ type: CompetencyFormPageActionTypes.LOAD_COMPETENCY_FORM_PAGE_START });
  const state = getState().competencyFormPage;
  const apiModel = {
    ...payload,
    studyProgramId: payload.studyProgram ? payload.studyProgram.id : null
  };
  let id = payload?.id;
  try {
    if (id > 0) {
      await api.put(`api/competencies/${id}`, apiModel);
    } else {
      id = await api.post('api/competencies', apiModel);
    }
    dispatch({
      type: CompetencyFormPageActionTypes.LOAD_COMPETENCY_FORM_PAGE_END,
      payload: {
        competency: {
          ...state.competency,
          id: id
        }
      }
    });
    history.back();
  } catch (error) {
    let validationResult = null;
    if (error instanceof ValidationError) {
      validationResult = {
        errorMessage: error.message,
        errors: error.errors
      };
    }
    dispatch({
      type: CompetencyFormPageActionTypes.LOAD_COMPETENCY_FORM_PAGE_END,
      payload: {
        validationResult,
        competency: state.competency
      }
    });
  }
};

export const cancelCompetencyFormPage = () => async(dispatch, getState) => {
  const state = getState().competencyFormPage;
  state.availableStudyProgramsCancelToken?.cancel();
  state.cancellationTokenSources?.forEach(token => {
    token?.cancel();
  });
};