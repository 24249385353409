import AppPageActionTypes from '../actions/app-page-action-types';

const initialState = {
  loading: true,
  userEmail: null,
  userInitials: null,
  isUserImpersonated: false,
  userId: null,
  userPermissions: [],
  userAdministratedStudyPrograms: [],
  userIsAdministrator: false,
  userMenuItems: [],
  userDrawerItems: [],
  userStudyPrograms: [],
  currentStudies: null,
  requestsHubData: {
    cycleEvaluationRequestCount: 0,
    surveyRequestCount: 0,
    competencyRequestCount: 0,
    studentCycleCaseAndSkillApprovalRequestCount: 0,
    unreadNotificationsCount: 0
  },
  requestsHubConnection: null,
  isError: false,
  errorMessage: null,
  errorMessageResource: null,
  errorMessageButtonText: null,
  errorMessageButtonTextResource: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AppPageActionTypes.START_APPLICATION_LOADING:
      return {
        ...state,
        loading: true
      };
    case AppPageActionTypes.END_APPLICATION_LOADING:
      return {
        ...state,
        loading: false
      };
    case AppPageActionTypes.UPDATE_USER_INFO:
      return {
        ...state,
        userEmail: action.payload?.userEmail,
        userInitials: action.payload?.userInitials,
        isUserImpersonated: action.payload?.isUserImpersonated,
        userId: action.payload?.userId,
        userPermissions: action.payload?.userPermissions,
        userMenuItems: action.payload?.userMenuItems,
        userDrawerItems: action.payload?.userDrawerItems,
        userStudyPrograms: action.payload?.userStudyPrograms,
        currentStudies: action.payload?.currentStudies,
        userAdministratedStudyPrograms: action.payload?.userAdministratedStudyPrograms,
        userIsAdministrator: action.payload?.userIsAdministrator
      };
    case AppPageActionTypes.ERROR:
      return {
        ...state,
        isError: action.payload?.isError,
        errorMessage: action.payload?.errorMessage
      };
    case AppPageActionTypes.HANDLE_STUDY_PROGRAM_CHANGE:
      return {
        ...state,
        currentStudies: action.payload?.currentStudies
      };
    case AppPageActionTypes.UPDATE_USER_MENU_ITEMS:
      return {
        ...state,
        userMenuItems: action.payload?.userMenuItems
      };
    case AppPageActionTypes.UPDATE_USER_DRAWER_ITEMS:
      return {
        ...state,
        userDrawerItems: action.payload?.userDrawerItems
      };
    case AppPageActionTypes.UPDATE_REQUESTS_HUB_DATA:
      return {
        ...state,
        requestsHubData: action.payload?.requestsHubData
      };
    case AppPageActionTypes.SET_REQUESTS_HUB_CONNECTION:
      return {
        ...state,
        requestsHubConnection: action.payload?.requestsHubConnection
      };
    default:
      return state;
  }
};