import studyPlanOverviewPageActionTypes from '../actions/study-plan-overview-page-action-types';

const initialState = {
  loading: false,
  studyPlan: null,
  studyPlanCancelToken: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case studyPlanOverviewPageActionTypes.LOAD_STUDY_PLAN_OVERVIEW_PAGE_START:
      return {
        ...state,
        loading: true
      };
    case studyPlanOverviewPageActionTypes.LOAD_STUDY_PLAN_OVERVIEW_PAGE_END:
      return {
        ...state,
        loading: false,
        studyPlan: action.payload
      };
    case studyPlanOverviewPageActionTypes.APPROVE_STUDY_PLAN_START:
      return {
        ...state,
        loading: true
      };
    case studyPlanOverviewPageActionTypes.APPROVE_STUDY_PLAN_END:
      return {
        ...state,
        loading: false,
        studyPlan: action.payload
      };
    default:
      return state;
  }
};