import * as React from 'react';
import { ListPage } from '../../../ui';
import { ManagedStudentListFilter, ManagedStudentList } from './index';

class ManagedStudentListPage extends React.PureComponent {
  render() {
    return (
      <ListPage filter={<ManagedStudentListFilter/>}>
        <ManagedStudentList/>
      </ListPage>
    );
  }
}

export default ManagedStudentListPage;