import axios from 'axios';
import TheoreticalPartStudentListActionTypes from './theoretical-part-student-list-action-types';

export const loadTheoreticalPartStudentList = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: TheoreticalPartStudentListActionTypes.LOAD_THEORETICAL_PART_STUDENT_LIST_START });
  const state = getState().theoreticalPartStudentList;
  try {
    state.theoreticalPartStudentsCancelToken?.cancel();
    state.theoreticalPartStudentsCancelToken = axios.CancelToken.source();
    const [ theoreticalPartStudents, studyPlanCycleStudents ] = await Promise.all([
      api.get(`api/theoretical_parts/${payload.theoreticalPartId}/students`, null, state.theoreticalPartStudentsCancelToken.token),
      api.get(`api/study_plans/${payload.studyPlanId}/cycles/${payload.studyPlanCycleId}/students`, null, state.theoreticalPartStudentsCancelToken.token)
    ]);
    const filteredStudyPlanCycleStudents = studyPlanCycleStudents?.data?.filter(studyPlanCycleStudent => !theoreticalPartStudents?.data?.some(theoreticalPartStudent => theoreticalPartStudent.studentId === studyPlanCycleStudent.studentId));
    const students = [];
    if (theoreticalPartStudents?.data) {
      students.push(...theoreticalPartStudents.data);
    }
    if (filteredStudyPlanCycleStudents) {
      students.push(...filteredStudyPlanCycleStudents.map(student => {
        student.name = student.studentName;
        student.surname = student.studentSurname;
        delete student['studentName'];
        delete student['studentSurname'];
        return student;
      }));
    }
    dispatch({
      type: TheoreticalPartStudentListActionTypes.LOAD_THEORETICAL_PART_STUDENT_LIST_END,
      payload: students
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: TheoreticalPartStudentListActionTypes.LOAD_THEORETICAL_PART_STUDENT_LIST_END });
      throw error;
    }
  }
};