import ComptetencySurveyQuestionListActionTypes from '../actions/competency-survey-question-list-action-types';

const initialState = {
  loading: false,
  competencySurveyQuestions: null,
  competencySurveyQuestionsCancelToken: null,

  isConfirmDialogVisible: false,
  selectedCompetencySurveyQuestionId: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ComptetencySurveyQuestionListActionTypes.LOAD_COMPETENCY_SURVEY_QUESTION_LIST_START:
      return {
        ...state,
        loading: true
      };
    case ComptetencySurveyQuestionListActionTypes.LOAD_COMPETENCY_SURVEY_QUESTION_LIST_END:
      return {
        ...state,
        loading: false,
        competencySurveyQuestions: action.payload.competencySurveyQuestions
      };
    case ComptetencySurveyQuestionListActionTypes.TOGGLE_COMPETENCY_SURVEY_QUESTION_LIST_CONFIRM_DIALOG:
      return {
        ...state,
        selectedCompetencySurveyQuestionId: action.payload?.id,
        isConfirmDialogVisible: !state.isConfirmDialogVisible
      };
    case ComptetencySurveyQuestionListActionTypes.CLEAR_COMPETENCY_SURVEY_QUESTION_LIST:
      return initialState;
    default:
      return state;
  }
};