import SubcompetencyFormPageActionTypes from './subcompetency-form-page-action-types';
import ValidationError from '../../../utils/validation-error';
import axios from 'axios';

export const loadSubcompetencyFormPage = ({ subcompetencyId }) => async(dispatch, getState, { api }) => {
  dispatch({ type: SubcompetencyFormPageActionTypes.LOAD_SUBCOMPETENCY_FORM_PAGE_START });
  try {
    if (subcompetencyId > 0) {
      const subcompetency = await api.get(`api/subcompetencies/${subcompetencyId}`);
      dispatch({
        type: SubcompetencyFormPageActionTypes.LOAD_SUBCOMPETENCY_FORM_PAGE_END,
        payload: {
          subcompetency: {
            ...subcompetency,
            group: subcompetency?.subcompetencyGroupId ? { id: subcompetency.subcompetencyGroupId, name: subcompetency.groupName } : null,
            studyProgram: subcompetency?.studyProgramId ? { id: subcompetency.studyProgramId, name: subcompetency.studyProgramName } : null
          }
        }
      });
    } else {
      dispatch({ type: SubcompetencyFormPageActionTypes.LOAD_SUBCOMPETENCY_FORM_PAGE_END });
    }
  } catch (error) {
    dispatch({ type: SubcompetencyFormPageActionTypes.LOAD_SUBCOMPETENCY_FORM_PAGE_END });
    throw error;
  }
  dispatch(loadSubcompetencyGroups());
  dispatch(loadStudyPrograms());
};

export const saveSubcompetency = (payload) => async(dispatch, getState, { api, history }) => {
  dispatch({ type: SubcompetencyFormPageActionTypes.LOAD_SUBCOMPETENCY_FORM_PAGE_START });
  const state = getState().subcompetencyFormPage;
  const apiModel = {
    ...payload,
    subcompetencyGroupId: payload?.group?.id,
    studyProgramId: payload?.studyProgram?.id
  };
  let id = payload?.id;
  try {
    if (id > 0) {
      await api.put(`api/subcompetencies/${id}`, apiModel);
    } else {
      id = await api.post(`api/subcompetencies`, apiModel);
    }
    dispatch({
      type: SubcompetencyFormPageActionTypes.LOAD_SUBCOMPETENCY_FORM_PAGE_END,
      payload: {
        subcompetency: {
          ...state.subcompetency,
          id: id
        }
      }
    });
    history.back();
  } catch (error) {
    let validationResult = null;
    if (error instanceof ValidationError) {
      validationResult = {
        errorMessage: error.message,
        errors: error.errors
      };
    }
    dispatch({
      type: SubcompetencyFormPageActionTypes.LOAD_SUBCOMPETENCY_FORM_PAGE_END,
      payload: {
        validationResult,
        subcompetency: state.subcompetency
      }
    });
  }
};

export const loadSubcompetencyGroups = () => async(dispatch, getState, { api }) => {
  dispatch({ type: SubcompetencyFormPageActionTypes.LOAD_SUBCOMPETENCY_GROUPS_START });
  try {
    const state = getState().subcompetencyFormPage;
    state.subcompetencyGroupsCancelToken?.cancel();
    state.subcompetencyGroupsCancelToken = axios.CancelToken.source();
    const subcompetencyGroups = await api.get(`api/subcompetency_groups`, null, state.subcompetencyGroupsCancelToken.token);
    dispatch({
      type: SubcompetencyFormPageActionTypes.LOAD_SUBCOMPETENCY_GROUPS_END,
      payload: {
        subcompetencyGroups: subcompetencyGroups.data
      }
    });
  } catch (error) {
    dispatch({ type: SubcompetencyFormPageActionTypes.LOAD_SUBCOMPETENCY_GROUPS_END });
    throw error;
  }
};

export const loadStudyPrograms = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: SubcompetencyFormPageActionTypes.LOAD_STUDY_PROGRAMS_START });
  try {
    const state = getState().subcompetencyFormPage;
    state.studyProgramsCancelToken?.cancel();
    state.studyProgramsCancelToken = axios.CancelToken.source();
    const query = {
      skip: 0,
      limit: 50,
      keyword: payload?.keyword
    };
    const studyPrograms = await api.get(`api/study_programs`, query);
    dispatch({
      type: SubcompetencyFormPageActionTypes.LOAD_STUDY_PROGRAMS_END,
      payload: {
        studyPrograms: studyPrograms ? studyPrograms.data : []
      }
    });
  } catch (error) {
    dispatch({
      type: SubcompetencyFormPageActionTypes.LOAD_STUDY_PROGRAMS_END
    });
    throw error;
  }
};