import DepartmentOverviewPageActionTypes from './department-overview-page-action-types';
import axios from 'axios';
import { ROLE_PERMISSIONS as Permissions } from '../../../resources/rolePermissions';

export const loadDepartmentOverviewPage = (departmentId) => async(dispatch, getState, { api }) => {
  dispatch({ type: DepartmentOverviewPageActionTypes.LOAD_DEPARTMENT_OVERVIEW_PAGE_START });
  const state = getState().departmentOverviewPage;
  try {
    state.departmentCancelToken?.cancel();
    state.departmentCancelToken = axios.CancelToken.source();
    const department = await api.get(`api/departments/${departmentId}`, null, state.departmentCancelToken.token);
    const canWrite = getState().app.userPermissions.includes(Permissions.DEPARTMENTS_WRITE);
    dispatch({
      type: DepartmentOverviewPageActionTypes.LOAD_DEPARTMENT_OVERVIEW_PAGE_END,
      payload: {
        department,
        canWrite
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: DepartmentOverviewPageActionTypes.LOAD_DEPARTMENT_OVERVIEW_PAGE_END });
      throw error;
    }
  }
};