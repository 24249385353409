import UserFormPageActionTypes from './user-form-page-action-types';
import ValidationError from '../../../utils/validation-error';
import { ROLE_TYPES as RoleTypes } from '../../../resources/roleType';

export const loadUserFormPage = ({ userId }) => async(dispatch, getState, { api }) => {
  dispatch({ type: UserFormPageActionTypes.LOAD_USER_FORM_PAGE_START });
  try {
    if (userId > 0) {
      const user = await api.get(`api/users/${userId}`);
      const roles = user.roles.reduce((acc, role) => {
        return { ...acc, [role.toLowerCase()]: true };
      }, {});
      const data = { ...roles, ...user };
      dispatch({
        type: UserFormPageActionTypes.LOAD_USER_FORM_PAGE_END,
        payload: {
          user: {
            ...data
          }
        }
      });
    } else {
      dispatch({ type: UserFormPageActionTypes.LOAD_USER_FORM_PAGE_END });
    }
  } catch (error) {
    dispatch({ type: UserFormPageActionTypes.LOAD_USER_FORM_PAGE_END });
    throw error;
  }
};

export const saveUser = (payload) => async(dispatch, getState, { api, history }) => {
  dispatch({ type: UserFormPageActionTypes.LOAD_USER_FORM_PAGE_START });
  const state = getState().userFormPage;
  const apiModel = {
    ...payload
  };
  apiModel.roles = [];
  if (apiModel.administrator) {
    apiModel.roles.push(RoleTypes.ADMINISTRATOR);
  }
  if (apiModel.mentor) {
    apiModel.roles.push(RoleTypes.MENTOR);
  }
  if (apiModel.manager) {
    apiModel.roles.push(RoleTypes.MANAGER);
  }
  let id = payload?.id;
  try {
    if (id > 0) {
      await api.put(`api/users/${id}`, apiModel);
    } else {
      id = await api.post('api/users', apiModel);
    }
    dispatch({
      type: UserFormPageActionTypes.LOAD_USER_FORM_PAGE_END,
      payload: {
        user: {
          ...state.user,
          id: id
        }
      }
    });
    history.back();
  } catch (error) {
    let validationResult = null;
    if (error instanceof ValidationError) {
      validationResult = {
        errorMessage: error.message,
        errors: error.errors
      };
    }
    dispatch({
      type: UserFormPageActionTypes.LOAD_USER_FORM_PAGE_END,
      payload: {
        validationResult,
        user: state.user
      }
    });
  }
};

export const clearUserFormPage = () => async(dispatch) => {
  dispatch({ type: UserFormPageActionTypes.CLEAR_USER_FORM_PAGE });
};