import SubcompetencyListPageActionTypes from '../actions/subcompetency-list-page-action-types';
import { pagingSettings } from '../../../utils/GridPagingUtils';

const initialState = {
  loading: false,
  subcompetencies: null,
  subcompetenciesCancelToken: null,
  filter: null,
  skip: pagingSettings.paging.skip,
  take: pagingSettings.paging.take,
  total: 0,
  sorting: null,

  isConfirmDialogVisible: false,
  selectedSubcompetency: null,

  filterStudyProgramsLoading: false,
  filterStudyProgramsCancelToken: null,
  filterStudyPrograms: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SubcompetencyListPageActionTypes.LOAD_SUBCOMPETENCY_LIST_START:
      return {
        ...state,
        loading: true
      };
    case SubcompetencyListPageActionTypes.LOAD_SUBCOMPETENCY_LIST_END:
      return {
        ...state,
        loading: false,
        subcompetencies: action.payload.subcompetencies,
        total: action.payload.total,
        skip: action.payload.skip,
        take: action.payload.take
      };
    case SubcompetencyListPageActionTypes.TOGGLE_SUBCOMPETENCY_LIST_CONFIRM_DIALOG:
      return {
        ...state,
        selectedSubcompetency: action.payload,
        isConfirmDialogVisible: !state.isConfirmDialogVisible
      };
    case SubcompetencyListPageActionTypes.FILTER_SUBCOMPETENCY_LIST:
      return {
        ...state,
        filter: action.payload,
        skip: initialState.skip,
        take: initialState.take
      };
    case SubcompetencyListPageActionTypes.SORT_SUBCOMPETENCY_LIST:
      return {
        ...state,
        sorting: action.payload
      };
    case SubcompetencyListPageActionTypes.SEARCH_SUBCOMPETENCY_LIST_FILTER_STUDY_PROGRAM_START:
      return {
        ...state,
        filterStudyProgramsLoading: true
      };
    case SubcompetencyListPageActionTypes.SEARCH_SUBCOMPETENCY_LIST_FILTER_STUDY_PROGRAM_END:
      return {
        ...state,
        filterStudyProgramsLoading: false,
        filterStudyPrograms: action.payload.studyPrograms
      };
    default:
      return state;
  }
};