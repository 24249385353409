import * as React from 'react';
import styled from 'styled-components';
import { InlineBadge, DateTimeFormatter, DecimalFormatter } from '../../components';
import { provideLocalizationService, registerForLocalization, provideIntlService, registerForIntl } from '@progress/kendo-react-intl';
import { Colors, ErrorMessage, LoadingPanel, StackLayout, Text } from '../../../../ui';
import { Button } from '@progress/kendo-react-buttons';
import { TextStyles } from '../../../../ui';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { getStudentCycleStatusColor } from '../../../../utils/studyPlanCycleDepartmentStudentStatusColors';
import { SCREEN_SIZES } from '../../../../resources/screenSize';
import colors from '../../../../ui/components/shared/colors';
import { STUDY_PLAN_CYCLE_STUDENT_APPROVING_USER_STATUS } from '../../../../resources/studyPlanCycleStudentApprovingUserStatus';
import { CYCLE_STUDENT_STATUS } from '../../../../resources/studyPlanCycleDepartmentStudentStatus';
import { getStudyPlanCycleStudentApprovingUserStatusColor } from '../../../../utils/studyPlanCycleStudentApprovingUserStatusColors';

const CompletionPageContainer = styled.div`
  margin-bottom: 76px;
`;

const TitleContainer = styled.div`
  background-color: ${colors.WHITE};
  text-align: left;
  padding: 8px;
  border: 1px solid lightgrey;
`;

const TopTitleContainer = styled(TitleContainer)`
  border-bottom-width: 0px;
`;

const RowContainer = styled.div`
  text-align: left;
  border: 1px solid lightgrey;
  border-bottom-width: ${props => props.showBottomBorder ? '1px' : '0'};
  border-top-width: ${props => props.hideTopBorder ? '0' : '1px'};
  display: flex;
  padding-left: 8px;
  background-color: ${Colors.WHITE};
  color: 'black';
`;

const CycleRowContainer = styled(RowContainer)`
  padding-left: 8px;
  border-top: 0;
  display: flex;
  align-items: center;
`;

const CycleManagersRowContainer = styled(RowContainer)`
  display: flex;
  align-items: center;
  padding-left: 8px;
`;

const CycleSubmitRowContainer = styled(RowContainer)`
  padding-left: 8px;
  border-bottom-width: 0px;
`;

const RowTextContainer = styled.div`
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
  padding-left: ${props => props.padLeft ?? '0'}
`;

const StatusContainer = styled(RowTextContainer)`
  display: flex;
  align-items: center;
`;

const BodyTextContainer = styled.span`
  color: ${Colors.GRAY_50};  
  ${TextStyles.body2};
  width: 20px;
  padding-right: 8px;
`;

const ErrorTextContainer = styled.span`
  color: ${Colors.ERROR_40};
  ${TextStyles.body2};
  width: 20px;
  padding-right: 8px;
`;

const GreyTextContainer = styled.span`
  color: ${Colors.GRAY_40};  
  ${TextStyles.body2};
  width: 20px;
  padding-right: 8px;
`;

const CaptionText = styled.span`
  color: ${Colors.GRAY_50};
  ${TextStyles.caption};
  padding-right: 8px;
`;

const GreyCaptionText = styled(CaptionText)`
  color: ${Colors.GRAY_40};
`;

const TextContainer = styled.span`
  ${TextStyles.body1};
  padding-right: 8px;
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  height: 36px;
  padding-right: 32px
`;

const ListButton = styled(Button)`
  height: 80%;
`;

const TitleText = styled(Text)`
  padding-right: 8px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TooltipContainer = styled(Tooltip)`
  width: 16rem;
`;

const Icon = styled.span`
  padding-right: 8px;
`;

const StatusHistoryRowContainer = styled(RowContainer)`
  cursor: pointer;
`;

const SurveyInfoContainer = styled.div`
  padding-left: ${props => props.paddingLeft ?? '24px'}
`;

const StatusCommentTextContainer = styled(BodyTextContainer)`
  margin-left: 8px;
  color: ${Colors.BLACK};
`;

const CircleIcon = styled.span`
  color: ${Colors.GRAY_40};
  transform: scale(0.75);
`;

const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 32px;
`;

export default class StudentCycleCompletionPage extends React.PureComponent {

  constructor() {
    super();
  }

  async componentDidMount() {
    await this.props.onLoadStudentCycleCompletion();
  }

  componentWillUnmount() {
    this.props.onStatusHistoryClose();
  }

  render() {
    const localizationService = provideLocalizationService(this);
    const intlService = provideIntlService(this);
    const hasOnlySingleForeignDepartment = this.props.availableDepartments.length === 1 && this.props.availableDepartments[0].isForeign;
    const canSubmit = !this.props.readOnly && (
      hasOnlySingleForeignDepartment ||
      this.props.managerSurveys?.length > 0 && this.props.managerSurveys.every(survey => survey.evaluationDate != null) && this.props.cycleDepartmentSurveys?.length > 0 && this.props.cycleDepartmentSurveys.every(survey => survey.evaluationDate != null)
    );
    const confirmDialogWidth = window.innerWidth > SCREEN_SIZES.XS ? SCREEN_SIZES.XS + 'px' : null;
    return (
      <CompletionPageContainer>
        {this.props.loading && <LoadingPanel/>}
        <TooltipContainer anchorElement='target' position={window.innerWidth < SCREEN_SIZES.XS ? 'right' : 'top'}>
          <StackLayout rowGap='0'>
            {this.props.cycleRequiresManagersConfirmation &&
              <>
                <TopTitleContainer>
                  <TitleWrapper>
                    <Icon className='k-icon k-i-information' title={localizationService.toLanguageString('studentCycleCompletion.approvalRequestTooltipInfo')}></Icon>
                    <TitleText variant='h6'>
                      {`${localizationService.toLanguageString('studentCycleCompletion.managerApprovals')}`}
                    </TitleText>
                  </TitleWrapper>
                </TopTitleContainer>
                {this.props.managerApprovals?.map((element, index) => (
                  <RowContainer key={index}>
                    <RowTextContainer>
                      <SurveyInfoContainer>
                        <BodyTextContainer>
                          {element.manager}
                        </BodyTextContainer>
                        {element.requestDate &&
                          <GreyCaptionText>
                            {DateTimeFormatter(element.requestDate, intlService)}
                          </GreyCaptionText>
                        }
                      </SurveyInfoContainer>
                    </RowTextContainer>
                    {element.status &&
                      <BadgeContainer><InlineBadge themeColor={getStudyPlanCycleStudentApprovingUserStatusColor(element.status)}>{localizationService.toLanguageString(`studyPlanCycleStudentApprovingUserStatus.${element.status}`)}</InlineBadge></BadgeContainer>
                    }
                    {(this.props.cycleStatus === CYCLE_STUDENT_STATUS.STARTED || this.props.cycleStatus === CYCLE_STUDENT_STATUS.RETURNED || this.props.cycleStatus === CYCLE_STUDENT_STATUS.NOT_STARTED) &&
                    (element.status == null || element.status === STUDY_PLAN_CYCLE_STUDENT_APPROVING_USER_STATUS.RETURNED || element.status === STUDY_PLAN_CYCLE_STUDENT_APPROVING_USER_STATUS.SUPPLEMENTED || element.status === STUDY_PLAN_CYCLE_STUDENT_APPROVING_USER_STATUS.UNAPPROVED) &&
                      <ButtonGroup>
                        <ListButton
                          themeColor='primary'
                          onClick={() => this.props.openConfirmApprovalRequestDialog(element)}
                        >
                          {localizationService.toLanguageString('studentCycleCompletion.requestApproval')}
                        </ListButton>
                      </ButtonGroup>
                    }
                  </RowContainer>
                ))}
              </>
            }
            <TopTitleContainer>
              <TitleWrapper>
                <Icon className='k-icon k-i-information' title={localizationService.toLanguageString('studentCycleCompletion.surveyTooltipInfo')}></Icon>
                <TitleText variant='h6'>
                  {`${localizationService.toLanguageString('studentCycleCompletion.managerEvaluations')}`}
                </TitleText>
              </TitleWrapper>
            </TopTitleContainer>
            {this.props.managerSurveys?.map((element, index) => (
              <RowContainer key={index}>
                <RowTextContainer>
                  <SurveyInfoContainer>
                    <BodyTextContainer>
                      {element.subjectsName}
                    </BodyTextContainer>
                    {element.evaluation &&
                      <GreyTextContainer>
                        {DecimalFormatter(element.evaluation)}
                      </GreyTextContainer>
                    }
                    {element.evaluationDate &&
                      <GreyCaptionText>
                        {DateTimeFormatter(element.evaluationDate, intlService)}
                      </GreyCaptionText>
                    }
                  </SurveyInfoContainer>
                </RowTextContainer>
                {!element.evaluationDate &&
                  <ButtonGroup>
                    <ListButton
                      themeColor='primary'
                      onClick={() => this.props.onEvaluateManagerClick(element?.subjectUserId)}
                    >
                      {localizationService.toLanguageString('studentCycleCompletion.evaluate')}
                    </ListButton>
                  </ButtonGroup>
                }
              </RowContainer>
            ))}
            <RowContainer/>
            <CycleRowContainer>
              <RowTextContainer>
                <TitleWrapper>
                  <Icon className='k-icon k-i-information' title={localizationService.toLanguageString('studentCycleCompletion.surveyTooltipInfo')}></Icon>
                  <TextContainer>
                    {localizationService.toLanguageString('studentCycleCompletion.cycleQualityEvaluation')}
                  </TextContainer>
                </TitleWrapper>
              </RowTextContainer>
            </CycleRowContainer>
            {!hasOnlySingleForeignDepartment && this.props.cycleDepartmentSurveys?.map((element, index) => (
              <RowContainer key={index}>
                <RowTextContainer>
                  <SurveyInfoContainer>
                    {element.departmentName
                      ? <BodyTextContainer>{element.departmentName}</BodyTextContainer>
                      : <ErrorTextContainer>{localizationService.toLanguageString('studentCycleCompletion.missingDepartmentError')}</ErrorTextContainer>
                    }
                    {element.evaluation &&
                      <GreyTextContainer>
                        {DecimalFormatter(element.evaluation)}
                      </GreyTextContainer>
                    }
                    {element.evaluationDate &&
                      <GreyCaptionText>
                        {DateTimeFormatter(element.evaluationDate, intlService)}
                      </GreyCaptionText>
                    }
                  </SurveyInfoContainer>
                </RowTextContainer>
                {!element.evaluationDate && element.departmentName &&
                  <ButtonGroup>
                    <ListButton
                      themeColor='primary'
                      onClick={() => this.props.onEvaluateCycleClick(element.departmentId)}
                    >
                      {localizationService.toLanguageString('studentCycleCompletion.evaluate')}
                    </ListButton>
                  </ButtonGroup>
                }
              </RowContainer>
            ))}
            {canSubmit &&
              <CycleSubmitRowContainer>
                <RowTextContainer>
                  {this.props.cycleRequiresManagersConfirmation &&
                    <Icon className='k-icon k-i-information' title={localizationService.toLanguageString('studentCycleCompletion.cycleCompletionTooltip')}></Icon>
                  }
                  <TextContainer>
                    {localizationService.toLanguageString('studentCycleCompletion.finishCycle')}
                  </TextContainer>
                </RowTextContainer>
                <ButtonGroup>
                  <ListButton
                    themeColor='primary'
                    onClick={this.props.openConfirmSubmitDialog}
                    disabled={this.props.cycleRequiresManagersConfirmation && !this.props.managerApprovals?.some(element => element.status === STUDY_PLAN_CYCLE_STUDENT_APPROVING_USER_STATUS.APPROVED)}
                  >
                    {localizationService.toLanguageString('studentCycleCompletion.submitCycle')}
                  </ListButton>
                </ButtonGroup>
              </CycleSubmitRowContainer>
            }
            <CycleManagersRowContainer>
              <RowTextContainer>
                <TitleWrapper>
                  <Icon className='k-icon k-i-information' title={localizationService.toLanguageString('studentCycleCompletion.surveyTooltipInfo')}></Icon>
                  <TextContainer>
                    {localizationService.toLanguageString('studentCycleCompletion.cycleManagerEvaluation')}
                  </TextContainer>
                </TitleWrapper>
                <SurveyInfoContainer>
                  {this.props.cycleManagerSurvey?.evaluation &&
                    <TextContainer>
                      {DecimalFormatter(this.props.cycleManagerSurvey.evaluation, 0)}
                    </TextContainer>
                  }
                  {this.props.cycleManagerSurvey?.evaluationDate
                    ?
                    <CaptionText textStyle={TextStyles.caption}>
                      {`${this.props.cycleManagerFullName ? `${this.props.cycleManagerFullName} ` : ''}${DateTimeFormatter(this.props.cycleManagerSurvey.evaluationDate, intlService)}`}
                    </CaptionText>
                    :
                    <CaptionText textStyle={TextStyles.caption}>
                      {`${this.props.cycleManagerFullName ? `${this.props.cycleManagerFullName} - ` : ''}${localizationService.toLanguageString('studentCycleCompletion.notEvaluated')}`}
                    </CaptionText>
                  }
                  <CaptionText>
                    {this.props.cycleManagerSurvey?.cycleManager}
                  </CaptionText>
                </SurveyInfoContainer>
              </RowTextContainer>
              <ButtonGroup>
                {this.props.cycleManagerSurvey?.evaluationDate &&
                  <ListButton
                    themeColor='primary'
                    onClick={this.props.onPreviewCycleManagerSurveyClick}
                  >
                    {localizationService.toLanguageString('studentCycleCompletion.preview')}
                  </ListButton>
                }
              </ButtonGroup>
            </CycleManagersRowContainer>
            <StatusHistoryRowContainer showBottomBorder={!this.props.isCycleStatusHistoryExpanded} onClick={this.props.onStatusHistoryExpand}>
              <RowTextContainer>
                <TextContainer>
                  {localizationService.toLanguageString('studentCycleCompletion.statusHistory')}
                  <span className={this.props.isCycleStatusHistoryExpanded ? 'k-icon k-i-arrow-60-down' : 'k-icon k-i-arrow-60-right'}></span>
                </TextContainer>
              </RowTextContainer>
            </StatusHistoryRowContainer>
            {this.props.isCycleStatusHistoryExpanded && this.props.cycleStatusHistory?.map((element, index) => (
              <RowContainer key={index} showBottomBorder={this.props.cycleStatusHistory?.length == index + 1} hideTopBorder={true}>
                <StatusContainer>
                  <CircleIcon className={'k-icon k-i-circle'}/>
                  <SurveyInfoContainer paddingLeft={'8px'}>
                    <BodyTextContainer>
                      {DateTimeFormatter(element.date, intlService)}
                    </BodyTextContainer>
                    <InlineBadge themeColor={getStudentCycleStatusColor(element.status)}>
                      {localizationService.toLanguageString('studyPlanCycleDepartmentStudentStatus.' + element.status)}
                    </InlineBadge>
                    {element.comment &&
                      <StatusCommentTextContainer>
                        {element.comment}
                      </StatusCommentTextContainer>
                    }
                  </SurveyInfoContainer>
                </StatusContainer>
              </RowContainer>
            ))}
            {this.props.isCycleStatusHistoryExpanded && !this.props.cycleStatusHistory &&
              <RowContainer showBottomBorder={true} hideTopBorder={true}>
                <RowTextContainer padLeft='24px'>
                  <BodyTextContainer>{localizationService.toLanguageString('grid.noRecords')}</BodyTextContainer>
                </RowTextContainer>
              </RowContainer>
            }
          </StackLayout>
        </TooltipContainer>
        {this.props.isConfirmSubmitDialogVisible &&
          <Dialog
            title={localizationService.toLanguageString('studentCycleCompletion.finishCycle')}
            onClose={this.props.closeConfirmSubmitDialog}
            width={confirmDialogWidth}>
            <Text>
              {this.props.cycleManagerId
                ? localizationService.toLanguageString(this.props.isSkillsAndCasesComplete ? 'studentCycleCompletion.confirmSubmitCycleComplete' : 'studentCycleCompletion.confirmSubmitCycleIncomplete')
                : localizationService.toLanguageString(`studentCycleCompletion.cycleManagerMissingWarningMessage`)
              }
            </Text>
            {this.props.confirmSubmitDialogErrorMessage && <ErrorMessage>{this.props.confirmSubmitDialogErrorMessage}</ErrorMessage>}
            <DialogActionsBar>
              {this.props.cycleManagerId
                ?
                <>
                  <Button onClick={async() => { await this.props.onSubmitCycleClick(); await this.props.onLoadStudentCycleCompletion(); }} themeColor='primary'>{localizationService.toLanguageString('common.yes')}</Button>
                  <Button onClick={this.props.closeConfirmSubmitDialog}>{localizationService.toLanguageString('common.no')}</Button>
                </>
                :
                <Button onClick={this.props.closeConfirmSubmitDialog}>{localizationService.toLanguageString('buttons.close')}</Button>
              }
            </DialogActionsBar>
          </Dialog>
        }
        {this.props.isConfirmApprovalRequestDialogVisible &&
          <Dialog
            title={localizationService.toLanguageString('studentCycleCompletion.requestSkillsAndCasesApprovalTitle')}
            onClose={this.props.closeConfirmApprovalRequestDialog}
            width={confirmDialogWidth}>
            <Text>
              {localizationService.toLanguageString(`studentCycleCompletion.requestSkillsAndCasesApprovalMessage`).replace('{0}', this.props.selectedManagerForApprovalRequestUser.managerFullName)}
            </Text>
            {this.props.confirmApprovalRequestDialogErrorMessage && <ErrorMessage>{this.props.confirmApprovalRequestDialogErrorMessage}</ErrorMessage>}
            <DialogActionsBar>
              <Button onClick={this.props.onRequestManagerApprovalClick} themeColor='primary'>{localizationService.toLanguageString('common.yes')}</Button>
              <Button onClick={this.props.closeConfirmApprovalRequestDialog}>{localizationService.toLanguageString('common.no')}</Button>
            </DialogActionsBar>
          </Dialog>
        }
      </CompletionPageContainer>
    );
  }
}

registerForLocalization(StudentCycleCompletionPage);
registerForIntl(StudentCycleCompletionPage);