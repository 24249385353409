export default {
  LOAD_USER_OVERVIEW_START: 'LoadUserListStart',
  LOAD_USER_OVERVIEW_END: 'LoadUserListEnd',
  SELECT_USER_OVERVIEW_PAGE_TAB: 'SelectUserOverviewPageTab',
  CHANGE_USER_STATUS_START: 'ChangeUserStatusStart',
  CHANGE_USER_STATUS_END: 'ChangeUserStatusEnd',
  TOGGLE_USER_OVERVIEW_CONFIRM_DIALOG: 'ToggleUserOverviewConfirmDialog',
  OPEN_USER_IMPERSONATE_CONFIRM_DIALOG: 'OpenUserImpersonateConfirmDialog',
  CLOSE_USER_IMPERSONATE_CONFIRM_DIALOG: 'CloseUserImpersonateConfirmDialog',
  CLEANUP_USER_OVERVIEW: 'CleanupUserOverview'
};