import { pagingSettings } from '../../../utils/GridPagingUtils';
import ManagedStudentListPageActionTypes from '../actions/managed-student-list-page-action-types';

const initialState = {
  loading: false,
  managedStudents: null,
  managedStudentsCancelToken: null,
  skip: pagingSettings.paging.skip,
  take: pagingSettings.paging.take,
  total: 0,
  filter: null,

  filterStatusesLoading: false,
  filterStatusesCancelToken: null,
  filterStatuses: null,
  cycleStudentStatuses: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ManagedStudentListPageActionTypes.LOAD_MANAGED_STUDENT_LIST_START:
      return {
        ...state,
        loading: true
      };
    case ManagedStudentListPageActionTypes.LOAD_MANAGED_STUDENT_LIST_END:
      return {
        ...state,
        loading: false,
        managedStudents: action.payload.managedStudents,
        total: action.payload.total,
        skip: action.payload.skip,
        take: action.payload.take
      };
    case ManagedStudentListPageActionTypes.FILTER_MANAGED_STUDENT_LIST:
      return {
        ...state,
        filter: action.payload,
        skip: initialState.skip,
        take: initialState.take
      };
    case ManagedStudentListPageActionTypes.LOAD_MANAGED_STUDENT_LIST_STATUSES:
      return {
        ...state,
        cycleStudentStatuses: action.payload ? action.payload.cycleStudentStatuses : []
      };
    default:
      return state;
  }
};