import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import { Button } from '@progress/kendo-react-buttons';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { WrapLayout, StackLayout } from '../layouts';
import { Text, Separator } from '../common';
import { Colors, elevation, respondTo } from '../shared';
import { SCREEN_SIZES as ScreenSizes } from '../../../resources/screenSize';
import { Badge, BadgeContainer } from '@progress/kendo-react-indicators';
import { Drawer, DrawerItem } from '@progress/kendo-react-layout';
import { NotificationBellIcon } from '../../icons';

const DEFAULT_HEADER_HEIGHT = 60;

const Container = styled(StackLayout).attrs(props => ({
  orientation: 'horizontal',
  align: 'left',
  valign: 'center'
}))`
  background-color: ${props => props.theme.primary70};
  position: ${props => props.position ?? 'sticky'};
  width: 100%;
  top: 0;
  z-index: 110;
  height: ${props => props.height ?? DEFAULT_HEADER_HEIGHT}px;
  color: ${props => props.theme.white};
  ${elevation(2)}
  ${props => props?.minWidth && `min-width: ${props.minWidth}px`};
`;

const MenuButton = styled(Button).attrs(props => ({
  fillMode: 'flat',
  icon: 'menu',
  rounded: null
}))`
  color: #ffffff;
  font-size: 24px;
  height: ${props => props.height ?? DEFAULT_HEADER_HEIGHT}px;
  width: 50px;
`;

const TitleContainer = styled(WrapLayout).attrs(props => ({
  align: 'left',
  valign: 'bottom'
}))`
  align-items: center;
`;

const Title = styled(Text).attrs(props => ({
  variant: 'h1',
  textColor: props.theme.white
}))`
  font-size: 16px;
  line-height: 20px;
  display: none;

  ${respondTo(ScreenSizes.XS, css`
    font-size: 24px;
    display: block;
  `)}
`;

const PageTitle = styled(Text).attrs(props => ({
  variant: 'h3',
  textColor: props.theme.white
}))`
  
  ${respondTo(ScreenSizes.XS, css`
    margin-top: auto;
  `)}
`;

const Delimiter = styled(Separator).attrs({
  orientation: 'vertical',
  height: '26px'
})`
  display: none;
  
  ${respondTo(ScreenSizes.XS, css`
    display: block;
  `)}
`;

const UserInitialsContainer = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  background-color: white;
  color: #0d5fa9;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  border-radius: 50%;
  cursor: pointer;
`;

const DrawerItemComponent = styled(DrawerItem)`
  display: flex;
  align-items: center;
  ${props => props.$isSubItem && `
    height: 56px;
    ${!props.selected && `background-color: ${Colors.GRAY_10};`}
  `}
`;

const IconAndTitleContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const ItemTitle = styled.div`
  padding-left: 16px;
  ${props => props.fontSize && `font-size: ${props.fontSize};`}
`;

const IconContainer = styled.span`
  ${props => props.isKendoIcon && `
    &&.k-icon {
      background-color: rgba(0, 0, 0, 0);
    }
  `}
`;

const NoIconDiv = styled.div`
  width: 36px;
  height: 18px;
`;

const UserInfoContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

const StatusText = styled.span`
  font-size: 12px;
  line-height: 16px;
  -webkit-letter-spacing: 0.4px;
  -moz-letter-spacing: 0.4px;
  -ms-letter-spacing: 0.4px;
  letter-spacing: 0.4px;
  font-family: 'Roboto',sans-serif;
  font-weight: 400;
`;

const TitleText = styled.span`
    line-height: normal;
`;

const LocalizationButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;
  width: 60px;
`;

const NotificationButton = styled.div`
  cursor: pointer;
`;

export default class Header extends PureComponent {

  constructor(props) {
    super(props);
    const isSmallScreen = window.innerWidth < ScreenSizes.XS;
    this.handleUserInfoClick = this.handleUserInfoClick.bind(this);
    this.renderDrawerItem = this.renderDrawerItem.bind(this);
    this.handleSelectClick = this.handleSelectClick.bind(this);
    this.handleWindowResize = this.handleWindowResize.bind(this);
    this.state = {
      userMenuExpanded: false,
      isSmallScreen: isSmallScreen
    };
  }

  async componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize);
    this.handleWindowResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  render() {
    const {
      onButtonClick,
      userEmail,
      userInitials,
      currentStudies,
      title,
      pageTitle,
      height,
      minWidth,
      drawerExpanded,
      requestCount,
      isUserImpersonated,
      onLogoutFromImpersonateClick,
      onLocaleChange,
      userIsAdministrator,
      unreadNotificationsCount,
      onNotificationsClick
    } = this.props;
    const localizationService = provideLocalizationService(this);
    return (
      <Container height={height} minWidth={minWidth}>
        <BadgeContainer>
          <MenuButton onClick={onButtonClick} height={height}/>
          {drawerExpanded == false && requestCount > 0 &&
            <Badge themeColor='primary' size='small' position={'inside'}>
              {requestCount}
            </Badge>
          }
        </BadgeContainer>
        <TitleContainer>
          <Title>{title}</Title>
          <Delimiter />
          <PageTitle>{pageTitle}</PageTitle>
        </TitleContainer>
        <Drawer
          expanded={this.state.userMenuExpanded}
          items={this.props.userDrawerItems}
          position={'end'}
          item={this.renderDrawerItem}
          mode={'overlay'}
          onOverlayClick={() => this.setState({ userMenuExpanded: false })}
        />
        <NotificationButton onClick={() => onNotificationsClick({ hasUnreadNotifications: unreadNotificationsCount > 0 })}>
          <BadgeContainer>
            <NotificationBellIcon fillColor={Colors.WHITE}/>
            {unreadNotificationsCount > 0 && <Badge themeColor='error' rounded='full' size='small' position='edge'>{unreadNotificationsCount}</Badge>}
          </BadgeContainer>
        </NotificationButton>
        {(userIsAdministrator || isUserImpersonated) &&
          <LocalizationButton onClick={onLocaleChange}>
            <Text textColor='white'>{localizationService.language === 'lt' ? 'EN' : 'LT'}</Text>
          </LocalizationButton>
        }
        {isUserImpersonated &&
          <Button
            onClick={onLogoutFromImpersonateClick}>
            <span className='k-icon k-i-logout'></span>
          </Button>
        }
        <UserInfoContainer onClick={this.handleUserInfoClick}>
          {!this.state.isSmallScreen && userEmail && currentStudies?.studyProgramName &&
            <StackLayout rowGap={0} width='170px'>
              <Text textColor='white' variant='caption'>{userEmail}</Text>
              <Text textColor='white' variant='caption'>{currentStudies.studyProgramName}</Text>
            </StackLayout>
          }
          <UserInitialsContainer>
            {userInitials}
          </UserInitialsContainer>
        </UserInfoContainer>
      </Container>
    );
  }

  handleWindowResize() {
    const isSmallScreen = window.innerWidth < ScreenSizes.XS;
    this.setState({ isSmallScreen });
  }

  handleSelectClick(studyProgramId, status) {
    const userDrawerItems = this.props.userDrawerItems?.map(element => {
      if (element.studyProgramId === studyProgramId && element.status === status) {
        this.props.handleStudyProgramChange({ studyProgram: element });
        return { ...element, selected: true };
      }
      return { ...element, selected: false };
    });
    this.props.onUserDrawerItemsUpdated({ userDrawerItems });
    this.setState({ userMenuExpanded: false });
  }

  handleUserInfoClick() {
    this.setState({ userMenuExpanded: !this.state.userMenuExpanded });
  }

  renderDrawerItem(props) {
    const { studyProgramName, studyProgramId, status, isSubItem, title, actionMethod, iconSvg, fontSize, isKendoIcon, index, separator, disabled, selected } = props;
    return (
      <DrawerItemComponent
        index={index}
        separator={separator}
        disabled={disabled}
        selected={selected}
        $isSubItem={isSubItem}
        onSelect={isSubItem ? () => this.handleSelectClick(studyProgramId, status) : actionMethod}
      >
        <IconAndTitleContainer>
          { iconSvg
            ? <IconContainer className={'k-icon ' + iconSvg} isKendoIcon={isKendoIcon}/>
            : <NoIconDiv/>
          }
          <ItemTitle className='k-item-text' fontSize={fontSize}>
            <StackLayout rowGap='0'>
              <TitleText>
                {isSubItem ? studyProgramName : title}
              </TitleText>
              <StatusText>
                {status}
              </StatusText>
            </StackLayout>
          </ItemTitle>
        </IconAndTitleContainer>
      </DrawerItemComponent>
    );
  }
}

registerForLocalization(Header);
