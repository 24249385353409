import React from 'react';
import { connect } from 'react-redux';
import { provideLocalizationService, registerForIntl, registerForLocalization } from '@progress/kendo-react-intl';
import { Grid, InlineBadge } from '../../shared/components';
import { loadTheoreticalPartStudentList } from '../actions/theoretical-part-student-list-actions';
import { StackLayout } from '@progress/kendo-react-layout';
import { WrapLayout, Text, Colors } from '../../../ui';
import { getTheoreticalPartStatusColor } from '../../../utils/theoreticalPartStatusColors';

class TheoreticalPartStudentList extends React.Component {
  constructor(props) {
    super(props);
    this.renderStatusCell = this.renderStatusCell.bind(this);
  }

  async componentDidMount() {
    const { theoreticalPartId, studyPlanId, studyPlanCycleId } = this.props;
    await this.props.loadTheoreticalPartStudentList({ theoreticalPartId, studyPlanId, studyPlanCycleId });
  }

  render() {
    const { theoreticalPartStudents, loading } = this.props;
    const localizationService = provideLocalizationService(this);
    return (
      <>
        <Grid
          loading={loading}
          data={theoreticalPartStudents}
          isPageable={false}
          columns={[
            {
              field: 'resident',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('theoreticalPart.resident'),
              cell: this.renderResident
            }, {
              field: 'status',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('theoreticalPart.status'),
              cell: this.renderStatusCell
            }
          ]}
        />
      </>
    );
  }

  renderStatusCell(e) {
    const { dataItem, className } = e;
    const localizationService = provideLocalizationService(this);
    return (
      <td className={className}>
        <InlineBadge themeColor={getTheoreticalPartStatusColor(dataItem)}>
          {localizationService.toLanguageString(`theoreticalPart.${dataItem.complete ? 'completed' : 'incomplete'}`)}
        </InlineBadge>
      </td>
    );
  }

  renderResident(e) {
    const { className, dataItem } = e;
    return (
      <td className={className}>
        <StackLayout rowGap='2px' orientation='vertical'>
          {`${dataItem.name} ${dataItem.surname}`}
          <WrapLayout rowGap='0' columnGap='0'>
            <Text textColor={Colors.GRAY_50} variant='caption'>
              {dataItem.studyProgram}
            </Text>
          </WrapLayout>
        </StackLayout>
      </td>
    );
  }
}

const mapStateToProps = (state) => ({
  theoreticalPartStudents: state.theoreticalPartStudentList.theoreticalPartStudents,
  loading: state.theoreticalPartStudentList.loading
});

const mapDispatchToProps = (dispatch) => ({
  loadTheoreticalPartStudentList: (payload) => dispatch(loadTheoreticalPartStudentList(payload))
});

registerForIntl(TheoreticalPartStudentList);
registerForLocalization(TheoreticalPartStudentList);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TheoreticalPartStudentList);