import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { React, Component } from 'react';
import styled from 'styled-components';
import { withRouter } from '../../../components/withRouter';
import { Button } from '@progress/kendo-react-buttons';
import { Colors } from '../../../ui';

const ErrorContainer = styled.div`
  background-color: ${Colors.WHITE};
  border: 1px solid ${Colors.GRAY_20};
  padding: 16px;
`;

const Message = styled.div`
  margin-top: 16px;
`;

const ReturnButton = styled(Button)`
  margin-top: 32px;
`;

export class Forbidden extends Component {

  constructor(props) {
    super(props);
    this.handleNavigateHome = this.handleNavigateHome.bind(this);
  }

  render() {
    const localizationService = provideLocalizationService(this);
    return (
      <ErrorContainer>
        <h2>{localizationService.toLanguageString('authorization.forbiddenTitle')}</h2>
        <Message>{localizationService.toLanguageString('authorization.forbiddenMessage')}</Message>
        <ReturnButton themeColor='primary' onClick={this.handleNavigateHome}>
          {localizationService.toLanguageString('authorization.returnHome')}
        </ReturnButton>
      </ErrorContainer>
    );
  }

  handleNavigateHome() {
    this.props.navigate('/');
  }
}

registerForLocalization(Forbidden);

export default withRouter(Forbidden);