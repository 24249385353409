import MyStudiesCycleListActionTypes from './my-studies-cycle-list-action-types';
import axios from 'axios';
import { process } from '@progress/kendo-data-query';
import { CYCLE_STUDENT_STATUS } from '../../../resources/studyPlanCycleDepartmentStudentStatus';

export const loadMyStudiesCycleList = (studentId) => async(dispatch, getState, { api }) => {
  dispatch({ type: MyStudiesCycleListActionTypes.LOAD_MY_STUDIES_CYCLE_LIST_START });
  const state = getState().myStudiesCycleList;
  try {
    state.myStudiesCancelToken?.cancel();
    state.myStudiesCancelToken = axios.CancelToken.source();
    const myCycles = await api.get(`api/students/${studentId}/cycles`, null, state.myStudiesCancelToken.token);
    const processedCycles = myCycles ? process(myCycles, { group: [{ field: 'year' }] })?.data?.reverse() : null;
    const initialSum = 0;
    const cycles = processedCycles?.map(group => ({
      ...group,
      value: group.value ?? null,
      expanded: true,
      assignedCredits: group.items?.reduce((sum, item) => sum + item.cycleCredits, initialSum),
      finishedCredits: group.items?.filter(item => item.status === CYCLE_STUDENT_STATUS.FINISHED).reduce((sum, item) => sum + item.cycleCredits, initialSum)
    }));
    dispatch({
      type: MyStudiesCycleListActionTypes.LOAD_MY_STUDIES_CYCLE_LIST_END,
      payload: cycles
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: MyStudiesCycleListActionTypes.LOAD_MY_STUDIES_CYCLE_LIST_END });
      throw error;
    }
  }
};

export const sortMyStudiesCyclesList = (payload) => async(dispatch, getState) => {
  let field = 'id';
  let dir = 'desc';
  if (payload[0]) {
    field = payload[0].field;
    dir = payload[0].dir;
  }
  const dateComparer = (a, b) => {
    if (a[field] && !b[field]) {
      return dir === 'asc' ? -1 : 1;
    }
    if (!a[field] && b[field]) {
      return dir === 'asc' ? 1 : -1;
    }
    if (!a[field] && !b[field]) {
      return 0;
    }
    const aDate = new Date(a[field]);
    const bDate = new Date(b[field]);
    if (aDate < bDate) {
      return dir === 'asc' ? 1 : -1;
    }
    if (aDate > bDate) {
      return dir === 'asc' ? -1 : 1;
    }
    return 0;
  };
  const cycles = getState().myStudiesCycleList.myCycles;
  const sortedCycles = cycles.map(element => ({ ...element, items: element.items.sort(dateComparer) }));
  dispatch({
    type: MyStudiesCycleListActionTypes.SORT_MY_STUDIES_CYCLE_LIST,
    payload: { myCycles: sortedCycles, sorting: payload }
  });
};