import StudyPlanCycleTheoreticalPartFormPageActionTypes from '../actions/study-plan-cycle-theoretical-part-form-page-action-types';

const initialState = {
  loading: false,
  cycle: null,
  studyPlanId: null,
  studyPlanCycleId: null,
  cycleTheoreticalPartId: null,
  studyPlanCycleTheoreticalPart: {},
  saveSuccess: false,
  validationResult: null,
  cancellationTokenSources: new Map(),

  availableTheoreticalPartsLoading: false,
  availableTheoreticalPartsCancelToken: null,
  availableTheoreticalParts: [],
  availableLecturersLoading: false,
  availableLecturersCancelToken: null,
  availableLecturers: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case StudyPlanCycleTheoreticalPartFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_THEORETICAL_PART_FORM_PAGE_START:
      return {
        ...state,
        loading: true,
        validationResult: null
      };
    case StudyPlanCycleTheoreticalPartFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_THEORETICAL_PART_FORM_PAGE_END:
      return {
        ...state,
        loading: false,
        cycle: action.payload?.cycle,
        studyPlanId: action.payload?.studyPlanId,
        studyPlanCycleId: action.payload?.studyPlanCycleId,
        studyPlanCycleTheoreticalPart: action.payload ? action.payload.studyPlanCycleTheoreticalPart : null,
        validationResult: action.payload ? action.payload.validationResult : null
      };
    case StudyPlanCycleTheoreticalPartFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_THEORETICAL_PART_AVAILABLE_THEORETICAL_PARTS_START:
      return {
        ...state,
        availableTheoreticalPartsLoading: true
      };
    case StudyPlanCycleTheoreticalPartFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_THEORETICAL_PART_AVAILABLE_THEORETICAL_PARTS_END:
      return {
        ...state,
        availableTheoreticalPartsLoading: false,
        availableTheoreticalParts: action.payload ? action.payload.availableTheoreticalParts : []
      };
    case StudyPlanCycleTheoreticalPartFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_THEORETICAL_PART_AVAILABLE_LECTURERS_START:
      return {
        ...state,
        availableLecturersLoading: true
      };
    case StudyPlanCycleTheoreticalPartFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_THEORETICAL_PART_AVAILABLE_LECTURERS_END:
      return {
        ...state,
        cycleTheoreticalPartId: action.payload ? action.payload.cycleTheoreticalPartId : null,
        availableLecturersLoading: false,
        availableLecturers: action.payload ? action.payload.users : [],
        studyPlanCycleTheoreticalPart: action.payload ? action.payload.studyPlanCycleTheoreticalPart : null
      };
    case StudyPlanCycleTheoreticalPartFormPageActionTypes.CLEAR_STUDY_PLAN_CYCLE_THEORETICAL_PART_CLEAR_STORE:
      return {
        ...initialState
      };
    default:
      return state;
  }
};