import * as React from 'react';
import { connect } from 'react-redux';
import { Field } from '@progress/kendo-react-form';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { FilterForm, YearPicker, TextBox, WrapLayout, ComboBox, StackLayout } from '../../../ui';
import { filterStudyPlans, clearStudyPlansFilter, searchStudyPlansFilterStudyPrograms } from '../actions/study-plan-list-page-actions';

class StudyPlanListFilter extends React.PureComponent {
  constructor() {
    super();
  }

  render() {
    const {
      filter,
      clearStudyPlansFilter,
      filterStudyPlans,
      filterStudyProgramsLoading,
      filterStudyPrograms,
      searchStudyPlansFilterStudyPrograms
    } = this.props;
    const localizationService = provideLocalizationService(this);
    return (
      <FilterForm
        initialValues={filter}
        onSubmit={filterStudyPlans}
        onClear={clearStudyPlansFilter}
        localizationService={localizationService}
        title={localizationService.toLanguageString('grid.filterTitle')}
        uniqueKey={JSON.stringify(filter)}
        render={() => (
          <WrapLayout>
            <StackLayout width='340px'>
              <Field
                name='studyProgram'
                width='340px'
                component={ComboBox}
                label={localizationService.toLanguageString('StudyPlan.studyProgram')}
                textField='name'
                dataItemKey='id'
                loading={filterStudyProgramsLoading}
                data={filterStudyPrograms}
                onFilter={searchStudyPlansFilterStudyPrograms}
              />
              <Field
                name='year'
                width='170px'
                component={YearPicker}
                label={localizationService.toLanguageString('StudyPlan.year')}
              />
            </StackLayout>
            <StackLayout width='340px'>
              <Field
                name='keyword'
                width='340px'
                component={TextBox}
                label={localizationService.toLanguageString('custom.search')}
              />
            </StackLayout>
          </WrapLayout>
        )}
      />
    );
  }
}

const mapStateToProps = state => ({
  filter: state.studyPlanListPage.filter,
  filterStudyProgramsLoading: state.studyPlanListPage.filterStudyProgramsLoading,
  filterStudyPrograms: state.studyPlanListPage.filterStudyPrograms
});

const mapDispatchToProps = dispatch => ({
  filterStudyPlans: (payload) => dispatch(filterStudyPlans(payload)),
  clearStudyPlansFilter: () => dispatch(clearStudyPlansFilter()),
  searchStudyPlansFilterStudyPrograms: (payload) => dispatch(searchStudyPlansFilterStudyPrograms(payload))
});

registerForLocalization(StudyPlanListFilter);

export default connect(mapStateToProps, mapDispatchToProps)(StudyPlanListFilter);
