import designationOverviewPageActionTypes from '../actions/designation-overview-page-action-types';

const initialState = {
  loading: false,
  designation: null,
  designationCancelToken: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case designationOverviewPageActionTypes.LOAD_DESIGNATION_OVERVIEW_PAGE_START:
      return {
        ...state,
        loading: true
      };
    case designationOverviewPageActionTypes.LOAD_DESIGNATION_OVERVIEW_PAGE_END:
      return {
        ...state,
        loading: false,
        designation: action.payload?.designation
      };
    case designationOverviewPageActionTypes.CLEANUP_DESIGNATION_OVERVIEW_PAGE:
      return initialState;
    default:
      return state;
  }
};