import * as React from 'react';
import { connect } from 'react-redux';
import { provideLocalizationService, registerForLocalization, provideIntlService, registerForIntl } from '@progress/kendo-react-intl';
import { Button } from '@progress/kendo-react-buttons';
import { Skeleton } from '@progress/kendo-react-indicators';
import { Colors, OverviewPage, StackLayout, Text, TextArea, WrapLayout } from '../../../ui';
import { ValueItem, DecimalFormatter, EmptyFormatter, InlineBadge, DateFormatter } from '../../shared/components';
import {
  selectManagedStudentOverviewPageTab,
  expandSkill,
  expandCase,
  openReturnDialog,
  closeReturnDialog,
  returnCycle,
  loadManagedStudentCycleSkills,
  loadManagedStudentCycleCases,
  loadManagedStudentCycleTheoreticalParts,
  clearManagedStudentsOverviewPage,
  openApproveDialog,
  closeApproveDialog,
  approveStudyPlanCycleStudentSkillsAndCases,
  returnStudyPlanCycleStudentSkillsAndCases
} from '../actions';
import PaperAndPencilIcon from '../../../ui/icons/paper-and-pencil-icon';
import NotebookIcon from '../../../ui/icons/notebok-icon';
import OpenBookIcon from '../../../ui/icons/open-book-icon';
import styled from 'styled-components';
import { StudentCycleSkillsPage, StudentCycleCasesPage, StudentCycleTheoreticalPartsPage } from '../../shared/student-cycle';
import { SURVEY_TYPES } from '../../../resources/surveyType';
import { CYCLE_STUDENT_STATUS } from '../../../resources/studyPlanCycleDepartmentStudentStatus';
import FormDialog from '../../../ui/components/application/form-dialog';
import { Field } from '@progress/kendo-react-form';
import { getStudentCycleStatusColor } from '../../../utils/studyPlanCycleDepartmentStudentStatusColors';
import { withRouter } from '../../../components/withRouter';
import { DatePeriodFormatter } from '../../shared/components/Formatter';
import { STUDY_PLAN_CYCLE_STUDENT_APPROVING_USER_STATUS } from '../../../resources/studyPlanCycleStudentApprovingUserStatus';
import { getStudyPlanCycleStudentApprovingUserStatusColorCode } from '../../../utils/studyPlanCycleStudentApprovingUserStatusColorCodes';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

const TabStripContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
`;

const TabStripItem = styled.div`
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  position: relative;

  &:hover {
    color: rgba(0, 0, 0, 0.87);
    background-color: rgba(0, 0, 0, 0.04);
  }  
`;

const TabStripBox = styled(StackLayout).attrs(props => ({
  orientation: 'vertical',
  align: 'center',
  rowGap: '0'
}))`
  cursor: pointer;
  ${(props) => props.selected ? `
    &:after{
      border-bottom-width: 2px;
      border-width: 2px;
      border-color: #0D84C6;
      display: block;
      content: "";
      border-style: solid;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0; }` : ''
};
`;

const TabStripText = styled(Text).attrs(props => ({
  variant: 'caption',
  textColor: props.theme.gray80,
  truncate: true
}))`
  font-size: 10px;
`;

const TabStripIcon = styled.div`
  position: relative;
`;

const DepartmentContainer = styled.div`
  white-space: break-spaces
`;

const ColoredSquare = styled.div`
  background-color: ${props => props.color};
  width: 12px;
  height: 12px;
  border-radius: 4px;
`;

const ColoredSquareContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: -4px;
`;

class ManagedStudentOverviewPage extends React.PureComponent {

  constructor() {
    super();
    this.handleBreadcrumbItemClick = this.handleBreadcrumbItemClick.bind(this);
    this.handleTabSelect = this.handleTabSelect.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleEvaluateClick = this.handleEvaluateClick.bind(this);
    this.handleReturnDialogValidate = this.handleReturnDialogValidate.bind(this);
    this.handleApproveClick = this.handleApproveClick.bind(this);
    this.handleReturnSkillsAndCasesClick = this.handleReturnSkillsAndCasesClick.bind(this);
  }

  componentWillUnmount() {
    this.props.clearManagedStudentsOverviewPage();
  }

  render() {
    const { managedStudent, loading } = this.props;
    const localizationService = provideLocalizationService(this);
    const intlService = provideIntlService(this);
    const isCycleSubmitted = managedStudent?.cycleStatus === CYCLE_STUDENT_STATUS.SUBMITTED;
    return (
      <OverviewPage
        title={loading
          ? <Skeleton shape='text' style={{ width: 300, height: 28 }} />
          : managedStudent?.cycle
        }
        breadcrumbItems={[this.props.cycleManagerOverview
          ? {
            id: 'managed-students',
            text: localizationService.toLanguageString(`managedStudent.myResidents`)
          }
          : {
            id: 'approval-requests',
            text: localizationService.toLanguageString(`managedStudent.approvalRequests`)
          }, {
          id: 'managed-student',
          text: localizationService.toLanguageString('managedStudent.residentsCycle'),
          disabled: true
        }
        ]}
        headerActions={
          (
            <>
              {isCycleSubmitted && this.props.cycleManagerOverview &&
                <Button
                  themeColor='primary'
                  onClick={() => this.props.openReturnDialog({ submitDialogFunction: this.props.returnCycle })}>
                  {localizationService.toLanguageString('buttons.return')}
                </Button>
              }
              {!managedStudent?.evaluation && isCycleSubmitted && this.props.cycleManagerOverview &&
                <Button
                  id='edit-button'
                  type='button'
                  themeColor='primary'
                  onClick={this.handleEvaluateClick}
                >
                  {localizationService.toLanguageString('managedStudent.evaluate')}
                </Button>
              }
              {managedStudent?.approvalStatus === STUDY_PLAN_CYCLE_STUDENT_APPROVING_USER_STATUS.SUBMITTED && !this.props.cycleManagerOverview &&
                <>
                  <Button
                    type='button'
                    themeColor='primary'
                    onClick={() => this.props.openApproveDialog()}
                  >
                    {localizationService.toLanguageString('managedStudent.approve')}
                  </Button>
                  <Button
                    type='button'
                    themeColor='primary'
                    onClick={() => this.props.openReturnDialog({ submitDialogFunction: this.handleReturnSkillsAndCasesClick })}
                  >
                    {localizationService.toLanguageString('managedStudent.return')}
                  </Button>
                </>
              }
            </>
          )
        }
        onBreadcrumbItemClick={this.handleBreadcrumbItemClick}
        onBackClick={this.handleBackClick}
        summary={
          <StackLayout rowGap='8px'>
            <ValueItem
              label={localizationService.toLanguageString('managedStudent.cycleCredits')}
              labelWidth='140px'
              loading={loading}
            >
              {managedStudent?.cycleCredits}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('managedStudent.type')}
              labelWidth='140px'
              loading={loading}
            >
              {managedStudent?.type ? localizationService.toLanguageString(`cycleType.${managedStudent.type}`) : '-'}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('managedStudent.dateFromTo')}
              labelWidth='140px'
              loading={loading}
            >
              {managedStudent?.dateFrom && managedStudent?.dateTo
                ? `${DateFormatter(managedStudent?.dateFrom, intlService)} - ${DateFormatter(managedStudent?.dateTo, intlService)}`
                : '-'
              }
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('managedStudent.cycleStatus')}
              labelWidth='140px'
              loading={loading}
            >
              {managedStudent?.cycleStatus &&
                <InlineBadge themeColor={getStudentCycleStatusColor(managedStudent.cycleStatus)}>
                  {localizationService.toLanguageString('studyPlanCycleDepartmentStudentStatus.' + managedStudent.cycleStatus)}
                </InlineBadge>
              }
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('managedStudent.studyProgram')}
              labelWidth='140px'
              loading={loading}
            >
              {managedStudent?.studyProgram}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('managedStudent.departments')}
              labelWidth='140px'
              loading={loading}
            >
              {managedStudent?.departments?.length
                ? managedStudent?.departments.map((department, index) => (
                  <DepartmentContainer key={index}>{DatePeriodFormatter(department.dateFrom, department.dateTo, intlService)} {department.departmentName}</DepartmentContainer>
                ))
                : '-'
              }
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('managedStudent.resident')}
              labelWidth='140px'
              loading={loading}
            >
              {`${managedStudent?.residentName} ${managedStudent?.residentSurname}`}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('managedStudent.year')}
              labelWidth='140px'
              loading={loading}
            >
              {EmptyFormatter(managedStudent?.residentStudyYear)}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('managedStudent.evaluation')}
              labelWidth='140px'
              loading={loading}
            >
              {EmptyFormatter(DecimalFormatter(managedStudent?.evaluation, 0))}
            </ValueItem>
            {managedStudent?.cycleRequiresManagersConfirmation &&
              <>
                <br />
                <Text variant={'caption'} textColor={Colors.GRAY_50}>{localizationService.toLanguageString('managedStudent.skillsAndCasesStatuses')}</Text>
                <WrapLayout orientation='horizontal'>
                  {Object.entries(STUDY_PLAN_CYCLE_STUDENT_APPROVING_USER_STATUS).map(([key, value]) => {
                    if (![STUDY_PLAN_CYCLE_STUDENT_APPROVING_USER_STATUS.SUPPLEMENTED, STUDY_PLAN_CYCLE_STUDENT_APPROVING_USER_STATUS.UNAPPROVED].includes(value)) {
                      return (
                        <ValueItem
                          key={key}
                          label={localizationService.toLanguageString('studyPlanCycleStudentApprovingUserStatus.' + value)}
                          loading={loading}
                        >
                          <ColoredSquareContainer>
                            <ColoredSquare color={getStudyPlanCycleStudentApprovingUserStatusColorCode(value)} />
                          </ColoredSquareContainer>
                        </ValueItem>
                      );
                    }
                  })}
                </WrapLayout>
              </>
            }
          </StackLayout>
        }
        tabs={
          <>
            <TabStripContainer>
              <TabStripItem selected={this.props.selectedTab === 0} onClick={() => this.handleTabSelect({ selected: 0 })}>
                <TabStripBox selected={this.props.selectedTab === 0}>
                  <TabStripIcon>
                    <PaperAndPencilIcon />
                  </TabStripIcon>
                  <TabStripText>{localizationService.toLanguageString('managedStudent.tabstripSkillsTitle')}</TabStripText>
                </TabStripBox>
              </TabStripItem>
              <TabStripItem selected={this.props.selectedTab === 1} onClick={() => this.handleTabSelect({ selected: 1 })}>
                <TabStripBox selected={this.props.selectedTab === 1}>
                  <TabStripIcon>
                    <NotebookIcon />
                  </TabStripIcon>
                  <TabStripText>{localizationService.toLanguageString('managedStudent.tabstripCasesTitle')}</TabStripText>
                </TabStripBox>
              </TabStripItem>
              <TabStripItem selected={this.props.selectedTab === 2} onClick={() => this.handleTabSelect({ selected: 2 })}>
                <TabStripBox selected={this.props.selectedTab === 2}>
                  <TabStripIcon>
                    <OpenBookIcon />
                  </TabStripIcon>
                  <TabStripText>{localizationService.toLanguageString('managedStudent.tabstripTheoreticalPartsTitle')}</TabStripText>
                </TabStripBox>
              </TabStripItem>
            </TabStripContainer>
          </>
        }
      >
        {this.props.selectedTab === 0 &&
          <StudentCycleSkillsPage
            loading={this.props.studentSkillsLoading}
            readOnly={true}
            onLoadStudentCycleSkills={() => this.props.loadManagedStudentCycleSkills({ ...this.props.params })}
            onSkillExpand={(skillId, isAdditional) => this.props.expandSkill({ skillId, isAdditional })}
            data={this.props.skills}
            additionalData={this.props.additionalSkills}
            approvalStatus={managedStudent?.approvalStatus}
            userId={this.props.userId}
            cycleManagerOverview={this.props.cycleManagerOverview}
          />
        }
        {this.props.selectedTab === 1 &&
          <StudentCycleCasesPage
            loading={this.props.studentCasesLoading}
            readOnly={true}
            onLoadStudentCycleCases={() => this.props.loadManagedStudentCycleCases({ ...this.props.params })}
            onCaseExpand={(cycleCaseId, isAdditional) => this.props.expandCase({ cycleCaseId, isAdditional })}
            data={this.props.cases}
            additionalData={this.props.additionalCases}
            approvalStatus={managedStudent?.approvalStatus}
            userId={this.props.userId}
            cycleManagerOverview={this.props.cycleManagerOverview}
          />
        }
        {this.props.selectedTab === 2 &&
          <StudentCycleTheoreticalPartsPage
            loading={this.props.studentTheoreticalPartsLoading}
            readOnly={true}
            onLoadStudentCycleTheoreticalParts={() => this.props.loadManagedStudentCycleTheoreticalParts({ ...this.props.params })}
            theoreticalParts={this.props.theoreticalParts}
          />
        }
        {this.props.isReturnDialogVisible &&
          <FormDialog
            title={localizationService.toLanguageString('managedStudent.returnForCorrection')}
            onCancel={this.props.closeReturnDialog}
            onSubmit={this.props.submitDialogFunction}
            confirmButtonText={localizationService.toLanguageString('buttons.return')}
            initialValues={null}
            validator={(values) => this.handleReturnDialogValidate(values, localizationService)}
            validationResult={this.props.returnDialogValidationResult}
            render={() => (
              <Field
                name='comment'
                component={TextArea}
                label={localizationService.toLanguageString('managedStudent.comment')}
              />
            )}
          />
        }
        {this.props.isApproveDialogVisible &&
          <Dialog title={localizationService.toLanguageString('custom.confirm')} onClose={this.props.closeApproveDialog}>
            <Text>{localizationService.toLanguageString('managedStudent.approveDialogMessage')}</Text>
            <DialogActionsBar>
              <Button themeColor={'primary'} onClick={this.handleApproveClick}>{localizationService.toLanguageString('managedStudent.approve')}</Button>
              <Button onClick={this.props.closeApproveDialog}>{localizationService.toLanguageString('buttons.close')}</Button>
            </DialogActionsBar>
          </Dialog>
        }
      </OverviewPage>
    );
  }

  handleApproveClick() {
    this.props.approveStudyPlanCycleStudentSkillsAndCases({ studyPlanCycleStudentId: this.props.managedStudent.id });
  }
  handleReturnSkillsAndCasesClick(e) {
    this.props.returnStudyPlanCycleStudentSkillsAndCases({ studyPlanCycleStudentId: this.props.managedStudent.id, comment: e.comment });
  }

  handleTabSelect(e) {
    this.props.selectManagedStudentOverviewPageTab({
      selected: e.selected
    });
  }

  handleBreadcrumbItemClick(e) {
    if (e.id === 'managed-students') {
      this.props.navigate(`/managed_students`);
    }
    if (e.id === 'approval-requests') {
      this.props.navigate(`/approval_requests`);
    }
  }

  handleBackClick() {
    this.props.navigate(-1);
  }

  handleEvaluateClick() {
    this.props.navigate(`/managed_students/${this.props.params.studyPlanCycleStudentId}/surveys/${SURVEY_TYPES.RESIDENT_EVALUATION_SURVEY}`);
  }

  handleReturnDialogValidate(values, localizationService) {
    const errors = {};
    if (!values.comment) {
      errors.comment = localizationService.toLanguageString('validation.required');
    }
    return errors;
  }
}

registerForLocalization(ManagedStudentOverviewPage);
registerForIntl(ManagedStudentOverviewPage);

const mapStateToProps = state => ({
  managedStudent: state.managedStudentOverviewPage.managedStudent,
  skills: state.managedStudentOverviewPage.skills,
  additionalSkills: state.managedStudentOverviewPage.additionalSkills,
  cases: state.managedStudentOverviewPage.cases,
  additionalCases: state.managedStudentOverviewPage.additionalCases,
  theoreticalParts: state.managedStudentOverviewPage.theoreticalParts,
  loading: state.managedStudentOverviewPage.loading,
  selectedTab: state.managedStudentOverviewPage.selectedTab,
  studentSkillsLoading: state.managedStudentOverviewPage.studentSkillsLoading,
  studentCasesLoading: state.managedStudentOverviewPage.studentCasesLoading,
  studentTheoreticalPartsLoading: state.managedStudentOverviewPage.studentTheoreticalPartsLoading,
  isReturnDialogVisible: state.managedStudentOverviewPage.isReturnDialogVisible,
  isApproveDialogVisible: state.managedStudentOverviewPage.isApproveDialogVisible,
  userId: state.app.userId,
  submitDialogFunction: state.managedStudentOverviewPage.submitDialogFunction
});

const mapDispatchToProps = dispatch => ({
  loadManagedStudentCycleSkills: (payload) => dispatch(loadManagedStudentCycleSkills(payload)),
  loadManagedStudentCycleCases: (payload) => dispatch(loadManagedStudentCycleCases(payload)),
  loadManagedStudentCycleTheoreticalParts: (payload) => dispatch(loadManagedStudentCycleTheoreticalParts(payload)),
  selectManagedStudentOverviewPageTab: (payload) => dispatch(selectManagedStudentOverviewPageTab(payload)),
  expandSkill: (payload) => dispatch(expandSkill(payload)),
  expandCase: (payload) => dispatch(expandCase(payload)),
  openReturnDialog: (payload) => dispatch(openReturnDialog(payload)),
  closeReturnDialog: () => dispatch(closeReturnDialog()),
  returnCycle: (payload) => dispatch(returnCycle(payload)),
  clearManagedStudentsOverviewPage: () => dispatch(clearManagedStudentsOverviewPage()),
  openApproveDialog: () => dispatch(openApproveDialog()),
  closeApproveDialog: () => dispatch(closeApproveDialog()),
  approveStudyPlanCycleStudentSkillsAndCases: (payload) => dispatch(approveStudyPlanCycleStudentSkillsAndCases(payload)),
  returnStudyPlanCycleStudentSkillsAndCases: (payload) => dispatch(returnStudyPlanCycleStudentSkillsAndCases(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ManagedStudentOverviewPage));
