import React from 'react';
import { DateTimeFormatter } from '.';
import { provideIntlService, registerForIntl } from '@progress/kendo-react-intl';

export default class GridDateTimeCell extends React.PureComponent {

  render() {
    const intlService = provideIntlService(this);
    const value = this.props.dataItem[this.props.field]
      ? DateTimeFormatter(new Date(this.props.dataItem[this.props.field]), intlService)
      : '';
    return (
      <td className={this.props.className }>{value.replaceAll('-', '\u2011')}</td>
    );
  }

}

registerForIntl(GridDateTimeCell);
