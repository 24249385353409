export default {
  LOAD_STUDY_PLAN_CYCLE_SELECT_PAGE_START: 'LoadStudyPlanCycleSelectPageStart',
  FIND_STUDY_PLAN_CYCLE_SELECT_PAGE_START: 'FindStudyPlanCycleSelectPageStart',
  FIND_STUDY_PLAN_CYCLE_SELECT_PAGE_END: 'FindStudyPlanCycleSelectPageEnd',
  FILTER_STUDY_PLAN_CYCLE_SELECT_PAGE: 'FilterStudyPlanCycleSelectPage',
  SELECT_STUDY_PLAN_CYCLE_SELECT_PAGE: 'SelectStudyPlanCycleSelectPage',
  SELECT_ALL_STUDY_PLAN_CYCLE_SELECT_PAGE: 'SelectAllStudyPlanCycleSelectPage',
  ADD_SELECTED_CYCLES_STUDY_PLAN_CYCLE_SELECT_PAGE_START: 'AddSelectedCyclesStudyPlanCycleSelectPageStart',
  ADD_SELECTED_CYCLES_STUDY_PLAN_CYCLE_SELECT_PAGE_END: 'AddSelectedCyclesFindStudyPlanCycleSelectPageEnd',
  CLEANUP_STUDY_PLAN_CYCLE_SELECT_PAGE: 'CleanupStudyPlanCycleSelectPage'
};