export default {
  LOAD_STUDENT_COMPETENCY_SURVEY_SUBMIT_FORM_AVAILABLE_MANAGERS_START: 'LoadStudentCompetencySurveySubmitFormAvailableManagersStart',
  LOAD_STUDENT_COMPETENCY_SURVEY_SUBMIT_FORM_AVAILABLE_MANAGERS_END: 'LoadStudentCompetencySurveySubmitFormAvailableManagersEnd',
  SAVE_STUDENT_COMPETENCY_SURVEY_SUBMIT_FORM_REQUEST_EVALUATION: 'SaveStudentCompetencySurveySubmitFormRequestEvaluation',
  CLEAR_STUDENT_COMPETENCY_SURVEY_SUBMIT_FORM: 'ClearStudentCompetencySurveySubmitForm',
  TOGGLE_STUDENT_COMPETENCY_SURVEY_SUBMIT_FORM_TYPE: 'ToggleStudentCompetencySurveySubmitRequestFormType',
  LOAD_STUDENT_COMPETENCY_SURVEY_SUBMIT_FORM_EVALUATOR_ROLES: 'LoadStudentCompetencySurveySubmitFormEvaluatorRoles',
  LOAD_STUDENT_COMPETENCY_SURVEY_SUBMIT_FORM_AVAILABLE_DEPARTMENTS_START: 'LoadStudentCompetencySurveySubmitFormAvailableDepartmentsStart',
  LOAD_STUDENT_COMPETENCY_SURVEY_SUBMIT_FORM_AVAILABLE_DEPARTMENTS_END: 'LoadStudentCompetencySurveySubmitFormAvailableDepartmentsEnd',
  LOAD_STUDENT_COMPETENCY_SURVEY_SUBMIT_FORM_AVAILABLE_EXTERNAL_USERS_START: 'LoadStudentCompetencySurveySubmitFormAvailableExternalUsersStart',
  LOAD_STUDENT_COMPETENCY_SURVEY_SUBMIT_FORM_AVAILABLE_EXTERNAL_USERS_END: 'LoadStudentCompetencySurveySubmitFormAvailableExternalUsersEnd',
  LOAD_STUDENT_COMPETENCY_SURVERY_SUBMIT_FORM_SURVEY_TYPE: 'LoadStudentCompetencySurveySubmitFormSurveyType'
};