import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { registerForLocalization, provideLocalizationService } from '@progress/kendo-react-intl';
import { ComboBox } from '../../../ui';
import { loadUsers, closeUserSearchDialog, addUser } from '../actions/user-search-dialog-actions';
import FormDialog from '../../../ui/components/application/form-dialog';
import { Field } from '@progress/kendo-react-form';

class UserSearchDialog extends React.PureComponent {

  constructor() {
    super();
    this.handleValidate = this.handleValidate.bind(this);
    this.handleLoadUsers = this.handleLoadUsers.bind(this);
  }

  render() {
    const localizationService = provideLocalizationService(this);
    return (this.props.visible &&
      <>
        <FormDialog
          title={localizationService.toLanguageString('UserSearchDialog.title')}
          loading={this.props.loading}
          onSubmit={this.props.addUser}
          onCancel={this.props.closeDialog}
          validator={this.handleValidate}
          validationResult={this.props.userSearchDialogValidationResult}
          confirmButtonText={localizationService.toLanguageString('UserSearchDialog.add')}
          closeButtonText={localizationService.toLanguageString('UserSearchDialog.close')}
          width='320px'
          className='user-search-dialog'
          render={() => (
            <Field
              name='user'
              component={ComboBox}
              label={localizationService.toLanguageString('UserSearchDialog.search')}
              loading={this.props.availableUsersLoading}
              data={this.props.availableUsers}
              onFilter={this.handleLoadUsers}
              textField='text'
              valueField='id'
            />
          )}/>
        {this.props.loading && this.renderLoader()}
      </>
    );
  }

  renderLoader() {
    const loadingPanel = (
      <div className='k-loading-mask'>
        <div className='k-loading-image' />
        <div className='k-loading-color' />
      </div>
    );
    const dialogContent = document && document.querySelector('.user-search-dialog');
    return dialogContent
      ? ReactDOM.createPortal(loadingPanel, dialogContent)
      : loadingPanel;
  }

  handleValidate(values, localizationService, validationResult, modified) {
    const errors = {};
    if (!values.user || !values.user?.key) {
      errors.user = localizationService.toLanguageString('validation.required');
    }
    if (!modified.user && validationResult?.errors?.name) {
      errors.user = validationResult.errors.name;
    }
    return errors;
  }

  handleLoadUsers(e) {
    this.props.loadUsers(e);
  }
}

registerForLocalization(UserSearchDialog);

const mapStateToProps = state => ({
  loading: state.userSearchDialog.loading,
  availableUsersLoading: state.userSearchDialog.availableUsersLoading,
  availableUsers: state.userSearchDialog.availableUsers,
  visible: state.userSearchDialog.visible,
  onAdd: state.userSearchDialog.onAdd,
  userSearchDialogValidationResult: state.userSearchDialog.userSearchDialogValidationResult
});

const mapDispatchToProps = dispatch => ({
  loadUsers: (payload) => dispatch(loadUsers(payload)),
  closeDialog: (payload) => dispatch(closeUserSearchDialog(payload)),
  addUser: (payload) => dispatch(addUser(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSearchDialog);