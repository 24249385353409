import { pagingSettings } from '../../../utils/GridPagingUtils';
import StudyPlanListPageActionTypes from '../actions/study-plan-list-page-action-types';

const initialState = {
  loading: false,
  studyPlans: null,
  studyPlansCancelToken: null,
  skip: pagingSettings.paging.skip,
  take: pagingSettings.paging.take,
  total: 0,
  sorting: null,
  filter: null,

  filterStudyProgramsLoading: false,
  filterStudyProgramsCancelToken: null,
  filterStudyPrograms: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case StudyPlanListPageActionTypes.LOAD_STUDY_PLAN_LIST_START:
      return {
        ...state,
        loading: true
      };
    case StudyPlanListPageActionTypes.LOAD_STUDY_PLAN_LIST_END:
      return {
        ...state,
        loading: false,
        studyPlans: action.payload.studyPlans,
        total: action.payload.total,
        skip: action.payload.skip,
        take: action.payload.take
      };
    case StudyPlanListPageActionTypes.FILTER_STUDY_PLAN_LIST:
      return {
        ...state,
        filter: action.payload,
        skip: initialState.skip,
        take: initialState.take
      };
    case StudyPlanListPageActionTypes.SORT_STUDY_PLAN_LIST:
      return {
        ...state,
        sorting: action.payload
      };
    case StudyPlanListPageActionTypes.SEARCH_STUDY_PLAN_LIST_FILTER_STUDY_PROGRAM_START:
      return {
        ...state,
        filterStudyProgramsLoading: true
      };
    case StudyPlanListPageActionTypes.SEARCH_STUDY_PLAN_LIST_FILTER_STUDY_PROGRAM_END:
      return {
        ...state,
        filterStudyProgramsLoading: false,
        filterStudyPrograms: action.payload.studyPrograms
      };
    default:
      return state;
  }
};