
import React from 'react';
import { connect } from 'react-redux';
import { GridToolbar } from '@progress/kendo-react-grid';
import { provideLocalizationService, registerForIntl, registerForLocalization } from '@progress/kendo-react-intl';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { Grid } from '../../shared/components';
import { Text } from '../../../ui';
import { closeCycleSkillAbilityConfirmDialog, loadCycleSkillAbilityList, openCycleSkillAbilityConfirmDialog, removeCycleSkillAbilityConfirmDialog } from '../actions/cycle-skill-ability-list-actions';
import { withRouter } from '../../../components/withRouter';
import styled from 'styled-components';

const FooterCell = styled.td`
  text-align: right !important;
`;

class CyckleSkillAbilityList extends React.Component {
  constructor(props) {
    super(props);
    this.expandChange = this.expandChange.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleConfirmDialogClose = this.handleConfirmDialogClose.bind(this);
    this.handleRemoveConfirm = this.handleRemoveConfirm.bind(this);
  }

  async componentDidMount() {
    await this.props.loadCycleSkillAbilityList({
      cycleId: this.props.cycleId
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.cycleId !== prevProps.cycleId) {
      this.props.loadCycleSkillAbilityList({ cycleId: this.props.cycleId });
    }
  }

  render() {
    const {
      cycleSkillAbilities,
      loading,
      isConfirmDialogVisible,
      cycleSkillAbilitiesTotalMinRequiredLevelA,
      cycleSkillAbilitiesTotalMinRequiredLevelB,
      cycleSkillAbilitiesTotalMinRequiredLevelC,
      canWrite
    } = this.props;
    const localizationService = provideLocalizationService(this);
    return (
      <>
        <Grid
          loading={loading}
          data={cycleSkillAbilities}
          isPageable={false}
          columns={[
            {
              field: 'studyProgramSkillAbilityName',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('cycle.name'),
              footerCell: (props) =>
                <td colSpan={props.colSpan} style={props.style} className={props.className}>
                  {`${localizationService.toLanguageString('common.total')}:`}
                </td>
            }, {
              field: 'minRequiredLevelA',
              className: 'align-right',
              headerClassName: 'align-right',
              title: localizationService.toLanguageString('cycle.minSkillRequiredLevelATitle'),
              footerCell: (props) =>
                <FooterCell colSpan={props.colSpan} style={props.style} className={props.className}>
                  {cycleSkillAbilitiesTotalMinRequiredLevelA}
                </FooterCell>
            }, {
              field: 'minRequiredLevelB',
              className: 'align-right',
              headerClassName: 'align-right',
              title: localizationService.toLanguageString('cycle.minSkillRequiredLevelBTitle'),
              footerCell: (props) =>
                <FooterCell colSpan={props.colSpan} style={props.style} className={props.className}>
                  {cycleSkillAbilitiesTotalMinRequiredLevelB}
                </FooterCell>
            }, {
              field: 'minRequiredLevelC',
              className: 'align-right',
              headerClassName: 'align-right',
              title: localizationService.toLanguageString('cycle.minSkillRequiredLevelCTitle'),
              footerCell: (props) =>
                <FooterCell colSpan={props.colSpan} style={props.style} className={props.className}>
                  {cycleSkillAbilitiesTotalMinRequiredLevelC}
                </FooterCell>
            }
          ]}
          actions={
            canWrite ?
              [
                {
                  icon: 'edit',
                  onClick: this.handleEditClick,
                  disabledOn: () => this.props.isCopyingInProgress
                }, {
                  icon: 'delete',
                  onClick: this.handleRemoveClick,
                  disabledOn: () => this.props.isCopyingInProgress
                }
              ]
              :
              []
          }
        >
          {canWrite &&
            <GridToolbar>
              <Button themeColor='primary' onClick={this.handleAddClick} disabled={this.props.isCopyingInProgress}>
                {localizationService.toLanguageString('custom.add')}
              </Button>
            </GridToolbar>
          }

        </Grid>
        {isConfirmDialogVisible &&
          <Dialog title={localizationService.toLanguageString('custom.confirm')} onClose={this.handleConfirmDialogClose}>
            <Text>{localizationService.toLanguageString('cycle.cycleSkillAbilityDialogMessage')}</Text>
            <DialogActionsBar>
              <Button themeColor={'primary'} onClick={this.handleRemoveConfirm}>{localizationService.toLanguageString('buttons.delete')}</Button>
              <Button onClick={this.handleConfirmDialogClose}>{localizationService.toLanguageString('buttons.cancel')}</Button>
            </DialogActionsBar>
          </Dialog>
        }
      </>
    );
  }

  renderFooterCell(props) {
    const total = this.props.cycleSkillAbilities?.reduce((acc, current) => acc + current[props.field], 0);
    return (
      <FooterCell colSpan={props.colSpan} style={props.style} className={props.className}>
        {total}
      </FooterCell>
    );
  }

  expandChange(event) {
    event.dataItem.expanded = !event.dataItem.expanded;
    this.forceUpdate();
  };

  handleEditClick(row) {
    this.props.navigate(`/cycles/${this.props.cycleId}/skill_abilities/${row.dataItem.id}`);
  }

  handleAddClick() {
    this.props.navigate(`/cycles/${this.props.cycleId}/skill_abilities/create`);
  }

  handleRemoveClick(e) {
    this.props.openCycleSkillAbilityConfirmDialog(e.dataItem);
  }

  handleConfirmDialogClose() {
    this.props.closeCycleSkillAbilityConfirmDialog();
  }

  handleRemoveConfirm() {
    this.props.removeCycleSkillAbilityConfirmDialog({
      cycleId: this.props.cycleId
    });
  }
}

const mapStateToProps = (state) => ({
  cycleSkillAbilities: state.cycleSkillAbilityList.cycleSkillAbilities,
  loading: state.cycleSkillAbilityList.loading,
  isConfirmDialogVisible: state.cycleSkillAbilityList.isConfirmDialogVisible,
  cycleSkillAbilitiesTotalMinRequiredLevelA: state.cycleSkillAbilityList.cycleSkillAbilitiesTotalMinRequiredLevelA,
  cycleSkillAbilitiesTotalMinRequiredLevelB: state.cycleSkillAbilityList.cycleSkillAbilitiesTotalMinRequiredLevelB,
  cycleSkillAbilitiesTotalMinRequiredLevelC: state.cycleSkillAbilityList.cycleSkillAbilitiesTotalMinRequiredLevelC
});

const mapDispatchToProps = (dispatch) => ({
  loadCycleSkillAbilityList: (payload) => dispatch(loadCycleSkillAbilityList(payload)),
  openCycleSkillAbilityConfirmDialog: (payload) => dispatch(openCycleSkillAbilityConfirmDialog(payload)),
  closeCycleSkillAbilityConfirmDialog: (payload) => dispatch(closeCycleSkillAbilityConfirmDialog(payload)),
  removeCycleSkillAbilityConfirmDialog: (payload) => dispatch(removeCycleSkillAbilityConfirmDialog(payload))
});

registerForIntl(CyckleSkillAbilityList);
registerForLocalization(CyckleSkillAbilityList);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CyckleSkillAbilityList));
