export default {
  LOAD_COMPETENCY_SUBCOMPETENCY_SELECT_PAGE_START: 'LoadCompetencySubcompetencySelectPageStart',
  FIND_COMPETENCY_SUBCOMPETENCY_SELECT_PAGE_START: 'FindCompetencySubcompetencySelectPageStart',
  FIND_COMPETENCY_SUBCOMPETENCY_SELECT_PAGE_END: 'FindCompetencySubcompetencySelectPageEnd',
  FILTER_COMPETENCY_SUBCOMPETENCY_SELECT_PAGE: 'FilterCompetencySubcompetencySelectPage',
  SELECT_COMPETENCY_SUBCOMPETENCY_SELECT_PAGE: 'SelectCompetencySubcompetencySelectPage',
  SELECT_ALL_COMPETENCY_SUBCOMPETENCY_SELECT_PAGE: 'SelectAllCompetencySubcompetencySelectPage',
  ADD_SELECTED_SUBCOMPETENCIES_COMPETENCY_SUBCOMPETENCY_SELECT_PAGE_START: 'AddSelectedSubcompetenciesCompetencySubcompetencySelectPageStart',
  ADD_SELECTED_SUBCOMPETENCIES_COMPETENCY_SUBCOMPETENCY_SELECT_PAGE_END: 'AddSelectedSubcompetenciesFindCompetencySubcompetencySelectPageEnd',
  CLEANUP_COMPETENCY_SUBCOMPETENCY_SELECT_PAGE: 'CleanupCompetencySubcompetencySelectPage'
};