import { StackLayout } from '@progress/kendo-react-layout';
import * as React from 'react';
import { TextBox } from '../..';
import styled from 'styled-components';
import { Button } from '@progress/kendo-react-buttons';
import { Html5QrcodeScanner, Html5QrcodeSupportedFormats, Html5QrcodeScanType } from 'html5-qrcode';

const QrButton = styled(Button)`
  margin-top: 28px;
  margin-left: 5px;
  position: inline-block;
  height: 100%;
`;

const QR_DIV_ID = 'html5qr-code-full-region';
const VALID_CODE_COUNT = 3;

export default class QrScanner extends React.PureComponent {

  constructor(props) {
    super(props);
    this.handleExpand = this.handleExpand.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      opened: false,
      codes: [],
      position: 0
    };
    this.config = {
      fps: 10,
      qrbox: { width: 300, height: 60 },
      disableFlip: false,
      useBarCodeDetectorIfSupported: true,
      rememberLastUsedCamera: false,
      aspectRatio: 1,
      videoConstraints: {
        width: 4080,
        height: 2295,
        facingMode: 'environment'
      },
      formatsToSupport: [Html5QrcodeSupportedFormats.CODE_39, Html5QrcodeSupportedFormats.CODE_128],
      supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
      showTorchButtonIfSupported: true
    };
  }

  componentWillUnmount() {
    if (this.state.opened) {
      this.setState({ opened: false, codes: [], position: 0 });
      this.html5QrcodeScanner.clear();
    }
  }

  render() {
    return (
      <>
        <StackLayout orientation='horizontal' valign='top'>
          <TextBox
            {...this.props}
            width='280px'
          />
          <QrButton onClick={(e) => { e.stopPropagation(); this.handleExpand(); }} type='Button' >
            <span className='k-icon k-i-qr-code'></span>
          </QrButton>
        </StackLayout>
        <div hidden={!this.state.opened}>
          <div id={QR_DIV_ID} />
          <div id='html5-qrcode-anchor-scan-type-change'></div>
        </div>
      </>
    );
  }

  handleExpand() {
    this.setState({ opened: !this.state.opened });
    if (!this.state.opened) {
      this.setState({ codes: [], position: 0 });
      this.html5QrcodeScanner = new Html5QrcodeScanner(QR_DIV_ID, this.config);
      this.html5QrcodeScanner.render(this.handleChange);
    } else {
      this.html5QrcodeScanner.clear();
    }
  }

  handleChange(decodedText) {
    const codes = this.state.codes;
    let position = this.state.position;
    if (!/^[a-zA-Z]+$/.test(decodedText)) {
      codes[position] = decodedText;
      if (position < VALID_CODE_COUNT) {
        position = position + 1;
      } else {
        position = 0;
      }
      this.setState({ codes: codes, position: position });
      if (this.state.codes.filter(x => x === decodedText).length == VALID_CODE_COUNT) {
        this.html5QrcodeScanner.clear().then(_ => {
          this.props.onChange({ value: decodedText });
          this.setState({ opened: false });
        });
      }
    }
  }
}