import CompetencySurveyQuestionFormPageActionTypes from '../actions/competency-survey-question-form-page-action-types';

const initialState = {
  loading: false,
  competencySurveyQuestion: null,
  validationResult: null,
  isSubcompetencyMatching: true,
  greatestExistingSurveyQuestionNumber: null,

  availableSubcompetenciesLoading: false,
  availableSubcompetenciesCancelToken: null,
  availableSubcompetencies: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CompetencySurveyQuestionFormPageActionTypes.LOAD_COMPETENCY_SURVEY_QUESTION_FORM_PAGE_START:
      return {
        ...state,
        loading: true,
        validationResult: null
      };
    case CompetencySurveyQuestionFormPageActionTypes.LOAD_COMPETENCY_SURVEY_QUESTION_FORM_PAGE_END:
      return {
        ...state,
        loading: false,
        competencySurveyQuestion: action.payload ? action.payload.competencySurveyQuestion : null,
        validationResult: action.payload ? action.payload.validationResult : null,
        isSubcompetencyMatching: action.payload?.isSubcompetencyMatching ?? false,
        greatestExistingSurveyQuestionNumber: action.payload?.greatestExistingSurveyQuestionNumber
      };
    case CompetencySurveyQuestionFormPageActionTypes.LOAD_COMPETENCY_SURVEY_QUESTION_AVAILABLE_SUBCOMPETENCIES_START:
      return {
        ...state,
        availableSubcompetenciesLoading: true
      };
    case CompetencySurveyQuestionFormPageActionTypes.LOAD_COMPETENCY_SURVEY_QUESTION_AVAILABLE_SUBCOMPETENCIES_END:
      return {
        ...state,
        availableSubcompetenciesLoading: false,
        availableSubcompetencies: action.payload ? action.payload.availableSubcompetencies : null
      };
    case CompetencySurveyQuestionFormPageActionTypes.SET_COMPETENCY_SURVEY_QUESTION_SUBCOMPETENCY_MATCH_FLAG:
      return {
        ...state,
        isSubcompetencyMatching: action.payload
      };
    case CompetencySurveyQuestionFormPageActionTypes.CLEAR_COMPETENCY_SURVEY_QUESTION_FORM_PAGE:
      return initialState;
    default:
      return state;
  }
};