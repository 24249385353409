import { GridToolbar } from '@progress/kendo-react-grid';
import { Grid, GridSize } from '../../shared/components';
import { provideLocalizationService, registerForIntl, registerForLocalization } from '@progress/kendo-react-intl';
import React from 'react';
import { connect } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { Text } from '../../../ui';
import { closeCycleTheoreticalPartConfirmDialog, loadCycleTheoreticalPartsList, openCycleTheoreticalPartConfirmDialog, removeCycleTheoreticalPartConfirmDialog } from '..';
import { withRouter } from '../../../components/withRouter';

class CycleTheoreticalPartList extends React.Component {
  constructor(props) {
    super(props);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleConfirmDialogClose = this.handleConfirmDialogClose.bind(this);
    this.handleRemoveConfirm = this.handleRemoveConfirm.bind(this);
  }

  async componentDidMount() {
    await this.props.loadCycleTheoreticalPartsList({
      cycleId: this.props.cycleId
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.cycleId !== prevProps.cycleId) {
      this.props.loadCycleTheoreticalPartsList({ cycleId: this.props.cycleId });
    }
  }

  render() {
    const { cycleTheoreticalParts, loading, isConfirmDialogVisible, canWrite } = this.props;
    const localizationService = provideLocalizationService(this);
    return (
      <>
        <Grid
          loading={loading}
          data={cycleTheoreticalParts}
          isPageable={false}
          columns={[
            {
              field: 'studyProgramTheoreticalPartName',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('cycle.name')
            },
            {
              field: 'type',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('cycle.theoreticalPartType'),
              cell: ({ dataItem, className }) => (
                <td className={className}>
                  { dataItem.type ? localizationService.toLanguageString(`cycleTheoreticalPartType.${dataItem.type}`) : null}
                </td>
              )
            },
            {
              field: 'teachers',
              className: 'align-left',
              headerClassName: 'align-left',
              width: '200px',
              title: localizationService.toLanguageString('cycle.theoreticalPartTeacher'),
              cell: this.renderTeacher,
              minGridWidth: GridSize.small
            }
          ]}
          actions={
            canWrite ?
              [
                {
                  icon: 'edit',
                  onClick: this.handleEditClick,
                  disabledOn: () => this.props.isCopyingInProgress
                }, {
                  icon: 'delete',
                  onClick: this.handleRemoveClick,
                  disabledOn: () => this.props.isCopyingInProgress
                }
              ]
              :
              []
          }
        >
          {canWrite &&
            <GridToolbar>
              <Button themeColor='primary' onClick={this.handleAddClick} disabled={this.props.isCopyingInProgress}>
                {localizationService.toLanguageString('custom.add')}
              </Button>
            </GridToolbar>
          }
        </Grid>
        {isConfirmDialogVisible &&
          <Dialog title={localizationService.toLanguageString('custom.confirm')} onClose={this.handleConfirmDialogClose}>
            <Text>{localizationService.toLanguageString('cycle.cycleTheoreticalPartDialogMessage')}</Text>
            <DialogActionsBar>
              <Button themeColor={'primary'} onClick={this.handleRemoveConfirm}>{localizationService.toLanguageString('buttons.delete')}</Button>
              <Button onClick={this.handleConfirmDialogClose}>{localizationService.toLanguageString('buttons.cancel')}</Button>
            </DialogActionsBar>
          </Dialog>
        }
      </>
    );
  }

  renderTeacher(e) {
    const { dataItem, className } = e;
    let renderedValue = '';
    if (dataItem.teachers.length > 0) {
      renderedValue = dataItem.teachers[0].name;
      if (dataItem.teachers.length > 1) {
        renderedValue = renderedValue + '...';
      }
    }
    return (
      <td className={className}>
        {renderedValue}
      </td>
    );
  }

  expandChange(event) {
    event.dataItem.expanded = !event.dataItem.expanded;
    this.forceUpdate();
  };

  handleEditClick(row) {
    this.props.navigate(`/cycles/${this.props.cycleId}/theoretical_parts/${row.dataItem.id}`);
  }

  handleAddClick() {
    this.props.navigate(`/cycles/${this.props.cycleId}/theoretical_parts/create`);
  }

  handleRemoveClick(e) {
    this.props.openCycleTheoreticalPartConfirmDialog(e.dataItem);
  }

  handleConfirmDialogClose() {
    this.props.closeCycleTheoreticalPartConfirmDialog();
  }

  handleRemoveConfirm() {
    this.props.removeCycleTheoreticalPartConfirmDialog({
      cycleId: this.props.cycleId
    });
  }
}

const mapStateToProps = (state) => ({
  cycleTheoreticalParts: state.cycleTheoreticalPartList.cycleTheoreticalParts,
  loading: state.cycleTheoreticalPartList.loading,
  isConfirmDialogVisible: state.cycleTheoreticalPartList.isConfirmDialogVisible
});

const mapDispatchToProps = (dispatch) => ({
  loadCycleTheoreticalPartsList: (payload) => dispatch(loadCycleTheoreticalPartsList(payload)),
  openCycleTheoreticalPartConfirmDialog: (payload) => dispatch(openCycleTheoreticalPartConfirmDialog(payload)),
  closeCycleTheoreticalPartConfirmDialog: (payload) => dispatch(closeCycleTheoreticalPartConfirmDialog(payload)),
  removeCycleTheoreticalPartConfirmDialog: (payload) => dispatch(removeCycleTheoreticalPartConfirmDialog(payload))
});

registerForIntl(CycleTheoreticalPartList);
registerForLocalization(CycleTheoreticalPartList);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CycleTheoreticalPartList));