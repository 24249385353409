import axios from 'axios';
import { CycleSkillAbilityFormPageActionTypes } from '.';
import CycleSkillAbilityListActionTypes from './cycle-skill-ability-list-action-types';

export const loadCycleSkillAbilityList = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: CycleSkillAbilityListActionTypes.LOAD_CYCLE_SKILL_ABILITY_LIST_START });
  const state = getState().cycleSkillAbilityList;
  try {
    state.cycleSkillAbilitiesCancelToken?.cancel();
    state.cycleSkillAbilitiesCancelToken = axios.CancelToken.source();
    const cycleSkillAbilities = await api.get(`api/cycles/${payload.cycleId}/skill_abilities`, null, state.cycleSkillAbilitiesCancelToken.token);
    dispatch({
      type: CycleSkillAbilityListActionTypes.LOAD_CYCLE_SKILL_ABILITY_LIST_END,
      payload: {
        cycleSkillAbilities: cycleSkillAbilities?.data,
        cycleSkillAbilitiesTotalMinRequiredLevelA: cycleSkillAbilities?.data?.reduce((acc, current) => acc + current.minRequiredLevelA, 0),
        cycleSkillAbilitiesTotalMinRequiredLevelB: cycleSkillAbilities?.data?.reduce((acc, current) => acc + current.minRequiredLevelB, 0),
        cycleSkillAbilitiesTotalMinRequiredLevelC: cycleSkillAbilities?.data?.reduce((acc, current) => acc + current.minRequiredLevelC, 0)
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: CycleSkillAbilityListActionTypes.LOAD_CYCLE_SKILL_ABILITY_LIST_END });
      throw error;
    }
  }
};

export const openCycleSkillAbilityConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  dispatch({
    type: CycleSkillAbilityListActionTypes.TOGGLE_CYCLE_SKILL_ABILITY_LIST_CONFIRM_DIALOG,
    payload: payload
  });
};

export const closeCycleSkillAbilityConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  dispatch({
    type: CycleSkillAbilityListActionTypes.TOGGLE_CYCLE_SKILL_ABILITY_LIST_CONFIRM_DIALOG
  });
};

export const removeCycleSkillAbilityConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().cycleSkillAbilityList;
  if (state.selectedCycleSkillAbilityId > 0) {
    dispatch({ type: CycleSkillAbilityFormPageActionTypes.LOAD_CYCLE_SKILL_ABILITY_FORM_PAGE_START });
    try {
      await api.remove(`api/cycles/${payload.cycleId}/skill_abilities/${state.selectedCycleSkillAbilityId}`);
      dispatch(loadCycleSkillAbilityList({ cycleId: payload.cycleId }));
    } catch (error) {
      dispatch({ type: CycleSkillAbilityFormPageActionTypes.LOAD_CYCLE_SKILL_ABILITY_FORM_PAGE_END });
    }
  }
  dispatch({ type: CycleSkillAbilityListActionTypes.TOGGLE_CYCLE_SKILL_ABILITY_LIST_CONFIRM_DIALOG });
};