import React, { PureComponent } from 'react';
import * as ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';
import { FormElement } from '@progress/kendo-react-form';
import { Breadcrumb } from '@progress/kendo-react-layout';
import { Button } from '@progress/kendo-react-buttons';
import { elevation, respondTo } from '../shared';
import { StackLayout } from '../layouts';
import { Form, ErrorMessage, SuccessMessage, Text } from '../common';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { SCREEN_SIZES as ScreenSizes } from '../../../resources/screenSize';

const Container = styled.div`
  display: flex;
  align-self: stretch;
  justify-content: center;
  width: 100%;
  background-color: ${props => props.theme.gray15};
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: stretch;
  max-width: 1600px;
  min-width: 320px;
  position: relative;
  margin-top: -68px;
  padding-top: 68px;
  background-color: ${props => props.theme.white};
  ${elevation(1)}
`;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  padding: 0 16px 16px 16px;

  ${respondTo(ScreenSizes.XS, css`
    padding: 0 24px 24px 24px;
  `)}
`;

const Title = styled(Text).attrs(props => ({
  variant: 'h3',
  textColor: props.theme.gray80,
  truncate: true
}))`
  margin-top: 8px;
  margin-bottom: 8px;
  white-space: normal;
`;

const Header = styled(StackLayout).attrs(props => ({
  rowGap: '8px',
  columnGap: '16px',
  orientation: 'horizontal'
}))`
  margin: 0 6px;

  ${respondTo(ScreenSizes.XS, css`
    margin: 0 16px;
  `)}
`;

const customDelimiter = () => {
  return <span className={'k-icon k-i-divide'} />;
};

const BackButton = styled(Button).attrs(props => ({
}))`
  margin: 8px 8px;
  flex: 0 0 36px;
`;

export default class FormPage extends PureComponent {

  constructor() {
    super();
    this.handleCancelButtonClick = this.handleCancelButtonClick.bind(this);
  }

    static defaultProps = {
      saveButtonName: 'save',
      saveButtonText: 'Save',
      cancelButtonText: 'Cancel'
    }

    render() {
      const localizationService = provideLocalizationService(this);
      let errorMessage = '';
      if (this.props.validationResult && this.props.validationResult.errorMessage) {
        errorMessage = this.props.validationResult.errorMessage;
      }
      if (this.props.errorMessage) {
        errorMessage = this.props.errorMessage;
      }
      const successMessage = this.props.successMessage;
      return (
        <Container>
          <InnerContainer>
            <Header rowGap='8px'>
              <StackLayout>
                <StackLayout orientation='horizontal'>
                  {this.props.renderBackButton &&
                    <BackButton
                      id='back-button'
                      type='button'
                      primary='false'
                      onClick={this.props.onBackClick}
                      icon='undo'
                    />
                  }
                  {this.props.breadcrumbItems?.length > 0 &&
                    <Breadcrumb
                      data={this.props.breadcrumbItems}
                      onItemSelect={this.props.onBreadcrumbItemClick}
                      breadcrumbDelimiter={customDelimiter}
                    />
                  }
                </StackLayout>
                {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
              </StackLayout>
            </Header>
            <Main>
              {this.props.title && <Title>{this.props.title}</Title>}
              <Form
                onSubmit={this.props.onSubmit}
                onCancel={this.props.onCancel}
                key={JSON.stringify(this.props.initialValues)}
                initialValues={this.props.initialValues}
                validator={this.props.validator}
                localizationService={this.props.localizationService}
                validationResult={this.props.validationResult}
                formValueHandler={this.props.formValueHandler}
                ignoreModified={true}
                render={(formRenderProps) => (
                  <FormElement>
                    {this.props.render(formRenderProps)}
                    <div className='k-form-buttons'>
                      {!this.props.hideSaveButton &&
                        <Button
                          themeColor='primary'
                          type={'submit'}
                          disabled={!formRenderProps.allowSubmit}
                          icon={this.props.loading ? 'loading' : ''}
                        >
                          {this.props.submitButtonLabel || localizationService.toLanguageString('buttons.save')}
                        </Button>
                      }
                      <Button
                        type={'button'}
                        onClick={this.handleCancelButtonClick}
                      >
                        {this.props.cancelButtonLabel || localizationService.toLanguageString('buttons.cancel')}
                      </Button>
                    </div>
                    {this.props.loading && <FormLoadingPanel/>}
                  </FormElement>
                )}
              >
              </Form>
            </Main>
          </InnerContainer>
        </Container>
      );
    }

    handleCancelButtonClick() {
      if (this.props.onCancel) {
        this.props.onCancel();
      }
    }
}
registerForLocalization(FormPage);

class FormLoadingPanel extends React.Component {
  render() {
    const localizationService = provideLocalizationService(this);
    const loadingPanel = (
      <div className='k-loading-mask'>
        <span className='k-loading-text'>{localizationService.toLanguageString('custom.loading')}</span>
        <div className='k-loading-image' />
        <div className='k-loading-color' />
      </div>
    );
    const gridContent = document && document.querySelector('.k-grid');
    return gridContent
      ? ReactDOM.createPortal(loadingPanel, gridContent)
      : loadingPanel;
  }
}
registerForLocalization(FormLoadingPanel);
