import * as React from 'react';
import styled, { css } from 'styled-components';
import { Error } from '@progress/kendo-react-labels';
import { Rating as KendoRating, RatingItem } from '@progress/kendo-react-inputs';
import colors from '../shared/colors';
import { toRomanNumeral } from '../../../utils';

export const RATING_MODES = {
  ROMAN: 'Roman'
};
const ACCEPTABLE_LIGHTNESS_RANGE = 54;
const MIN = 1;
const MAX = 10;

const Container = styled.fieldset`
  ${props => props.$width && css`
    flex-grow: 0;
    flex-basis: auto;
    width: ${props.$width};
  `}
`;

const StyledRatingItem = styled(RatingItem)`
  padding: 0 !important;
`;

const RatingItemDiv = styled.div`
  ${props => css`
    border-top: 1px solid ${props.valid ? props.color : colors.ERROR_40};
    border-bottom: 1px solid ${props.valid ? props.color : colors.ERROR_40};
    transition: background-color .2s ease-in-out;
  `}
  ${props => (props.selected || props.hovered) && css`
    background-color: ${props.color};
  `}
  ${props => props.value === props.min && css`
    border-left: 1px solid ${props.valid ? props.color : colors.ERROR_40};
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  `}
  ${props => props.value === props.max && css`
    border-right: 1px solid ${props.valid ? props.color : colors.ERROR_40};
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  `}
  width: 30px;
  height: 30px;
`;

const TextDiv = styled.div`
  text-align: center;
  ${props => css`
    color: ${(props.selected || props.hovered) ? 'white' : props.color}
  `}
`;

const RatingComponent = styled(KendoRating)`
  margin: 0 !important;
`;

const ErrorMessage = styled(Error)`
  margin: 0;
  margin-left: 4px;
`;

class Rating extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      isRomanRating: this.props.mode === RATING_MODES.ROMAN
    };
  }

  render() {
    const { valid, validationMessage, ...rest } = this.props;
    const { minimum, maximum } = this.getMinMax();
    return (
      <Container $width={this.props.width} className={'k-form-fieldset'}>
        <RatingComponent
          {...rest}
          min={minimum}
          max={maximum}
          item={this.customItem}
          valid={valid}
          selection={this.state.isRomanRating ? 'single' : 'continues'}
        />
        {!valid && <ErrorMessage>{validationMessage}</ErrorMessage>}
      </Container>
    );
  }

  customItem = (customItemProps) => {
    const { value, hovered } = customItemProps;
    const { minimum, maximum } = this.getMinMax();
    const stepSize = ACCEPTABLE_LIGHTNESS_RANGE / (maximum - minimum + 1);
    const stepIndex = value - minimum + 1;
    const color = `hsl(208, 86%, ${90 - stepIndex * stepSize}%)`;
    let selected;
    if (this.state.isRomanRating) {
      selected = value == this.props.value;
    } else {
      selected = value <= this.props.value && this.props.value !== null;
    }
    return (
      <StyledRatingItem {...customItemProps}>
        <RatingItemDiv
          value={value}
          selected={selected}
          hovered={hovered}
          color={color}
          valid={this.props.valid}
          min={minimum}
          max={maximum}
        >
          <TextDiv selected={selected} hovered={hovered} color={color} >
            {this.state.isRomanRating ? toRomanNumeral(value) : value}
          </TextDiv>
        </RatingItemDiv>
      </StyledRatingItem>
    );
  }

  getMinMax = () => ({ minimum: this.getMinimum(), maximum: this.getMaximum() });
  getMinimum = () => typeof this.props.min === 'number' ? this.props.min : MIN;
  getMaximum = () => typeof this.props.max === 'number' ? this.props.max : MAX;
};

export default Rating;