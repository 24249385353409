import React from 'react';
import * as ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { withRouter } from '../../../components/withRouter';
import { registerForLocalization, provideLocalizationService } from '@progress/kendo-react-intl';
import { Button } from '@progress/kendo-react-buttons';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Field } from '@progress/kendo-react-form';
import { TextBox, FilterForm, WrapLayout, SelectListPage } from '../../../ui';
import { Grid } from '../../shared/components';
import { GridToolbar } from '@progress/kendo-react-grid';
import {
  loadCompetencySubcompetencySelectPage,
  findCompetencySubcompetencySelectPage,
  selectCompetencySubcompetencySelectPage,
  selectAllCompetencySubcompetencySelectPage,
  addSelectedCompetencySubcompetencySelectPage,
  filterCompetencySubcompetencySelectPage,
  clearCompetencySubcompetencySelectPage,
  cleanupCompetencySubcompetencySelectPage
} from '../actions';

class CompetencySubcompetencySelectPage extends React.PureComponent {

  constructor() {
    super();
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleAddSelectedButtonClick = this.handleAddSelectedButtonClick.bind(this);
    this.handleBreadcrumbItemClick = this.handleBreadcrumbItemClick.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  async componentDidMount() {
    if (this.props.params?.competencyId) {
      this.props.loadCompetencySubcompetencySelectPage({ competencyId: this.props.params.competencyId });
    }
  }

  componentWillUnmount() {
    this.props.cleanupCompetencySubcompetencySelectPage();
  }

  render() {
    const localizationService = provideLocalizationService(this);
    return (
      <>
        <SelectListPage
          id='competency-subcompetency-select'
          errorMessage={this.props.errorMessage}
          successMessage={this.props.successMessage}
          breadcrumbItems={[
            {
              id: 'competencies',
              text: localizationService.toLanguageString('competency.tieredCompetencies')
            },
            {
              id: 'competency',
              text: localizationService.toLanguageString('competency.tieredCompetency')
            },
            {
              id: 'competency-subcompetency-select',
              text: localizationService.toLanguageString('competency.subcompetencyAssignation'),
              disabled: true
            }
          ]}
          onBreadcrumbItemClick={this.handleBreadcrumbItemClick}
          onBackClick={this.handleCancel}
          filter={
            <FilterForm
              initialValues={this.props.filter}
              onSubmit={this.props.filterCompetencySubcompetencySelectPage}
              onClear={this.props.clearCompetencySubcompetencySelectPage}
              localizationService={localizationService}
              title={localizationService.toLanguageString('grid.filterTitle')}
              uniqueKey={JSON.stringify(this.props.filter)}
              render={() => (
                <WrapLayout>
                  <Field
                    name='keyword'
                    width='320px'
                    component={TextBox}
                    label={localizationService.toLanguageString('custom.search')}
                  />
                </WrapLayout>
              )}
            />
          }
        >
          <Grid
            loading={this.props.loading}
            data={this.props.availableSubcompetencies}
            skip={this.props.skip}
            take={this.props.take}
            pageSize={this.props.take}
            total={this.props.total}
            onPageChange={this.handlePageChange}
            selectable={{
              enabled: true,
              drag: false,
              cell: false,
              mode: 'multiple'
            }}
            dataItemKey='id'
            selectedField='isSelected'
            onSelectionChange={this.handleSelect}
            onHeaderSelectionChange={this.handleSelectAll}
            columns={[
              {
                field: 'isSelected',
                width: '50px',
                headerSelectionValue: this.props.selectedAll,
                cell: this.renderSelected
              }, {
                field: 'name',
                className: 'align-left',
                headerClassName: 'align-left',
                title: localizationService.toLanguageString('competency.name')
              }, {
                field: 'code',
                className: 'align-left',
                headerClassName: 'align-left',
                title: localizationService.toLanguageString('competency.code'),
                cell: this.renderType
              }, {
                field: 'groupName',
                className: 'align-left',
                headerClassName: 'align-left',
                title: localizationService.toLanguageString('competency.group')
              }
            ]}
          >
            <GridToolbar>
              <Button
                type='button'
                themeColor='primary'
                onClick={this.handleAddSelectedButtonClick}
              >
                {localizationService.toLanguageString('competency.addSelectedSubcompetencies')}
              </Button>
            </GridToolbar>
          </Grid>
        </SelectListPage>
        {this.props.savingSelectedSubcompetencies && this.renderLoader(localizationService)}
      </>
    );
  }

  renderSelected(e) {
    return (
      <td role='gridcell'>
        <Checkbox disabled={e.dataItem.isDisabled} checked={e.dataItem.isSelected} onChange={() => e.selectionChange(e)} />
      </td>
    );
  }

  async handlePageChange(e) {
    await this.props.findCompetencySubcompetencySelectPage({ skip: e.page.skip, take: e.page.take });
  }

  handleSelect(e) {
    if (e.dataItem) {
      this.props.selectCompetencySubcompetencySelectPage(e.dataItem);
    }
  }

  handleSelectAll() {
    this.props.selectAllCompetencySubcompetencySelectPage();
  }

  handleAddSelectedButtonClick() {
    const localizationService = provideLocalizationService(this);
    this.props.addSelectedCompetencySubcompetencySelectPage({ localizationService, competencyId: this.props.competencyId });
  }

  handleBreadcrumbItemClick(e) {
    if (e.id === 'competencies') {
      this.props.navigate('/competencies');
    } else if (e.id === 'competency') {
      this.props.navigate(`/competencies/${this.props.params.competencyId}/overview`);
    }
  }

  handleCancel() {
    this.props.navigate(-1);
  }

  renderLoader(localizationService) {
    const loadingPanel = (
      <div className='k-loading-mask'>
        <span className='k-loading-text'>{localizationService.toLanguageString('custom.loading')}</span>
        <div className='k-loading-image' />
        <div className='k-loading-color' />
      </div>
    );
    const gridContent = document && document.querySelector('#competency-subcompetency-select');
    return gridContent
      ? ReactDOM.createPortal(loadingPanel, gridContent)
      : loadingPanel;
  }
}

const mapStateToProps = state => ({
  competencyId: state.competencySubcompetencySelectPage.competencyId,
  loading: state.competencySubcompetencySelectPage.loading,
  availableSubcompetencies: state.competencySubcompetencySelectPage.availableSubcompetencies,
  skip: state.competencySubcompetencySelectPage.skip,
  take: state.competencySubcompetencySelectPage.take,
  total: state.competencySubcompetencySelectPage.total,
  selectedAll: state.competencySubcompetencySelectPage.selectedAll,
  selectedIds: state.competencySubcompetencySelectPage.selectedIds,
  savingSelectedSubcompetencies: state.competencySubcompetencySelectPage.savingSelectedSubcompetencies,
  errorMessage: state.competencySubcompetencySelectPage.errorMessage,
  successMessage: state.competencySubcompetencySelectPage.successMessage,
  filter: state.competencySubcompetencySelectPage.filter
});

const mapDispatchToProps = dispatch => ({
  loadCompetencySubcompetencySelectPage: (payload) => dispatch(loadCompetencySubcompetencySelectPage(payload)),
  findCompetencySubcompetencySelectPage: (payload) => dispatch(findCompetencySubcompetencySelectPage(payload)),
  selectCompetencySubcompetencySelectPage: (payload) => dispatch(selectCompetencySubcompetencySelectPage(payload)),
  selectAllCompetencySubcompetencySelectPage: (payload) => dispatch(selectAllCompetencySubcompetencySelectPage(payload)),
  addSelectedCompetencySubcompetencySelectPage: (payload) => dispatch(addSelectedCompetencySubcompetencySelectPage(payload)),
  filterCompetencySubcompetencySelectPage: (payload) => dispatch(filterCompetencySubcompetencySelectPage(payload)),
  clearCompetencySubcompetencySelectPage: () => dispatch(clearCompetencySubcompetencySelectPage()),
  cleanupCompetencySubcompetencySelectPage: () => dispatch(cleanupCompetencySubcompetencySelectPage())
});

registerForLocalization(CompetencySubcompetencySelectPage);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CompetencySubcompetencySelectPage));