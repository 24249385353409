import React from 'react';
import { connect } from 'react-redux';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { GridSize, Grid, InlineBadge, Loader } from '../../components';
import { loadStudentCompetencyListPage, findStudentCompetencies, sortStudentCompetencies, loadStudentCompetenciesListDetails, changeExpandState } from '../../student-competency/actions/student-competency-list-actions';
import { withRouter } from '../../../../components/withRouter';
import styled from 'styled-components';
import { Text, WrapLayout } from '../../../../ui';
import colors from '../../../../ui/components/shared/colors';
import { getStudentCompetencyStatusColor } from '../../../../utils/studentCompetencyStatusColors';
import { getStudentCycleStatusColor } from '../../../../utils/studyPlanCycleDepartmentStudentStatusColors';
import { Expand } from '@progress/kendo-react-animation';

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ExpandCell = styled.td`
  padding-left: 16px !important;
  padding-right: 0px !important;
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
  margin-top: 8px;
`;

const SubcompetencyContainer = styled.div`
  display: flex;
  align-items: center;
`;

const BadgeContainer = styled.div`
  width: 76px;
`;

const ListContainer = styled.div`
  margin-left: 8px;
  display: flex;
`;

const DetailsWrapper = styled.div`
  width: 320px;
`;

const SubcompetencyTextContainer = styled.div`
  width: fit-content;
`;

const TextContainer = styled.div`
  width: 240px;
`;

const DetailsContainer = styled.div`
  min-height: 80px;
  padding: 8px 0 8px 0;
  position: relative;
`;

const SubcompetencyBadge = styled.div`
  font-size: 10pt;
  display: inline-block;
  opacity: 75%;
  border-radius: 4px;
  padding: 4px;
  border: 2px solid ${props => props.color};
  color: ${props => props.color};
`;

const ExpandAnimation = styled(Expand)`
  z-index: 0;
`;

const SubcompetencyExpandAnimation = styled(Expand)`
  z-index: 0;
  display: flex;
  flex-wrap: wrap;
  column-gap: 2px;
`;

class StudentCompetencyList extends React.PureComponent {

  constructor() {
    super();
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.renderStatus = this.renderStatus.bind(this);
    this.handleExpandChange = this.handleExpandChange.bind(this);
    this.renderDetails = this.renderDetails.bind(this);
    this.renderCell = this.renderCell.bind(this);
    this.renderName = this.renderName.bind(this);
  }

  async componentDidMount() {
    await this.props.loadStudentCompetencyListPage({ studentId: this.props.studentId });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.studentId !== this.props.studentId) {
      this.props.loadStudentCompetencyListPage({ studentId: this.props.studentId });
    }
  }

  render() {
    const localizationService = provideLocalizationService(this);
    return (
      <Grid
        loading={this.props.loading}
        data={this.props.studentCompetencies}
        skip={this.props.skip}
        take={this.props.take}
        total={this.props.total}
        sort={this.props.sorting}
        onPageChange={this.handlePageChange}
        onSortChange={this.handleSort}
        expandField='expanded'
        onExpandChange={this.handleExpandChange}
        detail={this.renderDetails}
        cellRender={this.renderCell}
        onRowClick={this.props.onRowClick}
        columns={[
          {
            field: 'name',
            className: 'align-left',
            headerClassName: 'align-left',
            title: localizationService.toLanguageString('studentCompetency.name'),
            cell: this.renderName
          }, {
            field: 'abbreviation',
            className: 'align-left',
            headerClassName: 'align-left',
            title: localizationService.toLanguageString('studentCompetency.code'),
            minGridWidth: GridSize.large
          }, {
            field: 'status',
            className: 'align-left',
            headerClassName: 'align-left',
            title: localizationService.toLanguageString('studentCompetency.status'),
            sortable: false,
            cell: this.renderStatus
          }
        ]}
      />
    );
  }

  renderName(e) {
    const { className, dataItem } = e;
    const localizationService = provideLocalizationService(this);
    return (
      <td className={className} onClick={() => this.props.onRowClick(e)}>
        <NameContainer>
          {dataItem.name}
          <WrapLayout rowGap='0' columnGap='0'>
            <Text textColor={colors.GRAY_50} variant='caption'>
              {`${localizationService.toLanguageString('studentCompetency.cycles')}: ${dataItem.completedCyclesCount}/${dataItem.cycleCount}`}
            </Text>
            <Text textColor={colors.GRAY_50} variant='caption'>
              {`${localizationService.toLanguageString('studentCompetency.surveys')}: ${dataItem.pendingSurveyEvaluationCount}/${dataItem.completedSurveyEvaluationCount}/${dataItem.minimumSurveyEvaluationCount}`}
            </Text>
          </WrapLayout>
        </NameContainer>
      </td>
    );
  }

  renderCell(tdElement, cellProps) {
    if (cellProps.rowType === 'data') {
      if (cellProps.field === 'expanded') {
        return (
          <ExpandCell>
            <a
              onClick={() => this.handleExpandChange(cellProps)}
              className={`k-icon k-i-${cellProps.dataItem.expanded ? 'collapse' : 'expand'}`}
            />
          </ExpandCell>
        );
      }
    }

    return tdElement;
  };

  async handleExpandChange(e) {
    const { dataItem } = e;
    this.props.changeExpandState(dataItem);
    if (dataItem.expanded) {
      await this.props.loadStudentCompetenciesListDetails({ dataItem });
    }
  };

  renderDetails(props) {
    const { dataItem } = props;
    const localizationService = provideLocalizationService(this);
    return (
      <DetailsContainer>
        {dataItem.loading && <Loader/>}
        <WrapLayout>
          <DetailsWrapper>
            <Text variant='subtitle2'>{localizationService.toLanguageString('studentCompetency.cycles')}</Text>
            <ListContainer>
              <ExpandAnimation transitionExitDuration={0}>
                {dataItem.loading
                  ? null
                  : dataItem.cycles && dataItem.cycles.length > 0
                    ?
                    dataItem.cycles.map((element, index) => (
                      <ListItem key={index}>
                        <TextContainer>
                          <Text variant={'caption'} overflowWrap={true}>
                            {element.cycleName}
                          </Text>
                        </TextContainer>
                        <BadgeContainer>
                          {element.status &&
                            <InlineBadge themeColor={getStudentCycleStatusColor(element.status)}>
                              {localizationService.toLanguageString(`studyPlanCycleDepartmentStudentStatus.${element.status}`)}
                            </InlineBadge>
                          }
                        </BadgeContainer>
                      </ListItem>
                    ))
                    :
                    <ListItem>
                      <TextContainer>
                        <Text variant={'caption'}>
                          {localizationService.toLanguageString('grid.noRecords')}
                        </Text>
                      </TextContainer>
                    </ListItem>
                }
              </ExpandAnimation>
            </ListContainer>
          </DetailsWrapper>
          <DetailsWrapper>
            <Text variant='subtitle2'>{localizationService.toLanguageString('studentCompetency.subcompetencies')}</Text>
            <ListContainer>
              <SubcompetencyExpandAnimation>
                {dataItem.loading
                  ? null
                  : dataItem.subcompetencies && dataItem.subcompetencies.length > 0
                    ?
                    dataItem.subcompetencies.map((element, index) => (
                      <ListItem key={index}>
                        <SubcompetencyContainer>
                          <SubcompetencyTextContainer>
                            <SubcompetencyBadge color={element.isCompleted ? colors.SUCCESS_50 : colors.GRAY_50}>
                              {`${element.code}`}
                            </SubcompetencyBadge>
                          </SubcompetencyTextContainer>
                        </SubcompetencyContainer>
                      </ListItem>
                    ))
                    :
                    <ListItem>
                      <SubcompetencyContainer>
                        <TextContainer>
                          <Text variant={'caption'}>
                            {localizationService.toLanguageString('grid.noRecords')}
                          </Text>
                        </TextContainer>
                      </SubcompetencyContainer>
                    </ListItem>
                }
              </SubcompetencyExpandAnimation>
            </ListContainer>
          </DetailsWrapper>
        </WrapLayout>
      </DetailsContainer>
    );
  }

  renderStatus(e) {
    const { className, dataItem } = e;
    const localizationService = provideLocalizationService(this);
    return (
      <td className={className}>
        {dataItem.status &&
          <InlineBadge themeColor={getStudentCompetencyStatusColor(dataItem.status)}>
            {localizationService.toLanguageString(`studentCompetencyStatus.${dataItem.status}`)}
          </InlineBadge>
        }
      </td>
    );
  }

  handleSort(e) {
    this.props.sortStudentCompetencies(e.sort, { studentId: this.props.studentId });
  }

  async handlePageChange(e) {
    await this.props.findStudentCompetencies({ skip: e.page.skip, take: e.page.take, studentId: this.props.studentId });
  }
}

const mapStateToProps = state => ({
  loading: state.studentCompetencyList.loading,
  studentCompetencies: state.studentCompetencyList.studentCompetencies,
  skip: state.studentCompetencyList.skip,
  take: state.studentCompetencyList.take,
  total: state.studentCompetencyList.total,
  sorting: state.studentCompetencyList.sorting
});

const mapDispatchToProps = dispatch => ({
  loadStudentCompetencyListPage: (payload) => dispatch(loadStudentCompetencyListPage(payload)),
  findStudentCompetencies: (payload) => dispatch(findStudentCompetencies(payload)),
  sortStudentCompetencies: (payload, params) => dispatch(sortStudentCompetencies(payload, params)),
  loadStudentCompetenciesListDetails: (payload) => dispatch(loadStudentCompetenciesListDetails(payload)),
  changeExpandState: payload => dispatch(changeExpandState(payload))
});

registerForLocalization(StudentCompetencyList);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StudentCompetencyList));