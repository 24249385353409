export function periodsIntersect(from1, to1, from2, to2) {
  const period1 = { from: toDate(from1), to: toDate(to1) };
  const period2 = { from: toDate(from2), to: toDate(to2) };
  const intersection = !(period1.from < period2.from && period1.to < period2.from || period2.from < period1.from && period2.to < period1.from);
  return intersection;
};

export function isDateInPeriod(value, from, to) {
  const dateFrom = toDate(from);
  const dateTo = addDays(toDate(to));
  const date = toDate(value);
  return dateFrom <= date && date <= dateTo;
}

export const months = [
  'jan',
  'feb',
  'mar',
  'apr',
  'may',
  'jun',
  'jul',
  'aug',
  'sep',
  'oct',
  'nov',
  'dec'
];

function toDate(date) {
  return date instanceof Date ? date : new Date(date);
}

function addDays(date) {
  const result = new Date(date);
  result.setDate(result.getDate() + 1);
  return result;
}