import { Grid } from '../../shared/components';
import { provideLocalizationService, registerForIntl, registerForLocalization } from '@progress/kendo-react-intl';
import React from 'react';
import { connect } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { Text } from '../../../ui';
import { closeStudyProgramTheoreticalPartConfirmDialog, loadStudyProgramTheoreticalPartList, openStudyProgramTheoreticalPartConfirmDialog, removeStudyProgramTheoreticalPartConfirmDialog } from '../actions/study-program-theoretical-part-list-actions';
import { withRouter } from '../../../components/withRouter';

class StudyProgramTheoreticalPartList extends React.Component {
  constructor(props) {
    super(props);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleConfirmDialogClose = this.handleConfirmDialogClose.bind(this);
    this.handleRemoveConfirm = this.handleRemoveConfirm.bind(this);
  }

  async componentDidMount() {
    await this.props.loadStudyProgramTheoreticalPartList({
      studyProgramId: this.props.studyProgramId
    });
  }

  render() {
    const { studyProgramTheoreticalParts, loading, isConfirmDialogVisible, canWrite } = this.props;
    const localizationService = provideLocalizationService(this);
    return (
      <>
        <Grid
          loading={loading}
          data={studyProgramTheoreticalParts}
          isPageable={false}
          columns={[
            {
              field: 'name',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('studyProgram.name')
            }
          ]}
          actions={ canWrite &&
          [
            { icon: 'edit', onClick: this.handleEditClick },
            { icon: 'delete', onClick: this.handleRemoveClick }
          ]}
        />
        {isConfirmDialogVisible &&
          <Dialog title={localizationService.toLanguageString('custom.confirm')} onClose={this.handleConfirmDialogClose}>
            <Text>{`${localizationService.toLanguageString('studyProgram.studyProgramTheoreticalPartDialogMessage')} ${this.props.selectedStudyProgramTheoreticalPartAssignedCount}`}</Text>
            <DialogActionsBar>
              <Button themeColor={'primary'} onClick={this.handleRemoveConfirm}>{localizationService.toLanguageString('buttons.delete')}</Button>
              <Button onClick={this.handleConfirmDialogClose}>{localizationService.toLanguageString('buttons.cancel')}</Button>
            </DialogActionsBar>
          </Dialog>
        }
      </>
    );
  }

  expandChange(event) {
    event.dataItem.expanded = !event.dataItem.expanded;
    this.forceUpdate();
  };

  handleEditClick(row) {
    this.props.navigate(`/study_programs/${this.props.studyProgramId}/theoretical_parts/${row.dataItem.id}`);
  }

  handleRemoveClick(e) {
    this.props.openStudyProgramTheoreticalPartConfirmDialog(e.dataItem);
  }

  handleConfirmDialogClose() {
    this.props.closeStudyProgramTheoreticalPartConfirmDialog();
  }

  handleRemoveConfirm() {
    this.props.removeStudyProgramTheoreticalPartConfirmDialog({
      studyProgramId: this.props.studyProgramId
    });
  }
}

const mapStateToProps = (state) => ({
  studyProgramTheoreticalParts: state.studyProgramTheoreticalPartList.studyProgramTheoreticalParts,
  loading: state.studyProgramTheoreticalPartList.loading,
  isConfirmDialogVisible: state.studyProgramTheoreticalPartList.isConfirmDialogVisible,
  selectedStudyProgramTheoreticalPartAssignedCount: state.studyProgramTheoreticalPartList.selectedStudyProgramTheoreticalPartAssignedCount,
  canWrite: state.studyProgramTheoreticalPartList.canWrite
});

const mapDispatchToProps = (dispatch) => ({
  loadStudyProgramTheoreticalPartList: (payload) => dispatch(loadStudyProgramTheoreticalPartList(payload)),
  openStudyProgramTheoreticalPartConfirmDialog: (payload) => dispatch(openStudyProgramTheoreticalPartConfirmDialog(payload)),
  closeStudyProgramTheoreticalPartConfirmDialog: (payload) => dispatch(closeStudyProgramTheoreticalPartConfirmDialog(payload)),
  removeStudyProgramTheoreticalPartConfirmDialog: (payload) => dispatch(removeStudyProgramTheoreticalPartConfirmDialog(payload))
});

registerForIntl(StudyProgramTheoreticalPartList);
registerForLocalization(StudyProgramTheoreticalPartList);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StudyProgramTheoreticalPartList));