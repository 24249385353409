import DesignationOverviewPageActionTypes from './designation-overview-page-action-types';
import axios from 'axios';

export const loadDesignationOverviewPage = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: DesignationOverviewPageActionTypes.LOAD_DESIGNATION_OVERVIEW_PAGE_START });
  const { studyPlanId, studyPlanCycleId, studyPlanCycleStudentId, studyPlanCycleDepartmentStudentId } = payload;
  const state = getState().designationOverviewPage;
  try {
    state.designationCancelToken?.cancel();
    state.designationCancelToken = axios.CancelToken.source();
    const studyPlanCycleDepartmentStudent = await api.get(`api/study_plans/${studyPlanId}/cycles/${studyPlanCycleId}/students/${studyPlanCycleStudentId}/departments/${studyPlanCycleDepartmentStudentId}`, state.designationCancelToken.token);
    const designationDocumentPdf = await api.get(`api/study_plan_cycle_department_student/${studyPlanCycleDepartmentStudentId}/designation_download`, {}, state.designationCancelToken.token, 'blob');
    const designationHistory = await api.get(`api/study_plan_cycle_department_student/${studyPlanCycleDepartmentStudentId}/designation_history`, state.designationCancelToken.token);
    dispatch({
      type: DesignationOverviewPageActionTypes.LOAD_DESIGNATION_OVERVIEW_PAGE_END,
      payload: {
        designation: {
          ...studyPlanCycleDepartmentStudent,
          designationHistory: designationHistory?.data ?? [],
          designationPdfObjectUrl: designationDocumentPdf ? URL.createObjectURL(designationDocumentPdf) : null
        }
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: DesignationOverviewPageActionTypes.LOAD_DESIGNATION_OVERVIEW_PAGE_END });
      throw error;
    }
  }
};

export const cleanupDesignationOverviewPage = () => async(dispatch, getState) => {
  const state = getState().designationOverviewPage;
  URL.revokeObjectURL(state.designation?.designationPdfObjectUrl);
  dispatch({ type: DesignationOverviewPageActionTypes.CLEANUP_DESIGNATION_OVERVIEW_PAGE });
};