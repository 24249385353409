import React from 'react';
import ReactDOMClient from 'react-dom/client';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import configureStore from './store/configureStore';
import { App } from './features/common/components';
import registerServiceWorker from './registerServiceWorker';
import './features/common/components/App.scss';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import AuthorizationServiceAzureAd from './utils/authorizationServiceAzureAd';
import { ThemeProvider } from 'styled-components';
import { Theme } from './ui';
import LocalizationWrapper from './components/localizationWrapper';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const history = createBrowserHistory({ window, basename: baseUrl });
const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container);

window.authorizationService = new AuthorizationServiceAzureAd();

root.render(
  <ThemeProvider theme={Theme}>
    <Provider store={configureStore(history)}>
      <HistoryRouter history={history}>
        <LocalizationWrapper>
          <App />
        </LocalizationWrapper>
      </HistoryRouter>
    </Provider>
  </ThemeProvider>
);

registerServiceWorker();