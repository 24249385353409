export default {
  LOAD_STUDY_PLAN_CYCLE_DEPARTMENT_FORM_PAGE_START: 'LoadStudyPlanCycleDepartmentFormPageStart',
  LOAD_STUDY_PLAN_CYCLE_DEPARTMENT_FORM_PAGE_END: 'LoadStudyPlanCycleDepartmentFormPageEnd',
  LOAD_STUDY_PLAN_CYCLE_DEPARTMENT_AVAILABLE_DEPARTMENTS_START: 'LoadStudyPlanCycleDepartmentAvailableDepartmentsStart',
  LOAD_STUDY_PLAN_CYCLE_DEPARTMENT_AVAILABLE_DEPARTMENTS_END: 'LoadStudyPlanCycleDepartmentAvailableDepartmentsEnd',
  LOAD_STUDY_PLAN_CYCLE_DEPARTMENT_AVAILABLE_USERS_START: 'LoadStudyPlanCycleDepartmentAvailableUsersStart',
  LOAD_STUDY_PLAN_CYCLE_DEPARTMENT_AVAILABLE_USERS_END: 'LoadStudyPlanCycleDepartmentAvailableUsersEnd',
  LOAD_STUDY_PLAN_CYCLE_DEPARTMENT_AVAILABLE_CYCLE_MANAGERS_START: 'LoadStudyPlanCycleDepartmentAvailableCycleManagersStart',
  LOAD_STUDY_PLAN_CYCLE_DEPARTMENT_AVAILABLE_CYCLE_MANAGERS_END: 'LoadStudyPlanCycleDepartmentAvailableCycleManagersEnd',
  CHANGE_STUDY_PLAN_CYCLE_DEPARTMENT_WARNING_STATUS: 'ChangeStudyPlanCycleDepartmentWarningStatus'
};