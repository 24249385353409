import CompetencyFormPageActionTypes from '../actions/competency-form-page-action-types';

const initialState = {
  loading: false,
  competency: null,
  saveSuccess: false,
  validationResult: null,

  availableStudyProgramsLoading: false,
  availableStudyProgramsCancelToken: null,
  availableStudyPrograms: [],

  cancellationTokenSources: new Map()
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CompetencyFormPageActionTypes.LOAD_COMPETENCY_FORM_PAGE_START:
      return {
        ...state,
        loading: true,
        validationResult: null
      };
    case CompetencyFormPageActionTypes.LOAD_COMPETENCY_FORM_PAGE_END:
      return {
        ...state,
        loading: false,
        competency: action.payload ? action.payload.competency : null,
        validationResult: action.payload ? action.payload.validationResult : null
      };
    case CompetencyFormPageActionTypes.LOAD_COMPETENCY_AVAILABLE_STUDY_PROGRAMS_START:
      return {
        ...state,
        availableStudyProgramsLoading: true
      };
    case CompetencyFormPageActionTypes.LOAD_COMPETENCY_AVAILABLE_STUDY_PROGRAMS_END:
      return {
        ...state,
        availableStudyProgramsLoading: false,
        availableStudyPrograms: action.payload ? action.payload.studyPrograms : null
      };
    default:
      return state;
  }
};