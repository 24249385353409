export const SIGNALR_METHODS = {
  CLIENT: {
    SET_CYCLE_EVALUATION_REQUEST_COUNT: 'SetCycleEvaluationRequestCount',
    SET_BATCHED_CYCLE_EVALUATION_REQUEST_COUNT: 'SetBatchedCycleEvaluationRequestCount',
    SET_SURVEY_REQUEST_COUNT: 'SetSurveyRequestCount',
    SET_COMPETENCY_REQUEST_COUNT: 'SetCompetencyRequestCount',
    SET_STUDENT_CYCLE_CASE_AND_SKILL_APPROVAL_REQUEST_COUNT: 'SetStudentCycleCaseAndSkillApprovalRequestCount',
    SET_UNREAD_NOTIFICATIONS_COUNT: 'SetUnreadNotificationsCount',
    SET_UNREAD_NOTIFICATIONS_COUNT_INVALIDATED: 'SetUnreadNotificationsCountInvalidated',
    SEVER_CONNECTION: 'SeverConnection'
  },
  SERVER: {
    REQUESTS_HUB: {
      SEND_CYCLE_EVALUATION_REQUEST_COUNT: 'SendCycleEvaluationRequestCount',
      SEND_SURVEY_REQUEST_COUNT: 'SendSurveyRequestCount',
      SEND_COMPETENCY_REQUEST_COUNT: 'SendCompetencyRequestCount',
      SEND_STUDENT_CYCLE_CASE_AND_SKILL_APPROVAL_REQUEST_COUNT: 'SendStudentCycleCaseAndSkillApprovalRequestCount',
      SEND_USER_UNREAD_NOTIFICATIONS_COUNT: 'SendUserUnreadNotificationsCount'
    }
  }
};