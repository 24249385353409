import axios from 'axios';
import CycleCaseListActionTypes from './cycle-case-list-action-types';

export const loadCycleCaseList = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: CycleCaseListActionTypes.LOAD_CYCLE_CASE_LIST_START });
  const state = getState().cycleCaseList;
  try {
    state.cycleCasesCancelToken?.cancel();
    state.cycleCasesCancelToken = axios.CancelToken.source();
    const cycleCases = await api.get(`api/cycles/${payload.cycleId}/cases`, null, state.cycleCasesCancelToken.token);
    dispatch({
      type: CycleCaseListActionTypes.LOAD_CYCLE_CASE_LIST_END,
      payload: {
        cycleCases: cycleCases?.data,
        cycleCasesTotalDiagnoses: cycleCases?.data?.reduce((acc, current) => acc + (current?.diagnoses?.length ?? 0), 0),
        cycleCasesTotalMinRequiredNumber: cycleCases?.data?.reduce((acc, current) => acc + (current?.minRequiredNumber ?? 0), 0)
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: CycleCaseListActionTypes.LOAD_CYCLE_CASE_LIST_END });
      throw error;
    }
  }
};

export const openCycleCaseConfirmDialog = (payload) => async(dispatch) => {
  dispatch({
    type: CycleCaseListActionTypes.TOGGLE_CYCLE_CASE_LIST_CONFIRM_DIALOG,
    payload: payload
  });
};

export const closeCycleCaseConfirmDialog = () => async(dispatch) => {
  dispatch({ type: CycleCaseListActionTypes.TOGGLE_CYCLE_CASE_LIST_CONFIRM_DIALOG });
};

export const removeCycleCaseConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().cycleCaseList;
  if (state.selectedCycleCaseId > 0) {
    dispatch({ type: CycleCaseListActionTypes.LOAD_CYCLE_CASE_LIST_START });
    try {
      await api.remove(`api/cycles/${payload.cycleId}/cases/${state.selectedCycleCaseId}`);
      dispatch(loadCycleCaseList({ cycleId: payload.cycleId }));
    } catch (error) {
      dispatch({ type: CycleCaseListActionTypes.LOAD_CYCLE_CASE_LIST_END });
    }
  }
  dispatch({ type: CycleCaseListActionTypes.TOGGLE_CYCLE_CASE_LIST_CONFIRM_DIALOG });
};