import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Text, StackLayout } from '../../../ui';
import { Skeleton } from '@progress/kendo-react-indicators';

const ValueItemContainer = styled(StackLayout).attrs(props => ({
  orientation: 'horizontal',
  columnGap: '8px',
  align: 'left'
}))`
  flex-grow: 0;
`;

const LabelText = styled(Text).attrs(props => ({
  variant: 'subtitle2',
  textColor: props.theme.gray80
}))`
  min-width: ${props => props.labelWidth};
`;

const ValueText = styled(Text).attrs(props => ({
  variant: 'body2',
  textColor: props.theme.gray80,
  truncate: props.truncateValue ?? true
}))``;

export default class ValueItem extends PureComponent {

  render() {
    return (
      <ValueItemContainer>
        <LabelText labelWidth={this.props.labelWidth}>
          {this.props.label}:
        </LabelText>
        {this.renderContent()}
      </ValueItemContainer>
    );
  }

  renderContent() {
    if (this.props.loading) {
      return (
        <Skeleton shape='text' style={{ width: 80, height: 20 }} />
      );
    } else {
      if (!React.isValidElement(this.props.children)) {
        return (
          <ValueText truncateValue={this.props.truncateValue}>
            {this.props.children}
          </ValueText>
        );
      } else {
        return this.props.children;
      }
    }
  }
}