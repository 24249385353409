import { pagingSettings } from '../../../utils/GridPagingUtils';
import StudentListPageActionTypes from '../actions/student-list-page-action-types';

const initialState = {
  isInitialLoad: true,
  loading: false,
  students: null,
  studentsCancelToken: null,
  skip: pagingSettings.paging.skip,
  take: pagingSettings.paging.take,
  total: 0,
  sorting: null,
  filter: null,
  filterStudyProgramsLoading: false,
  filterStudyProgramsCancelToken: null,
  filterStudyPrograms: null,
  studentStatuses: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case StudentListPageActionTypes.SORT_STUDENT_LIST:
      return {
        ...state,
        sorting: action.payload
      };
    case StudentListPageActionTypes.LOAD_STUDENT_LIST_START:
      return {
        ...state,
        loading: true,
        isInitialLoad: false
      };
    case StudentListPageActionTypes.LOAD_STUDENT_LIST_END:
      return {
        ...state,
        loading: false,
        students: action.payload?.students,
        total: action.payload?.total,
        skip: action.payload?.skip,
        take: action.payload?.take
      };
    case StudentListPageActionTypes.FILTER_STUDENT_LIST:
      return {
        ...state,
        filter: action.payload,
        skip: initialState.skip,
        take: initialState.take
      };
    case StudentListPageActionTypes.SEARCH_STUDENT_LIST_FILTER_STUDY_PROGRAM_START:
      return {
        ...state,
        filterStudyProgramsLoading: true
      };
    case StudentListPageActionTypes.SEARCH_STUDENT_LIST_FILTER_STUDY_PROGRAM_END:
      return {
        ...state,
        filterStudyProgramsLoading: false,
        filterStudyPrograms: action.payload.studyPrograms
      };
    case StudentListPageActionTypes.LOAD_STUDENT_LIST_STUDENT_TYPES:
      return {
        ...state,
        studentStatuses: action.payload ? action.payload.studentStatuses : []
      };
    default:
      return state;
  }
};