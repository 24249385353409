import CycleCaseDiagnosisListActionTypes from '../actions/cycle-case-diagnosis-list-action-type';

const initialState = {
  loading: false,
  cycleCaseDiagnosis: null,
  cycleCaseDiagnosisCancelToken: null,
  isConfirmDialogVisible: false,
  isAddDialogVisible: false,
  selectedCycleCaseDiagnosisId: null,
  cycleId: null,
  cycleCaseId: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CycleCaseDiagnosisListActionTypes.LOAD_CYCLE_CASE_DIAGNOSIS_LIST_START:
      return {
        ...state,
        loading: true
      };
    case CycleCaseDiagnosisListActionTypes.LOAD_CYCLE_CASE_DIAGNOSIS_LIST_END:
      return {
        ...state,
        cycleCaseDiagnosis: action.payload.cycleCaseDiagnosis,
        loading: false,
        cycleId: action.payload.cycleId,
        cycleCaseId: action.payload.cycleCaseId
      };
    case CycleCaseDiagnosisListActionTypes.TOGGLE_CYCLE_CASE_DIAGNOSIS_LIST_CONFIRM_DIALOG:
      return {
        ...state,
        selectedCycleCaseDiagnosisId: action.payload?.id,
        isConfirmDialogVisible: !state.isConfirmDialogVisible
      };
    case CycleCaseDiagnosisListActionTypes.OPEN_CYCLE_CASE_DIAGNOSIS_TLK_DIALOG:
      return {
        ...state,
        isAddDialogVisible: true
      };
    case CycleCaseDiagnosisListActionTypes.CLOSE_CYCLE_CASE_DIAGNOSIS_TLK_DIALOG:
      return {
        ...state,
        isAddDialogVisible: false
      };
    default:
      return state;
  }
};