
import { Form } from '@progress/kendo-react-form';

export default class ExtendedForm extends Form {

  constructor(props) {
    super(props);
    this.modifiedAfterSubmit = {};
    this.onSubmit = (event) => {
      let touchedVisited = {};
      let fields = this.fields;
      if (event) {
        if (typeof event.preventDefault === 'function') {
          event.preventDefault();
        }
        if (typeof event.stopPropagation === 'function') {
          event.stopPropagation();
        }
      }
      fields.forEach(function(fieldName) {
        touchedVisited[fieldName] = true;
      });
      this.visited = touchedVisited;
      this.touched = touchedVisited;
      let values = this.values;
      let isValid = this.isFormValid(this.errors);
      let isModified = this.isFormModified(this.modified, fields);
      if (isValid && (this.props.ignoreModified || isModified)) {
        this._submitted = true;
        this.props.onSubmit(values, event);
        this.modifiedAfterSubmit = {};
      }
      this.forceUpdate();
    };
    this.isFormValid = (errors) => {
      const formValid = errors ? !Object.keys(errors).some(function(fieldName) { return Boolean(errors[fieldName]); }) : true;
      if (!formValid && this.props.validationResult) {
        return true;
      }
      return formValid;
    };
    this.onChange = (name, options) => {
      const value = options.value;
      this.addField(name);
      if (!this.modifiedAfterSubmit[name]) {
        this.modifiedAfterSubmit = {
          ...this.modifiedAfterSubmit,
          [name]: true
        };
      }
      if (!this.modified[name]) {
        this.modified = {
          ...this.modified,
          [name]: true
        };
      }
      this.valueSetter(name, value);
      this.forceUpdate();
      if (this.props.formValueHandler) {
        this.props.formValueHandler(this.values);
      }
    };
  }

  get formErrors() {
    if (this.props.validator) {
      return this.props.validator(this.values, this.props.localizationService, this.props.validationResult, this.modifiedAfterSubmit, this.touched);
    }
  }

  get errors() {
    return this.formErrors ? this.formErrors : {};
  }
};