import CompetencySurveyFormPageActionTypes from '../actions/competency-survey-form-page-action-types';

const initialState = {
  loading: false,
  competencySurvey: null,
  validationResult: null,
  studyProgramId: null,

  availableStudyPrograms: null,
  availableStudyProgramsLoading: false,
  availableStudyProgramsCancelToken: null,

  competencies: [],

  cancellationTokenSources: new Map()
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CompetencySurveyFormPageActionTypes.LOAD_COMPETENCY_SURVEY_FORM_PAGE_START:
      return {
        ...state,
        loading: true,
        validationResult: null
      };
    case CompetencySurveyFormPageActionTypes.LOAD_COMPETENCY_SURVEY_FORM_PAGE_END:
      return {
        ...state,
        loading: false,
        competencySurvey: action.payload ? action.payload.competencySurvey : null,
        validationResult: action.payload ? action.payload.validationResult : null,
        studyProgramId: action.payload ? action.payload.studyProgramId : null
      };
    case CompetencySurveyFormPageActionTypes.LOAD_COMPETENCY_SURVEY_AVAILABLE_COMPETENCIES_START:
      return {
        ...state,
        competencies: action.payload?.competencies ? action.payload?.competencies : []
      };
    case CompetencySurveyFormPageActionTypes.LOAD_COMPETENCY_SURVEY_AVAILABLE_COMPETENCIES_END:
      return {
        ...state,
        competencies: action.payload?.competencies ? action.payload?.competencies : []
      };
    case CompetencySurveyFormPageActionTypes.LOAD_COMPETENCY_SURVEY_AVAILABLE_STUDY_PROGRAMS_START:
      return {
        ...state,
        availableStudyProgramsLoading: true
      };
    case CompetencySurveyFormPageActionTypes.LOAD_COMPETENCY_SURVEY_AVAILABLE_STUDY_PROGRAMS_END:
      return {
        ...state,
        availableStudyProgramsLoading: false,
        availableStudyPrograms: action.payload ? action.payload.studyPrograms : null
      };
    case CompetencySurveyFormPageActionTypes.COMPETENCY_SURVEY_SELECT_STUDY_PROGRAM:
      return {
        ...state,
        studyProgramId: action.payload?.studyProgramId
      };
    default:
      return state;
  }
};