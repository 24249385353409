import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../../components/withRouter';
import { Text } from '../../../ui';
import { Grid, GridDateCell, GridSize } from '../../shared/components';
import { Button } from '@progress/kendo-react-buttons';
import {
  loadStudyPlanCycleStudentDepartmentsList,
  openStudyPlanCycleStudentDepartmentDeleteConfirmDialog,
  closeStudyPlanCycleStudentDepartmentDeleteConfirmDialog,
  deleteStudyPlanCycleStudentDepartment,
  submitStudyPlanCycleDepartmentStudentDesignation
} from '..';
import { GridToolbar } from '@progress/kendo-react-grid';

class StudyPlanCycleStudentDepartmentsList extends React.PureComponent {
  constructor() {
    super();
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
    this.handleConfirmDeleteDialogClose = this.handleConfirmDeleteDialogClose.bind(this);
    this.handleSubmitClick = this.handleSubmitClick.bind(this);
  }

  async componentDidMount() {
    await this.props.loadStudyPlanCycleStudentDepartmentsList({
      studyPlanId: this.props.studyPlanId,
      studyPlanCycleId: this.props.studyPlanCycleId,
      studyPlanCycleStudentId: this.props.studyPlanCycleStudentId
    });
  }

  render() {
    const { loading, studentDepartments, isConfirmDialogVisible } = this.props;
    const localizationService = provideLocalizationService(this);
    return (
      <>
        <Grid
          loading={loading}
          data={studentDepartments}
          isPageable={false}
          dataItemKey={'id'}
          columns={[
            {
              field: 'studyPlanCycleDepartmentName',
              title: localizationService.toLanguageString('StudyPlanCycleStudentOverviewPage.department')
            },
            {
              field: 'dateFrom',
              title: localizationService.toLanguageString('StudyPlanCycleStudentOverviewPage.dateFrom'),
              cell: GridDateCell,
              minGridWidth: GridSize.small
            },
            {
              field: 'dateTo',
              title: localizationService.toLanguageString('StudyPlanCycleStudentOverviewPage.dateTo'),
              cell: GridDateCell,
              minGridWidth: GridSize.small
            },
            {
              field: 'credits',
              title: localizationService.toLanguageString('StudyPlanCycleStudentOverviewPage.credits')
            },
            {
              field: 'year',
              title: localizationService.toLanguageString('StudyPlanCycleStudentOverviewPage.year'),
              minGridWidth: GridSize.small
            }
          ]}
          actions={[
            { icon: 'edit', onClick: this.handleEditClick },
            { icon: 'delete', onClick: this.handleDeleteClick },
            { label: localizationService.toLanguageString('studentCompetency.request'),
              onClick: this.handleSubmitClick,
              disabledOn: (e) => !e.dataItem.isDepartmentExternal || e.dataItem.isDepartmentDesignationSubmitted
            }
          ]}
        >
          <GridToolbar>
            <Button
              type='action'
              themeColor='primary'
              onClick={this.handleAddClick}
            >
              {localizationService.toLanguageString('StudyPlanCycleStudentOverviewPage.addNew')}
            </Button>
          </GridToolbar>
        </Grid>
        {isConfirmDialogVisible &&
          <Dialog title={localizationService.toLanguageString('StudyPlanCycleStudentOverviewPage.deleteConfirmDialogTitle')} onClose={this.handleConfirmDeleteDialogClose}>
            <Text>{localizationService.toLanguageString('StudyPlanCycleStudentOverviewPage.deleteConfirmDialogText')}</Text>
            <DialogActionsBar>
              <Button onClick={this.handleConfirmDelete} themeColor='primary'>{localizationService.toLanguageString('buttons.delete')}</Button>
              <Button onClick={this.handleConfirmDeleteDialogClose}>{localizationService.toLanguageString('buttons.cancel')}</Button>
            </DialogActionsBar>
          </Dialog>
        }
      </>
    );
  }

  handleEditClick(e) {
    this.props.navigate(`/study_plans/${this.props.params.studyPlanId}/cycles/${this.props.params.studyPlanCycleId}/students/${this.props.params.studyPlanCycleStudentId}/departments/${e.dataItem.id}`);
  }

  handleAddClick() {
    this.props.navigate(`/study_plans/${this.props.params.studyPlanId}/cycles/${this.props.params.studyPlanCycleId}/students/${this.props.params.studyPlanCycleStudentId}/departments/create`);
  }

  handleDeleteClick(e) {
    this.props.openStudyPlanCycleStudentDepartmentDeleteConfirmDialog({
      studyPlanCycleDepartmentStudentId: e.dataItem.id
    });
  }

  handleSubmitClick(e) {
    this.props.submitStudyPlanCycleDepartmentStudentDesignation({
      studyPlanCycleDepartmentStudentId: e.dataItem.id,
      studyPlanId: this.props.params.studyPlanId,
      studyPlanCycleId: this.props.params.studyPlanCycleId,
      studyPlanCycleStudentId: this.props.params.studyPlanCycleStudentId
    });
  }

  async handleConfirmDelete() {
    await this.props.deleteStudyPlanCycleStudentDepartment();
  }

  handleConfirmDeleteDialogClose() {
    this.props.closeStudyPlanCycleStudentDepartmentDeleteConfirmDialog();
  }
}

const mapStateToProps = (state) => ({
  loading: state.studyPlanCycleStudentDepartmentsList.loading,
  studentDepartments: state.studyPlanCycleStudentDepartmentsList.studentDepartments,
  isConfirmDialogVisible: state.studyPlanCycleStudentDepartmentsList.isConfirmDialogVisible
});

const mapDispatchToProps = (dispatch) => ({
  loadStudyPlanCycleStudentDepartmentsList: (payload) => dispatch(loadStudyPlanCycleStudentDepartmentsList(payload)),
  openStudyPlanCycleStudentDepartmentDeleteConfirmDialog: (payload) => dispatch(openStudyPlanCycleStudentDepartmentDeleteConfirmDialog(payload)),
  closeStudyPlanCycleStudentDepartmentDeleteConfirmDialog: () => dispatch(closeStudyPlanCycleStudentDepartmentDeleteConfirmDialog()),
  deleteStudyPlanCycleStudentDepartment: () => dispatch(deleteStudyPlanCycleStudentDepartment()),
  submitStudyPlanCycleDepartmentStudentDesignation: (payload) => dispatch(submitStudyPlanCycleDepartmentStudentDesignation(payload))
});

registerForLocalization(StudyPlanCycleStudentDepartmentsList);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StudyPlanCycleStudentDepartmentsList));