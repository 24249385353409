import MySurveyFormPageActionTypes from '../actions/my-survey-form-page-action-types';

const initialState = {
  loading: false,
  mySurvey: null,
  validationResult: null,
  mySurveyFormPageCancelToken: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MySurveyFormPageActionTypes.LOAD_MY_SURVEY_FORM_PAGE_START:
      return {
        ...state,
        loading: true,
        validationResult: null
      };
    case MySurveyFormPageActionTypes.LOAD_MY_SURVEY_FORM_PAGE_END:
      return {
        ...state,
        loading: false,
        mySurvey: action.payload ? action.payload.mySurvey : null,
        validationResult: action.payload ? action.payload.validationResult : null
      };
    default:
      return state;
  }
};