import * as React from 'react';
import { connect } from 'react-redux';
import { Field } from '@progress/kendo-react-form';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { ComboBox, FilterForm, StackLayout, TextBox, WrapLayout } from '../../../ui';
import { filterSurveys, clearSurveysFilter, searchSurveysFilterStudyPrograms } from '../actions/survey-list-page-actions';

class SurveyListFilter extends React.PureComponent {

  render() {
    const {
      filter,
      clearSurveysFilter,
      filterSurveys,
      filterStudyProgramsLoading,
      filterStudyPrograms,
      searchSurveysFilterStudyPrograms
    } = this.props;
    const localizationService = provideLocalizationService(this);
    return (
      <FilterForm
        initialValues={filter}
        onSubmit={filterSurveys}
        onClear={clearSurveysFilter}
        localizationService={localizationService}
        title={localizationService.toLanguageString('grid.filterTitle')}
        uniqueKey={JSON.stringify(filter)}
        render={() => (
          <WrapLayout>
            <StackLayout width='340px'>
              <Field
                name='studyProgram'
                width='340px'
                component={ComboBox}
                label={localizationService.toLanguageString('competency.studyProgram')}
                textField='name'
                dataItemKey='id'
                loading={filterStudyProgramsLoading}
                data={filterStudyPrograms}
                onFilter={searchSurveysFilterStudyPrograms}
              />
            </StackLayout>
            <StackLayout width='340px'>
              <Field
                name='keyword'
                width='340px'
                component={TextBox}
                label={localizationService.toLanguageString('custom.search')}
              />
            </StackLayout>
          </WrapLayout>
        )}
      />
    );
  }
}

const mapStateToProps = state => ({
  filter: state.surveyListPage.filter,
  filterStudyProgramsLoading: state.surveyListPage.filterStudyProgramsLoading,
  filterStudyPrograms: state.surveyListPage.filterStudyPrograms
});

const mapDispatchToProps = dispatch => ({
  filterSurveys: (payload) => dispatch(filterSurveys(payload)),
  clearSurveysFilter: () => dispatch(clearSurveysFilter()),
  searchSurveysFilterStudyPrograms: (payload) => dispatch(searchSurveysFilterStudyPrograms(payload))
});

registerForLocalization(SurveyListFilter);

export default connect(mapStateToProps, mapDispatchToProps)(SurveyListFilter);