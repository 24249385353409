import CompetencySurveyFormPageActionTypes from './competency-survey-form-page-action-types';
import ValidationError from '../../../utils/validation-error';
import axios from 'axios';

export const loadCompetencySurveyFormPage = ({ competencyId, surveyId, localizationService }) => async(dispatch, getState, { api }) => {
  dispatch({ type: CompetencySurveyFormPageActionTypes.LOAD_COMPETENCY_SURVEY_FORM_PAGE_START });
  try {
    if (surveyId > 0) {
      const dataConsumerIndexes = [];
      const survey = await api.get(`api/surveys/${surveyId}`);
      const surveyCompetencies = await api.get(`api/surveys/${surveyId}/competencies`);
      dispatch({
        type: CompetencySurveyFormPageActionTypes.LOAD_COMPETENCY_SURVEY_FORM_PAGE_END,
        payload: {
          competencySurvey: {
            ...survey,
            surveyName: survey?.name,
            surveyCode: survey?.code,
            surveyType: survey.type ? { value: survey.type, text: localizationService.toLanguageString(`surveyType.${survey.type}`) } : null,
            studyProgram: survey.studyProgramId ? { id: survey.studyProgramId, name: survey.studyProgramName } : null,
            competencies: surveyCompetencies?.data?.map((element, index) => {
              dataConsumerIndexes.push(index);
              return { competency: { id: element.competencyId, name: element.competencyName }, minCount: element.minCount };
            })
          },
          studyProgramId: survey?.studyProgramId
        }
      });
      dataConsumerIndexes.forEach(element => {
        dispatch(loadAvailableCompetencies({ index: element }));
      });
    } else if (competencyId > 0) {
      const competency = await api.get(`api/competencies/${competencyId}`);
      dispatch({
        type: CompetencySurveyFormPageActionTypes.LOAD_COMPETENCY_SURVEY_FORM_PAGE_END,
        payload: {
          competencySurvey: {
            studyProgram: competency.studyProgramId ? { id: competency.studyProgramId, name: competency.studyProgramName } : null,
            competencies: [{ competency: { id: competency.id, name: competency.name }, minCount: null }]
          },
          studyProgramId: competency?.studyProgramId
        }
      });
    } else {
      dispatch({ type: CompetencySurveyFormPageActionTypes.LOAD_COMPETENCY_SURVEY_FORM_PAGE_END });
    }
  } catch (error) {
    dispatch({ type: CompetencySurveyFormPageActionTypes.LOAD_COMPETENCY_SURVEY_FORM_PAGE_END });
    throw error;
  }
  dispatch(loadAvailableStudyPrograms());
};

export const saveCompetencySurvey = (payload) => async(dispatch, getState, { api, history }) => {
  dispatch({ type: CompetencySurveyFormPageActionTypes.LOAD_COMPETENCY_SURVEY_FORM_PAGE_START });
  const state = getState().competencySurveyFormPage;
  const surveyApiModel = {
    ...payload,
    name: payload?.surveyName,
    code: payload?.surveyCode,
    type: payload?.surveyType?.value,
    studyProgramId: payload?.studyProgram?.id
  };
  let { id, competencyId } = payload;
  try {
    if (id > 0) {
      await api.put(`api/surveys/${id}`, surveyApiModel);
    } else {
      id = await api.post(`api/surveys`, surveyApiModel);
    }
    const competencySurveysApiModel = payload?.competencies?.map(element => (
      {
        competencyId: element.competency.id,
        surveyId: id,
        minCount: element.minCount
      }
    ));
    if (id > 0) {
      await api.post(`api/surveys/${id}/competencies`, competencySurveysApiModel ?? []);
    }

    let competencySurveyId = null;
    if (competencyId > 0) {
      const competencySurveys = await api.get(`api/competencies/${competencyId}/surveys`);
      competencySurveyId = competencySurveys?.data?.find(element => element.surveyId === id)?.id;
    }

    dispatch({
      type: CompetencySurveyFormPageActionTypes.LOAD_COMPETENCY_SURVEY_FORM_PAGE_END,
      payload: {
        competencySurvey: {
          ...state.competencySurvey,
          id: id
        }
      }
    });

    payload.afterSubmit(competencySurveyId);
  } catch (error) {
    let validationResult = null;
    if (error instanceof ValidationError) {
      validationResult = {
        errorMessage: error.message,
        errors: error.errors
      };
    }
    dispatch({
      type: CompetencySurveyFormPageActionTypes.LOAD_COMPETENCY_SURVEY_FORM_PAGE_END,
      payload: {
        validationResult,
        competencySurvey: state.competencySurvey
      }
    });
  }
};

export const selectStudyProgram = (payload) => (dispatch, getState) => {
  const state = getState().competencySurveyFormPage;
  dispatch({
    type: CompetencySurveyFormPageActionTypes.COMPETENCY_SURVEY_SELECT_STUDY_PROGRAM,
    payload: {
      studyProgramId: payload.studyProgram?.id
    }
  });
  state.competencies?.forEach((element, index) => {
    dispatch(loadAvailableCompetencies({ index }));
  });
};

export const loadAvailableStudyPrograms = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: CompetencySurveyFormPageActionTypes.LOAD_COMPETENCY_SURVEY_AVAILABLE_STUDY_PROGRAMS_START });
  try {
    const state = getState().competencySurveyFormPage;
    const query = {
      skip: 0,
      limit: 50,
      keyword: payload?.keyword
    };
    state.availableStudyProgramsCancelToken?.cancel();
    state.availableStudyProgramsCancelToken = axios.CancelToken.source();
    const studyPrograms = await api.get(`api/study_programs`, query, state.availableStudyProgramsCancelToken.token);
    dispatch({
      type: CompetencySurveyFormPageActionTypes.LOAD_COMPETENCY_SURVEY_AVAILABLE_STUDY_PROGRAMS_END,
      payload: {
        studyPrograms: studyPrograms ? studyPrograms.data : []
      }
    });
  } catch (error) {
    dispatch({ type: CompetencySurveyFormPageActionTypes.LOAD_COMPETENCY_SURVEY_AVAILABLE_STUDY_PROGRAMS_END });
    throw error;
  }
};

export const loadAvailableCompetencies = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().competencySurveyFormPage;
  if (state.studyProgramId > 0) {
    let competencies = [...state.competencies];
    competencies[payload.index] = {
      loading: true,
      availableCompetencies: competencies[payload.index]?.availableCompetencies ? [...competencies[payload.index].availableCompetencies] : []
    };
    dispatch({
      type: CompetencySurveyFormPageActionTypes.LOAD_COMPETENCY_SURVEY_AVAILABLE_COMPETENCIES_START,
      payload: {
        competencies: competencies
      }
    });
    const query = {
      skip: 0,
      limit: 50,
      keyword: payload.keyword,
      studyProgramId: state.studyProgramId
    };
    try {
      const cancellationTokenKey = `competencies[${payload.index}]`;
      state.cancellationTokenSources.get(cancellationTokenKey)?.cancel();
      state.cancellationTokenSources.set(cancellationTokenKey, axios.CancelToken.source());
      const fetchedCompetencies = await api.get(`api/competencies`, query, state.cancellationTokenSources.get(cancellationTokenKey).token);
      competencies = [...getState().competencySurveyFormPage.competencies];
      competencies[payload.index] = {
        loading: false,
        availableCompetencies: fetchedCompetencies ? fetchedCompetencies.data : []
      };
      dispatch({
        type: CompetencySurveyFormPageActionTypes.LOAD_COMPETENCY_SURVEY_AVAILABLE_COMPETENCIES_END,
        payload: {
          competencies: competencies
        }
      });
    } catch (error) {
      dispatch({ type: CompetencySurveyFormPageActionTypes.LOAD_COMPETENCY_SURVEY_AVAILABLE_COMPETENCIES_END });
    }
  }
};