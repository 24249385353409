import React, { PureComponent } from 'react';

export default class NotebookIcon extends PureComponent {
  render() {
    return (
      <svg
        height='24'
        width='24'
        viewBox='0 0 380 380'
        style={this.props.style}
      >
        <g>
          <g>
            <path d='M320.159,30.864h-47.243V12.5c0-6.904-5.597-12.5-12.5-12.5s-12.5,5.596-12.5,12.5v18.364h-45V12.5
			c0-6.904-5.597-12.5-12.5-12.5c-6.903,0-12.5,5.596-12.5,12.5v18.364h-45V12.5c0-6.904-5.597-12.5-12.5-12.5
			s-12.5,5.596-12.5,12.5v18.364H60.673c-6.903,0-12.5,5.597-12.5,12.5v324.968c0,6.903,5.597,12.5,12.5,12.5h259.486
			c6.903,0,12.5-5.597,12.5-12.5V43.364C332.659,36.461,327.062,30.864,320.159,30.864z M307.659,355.832H73.173V55.864h34.743
			v14.59c0,6.904,5.597,12.5,12.5,12.5s12.5-5.596,12.5-12.5v-14.59h45v14.59c0,6.904,5.597,12.5,12.5,12.5
			c6.903,0,12.5-5.596,12.5-12.5v-14.59h45v14.59c0,6.904,5.597,12.5,12.5,12.5s12.5-5.596,12.5-12.5v-14.59h34.743V355.832z'/>
            <path d='M264.416,139.758h-148c-6.903,0-12.5,5.597-12.5,12.5c0,6.903,5.597,12.5,12.5,12.5h148c6.903,0,12.5-5.597,12.5-12.5
			C276.916,145.355,271.319,139.758,264.416,139.758z'/>
            <path d='M264.416,209.758h-148c-6.903,0-12.5,5.598-12.5,12.5c0,6.903,5.597,12.5,12.5,12.5h148c6.903,0,12.5-5.597,12.5-12.5
			C276.916,215.355,271.319,209.758,264.416,209.758z'/>
            <path d='M264.416,279.758h-148c-6.903,0-12.5,5.598-12.5,12.5c0,6.903,5.597,12.5,12.5,12.5h148c6.903,0,12.5-5.597,12.5-12.5
			C276.916,285.355,271.319,279.758,264.416,279.758z'/>
          </g>
        </g>
      </svg>
    );
  }
}