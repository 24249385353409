import StudyProgramCaseFormPageActionTypes from './study-program-case-form-page-action-types';
import ValidationError from '../../../utils/validation-error';

export const loadStudyProgramCaseFormPage = ({ studyProgramId, studyProgramCaseId }) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudyProgramCaseFormPageActionTypes.LOAD_STUDY_PROGRAM_CASE_FORM_PAGE_START });
  try {
    const studyProgram = await api.get(`api/study_programs/${studyProgramId}`);
    if (studyProgramCaseId > 0) {
      const studyProgramCase = await api.get(`api/study_programs/${studyProgramId}/cases/${studyProgramCaseId}`);
      dispatch({
        type: StudyProgramCaseFormPageActionTypes.LOAD_STUDY_PROGRAM_CASE_FORM_PAGE_END,
        payload: {
          studyProgramId: studyProgram.id,
          studyProgramCase: studyProgramCase
        }
      });
    } else if (studyProgramId) {
      dispatch({
        type: StudyProgramCaseFormPageActionTypes.LOAD_STUDY_PROGRAM_CASE_FORM_PAGE_END,
        payload: {
          studyProgramId: studyProgram.id,
          studyProgramCase: {
            studyProgramId: studyProgram.id
          }
        }
      });
    }
  } catch (error) {
    dispatch({ type: StudyProgramCaseFormPageActionTypes.LOAD_STUDY_PROGRAM_CASE_FORM_PAGE_END });
    throw error;
  }
};

export const saveStudyProgramCase = (payload) => async(dispatch, getState, { api, history }) => {
  dispatch({ type: StudyProgramCaseFormPageActionTypes.LOAD_STUDY_PROGRAM_CASE_FORM_PAGE_START });
  const state = getState().studyProgramCaseFormPage;
  const apiModel = {
    ...payload
  };
  let id = payload?.id;
  try {
    if (id > 0) {
      await api.put(`api/study_programs/${payload.studyProgramId}/cases/${id}`, apiModel);
    } else {
      id = await api.post(`api/study_programs/${payload.studyProgramId}/cases`, apiModel);
    }
    dispatch({
      type: StudyProgramCaseFormPageActionTypes.LOAD_STUDY_PROGRAM_CASE_FORM_PAGE_END,
      payload: {
        studyProgramCase: {
          ...state.studyProgramCase,
          id: id
        }
      }
    });
    history.back();
  } catch (error) {
    let validationResult = null;
    if (error instanceof ValidationError) {
      validationResult = {
        errorMessage: error.message,
        errors: error.errors
      };
    }
    dispatch({
      type: StudyProgramCaseFormPageActionTypes.LOAD_STUDY_PROGRAM_CASE_FORM_PAGE_END,
      payload: {
        validationResult,
        studyProgramCase: state.studyProgramCase
      }
    });
  }
};
