import axios from 'axios';
import StudyPlanCycleStudentOverviewPageActionTypes from './study-plan-cycle-student-overview-page-action-types';

export const loadStudyPlanCycleStudentOverviewPage = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().studyPlanCycleStudentOverviewPage;
  state.studyPlanCycleStudentOverviewCancelToken?.cancel();
  state.studyPlanCycleStudentOverviewCancelToken = axios.CancelToken.source();
  dispatch({ type: StudyPlanCycleStudentOverviewPageActionTypes.LOAD_STUDY_PLAN_CYCLE_STUDENT_OVERVIEW_PAGE_START });
  try {
    const { studyPlanId, studyPlanCycleId, studyPlanCycleStudentId } = payload;
    const studyPlanCycleStudent = await api.get(`api/study_plans/${studyPlanId}/cycles/${studyPlanCycleId}/students/${studyPlanCycleStudentId}`, state.studyPlanCycleStudentOverviewCancelToken.token);
    dispatch({
      type: StudyPlanCycleStudentOverviewPageActionTypes.LOAD_STUDY_PLAN_CYCLE_STUDENT_OVERVIEW_PAGE_END,
      payload: {
        studyPlanId,
        studyPlanCycleId,
        studyPlanCycleStudentId,
        studyPlanCycleStudent
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: StudyPlanCycleStudentOverviewPageActionTypes.LOAD_STUDY_PLAN_CYCLE_STUDENT_OVERVIEW_PAGE_END });
      throw error;
    }
  }
};