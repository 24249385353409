import SubcomptetencyListPageActionTypes from './subcompetency-list-page-action-types';
import axios from 'axios';
import { sortingToString, sortingToStringWithSubstitutes } from '../../../utils/objectUtils';
import { ROLE_PERMISSIONS as Permissions } from '../../../resources/rolePermissions';

export const loadSubcompetencyList = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: SubcomptetencyListPageActionTypes.LOAD_SUBCOMPETENCY_LIST_START });
  const state = getState().subcompetencyListPage;
  const query = {
    skip: payload ? payload.skip : state.skip,
    limit: payload ? payload.take : state.take,
    sorting: payload?.competencyId > 0
      ? sortingToStringWithSubstitutes(state.sorting, [
        { originalName: 'code', substituteName: 'Code' },
        { originalName: 'name', substituteName: 'Name' }
      ])
      : sortingToString(state.sorting),
    keyword: state.filter?.keyword,
    studyProgramId: state.filter?.studyProgram?.id,
    extendedSearch: true
  };
  try {
    state.subcompetenciesCancelToken?.cancel();
    state.subcompetenciesCancelToken = axios.CancelToken.source();
    let subcompetencies = [];
    if (payload?.competencyId > 0) {
      subcompetencies = await api.get(`api/competencies/${payload.competencyId}/subcompetencies`, query, state.subcompetenciesCancelToken.token);
    } else {
      subcompetencies = await api.get(`api/subcompetencies`, query, state.subcompetenciesCancelToken.token);
    }
    const canWrite = getState().app.userPermissions.includes(Permissions.COMPETENCIES_WRITE);
    dispatch({
      type: SubcomptetencyListPageActionTypes.LOAD_SUBCOMPETENCY_LIST_END,
      payload: {
        subcompetencies: subcompetencies ? subcompetencies.data : [],
        total: subcompetencies ? subcompetencies.total : 0,
        skip: payload ? payload.skip : state.skip,
        take: payload ? payload.take : state.take,
        canWrite
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: SubcomptetencyListPageActionTypes.LOAD_SUBCOMPETENCY_LIST_END });
      throw error;
    }
  }
  dispatch(searchSubcompetenciesFilterStudyPrograms());
};

export const openSubcompetencyConfirmDialog = (payload) => async(dispatch) => {
  dispatch({
    type: SubcomptetencyListPageActionTypes.TOGGLE_SUBCOMPETENCY_LIST_CONFIRM_DIALOG,
    payload: payload
  });
};

export const closeSubcompetencyConfirmDialog = () => async(dispatch) => {
  dispatch({ type: SubcomptetencyListPageActionTypes.TOGGLE_SUBCOMPETENCY_LIST_CONFIRM_DIALOG });
};

export const removeSubcompetencyConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().subcompetencyListPage;
  if (state.selectedSubcompetency) {
    dispatch({ type: SubcomptetencyListPageActionTypes.LOAD_SUBCOMPETENCY_LIST_START });
    try {
      if (payload?.competencyId > 0) {
        await api.remove(`api/competencies/${payload.competencyId}/subcompetencies/${state.selectedSubcompetency.competencySubcompetencyId}`);
      } else {
        await api.remove(`api/subcompetencies/${state.selectedSubcompetency.id}`);
      }
      dispatch(loadSubcompetencyList({ competencyId: payload.competencyId, skip: state.skip, take: state.take }));
    } catch (error) {
      dispatch({ type: SubcomptetencyListPageActionTypes.LOAD_SUBCOMPETENCY_LIST_END });
    }
  }
  dispatch({ type: SubcomptetencyListPageActionTypes.TOGGLE_SUBCOMPETENCY_LIST_CONFIRM_DIALOG });
};

export const filterSubcompetencies = (payload) => (dispatch) => {
  dispatch({
    type: SubcomptetencyListPageActionTypes.FILTER_SUBCOMPETENCY_LIST,
    payload: payload
  });
  dispatch(loadSubcompetencyList());
};

export const clearSubcompetenciesFilter = () => (dispatch) => {
  dispatch({
    type: SubcomptetencyListPageActionTypes.FILTER_SUBCOMPETENCY_LIST,
    payload: {}
  });
  dispatch(loadSubcompetencyList());
};

export const sortSubcompetencies = (payload) => (dispatch, getState) => {
  const state = getState().subcompetencyListPage;
  dispatch({ type: SubcomptetencyListPageActionTypes.SORT_SUBCOMPETENCY_LIST, payload: payload.sorting });
  dispatch(loadSubcompetencyList({ competencyId: payload.competencyId, skip: state.skip, take: state.take }));
};

export const searchSubcompetenciesFilterStudyPrograms = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: SubcomptetencyListPageActionTypes.SEARCH_SUBCOMPETENCY_LIST_FILTER_STUDY_PROGRAM_START });
  const query = {
    skip: 0,
    limit: 50,
    keyword: payload?.keyword
  };
  const state = getState().subcompetencyListPage;
  try {
    state.filterStudyProgramsCancelToken?.cancel();
    state.filterStudyProgramsCancelToken = axios.CancelToken.source();
    const studyPrograms = await api.get('api/study_programs', query, state.filterStudyProgramsCancelToken.token);
    dispatch({
      type: SubcomptetencyListPageActionTypes.SEARCH_SUBCOMPETENCY_LIST_FILTER_STUDY_PROGRAM_END,
      payload: {
        studyPrograms: studyPrograms ? studyPrograms.data : []
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: SubcomptetencyListPageActionTypes.SEARCH_SUBCOMPETENCY_LIST_FILTER_STUDY_PROGRAM_END });
      throw error;
    }
  }
};