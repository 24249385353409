import React from 'react';
import { connect } from 'react-redux';
import { GridToolbar } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { Grid, GridSize } from '../../shared/components';
import { loadCompetencySurveyList, openCompetencySurveyConfirmDialog, closeCompetencySurveyConfirmDialog, removeCompetencySurveyConfirmDialog } from '../actions/competency-survey-list-actions';
import { canWriteStudyProgram } from '../../common/actions';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Text } from '../../../ui';
import { withRouter } from '../../../components/withRouter';

class CompetencySurveyList extends React.PureComponent {

  constructor() {
    super();
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleAddClicked = this.handleAddClicked.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleConfirmDialogClose = this.handleConfirmDialogClose.bind(this);
    this.handleRemoveConfirm = this.handleRemoveConfirm.bind(this);
  }

  async componentDidMount() {
    await this.props.loadCompetencySurveyList({ competencyId: this.props.competencyId });
  }

  render() {
    const { loading, competencySurveys, canWrite } = this.props;
    const localizationService = provideLocalizationService(this);
    const canDeleteRow = canWriteStudyProgram(this.props.studyProgramId, this.props.app);
    return (
      <>
        <Grid
          loading={loading}
          data={competencySurveys}
          onRowClick={this.handleRowClick}
          isPageable={false}
          columns={[
            {
              field: 'surveyCode',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('competency.code')
            },
            {
              field: 'surveyName',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('competency.name')
            },
            {
              field: 'minCount',
              className: 'align-center',
              headerClassName: 'align-center',
              title: localizationService.toLanguageString('competency.minCount'),
              minGridWidth: GridSize.medium
            },
            {
              field: 'questionCount',
              className: 'align-center',
              headerClassName: 'align-center',
              title: localizationService.toLanguageString('competency.questions'),
              minGridWidth: GridSize.medium
            }
          ]}
          actions={
            [
              {
                icon: 'edit',
                onClick: this.handleEditClick,
                disabledOn: () => {
                  return !canDeleteRow;
                }
              }, {
                icon: 'delete',
                onClick: this.handleRemoveClick,
                disabledOn: () => {
                  return !canDeleteRow;
                }
              }
            ]
          }
        >
          {canWrite &&
            <GridToolbar>
              <Button themeColor='primary' onClick={this.handleAddClicked}>
                {localizationService.toLanguageString('custom.add')}
              </Button>
            </GridToolbar>
          }
        </Grid>
        {this.props.isConfirmDialogVisible &&
          <Dialog title={localizationService.toLanguageString('custom.confirm')} onClose={this.handleConfirmDialogClose}>
            <Text>{localizationService.toLanguageString('competency.competencySurveyDialogMessage')}</Text>
            <DialogActionsBar>
              <Button themeColor={'primary'} onClick={this.handleRemoveConfirm}>{localizationService.toLanguageString('buttons.delete')}</Button>
              <Button onClick={this.handleConfirmDialogClose}>{localizationService.toLanguageString('buttons.cancel')}</Button>
            </DialogActionsBar>
          </Dialog>
        }
      </>
    );
  }

  handleRemoveClick(row) {
    this.props.openCompetencySurveyConfirmDialog(row.dataItem);
  }

  handleEditClick(row) {
    this.props.navigate(`/competencies/${this.props.competencyId}/surveys/${row.dataItem.surveyId}`);
  }

  handleConfirmDialogClose() {
    this.props.closeCompetencySurveyConfirmDialog();
  }

  handleRemoveConfirm() {
    this.props.removeCompetencySurveyConfirmDialog({ competencyId: this.props.competencyId });
  }

  handleRowClick(e) {
    if (this.props.canWrite) {
      this.props.navigate(`/competencies/${this.props.competencyId}/surveys/${e.dataItem.id}/overview`);
    }
  }

  handleAddClicked() {
    this.props.navigate(`/competencies/${this.props.competencyId}/surveys/create`);
  }
}

const mapStateToProps = state => ({
  loading: state.competencySurveyList.loading,
  competencySurveys: state.competencySurveyList.competencySurveys,
  isConfirmDialogVisible: state.competencySurveyList.isConfirmDialogVisible,
  app: state.app
});

const mapDispatchToProps = dispatch => ({
  loadCompetencySurveyList: (payload) => dispatch(loadCompetencySurveyList(payload)),
  openCompetencySurveyConfirmDialog: (payload) => dispatch(openCompetencySurveyConfirmDialog(payload)),
  closeCompetencySurveyConfirmDialog: () => dispatch(closeCompetencySurveyConfirmDialog()),
  removeCompetencySurveyConfirmDialog: (payload) => dispatch(removeCompetencySurveyConfirmDialog(payload))
});

registerForLocalization(CompetencySurveyList);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CompetencySurveyList));