export default {
  LOAD_STUDENT_CYCLE_OVERVIEW_START: 'LoadStudentCycleOverviewStart',
  LOAD_STUDENT_CYCLE_OVERVIEW_END: 'LoadStudentCycleOverviewEnd',

  LOAD_STUDENT_CYCLE_OVERVIEW_SKILLS_TAB_START: 'LoadStudentCycleOverviewSkillsTabStart',
  LOAD_STUDENT_CYCLE_OVERVIEW_SKILLS_TAB_END: 'LoadStudentCycleOverviewSkillsTabEnd',
  LOAD_STUDENT_CYCLE_OVERVIEW_CASES_TAB_START: 'LoadStudentCycleOverviewCasesTabStart',
  LOAD_STUDENT_CYCLE_OVERVIEW_CASES_TAB_END: 'LoadStudentCycleOverviewCasesTabEnd',
  LOAD_STUDENT_CYCLE_OVERVIEW_THEORETICAL_PARTS_TAB_START: 'LoadStudentCycleOverviewTheoreticalPartsTabStart',
  LOAD_STUDENT_CYCLE_OVERVIEW_THEORETICAL_PARTS_TAB_END: 'LoadStudentCycleOverviewTheoreticalPartsTabEnd',
  LOAD_STUDENT_CYCLE_OVERVIEW_COMPLETION_TAB_START: 'LoadStudentCycleOverviewCompletionTabStart',
  LOAD_STUDENT_CYCLE_OVERVIEW_COMPLETION_TAB_END: 'LoadStudentCycleOverviewCompletionTabEnd',

  FILTER_STUDENT_CYCLE_OVERVIEW_AVAILABLE_DEPARTMENTS: 'FilterStudentCycleOverviewAvailableDepartments',
  FILTER_STUDENT_CYCLE_OVERVIEW_AVAILABLE_MANAGERS: 'FilterStudentCycleOverviewAvailableManagers',
  FILTER_STUDENT_CYCLE_OVERVIEW_AVAILABLE_CYCLES: 'FilterStudentCycleOverviewAvailableCycles',

  LOAD_STUDENT_CYCLE_OVERVIEW_AVAILABLE_CYCLE_SKILLS_START: 'LoadStudentCycleOverviewAvailableCycleSkillsStart',
  LOAD_STUDENT_CYCLE_OVERVIEW_AVAILABLE_CYCLE_SKILLS_END: 'LoadStudentCycleOverviewAvailableCycleSkillsEnd',

  LOAD_STUDENT_CYCLE_OVERVIEW_AVAILABLE_CYCLE_CASES_START: 'LoadStudentCycleOverviewAvailableCycleCasesStart',
  LOAD_STUDENT_CYCLE_OVERVIEW_AVAILABLE_CYCLE_CASES_END: 'LoadStudentCycleOverviewAvailableCycleCasesEnd',

  SELECT_STUDENT_CYCLE_OVERVIEW_TAB: 'SelectStudentCycleOverviewTab',
  TOGGLE_STUDENT_CYCLE_OVERVIEW_SUMMARY_EXPANDED: 'ToggleStudentCycleOverviewSummaryExpanded',

  OPEN_STUDENT_CYCLE_OVERVIEW_EDIT_DIALOG: 'OpenStudentCycleOverviewEditDialog',
  CLOSE_STUDENT_CYCLE_OVERVIEW_EDIT_DIALOG: 'CloseStudentCycleOverviewEditDialog',
  STUDENT_CYCLE_OVERVIEW_EDIT_DIALOG_SAVING_BEGIN: 'StudentCycleOverviewEditDialogSavingBegin',
  STUDENT_CYCLE_OVERVIEW_EDIT_DIALOG_SAVING_END: 'StudentCycleOverviewEditDialogSavingEnd',

  OPEN_STUDENT_CYCLE_OVERVIEW_DELETE_DIALOG: 'OpenStudentCycleOverviewDeleteDialog',
  CLOSE_STUDENT_CYCLE_OVERVIEW_DELETE_DIALOG: 'CloseStudentCycleOverviewDeleteDialog',
  STUDENT_CYCLE_OVERVIEW_DELETE_DIALOG_DELETING_BEGIN: 'StudentCycleOverviewDeleteDialogDeletingBegin',
  STUDENT_CYCLE_OVERVIEW_DELETE_DIALOG_DELETING_END: 'StudentCycleOverviewDeleteDialogDeletingEnd',

  UPDATE_STUDENT_CYCLE_OVERVIEW_SKILLS: 'UpdateStudentCycleOverviewSkills',
  UPDATE_STUDENT_CYCLE_OVERVIEW_CASES: 'UpdateStudentCycleOverviewCases',

  OPEN_STUDENT_CYCLE_OVERVIEW_ADD_DIALOG: 'OpenStudentCycleOverviewAddDialog',
  CLOSE_STUDENT_CYCLE_OVERVIEW_ADD_DIALOG: 'CloseStudentCycleOverviewAddDialog',
  STUDENT_CYCLE_OVERVIEW_ADD_DIALOG_SAVING_BEGIN: 'StudentCycleOverviewAddDialogSavingBegin',
  STUDENT_CYCLE_OVERVIEW_ADD_DIALOG_SAVING_END: 'StudentCycleOverviewAddDialogSavingEnd',

  OPEN_STUDENT_CYCLE_OVERVIEW_ADDITIONAL_CASE_ADD_DIALOG: 'OpenStudentCycleOverviewAdditionalCaseAddDialog',
  CLOSE_STUDENT_CYCLE_OVERVIEW_ADDITIONAL_CASE_ADD_DIALOG: 'CloseStudentCycleOverviewAdditionalCaseAddDialog',

  COMPLETE_STUDENT_CYCLE_OVERVIEW_THEORETICAL_PART_STUDENT_START: 'CompleteStudentCycleOverviewTheoreticalPartStudentStart',
  COMPLETE_STUDENT_CYCLE_OVERVIEW_THEORETICAL_PART_STUDENT_END: 'CompleteStudentCycleOverviewTheoreticalPartStudentEnd',

  OPEN_STUDENT_CYCLE_OVERVIEW_CYCLE_SUBMIT_DIALOG: 'OpenStudentCycleOverviewCycleSubmitDialog',
  CLOSE_STUDENT_CYCLE_OVERVIEW_CYCLE_SUBMIT_DIALOG: 'CloseStudentCycleOverviewCycleSubmitDialog',

  TOGGLE_STUDENT_CYCLE_OVERVIEW_CYCLE_STATUS_HISTORY_EXPANDED: 'ToggleStudentCycleOverviewCycleStatusHistoryExpanded',
  CLOSE_STUDENT_CYCLE_OVERVIEW_CYCLE_STATUS_HISTORY: 'CloseStudentCycleOverviewCycleStatusHistory',

  OPEN_STUDENT_CYCLE_OVERVIEW_THEORETICAL_PART_SUBMIT_DIALOG: 'OpenStudentCycleOverviewTheoreticalPartSubmitDialog',
  CLOSE_STUDENT_CYCLE_OVERVIEW_THEORETICAL_PART_SUBMIT_DIALOG: 'CloseStudentCycleOverviewThoereticalPartSubmitDialog',

  CLEAR_STUDENT_CYCLE_OVERVIEW: 'ClearStudentCycleOverview',

  STUDENT_CYCLE_OVERVIEW_SUBMIT_APPROVAL_REQUEST_START: 'StudentCycleOverviewSubmitApprovalRequestStart',
  STUDENT_CYCLE_OVERVIEW_SUBMIT_APPROVAL_REQUEST_END: 'StudentCycleOverviewSubmitApprovalRequestEnd',
  OPEN_STUDENT_CYCLE_OVERVIEW_APPROVAL_REQUEST_CONFIRM_DIALOG: 'OpenStudentCycleOverviewApprovalRequestConfirmDialog',
  CLOSE_STUDENT_CYCLE_OVERVIEW_APPROVAL_REQUEST_CONFIRM_DIALOG: 'CloseStudentCycleOverviewApprovalRequestConfirmDialog',

  OPEN_STUDENT_CYCLE_OVERVIEW_DEPARTMENT_DESIGNATION_SUBMIT_DIALOG: 'OpenStudentCycleOverviewDepartmentDesignationSubmitDialog',
  CLOSE_STUDENT_CYCLE_OVERVIEW_DEPARTMENT_DESIGNATION_SUBMIT_DIALOG: 'CloseStudentCycleOverviewDepartmentDesignationSubmitDialog',
  SUBMIT_STUDY_PLAN_CYCLE_STUDENT_DEPARTMENT_DESIGNATION_START: 'SubmitStudyPlanCycleStudentDepartmentDesignationStart',
  SUBMIT_STUDY_PLAN_CYCLE_STUDENT_DEPARTMENT_DESIGNATION_END: 'SubmitStudyPlanCycleStudentDepartmentDesignationEnd'
};