import StudentCompetencyCycleListActionTypes from '../actions/student-competency-cycle-list-action-types';

const initialState = {
  loading: false,
  studentCompetencyCycles: null,
  studentCompetencyCyclesCancelToken: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case StudentCompetencyCycleListActionTypes.LOAD_STUDENT_COMPETENCY_CYCLE_LIST_START:
      return {
        ...state,
        loading: true
      };
    case StudentCompetencyCycleListActionTypes.LOAD_STUDENT_COMPETENCY_CYCLE_LIST_END:
      return {
        ...state,
        studentCompetencyCycles: action.payload,
        loading: false
      };
    default:
      return state;
  }
};
