import React from 'react';
import { connect } from 'react-redux';
import { GridToolbar } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { GridSize, Grid } from '../../shared/components';
import { loadCycleListPage, findCycles, sortCycles, openCycleConfirmDialog, closeCycleConfirmDialog, removeCycleConfirmDialog } from '../actions/cycle-list-page-actions';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Colors, StackLayout, Text, WrapLayout } from '../../../ui';
import { withRouter } from '../../../components/withRouter';
import { Tooltip } from '@progress/kendo-react-tooltip';

class CycleList extends React.PureComponent {

  constructor() {
    super();
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleAddClicked = this.handleAddClicked.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleConfirmDialogClose = this.handleConfirmDialogClose.bind(this);
    this.handleRemoveConfirm = this.handleRemoveConfirm.bind(this);
    this.renderName = this.renderName.bind(this);
    this.renderTooltip = this.renderTooltip.bind(this);
  }

  async componentDidMount() {
    await this.props.loadCycleListPage();
  }

  render() {
    const localizationService = provideLocalizationService(this);
    return (
      <>
        <Grid
          loading={this.props.loading}
          data={this.props.cycles}
          skip={this.props.skip}
          take={this.props.take}
          total={this.props.total}
          sort={this.props.sorting}
          onPageChange={this.handlePageChange}
          onSortChange={this.handleSort}
          onRowClick={this.handleRowClick}
          columns={[
            {
              field: 'name',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('cycle.cycle'),
              cell: this.renderName
            }, {
              field: 'studyProgramName',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('cycle.coordinatingStudyProgram')
            }, {
              field: 'credits',
              className: 'align-right',
              headerClassName: 'align-right',
              title: localizationService.toLanguageString('cycle.credits'),
              minGridWidth: GridSize.small
            }
          ]}
          actions={[
            {
              icon: 'delete',
              onClick: this.handleRemoveClick,
              disabledOn: e => e.dataItem.studyPlanCount > 0 || e.dataItem.competencyCount > 0 || !this.props.usersStudyPrograms.some(element => element.id === e.dataItem.studyProgramId),
              renderTitle: e => {
                let title = '';
                if (e.dataItem.studyPlanCount > 0) {
                  title += `${localizationService.toLanguageString('cycle.assignedToStudyPlan')}\n`;
                }
                if (e.dataItem.competencyCount > 0) {
                  title += localizationService.toLanguageString('cycle.assignedToCompetency');
                }
                return title;
              }
            }
          ]}
        >
          <GridToolbar>
            <Button themeColor='primary' onClick={this.handleAddClicked}>
              {localizationService.toLanguageString('custom.add')}
            </Button>
          </GridToolbar>
        </Grid>
        {this.props.isConfirmDialogVisible &&
          <Dialog title={localizationService.toLanguageString('custom.confirm')} onClose={this.handleConfirmDialogClose}>
            <Text>{localizationService.toLanguageString('cycle.cycleDialogMessage')}</Text>
            <DialogActionsBar>
              <Button themeColor={'primary'} onClick={this.handleRemoveConfirm}>{localizationService.toLanguageString('buttons.delete')}</Button>
              <Button onClick={this.handleConfirmDialogClose}>{localizationService.toLanguageString('buttons.cancel')}</Button>
            </DialogActionsBar>
          </Dialog>
        }
      </>
    );
  }

  renderName(e) {
    const { className, dataItem } = e;
    const localizationService = provideLocalizationService(this);
    return (
      <td className={className} onClick={() => this.handleRowClick(e)}>
        <StackLayout rowGap='2px'>
          {dataItem.name}
          <WrapLayout rowGap='0' columnGap='0' width='fit-content'>
            <Text textColor={Colors.GRAY_50} variant='caption'>
              {dataItem.code}
            </Text>
            {dataItem.taughtInOtherStudyPrograms.length !== 0 &&
              <Tooltip position='top' content={(e) => this.renderTooltip(e, dataItem)}>
                <Text textColor={Colors.GRAY_50} variant='caption' title='true'>
                  {`, ${localizationService.toLanguageString('cycle.taughtIn')}: ${dataItem.taughtInOtherStudyPrograms.length + 1}`}
                </Text>
              </Tooltip>
            }
          </WrapLayout>
        </StackLayout>
      </td>
    );
  }

  renderTooltip(props, dataItem) {
    const studyPrograms = [{ studyProgramName: dataItem.studyProgramName, code: dataItem.code }, ...dataItem.taughtInOtherStudyPrograms];
    return (
      <StackLayout rowGap='0'>
        {studyPrograms.map((element, index) => <div key={index}>{`${element.studyProgramName} ${element.code}`}</div>)}
      </StackLayout>
    );
  }

  handleSort(e) {
    this.props.sortCycles(e.sort);
  }

  async handlePageChange(e) {
    await this.props.findCycles({ skip: e.page.skip, take: e.page.take });
  }

  handleRowClick(e) {
    this.props.navigate(`/cycles/${e.dataItem.id}/overview`);
  }

  handleAddClicked() {
    this.props.navigate('/cycles/create');
  }

  handleRemoveClick(row) {
    this.props.openCycleConfirmDialog(row.dataItem);
  }

  handleConfirmDialogClose() {
    this.props.closeCycleConfirmDialog();
  }

  handleRemoveConfirm() {
    this.props.removeCycleConfirmDialog({
      cycleId: this.props.cycleId
    });
  }
}

const mapStateToProps = state => ({
  loading: state.cycleListPage.loading,
  cycles: state.cycleListPage.cycles,
  skip: state.cycleListPage.skip,
  take: state.cycleListPage.take,
  total: state.cycleListPage.total,
  sorting: state.cycleListPage.sorting,
  isConfirmDialogVisible: state.cycleListPage.isConfirmDialogVisible,
  usersStudyPrograms: state.cycleListPage.usersStudyPrograms
});

const mapDispatchToProps = dispatch => ({
  loadCycleListPage: () => dispatch(loadCycleListPage()),
  findCycles: (payload) => dispatch(findCycles(payload)),
  sortCycles: (payload) => dispatch(sortCycles(payload)),
  openCycleConfirmDialog: (payload) => dispatch(openCycleConfirmDialog(payload)),
  closeCycleConfirmDialog: () => dispatch(closeCycleConfirmDialog()),
  removeCycleConfirmDialog: (payload) => dispatch(removeCycleConfirmDialog(payload))
});

registerForLocalization(CycleList);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CycleList));