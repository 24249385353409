import React, { PureComponent } from 'react';
import styled from 'styled-components';

const Orientation = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical'
};

const Horizontal = styled.div`
  height: 1px;
  background-color: #e0e0e0;
  flex-grow: 0;
  ${props => props.width && `height: ${props.width};`}
`;

const Vertical = styled.div`
  width: 1px;
  background-color: #e0e0e0;
  ${props => props.height && `height: ${props.height};`}
`;

export default class Separator extends PureComponent {

  static defaultProps = {
    orientation: Orientation.HORIZONTAL
  }

  render() {
    if (this.props.orientation === Orientation.HORIZONTAL) {
      return (
        <Horizontal width={this.props.width} className={this.props.className} />
      );
    } else {
      return (
        <Vertical height={this.props.height} className={this.props.className} />
      );
    }
  }

}