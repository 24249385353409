export default class ForbiddenError extends Error {

  constructor(message, ...params) {
    super(...params);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ForbiddenError);
    }
    this.name = 'ForbiddenError';
    this.message = message;
  }
}