import { MyNotificationsListPageActionTypes } from '../actions';

const initialState = {
  loading: false,
  notifications: [],
  readNotificationIds: [],
  totalNotificationsCount: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MyNotificationsListPageActionTypes.LOAD_MY_NOTIFICATIONS_LIST_PAGE_NOTIFICATIONS_START:
      return {
        ...state,
        loading: true
      };
    case MyNotificationsListPageActionTypes.LOAD_MY_NOTIFICATIONS_LIST_PAGE_NOTIFICATIONS_END:
      return {
        ...state,
        loading: false,
        notifications: action.payload?.notifications,
        readNotificationIds: action.payload?.readNotificationIds,
        totalNotificationsCount: action.payload?.totalNotificationsCount
      };
    case MyNotificationsListPageActionTypes.UPDATE_MY_NOTIFICATIONS_LIST_PAGE_READ_NOTIFICATION_IDS:
      return {
        ...state,
        readNotificationIds: action.payload?.readNotificationIds
      };
    default:
      return state;
  }
};