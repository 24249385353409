import TheoreticalPartOverviewPageActionTypes from './theoretical-part-overview-page-action-types';
import axios from 'axios';

export const loadTheoreticalPartOverviewPage = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: TheoreticalPartOverviewPageActionTypes.LOAD_THEORETICAL_PART_OVERVIEW_PAGE_START });
  const state = getState().theoreticalPartOverviewPage;
  try {
    state.theoreticalPartCancelToken?.cancel();
    state.theoreticalPartCancelToken = axios.CancelToken.source();
    const theoreticalPart = await api.get(`api/theoretical_parts/${payload.theoreticalPartId}`, null, state.theoreticalPartCancelToken.token);
    dispatch({
      type: TheoreticalPartOverviewPageActionTypes.LOAD_THEORETICAL_PART_OVERVIEW_PAGE_END,
      payload: {
        theoreticalPart: theoreticalPart
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: TheoreticalPartOverviewPageActionTypes.LOAD_THEORETICAL_PART_OVERVIEW_PAGE_END });
      throw error;
    }
  }
};

export const selectTheoreticalPartOverviewPageTab = (payload) => async(dispatch) => {
  dispatch({
    type: TheoreticalPartOverviewPageActionTypes.SELECT_THEORETICAL_PART_OVERVIEW_PAGE_TAB,
    payload: {
      selected: payload.selected
    }
  });
};

export const clearTheoreticalPartOverviewPage = () => async(dispatch) => {
  dispatch({ type: TheoreticalPartOverviewPageActionTypes.CLEAR_THEORETICAL_PART_OVERVIEW_PAGE });
};
