import { pagingSettings } from '../../../utils/GridPagingUtils';
import { StudyPlanCycleStudentSelectPageActionTypes } from '../actions';

const initialState = {
  loading: false,
  studyPlanId: null,
  studyPlanCycleId: null,
  studyProgramId: null,
  studyPlanCycleStudentIds: null,
  studyPlanCycleStudentsSelectCancelToken: null,
  availableStudents: null,
  availableStudyYears: null,
  skip: pagingSettings.paging.skip,
  take: pagingSettings.paging.take,
  total: 0,
  filter: null,
  selectedAll: false,
  savingSelectedStudents: false,
  errorMessage: null,
  successMessage: null,
  isSaveDialogVisible: false,
  availableCycleManagers: [],
  availableStudentStatuses: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case StudyPlanCycleStudentSelectPageActionTypes.LOAD_STUDY_PLAN_CYCLE_STUDENT_SELECT_PAGE_START:
      return {
        ...state,
        studyPlanId: action.payload?.studyPlanId,
        studyPlanCycleId: action.payload?.studyPlanCycleId,
        studyProgramId: action.payload?.studyProgramId,
        studyPlanCycleStudentIds: action.payload?.studyPlanCycleStudentIds,
        availableCycleManagers: action.payload?.availableCycleManagers ?? [],
        errorMessage: null,
        successMessage: null
      };
    case StudyPlanCycleStudentSelectPageActionTypes.FIND_STUDY_PLAN_CYCLE_STUDENT_SELECT_PAGE_START:
      return {
        ...state,
        loading: true
      };
    case StudyPlanCycleStudentSelectPageActionTypes.FIND_STUDY_PLAN_CYCLE_STUDENT_SELECT_PAGE_END:
      return {
        ...state,
        loading: false,
        availableStudents: action.payload?.availableStudents,
        total: action.payload?.total,
        skip: action.payload?.skip !== undefined ? action.payload.skip : state.skip,
        take: action.payload?.take !== undefined ? action.payload.take : state.take
      };
    case StudyPlanCycleStudentSelectPageActionTypes.FILTER_STUDY_PLAN_CYCLE_STUDENT_SELECT_PAGE:
      return {
        ...state,
        filter: action.payload,
        skip: initialState.skip,
        take: initialState.take
      };
    case StudyPlanCycleStudentSelectPageActionTypes.SELECT_STUDY_PLAN_CYCLE_STUDENT_SELECT_PAGE:
      return {
        ...state,
        availableStudents: action.payload?.availableStudents,
        selectedAll: false
      };
    case StudyPlanCycleStudentSelectPageActionTypes.SELECT_ALL_STUDY_PLAN_CYCLE_STUDENT_SELECT_PAGE:
      return {
        ...state,
        availableStudents: action.payload?.availableStudents,
        selectedAll: !state.selectedAll
      };
    case StudyPlanCycleStudentSelectPageActionTypes.ADD_SELECTED_STUDENTS_STUDY_PLAN_CYCLE_STUDENT_SELECT_PAGE_START:
      return {
        ...state,
        savingSelectedStudents: true,
        errorMessage: null,
        successMessage: null,
        isSaveDialogVisible: false
      };
    case StudyPlanCycleStudentSelectPageActionTypes.ADD_SELECTED_STUDENTS_STUDY_PLAN_CYCLE_STUDENT_SELECT_PAGE_END:
      return {
        ...state,
        savingSelectedStudents: false,
        errorMessage: action.payload?.errorMessage,
        successMessage: action.payload?.successMessage,
        studyPlanCycleStudentIds: action.payload?.studyPlanCycleStudentIds
      };
    case StudyPlanCycleStudentSelectPageActionTypes.LOAD_STUDY_PLAN_CYCLE_STUDENT_STUDY_YEARS:
      return {
        ...state,
        availableStudyYears: action.payload?.availableStudyYears
      };
    case StudyPlanCycleStudentSelectPageActionTypes.OPEN_STUDY_PLAN_CYCLE_STUDENT_SELECT_PAGE_SAVE_DIALOG:
      return {
        ...state,
        isSaveDialogVisible: true
      };
    case StudyPlanCycleStudentSelectPageActionTypes.CLOSE_STUDY_PLAN_CYCLE_STUDENT_SELECT_PAGE_SAVE_DIALOG:
      return {
        ...state,
        isSaveDialogVisible: false
      };
    case StudyPlanCycleStudentSelectPageActionTypes.CLEANUP_STUDY_PLAN_CYCLE_STUDENT_SELECT_PAGE:
      return {
        ...initialState
      };
    case StudyPlanCycleStudentSelectPageActionTypes.LOAD_STUDY_PLAN_CYCLE_STUDENT_SELECT_PAGE_STUDENT_STATUSES:
      return {
        ...state,
        availableStudentStatuses: action.payload ? action.payload.availableStudentStatuses : []
      };
    default:
      return state;
  }
};