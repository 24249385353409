import * as React from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { isFieldValid } from '../../../utils/formUtils';
import { Error } from '@progress/kendo-react-labels';

class DropDownWithRemoteData extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false
    };
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {

    const { handleData, data } = this.props;

    let initialData = [];

    if (handleData) {
      this.setState({
        ...this.state,
        loading: true
      });
      const result = await handleData();
      if (result) {
        initialData = result.data;
      }
    } else {
      initialData = data;
    }

    this.setState({
      ...this.state,
      loading: false,
      data: initialData
    });
  }

  render() {
    const {
      label,
      input,
      textField,
      disabled,
      required,
      meta
    } = this.props;

    const { data, loading } = this.state;

    const valid = isFieldValid(meta);

    return (
      <fieldset className={'k-form-fieldset'}>
        <DropDownList
          label={label}
          value={input.value}
          onChange={this.handleChange}
          data={data}
          textField={textField}
          loading={loading}
          disabled={disabled}
          required={required}
        />
        {!valid && meta && <Error>{meta.error || meta.submitError}</Error>}
      </fieldset>
    );
  }

  handleChange(e) {
    const value = e.value || e.target.value;
    this.props.input.onChange(value);
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }
};

export default DropDownWithRemoteData;