import * as React from 'react';
import { useLocalization } from '@progress/kendo-react-intl';

const Settings = () => {
  const localizationService = useLocalization();
  return (
    <div id='Settings' className='main-content'>
      <div className='card-container grid'>
        <h3 className='card-title'>{localizationService.toLanguageString('custom.settings')}</h3>
      </div>
    </div>
  );
};
export default Settings;
