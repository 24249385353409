import * as React from 'react';
import StudentCompetencyOverview from '../../shared/student-competency/components/student-competency-overview';
import { withRouter } from '../../../components/withRouter';
import { connect } from 'react-redux';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';

class StudentCompetencyOverviewPage extends React.PureComponent {
  render() {
    const localizationService = provideLocalizationService(this);
    return (
      <StudentCompetencyOverview
        preview
        showReportButton={true}
        studentId={this.props.params?.studentId}
        studentCompetencySurveyListOnRowClick={e => this.props.navigate(`/students/${this.props.params?.studentId}/competencies/${this.props.params?.competencyId}/surveys/${e.dataItem.id}/overview`)}
        breadcrumbItems={[
          {
            id: 'students',
            text: localizationService.toLanguageString('student.residents')
          },
          {
            id: 'student',
            text: localizationService.toLanguageString('student.resident')
          },
          {
            id: 'studentCompetency',
            text: localizationService.toLanguageString('studentCompetency.tieredCompetency'),
            disabled: true
          }
        ]}
      />
    );
  }
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
});

registerForLocalization(StudentCompetencyOverviewPage);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StudentCompetencyOverviewPage));