export default {
  LOAD_CYCLE_PLAN_LIST_START: 'LoadCyclePlanListStart',
  LOAD_CYCLE_PLAN_LIST_END: 'LoadCyclePlanListEnd',
  FILTER_CYCLE_PLAN_LIST: 'FilterCyclePlanList',
  SEARCH_CYCLE_PLAN_LIST_FILTER_DEPARTMENT_START: 'SearchCyclePlanListFilterDepartmentStart',
  SEARCH_CYCLE_PLAN_LIST_FILTER_DEPARTMENT_END: 'SearchCyclePlanListFilterDepartmentEnd',
  SEARCH_CYCLE_PLAN_LIST_FILTER_RESIDENT_START: 'SearchCyclePlanListFilterResidentStart',
  SEARCH_CYCLE_PLAN_LIST_FILTER_RESIDENT_END: 'SearchCyclePlanListFilterResidentEnd',
  SEARCH_CYCLE_PLAN_LIST_FILTER_STUDY_PROGRAM_START: 'SearchCyclePlanListFilterStudyProgramStart',
  SEARCH_CYCLE_PLAN_LIST_FILTER_STUDY_PROGRAM_END: 'SearchCyclePlanListFilterStudyProgramEnd',
  SEARCH_CYCLE_PLAN_LIST_FILTER_CYCLE_START: 'SearchCyclePlanListFilterCycleStart',
  SEARCH_CYCLE_PLAN_LIST_FILTER_CYCLE_END: 'SearchCyclePlanListFilterCycleEnd'
};