import departmentOverviewPageActionTypes from '../actions/department-overview-page-action-types';

const initialState = {
  loading: false,
  department: null,
  departmentCancelToken: null,
  canWrite: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case departmentOverviewPageActionTypes.LOAD_DEPARTMENT_OVERVIEW_PAGE_START:
      return {
        ...state,
        loading: true
      };
    case departmentOverviewPageActionTypes.LOAD_DEPARTMENT_OVERVIEW_PAGE_END:
      return {
        ...state,
        loading: false,
        department: action.payload?.department,
        canWrite: action.payload?.canWrite
      };
    default:
      return state;
  }
};