import axios from 'axios';
import ValidationError from '../../../utils/validation-error';
import StudyPlanCycleStudentFormPageActionTypes from './study-plan-cycle-student-form-page-action-types';

export const loadStudyPlanCycleStudentFormPage = (payload) => async(dispatch, getState, { api, history }) => {
  const state = getState().studyPlanCycleStudentFormPage;
  const { studyPlanId, studyPlanCycleId, studyPlanCycleStudentId, studyPlanCycleDepartmentStudentId, studyPlanCycleStudentIds, localizationService } = payload;
  dispatch({ type: StudyPlanCycleStudentFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_STUDENT_FORM_PAGE_START });
  let studyPlanCycleStudentDetails = null;
  let studyPlanCycleStudentDepartments = [];
  try {
    state.studyPlanCycleStudentFormCancelToken?.cancel();
    state.studyPlanCycleStudentFormCancelToken = axios.CancelToken.source();
    if (studyPlanCycleStudentIds?.length > 0) {
      const studyPlanCycle = await api.get(`api/study_plans/${studyPlanId}/cycles/${studyPlanCycleId}`, null, state.studyPlanCycleStudentFormCancelToken.token);
      studyPlanCycleStudentDetails = {
        studentFullName: `${localizationService.toLanguageString('StudyPlanCycle.selectedStudents')} ${studyPlanCycleStudentIds.length}`,
        cycleYears: studyPlanCycle.cycleYears,
        cycleName: studyPlanCycle.cycleName
      };
      const studyPlanCycleStudentsDepartments = await Promise.all(studyPlanCycleStudentIds.map(studyPlanCycleStudentId => api.get(`api/study_plans/${studyPlanId}/cycles/${studyPlanCycleId}/students/${studyPlanCycleStudentId.id}/departments`)));
      studyPlanCycleStudentsDepartments?.forEach(studyPlanCycleStudentDepartmentList => {
        if (studyPlanCycleStudentDepartmentList?.data) {
          studyPlanCycleStudentDepartments = studyPlanCycleStudentDepartments.concat(studyPlanCycleStudentDepartmentList?.data.map(item => ({
            ...item,
            dateFrom: new Date(item.dateFrom),
            dateTo: new Date(item.dateTo)
          })));
        }
      });
    } else {
      const [ studyPlanCycleStudent, studyPlanCycleDepartmentStudentsList ] = await Promise.all([
        api.get(`api/study_plans/${studyPlanId}/cycles/${studyPlanCycleId}/students/${studyPlanCycleStudentId}`, null, state.studyPlanCycleStudentFormCancelToken.token),
        api.get(`api/study_plans/${studyPlanId}/cycles/${studyPlanCycleId}/students/${studyPlanCycleStudentId}/departments`, null, state.studyPlanCycleStudentFormCancelToken.token)
      ]);
      studyPlanCycleStudentDepartments = studyPlanCycleDepartmentStudentsList?.data.map(item => ({
        ...item,
        dateFrom: new Date(item.dateFrom),
        dateTo: new Date(item.dateTo)
      }));
      if (studyPlanCycleDepartmentStudentId) {
        const studyPlanCycleDepartmentStudent = await api.get(`api/study_plans/${studyPlanId}/cycles/${studyPlanCycleId}/students/${studyPlanCycleStudentId}/departments/${studyPlanCycleDepartmentStudentId}`, null, state.studyPlanCycleStudentFormCancelToken.token);
        let studyPlanCycleDepartment = null;
        if (studyPlanCycleDepartmentStudent.studyPlanCycleDepartmentId) {
          studyPlanCycleDepartment = await api.get(`api/study_plans/${studyPlanId}/cycles/${studyPlanCycleId}/departments/${studyPlanCycleDepartmentStudent.studyPlanCycleDepartmentId}`, null, state.studyPlanCycleStudentFormCancelToken.token);
        }
        studyPlanCycleStudentDetails = {
          ...studyPlanCycleDepartmentStudent,
          studentFullName: studyPlanCycleStudent.studentFullName,
          cycleName: studyPlanCycleStudent.cycleName,
          dateFrom: studyPlanCycleDepartmentStudent.dateFrom ? new Date(studyPlanCycleDepartmentStudent.dateFrom) : null,
          dateTo: studyPlanCycleDepartmentStudent.dateTo ? new Date(studyPlanCycleDepartmentStudent.dateTo) : null,
          department: studyPlanCycleDepartment,
          year: studyPlanCycleDepartmentStudent.year && { id: studyPlanCycleDepartmentStudent.year, name: studyPlanCycleDepartmentStudent.year }
        };
      } else {
        const studyPlanCycle = await api.get(`api/study_plans/${studyPlanId}/cycles/${studyPlanCycleId}`, null, state.studyPlanCycleStudentFormCancelToken.token);
        studyPlanCycleStudentDetails = {
          ...studyPlanCycleStudent,
          id: null,
          dateFrom: studyPlanCycleStudent.dateFrom ? new Date(studyPlanCycleStudent.dateFrom) : null,
          dateTo: studyPlanCycleStudent.dateTo ? new Date(studyPlanCycleStudent.dateTo) : null,
          year: studyPlanCycleStudent.year && { id: studyPlanCycleStudent.year, name: studyPlanCycleStudent.year },
          cycleYears: studyPlanCycle.cycleYears
        };
      }
    }
  } catch (error) {
    dispatch({ type: StudyPlanCycleStudentFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_STUDENT_FORM_PAGE_END });
    if (!(error instanceof axios.Cancel)) {
      throw error;
    }
  }
  dispatch({
    type: StudyPlanCycleStudentFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_STUDENT_FORM_PAGE_END,
    payload: {
      studyPlanCycleStudentDetails,
      studyPlanId,
      studyPlanCycleId,
      studyPlanCycleStudentId,
      studyPlanCycleStudentIds,
      studyPlanCycleStudentDepartments
    }
  });
  dispatch(loadAvailableStudyYears());
  dispatch(loadStudyPlanCycleStudentFormPageAvailableDepartments());
};

export const loadAvailableStudyYears = () => (dispatch, getState) => {
  const state = getState().studyPlanCycleStudentFormPage;
  const availableStudyYears = state.studyPlanCycleStudentDetails?.cycleYears?.map(key => ({
    id: key,
    name: `${key}`
  })) ?? [];
  dispatch({
    type: StudyPlanCycleStudentFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_STUDENT_FORM_PAGE_AVAILABLE_STUDY_YEARS,
    payload: {
      availableStudyYears
    }
  });
};

export const loadStudyPlanCycleStudentFormPageAvailableDepartments = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().studyPlanCycleStudentFormPage;
  dispatch({ type: StudyPlanCycleStudentFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_STUDENT_FORM_PAGE_AVAILABLE_DEPARTMENTS_START });
  try {
    const query = {
      keyword: payload?.keyword
    };
    state.studyPlanCycleStudentFormCancelToken?.cancel();
    state.studyPlanCycleStudentFormCancelToken = axios.CancelToken.source();
    const { studyPlanId, studyPlanCycleId } = state;
    const availableDepartments = await api.get(`api/study_plans/${studyPlanId}/cycles/${studyPlanCycleId}/departments`, query, state.studyPlanCycleStudentFormCancelToken.token);
    dispatch({
      type: StudyPlanCycleStudentFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_STUDENT_FORM_PAGE_AVAILABLE_DEPARTMENTS_END,
      payload: {
        availableDepartments: availableDepartments?.data
      }
    });
  } catch (error) {
    dispatch({ type: StudyPlanCycleStudentFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_STUDENT_FORM_PAGE_AVAILABLE_DEPARTMENTS_END });
    throw error;
  }
};

export const loadStudyPlanCycleDepartmentStudents = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().studyPlanCycleStudentFormPage;
  const { studyPlanId, studyPlanCycleId } = state;
  try {
    state.studyPlanCycleStudentFormCancelToken?.cancel();
    state.studyPlanCycleStudentFormCancelToken = axios.CancelToken.source();
    const response = await api.get(`api/study_plans/${studyPlanId}/cycles/${studyPlanCycleId}/departments/${payload.studyPlanCycleDepartmentId}/students`, null, state.studyPlanCycleStudentFormCancelToken.token);
    const departmentStudents = response?.map(student => ({
      id: student.id,
      dateFrom: new Date(student.dateFrom),
      dateTo: new Date(student.dateTo),
      studyPlanCycleStudentId: student.studyPlanCycleStudentId
    }));
    dispatch({
      type: StudyPlanCycleStudentFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_STUDENT_FORM_PAGE_DEPARTMENT_STUDENTS,
      payload: {
        departmentStudents
      }
    });
  } catch (error) {
    dispatch({ type: StudyPlanCycleStudentFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_STUDENT_FORM_PAGE_DEPARTMENT_STUDENTS });
    if (!(error instanceof axios.Cancel)) {
      throw error;
    }
  }
};

export const saveStudyPlanCycleStudentFormPage = (payload) => async(dispatch, getState, { api, history }) => {
  dispatch({ type: StudyPlanCycleStudentFormPageActionTypes.SAVE_STUDY_PLAN_CYCLE_STUDENT_FORM_PAGE_START });
  const state = getState().studyPlanCycleStudentFormPage;
  try {
    if (state.studyPlanCycleStudentIds?.length > 0) {
      const apiModels = state.studyPlanCycleStudentIds.map(studyPlanCycleStudent => ({
        studyPlanCycleDepartmentId: payload.department?.id,
        studyPlanCycleStudentId: studyPlanCycleStudent.id,
        dateFrom: payload.dateFrom,
        dateTo: payload.dateTo,
        credits: payload.credits,
        year: payload.year?.id
      }));
      await Promise.all(apiModels.map(apiModel => api.post(`api/study_plans/${state.studyPlanId}/cycles/${state.studyPlanCycleId}/students/${apiModel.studyPlanCycleStudentId}/departments`, { ...apiModel })));
    } else {
      const apiModel = {
        id: payload.id,
        studyPlanCycleDepartmentId: payload.department?.id,
        studyPlanCycleStudentId: state.studyPlanCycleStudentId,
        dateFrom: payload.dateFrom,
        dateTo: payload.dateTo,
        credits: payload.credits,
        year: payload.year?.id
      };
      if (payload.id) {
        await api.put(`api/study_plans/${state.studyPlanId}/cycles/${state.studyPlanCycleId}/students/${state.studyPlanCycleStudentId}/departments`, apiModel);
      } else {
        await api.post(`api/study_plans/${state.studyPlanId}/cycles/${state.studyPlanCycleId}/students/${state.studyPlanCycleStudentId}/departments`, apiModel);
      }
    }
    dispatch({
      type: StudyPlanCycleStudentFormPageActionTypes.SAVE_STUDY_PLAN_CYCLE_STUDENT_FORM_PAGE_END,
      payload: {
        studyPlanCycleStudentDetails: state.studyPlanCycleStudentDetails,
        studyPlanId: state.studyPlanId,
        studyPlanCycleId: state.studyPlanCycleId,
        studyPlanCycleStudentId: state.studyPlanCycleStudentId,
        studyPlanCycleStudentIds: state.studyPlanCycleStudentIds,
        studyPlanCycleStudentDepartments: state.studyPlanCycleStudentDepartments
      }
    });
    history.back();
  } catch (error) {
    let validationResult = null;
    if (error instanceof ValidationError) {
      validationResult = {
        errorMessage: error.message,
        errors: error.errors
      };
    } else {
      throw error;
    }
    dispatch({
      type: StudyPlanCycleStudentFormPageActionTypes.SAVE_STUDY_PLAN_CYCLE_STUDENT_FORM_PAGE_END,
      payload: {
        validationResult,
        studyPlanCycleStudentDetails: state.studyPlanCycleStudentDetails,
        studyPlanId: state.studyPlanId,
        studyPlanCycleId: state.studyPlanCycleId,
        studyPlanCycleStudentId: state.studyPlanCycleStudentId,
        studyPlanCycleStudentIds: state.studyPlanCycleStudentIds,
        studyPlanCycleStudentDepartments: state.studyPlanCycleStudentDepartments
      }
    });
  }
};