import StudyProgramTheoreticalPartListActionTypes from '../actions/study-program-theoretical-part-list-action-types';

const initialState = {
  loading: false,
  studyProgramTheoreticalParts: null,
  studyProgramTheoreticalPartsCancelToken: null,
  isConfirmDialogVisible: false,
  selectedStudyProgramTheoreticalPartId: null,
  selectedStudyProgramTheoreticalPartAssignedCount: null,
  canWrite: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case StudyProgramTheoreticalPartListActionTypes.LOAD_STUDY_PROGRAM_THEORETICAL_PART_LIST_START:
      return {
        ...state,
        loading: true
      };
    case StudyProgramTheoreticalPartListActionTypes.LOAD_STUDY_PROGRAM_THEORETICAL_PART_LIST_END:
      return {
        ...state,
        studyProgramTheoreticalParts: action.payload.studyProgramTheoreticalParts,
        loading: false,
        canWrite: action.payload.canWrite
      };
    case StudyProgramTheoreticalPartListActionTypes.TOGGLE_STUDY_PROGRAM_THEORETICAL_PART_LIST_CONFIRM_DIALOG:
      return {
        ...state,
        selectedStudyProgramTheoreticalPartId: action.payload?.id,
        selectedStudyProgramTheoreticalPartAssignedCount: action.payload?.assignedCycleTheoreticalPartCount,
        isConfirmDialogVisible: !state.isConfirmDialogVisible
      };
    default:
      return state;
  }
};