import * as React from 'react';
import { useLocalization } from '@progress/kendo-react-intl';

const Home = () => {
  const localizationService = useLocalization();

  return (
    <div id='Home' className='main-content'>
      <div className='card-container grid'>
        <h3 className='card-title'>{localizationService.toLanguageString('custom.title')}</h3>
      </div>
    </div>
  );
};

export default Home;

