import studyProgramOverviewPageActionTypes from '../actions/study-program-overview-page-action-types';

const initialState = {
  loading: false,
  studyProgram: null,
  studyProgramCancelToken: null,
  selectedTab: 0,
  canWrite: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case studyProgramOverviewPageActionTypes.LOAD_STUDY_PROGRAM_OVERVIEW_PAGE_START:
      return {
        ...state,
        loading: true
      };
    case studyProgramOverviewPageActionTypes.LOAD_STUDY_PROGRAM_OVERVIEW_PAGE_END:
      return {
        ...state,
        loading: false,
        studyProgram: action.payload?.studyProgram,
        canWrite: action.payload?.canWrite
      };
    case studyProgramOverviewPageActionTypes.SELECT_STUDY_PROGRAM_OVERVIEW_PAGE_TAB:
      return {
        ...state,
        selectedTab: action.payload?.selected
      };
    default:
      return state;
  }
};