import UserOverviewPageActionTypes from './user-overview-page-action-types';
import axios from 'axios';
import { loadUserData } from '../../common/actions';

export const loadUserOverviewPage = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: UserOverviewPageActionTypes.LOAD_USER_OVERVIEW_START });
  const state = getState().userOverviewPage;
  try {
    state.userCancelToken?.cancel();
    state.userCancelToken = axios.CancelToken.source();
    const user = await api.get(`api/users/${payload.userId}`, state.userCancelToken.token);
    dispatch({
      type: UserOverviewPageActionTypes.LOAD_USER_OVERVIEW_END,
      payload: { user }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: UserOverviewPageActionTypes.LOAD_USER_OVERVIEW_END });
      throw error;
    }
  }
};

export const selectUserOverviewPageTab = (payload) => async(dispatch) => {
  dispatch({
    type: UserOverviewPageActionTypes.SELECT_USER_OVERVIEW_PAGE_TAB,
    payload: {
      selected: payload.selected
    }
  });
};

export const changeUserStatus = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: UserOverviewPageActionTypes.CHANGE_USER_STATUS_START });
  const state = getState().userOverviewPage;
  const userId = state.user.id;
  try {
    await api.put(`api/users/${userId}/status/${payload.newStatus}`);
    dispatch({ type: UserOverviewPageActionTypes.CHANGE_USER_STATUS_END });
    dispatch(loadUserOverviewPage({ userId }));
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: UserOverviewPageActionTypes.CHANGE_USER_STATUS_END });
      throw error;
    }
  }
};

export const openUserOverviewConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  dispatch({
    type: UserOverviewPageActionTypes.TOGGLE_USER_OVERVIEW_CONFIRM_DIALOG,
    payload: payload
  });
};

export const closeUserOverviewConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: UserOverviewPageActionTypes.TOGGLE_USER_OVERVIEW_CONFIRM_DIALOG });
};

export const removeUserOverviewConfirmDialog = (payload) => async(dispatch, getState, { api, history }) => {
  const state = getState().userOverviewPage;
  if (state.selectedUserId > 0) {
    dispatch({ type: UserOverviewPageActionTypes.LOAD_USER_OVERVIEW_START });
    try {
      await api.remove(`api/users/${state.selectedUserId}`);
      dispatch({
        type: UserOverviewPageActionTypes.CLEANUP_USER_OVERVIEW
      });
      history.push('/users');
    } catch (error) {
    }
  }
};

export const openImpersonateConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  const { userId } = payload;
  dispatch({
    type: UserOverviewPageActionTypes.OPEN_USER_IMPERSONATE_CONFIRM_DIALOG,
    payload: {
      userId
    }
  });
};

export const closeImpersonateConfirmDialog = () => (dispatch) => {
  dispatch({
    type: UserOverviewPageActionTypes.CLOSE_USER_IMPERSONATE_CONFIRM_DIALOG
  });
};

export const impersonateAsUser = (payload) => async(dispatch, getState, { api, history }) => {
  const { localizationService } = payload;
  const state = getState().userOverviewPage;
  try {
    const user = await api.get(`/api/users/${state.selectedImpersonateUserId}`);
    if (user?.username == null) {
      throw new Error(localizationService.toLanguageString('User.userHasNoUsername'));
    }
    await api.post('/api/users/impersonate', { username: user.username });
    api.setImpersonatedUserHeader(user.username);
    dispatch(loadUserData({ localizationService, isUserImpersonated: true }));
    dispatch(closeImpersonateConfirmDialog());
    history.push('/');
  } catch (error) {
    dispatch(closeImpersonateConfirmDialog());
    throw error;
  }
};