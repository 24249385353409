import UserRequestListActionTypes from '../actions/user-request-list-action-types';

const initialState = {
  loading: false,
  userRequests: null,
  userRequestsCancelToken: null,
  isConfirmDialogVisible: false,
  selectedCompetencySurveyStudentId: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UserRequestListActionTypes.LOAD_USER_REQUEST_LIST_START:
      return {
        ...state,
        loading: true
      };
    case UserRequestListActionTypes.LOAD_USER_REQUEST_LIST_END:
      return {
        ...state,
        userRequests: action.payload,
        loading: false
      };
    case UserRequestListActionTypes.TOGGLE_USER_REQUEST_LIST_CONFIRM_DIALOG:
      return {
        ...state,
        selectedCompetencySurveyStudentId: action.payload?.id,
        isConfirmDialogVisible: !state.isConfirmDialogVisible
      };
    default:
      return state;
  }
};
