import React from 'react';
import styled, { css } from 'styled-components';
import { FormElement } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { StackLayout } from '../layouts';
import { Form, Separator } from '.';
import { respondTo } from '../shared';
import { SCREEN_SIZES as ScreenSizes } from '../../../resources/screenSize';

const Filter = styled.div`
  margin: 8px 16px;

  ${respondTo(ScreenSizes.XS, css`
    margin: 8px 24px;
  `)}
`;

const ActionsContainer = styled(StackLayout).attrs((props) => ({
  orientation: 'horizontal'
}))`
  margin: 8px 16px;
  ${respondTo(ScreenSizes.XS, css`
    margin: 8px 24px;
  `)}
`;

const ActionButton = styled(Button)`
  flex-grow: 0;
`;

export default class FilterForm extends React.PureComponent {

  constructor() {
    super();
    this.handleClear = this.handleClear.bind(this);
  }

  render() {
    const {
      initialValues,
      onSubmit,
      localizationService,
      uniqueKey
    } = this.props;
    return (
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        localizationService={localizationService}
        key={uniqueKey}
        render={(formRenderProps) => (
          <FormElement>
            <Filter>
              {this.props.render(formRenderProps)}
            </Filter>
            <Separator/>
            <ActionsContainer>
              <ActionButton
                themeColor='primary'
                type='submit'
                disabled={!formRenderProps.allowSubmit}
              >
                {localizationService.toLanguageString('grid.filterSubmitButton')}
              </ActionButton>
              <ActionButton
                type='button'
                onClick={() => {
                  formRenderProps.onFormReset();
                  this.handleClear();
                }}
              >
                {localizationService.toLanguageString('grid.filterClearButton')}
              </ActionButton>
            </ActionsContainer>
          </FormElement>
        )}
      >
      </Form>
    );
  }

  handleClear() {
    if (this.props.onClear) {
      this.props.onClear();
    }
  }
};