import axios from 'axios';
import { CycleTheoreticalPartListActionTypes } from '.';

export const loadCycleTheoreticalPartsList = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: CycleTheoreticalPartListActionTypes.LOAD_CYCLE_THEORETICAL_PART_LIST_START });
  const state = getState().cycleTheoreticalPartList;
  try {
    state.cycleTheoreticalPartsCancelToken?.cancel();
    state.cycleTheoreticalPartsCancelToken = axios.CancelToken.source();
    const cycleTheoreticalParts = await api.get(`api/cycles/${payload.cycleId}/theoretical_parts`, null, state.cycleTheoreticalPartsCancelToken.token);
    dispatch({
      type: CycleTheoreticalPartListActionTypes.LOAD_CYCLE_THEORETICAL_PART_LIST_END,
      payload: cycleTheoreticalParts?.data
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: CycleTheoreticalPartListActionTypes.LOAD_CYCLE_THEORETICAL_PART_LIST_END });
      throw error;
    }
  }
};

export const openCycleTheoreticalPartConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  dispatch({
    type: CycleTheoreticalPartListActionTypes.TOGGLE_CYCLE_THEORETICAL_PART_LIST_CONFIRM_DIALOG,
    payload: payload
  });
};

export const closeCycleTheoreticalPartConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  dispatch({
    type: CycleTheoreticalPartListActionTypes.TOGGLE_CYCLE_THEORETICAL_PART_LIST_CONFIRM_DIALOG
  });
};

export const removeCycleTheoreticalPartConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().cycleTheoreticalPartList;
  if (state.selectedCycleTheoreticalPartId > 0) {
    dispatch({ type: CycleTheoreticalPartListActionTypes.LOAD_CYCLE_THEORETICAL_PART_LIST_START });
    try {
      await api.remove(`api/cycles/${payload.cycleId}/theoretical_parts/${state.selectedCycleTheoreticalPartId}`);
      dispatch(loadCycleTheoreticalPartsList({ cycleId: payload.cycleId }));
    } catch (error) {
      dispatch({ type: CycleTheoreticalPartListActionTypes.LOAD_CYCLE_THEORETICAL_PART_LIST_END });
    }
  }
  dispatch({ type: CycleTheoreticalPartListActionTypes.TOGGLE_CYCLE_THEORETICAL_PART_LIST_CONFIRM_DIALOG });
};