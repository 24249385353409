import * as React from 'react';
import { ListPage } from '../../../ui';
import { ApprovalRequestListFilter, ApprovalRequestList } from './index';

class ApprovalRequestListPage extends React.PureComponent {
  render() {
    return (
      <ListPage filter={<ApprovalRequestListFilter/>}>
        <ApprovalRequestList/>
      </ListPage>
    );
  }
}

export default ApprovalRequestListPage;