import React from 'react';
import { connect } from 'react-redux';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { GridSize, Grid, InlineBadge } from '../../shared/components';
import { loadStudentListPage, findStudents, sortStudents, filterStudents } from '../actions/student-list-page-actions';
import { withRouter } from '../../../components/withRouter';
import { WrapLayout, Text } from '../../../ui';
import colors from '../../../ui/components/shared/colors';
import { StackLayout } from '@progress/kendo-react-layout';
import { getStudentStatusColor } from '../../../utils/studentStatusColors';
import { STUDENT_STATUSES } from '../../../resources/studentStatus';

class StudentList extends React.PureComponent {

  constructor(props) {
    super(props);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.renderResident = this.renderResident.bind(this);
  }

  async componentDidMount() {
    const localizationService = provideLocalizationService(this);
    if (this.props.isInitialLoad) {
      await this.props.filterStudents({ studentStatus: { id: STUDENT_STATUSES.STUDYING, name: localizationService.toLanguageString(`studentStatus.${STUDENT_STATUSES.STUDYING}`) } });
    }
  }

  render() {
    const {
      loading,
      students,
      skip,
      take,
      total,
      sorting
    } = this.props;

    const localizationService = provideLocalizationService(this);
    return (
      <Grid
        loading={loading}
        data={students}
        skip={skip}
        take={take}
        total={total}
        sort={sorting}
        onPageChange={this.handlePageChange}
        onSortChange={this.handleSort}
        onRowClick={this.handleRowClick}
        columns={[
          {
            field: 'displayName',
            className: 'align-left',
            headerClassName: 'align-left',
            title: localizationService.toLanguageString('student.resident'),
            cell: this.renderResident
          }, {
            field: 'studyProgram',
            className: 'align-left',
            headerClassName: 'align-left',
            title: localizationService.toLanguageString('student.studyProgram'),
            minGridWidth: GridSize.large
          }, {
            field: 'year',
            className: 'align-right',
            headerClassName: 'align-right',
            title: localizationService.toLanguageString('student.year'),
            minGridWidth: GridSize.large
          }, {
            className: 'align-left',
            headerClassName: 'align-left',
            title: localizationService.toLanguageString('student.credits'),
            cell: this.renderCredits,
            minGridWidth: GridSize.small
          }, {
            field: 'status',
            className: 'align-left',
            headerClassName: 'align-left',
            title: localizationService.toLanguageString('student.status'),
            cell: ({ className, dataItem }) =>
              <td className={className}>
                <InlineBadge themeColor={getStudentStatusColor(dataItem.status)}>
                  {localizationService.toLanguageString(`studentStatus.${dataItem.status}`)}
                </InlineBadge>
              </td>
          }
        ]}
      />
    );
  }

  renderResident(e) {
    const { className, dataItem } = e;
    const localizationService = provideLocalizationService(this);
    return (
      <td className={className} onClick={() => this.handleRowClick(e)}>
        <StackLayout orientation='vertical'>
          {dataItem.displayName}
          <WrapLayout>
            <Text textColor={colors.GRAY_50} variant='caption'>
              {`${localizationService.toLanguageString('student.studentNumber')}: ${dataItem.studentNumber}`}
            </Text>
          </WrapLayout>
        </StackLayout>
      </td>
    );
  }

  renderCredits(e) {
    const { dataItem, className } = e;
    return (
      <td className={className}>
        {`${dataItem.finishedCredits}/${dataItem.cyclesCredits ? dataItem.cyclesCredits : '-'}/${dataItem.studyProgramCredits ? dataItem.studyProgramCredits : '-'}`}
      </td>
    );
  }

  handleSort(e) {
    this.props.sortStudents(e.sort);
  }

  async handlePageChange(e) {
    await this.props.findStudents({ skip: e.page.skip, take: e.page.take });
  }

  handleRowClick(e) {
    this.props.navigate(`/students/${e.dataItem.id}/overview`);
  }
}

const mapStateToProps = state => ({
  loading: state.studentListPage.loading,
  students: state.studentListPage.students,
  skip: state.studentListPage.skip,
  take: state.studentListPage.take,
  total: state.studentListPage.total,
  sorting: state.studentListPage.sorting,
  isInitialLoad: state.studentListPage.isInitialLoad
});

const mapDispatchToProps = dispatch => ({
  loadStudentListPage: (payload) => dispatch(loadStudentListPage(payload)),
  findStudents: (payload) => dispatch(findStudents(payload)),
  sortStudents: (payload) => dispatch(sortStudents(payload)),
  filterStudents: (payload) => dispatch(filterStudents(payload))
});

registerForLocalization(StudentList);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StudentList));
