import axios from 'axios';
import CycleCaseDiagnosisTlkDialogActionTypes from './cycle-case-diagnosis-tlk-dialog-action-types';

export const loadTlks = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: CycleCaseDiagnosisTlkDialogActionTypes.LOAD_CYCLE_CASE_DIAGNOSIS_TLK_DIALOG_START });
  try {
    const state = getState().cycleCaseDiagnosisTlkDialog;
    state.availableCycleCaseDiagnosisTlksCancelToken?.cancel();
    state.availableCycleCaseDiagnosisTlksCancelToken = axios.CancelToken.source();
    const query = { skip: 0, limit: 50, keyword: payload.keyword, extendedSearch: true };
    const tlks = await api.get(`api/tlk`, query, state.availableCycleCaseDiagnosisTlksCancelToken.token);
    dispatch({
      type: CycleCaseDiagnosisTlkDialogActionTypes.LOAD_CYCLE_CASE_DIAGNOSIS_TLK_DIALOG_END,
      payload: {
        tlks: tlks ? tlks.data?.map(tlk => ({
          ...tlk,
          text: `${tlk.code} ${tlk.name}`
        })) : []
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      throw error;
    }
  }
};

export const addTlk = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: CycleCaseDiagnosisTlkDialogActionTypes.ADD_CYCLE_CASE_DIAGNOSIS_TLK_DIALOG_START });
  try {
    const apiModel = {
      ...payload.tlk,
      tlkId: payload.tlk.id,
      cycleCaseId: payload.cycleCaseId
    };
    await api.post(`api/cycles/${payload.cycleId}/cases/${payload.cycleCaseId}/diagnosis`, apiModel);
    dispatch({
      type: CycleCaseDiagnosisTlkDialogActionTypes.ADD_CYCLE_CASE_DIAGNOSIS_TLK_DIALOG_END
    });
  } catch (error) {
    dispatch({ type: CycleCaseDiagnosisTlkDialogActionTypes.ADD_CYCLE_CASE_DIAGNOSIS_TLK_DIALOG_END });
  }
  payload.onClose();
};