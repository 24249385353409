import React from 'react';
import { connect } from 'react-redux';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { GridSize, Grid, GridDateCell, InlineBadge } from '../../shared/components';
import {
  loadMySurveyListPage,
  findMySurveys,
  sortMySurveys
} from '../actions/my-survey-list-page-actions';
import { withRouter } from '../../../components/withRouter';
import { getCompetencySurveyStudentStatusColor } from '../../../utils/competencySurveyStudentStatusColor';
import { COMPETENCY_SURVEY_STUDENT_STATUS } from '../../../resources/competencySurveyStudentStatus';
import { Text, Colors, StackLayout, WrapLayout } from '../../../ui';

class MySurveyList extends React.PureComponent {

  constructor(props) {
    super(props);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleEvaluateClick = this.handleEvaluateClick.bind(this);
    this.renderStatus = this.renderStatus.bind(this);
    this.handlePreviewClick = this.handlePreviewClick.bind(this);
  }

  async componentDidMount() {
    await this.props.loadMySurveyListPage();
  }

  render() {
    const localizationService = provideLocalizationService(this);
    return (
      <Grid
        loading={this.props.loading}
        data={this.props.mySurveys}
        skip={this.props.skip}
        take={this.props.take}
        total={this.props.total}
        sort={this.props.sorting}
        onPageChange={this.handlePageChange}
        onSortChange={this.handleSort}
        columns={[
          {
            field: 'evaluationRequest',
            className: 'align-left',
            headerClassName: 'align-left',
            title: localizationService.toLanguageString('mySurvey.requestsEvaluation'),
            cell: this.renderEvaluationRequest
          }, {
            field: 'requestDate',
            className: 'align-center',
            headerClassName: 'align-center',
            title: localizationService.toLanguageString('mySurvey.requestDate'),
            minGridWidth: GridSize.medium,
            cell: GridDateCell
          }, {
            field: 'surveyStatus',
            className: 'align-left',
            headerClassName: 'align-left',
            title: localizationService.toLanguageString('mySurvey.surveyStatus'),
            sortable: false,
            cell: this.renderStatus
          }
        ]}
        actions={[
          {
            icon: 'track-changes-enable',
            onClick: this.handleEvaluateClick,
            renderOn: (e) => e.dataItem.status === COMPETENCY_SURVEY_STUDENT_STATUS.SUBMITTED
          }, {
            icon: 'preview',
            onClick: this.handlePreviewClick,
            renderOn: (e) => e.dataItem.status === COMPETENCY_SURVEY_STUDENT_STATUS.EVALUATED
          }
        ]}
      />
    );
  }

  renderStatus({ dataItem, className }) {
    const localizationService = provideLocalizationService(this);
    return (
      <td className={className}>
        {dataItem.status &&
          <InlineBadge themeColor={getCompetencySurveyStudentStatusColor(dataItem.status)}>
            {localizationService.toLanguageString('competencySurveyStudentStatus.' + dataItem.status)}
          </InlineBadge>
        }
      </td>
    );
  }

  renderEvaluationRequest({ dataItem, className }) {
    return (
      <td className={className}>
        <StackLayout rowGap='2px'>
          {`${dataItem.student}, ${dataItem.surveyCode}, ${dataItem.surveyName}`}
          <WrapLayout rowGap='0' columnGap='0'>
            <Text variant='caption' textColor={Colors.GRAY_50}>
              {`${dataItem.competencyAbbreviation} ${dataItem.competencyName}`}
            </Text>
          </WrapLayout>
        </StackLayout>
      </td>
    );
  }

  handleEvaluateClick(e) {
    this.props.navigate(`/my_surveys/${e.dataItem.surveyId}/competency_survey_students/${e.dataItem.id}`);
  }

  handlePreviewClick(e) {
    this.props.navigate(`/my_surveys/${e.dataItem.surveyId}/competency_survey_students/${e.dataItem.id}/preview`);
  }

  handleSort(e) {
    this.props.sortMySurveys(e.sort);
  }

  async handlePageChange(e) {
    await this.props.findMySurveys({ skip: e.page.skip, take: e.page.take });
  }
}

const mapStateToProps = state => ({
  loading: state.mySurveyListPage.loading,
  mySurveys: state.mySurveyListPage.mySurveys,
  skip: state.mySurveyListPage.skip,
  take: state.mySurveyListPage.take,
  total: state.mySurveyListPage.total,
  sorting: state.mySurveyListPage.sorting
});

const mapDispatchToProps = dispatch => ({
  loadMySurveyListPage: () => dispatch(loadMySurveyListPage()),
  findMySurveys: (payload) => dispatch(findMySurveys(payload)),
  sortMySurveys: (payload) => dispatch(sortMySurveys(payload))
});

registerForLocalization(MySurveyList);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MySurveyList));