import * as React from 'react';
import { ListPage } from '../../../ui';
import { SubcompetencyListFilter, SubcompetencyList } from './index';

class SubcompetencyListPage extends React.PureComponent {
  render() {
    return (
      <ListPage filter={< SubcompetencyListFilter/>}>
        <SubcompetencyList renderForListPage={true}/>
      </ListPage>
    );
  }
}

export default SubcompetencyListPage;