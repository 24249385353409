import axios from 'axios';
import { CYCLE_STUDENT_STATUS } from '../../../../resources/studyPlanCycleDepartmentStudentStatus';
import StudentCompetencyCycleListActionTypes from './student-competency-cycle-list-action-types';

export const loadStudentCompetencyCycleList = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudentCompetencyCycleListActionTypes.LOAD_STUDENT_COMPETENCY_CYCLE_LIST_START });
  const states = getState();
  const state = states.studentCompetencyCycleList;
  try {
    state.studentCompetencyCyclesCancelToken?.cancel();
    state.studentCompetencyCyclesCancelToken = axios.CancelToken.source();

    const [competencyCycles, studentCycles] = await Promise.all([
      api.get(`api/competencies/${payload.competencyId}/cycles`, state.studentCompetencyCyclesCancelToken.token),
      api.get(`api/students/${payload.studentId}/cycles`, null, state.studentCompetencyCyclesCancelToken.token)
    ]);

    const cycles = competencyCycles?.data?.map(competencyCycle => {
      const status = studentCycles?.find(studentCycle => studentCycle.cycleId === competencyCycle.cycleId)?.status || CYCLE_STUDENT_STATUS.NOT_STARTED;
      return { ...competencyCycle, status };
    });

    dispatch({
      type: StudentCompetencyCycleListActionTypes.LOAD_STUDENT_COMPETENCY_CYCLE_LIST_END,
      payload: cycles ?? []
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: StudentCompetencyCycleListActionTypes.LOAD_STUDENT_COMPETENCY_CYCLE_LIST_END });
      throw error;
    }
  }
};