import StudyProgramCaseListActionTypes from '../actions/study-program-case-list-action-types';

const initialState = {
  loading: false,
  studyProgramCases: null,
  studyProgramCasesCancelToken: null,
  isConfirmDialogVisible: false,
  selectedStudyProgramCaseId: null,
  selectedStudyProgramCaseAssignedCount: null,
  canWrite: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case StudyProgramCaseListActionTypes.LOAD_STUDY_PROGRAM_CASE_LIST_START:
      return {
        ...state,
        loading: true
      };
    case StudyProgramCaseListActionTypes.LOAD_STUDY_PROGRAM_CASE_LIST_END:
      return {
        ...state,
        studyProgramCases: action.payload.studyProgramCases,
        loading: false,
        canWrite: action.payload.canWrite
      };
    case StudyProgramCaseListActionTypes.TOGGLE_STUDY_PROGRAM_CASE_LIST_CONFIRM_DIALOG:
      return {
        ...state,
        selectedStudyProgramCaseId: action.payload?.id,
        selectedStudyProgramCaseAssignedCount: action.payload?.assignedCycleCaseCount,
        isConfirmDialogVisible: !state.isConfirmDialogVisible
      };
    default:
      return state;
  }
};
