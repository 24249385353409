import * as React from 'react';
import { connect } from 'react-redux';
import { Field } from '@progress/kendo-react-form';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { FilterForm, TextBox, WrapLayout, StackLayout } from '../../../ui';
import { filterDepartments, clearDepartmentsFilter } from '../actions/department-list-page-actions';

class DepartmentListFilter extends React.PureComponent {

  render() {
    const {
      filter,
      clearDepartmentsFilter,
      filterDepartments
    } = this.props;
    const localizationService = provideLocalizationService(this);
    return (
      <FilterForm
        initialValues={filter}
        onSubmit={filterDepartments}
        onClear={clearDepartmentsFilter}
        localizationService={localizationService}
        title={localizationService.toLanguageString('grid.filterTitle')}
        uniqueKey={JSON.stringify(filter)}
        render={() => (
          <WrapLayout>
            <StackLayout width='340px'>
              <Field
                name='keyword'
                width='340px'
                component={TextBox}
                label={localizationService.toLanguageString('custom.search')}
              />
            </StackLayout>
          </WrapLayout>
        )}
      />
    );
  }
}

const mapStateToProps = state => ({
  filter: state.departmentListPage.filter
});

const mapDispatchToProps = dispatch => ({
  filterDepartments: (payload) => dispatch(filterDepartments(payload)),
  clearDepartmentsFilter: () => dispatch(clearDepartmentsFilter())
});

registerForLocalization(DepartmentListFilter);

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentListFilter);