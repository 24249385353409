import * as React from 'react';
import { connect } from 'react-redux';
import { Field } from '@progress/kendo-react-form';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { FilterForm, StackLayout, WrapLayout, ComboBox, DropDown } from '../../../ui';
import { filterCompetencyRequests, clearCompetencyRequestsFilter, filterStudyProgramsComboBox, filterResidentsComboBox } from '../actions/competency-request-list-page-actions';
import { STUDENT_COMPETENCY_STATUS } from '../../../resources/studentCompetencyStatus';
import { Badge, BadgeContainer } from '@progress/kendo-react-indicators';
import styled from 'styled-components';
import { getStudentCompetencyStatusColor } from '../../../utils/studentCompetencyStatusColors';

const DropdownItemContainer = styled.div`
  height: 24px;
  padding-top: 8px;
`;

const StyledBadge = styled(Badge)`
  opacity: 75%
`;

class CompetencyRequestListFilter extends React.PureComponent {

  constructor(props) {
    super(props);
    this.statusDropdownItemRender = this.statusDropdownItemRender.bind(this);
  }

  render() {
    const {
      filter,
      clearCompetencyRequestsFilter,
      filterCompetencyRequests,

      studyProgramsComboBoxLoading,
      studyProgramsComboBoxData,
      filterStudyProgramsComboBox,

      residentsComboBoxLoading,
      residentsComboBoxData,
      filterResidentsComboBox
    } = this.props;
    const localizationService = provideLocalizationService(this);
    return (
      <FilterForm
        initialValues={filter}
        onSubmit={filterCompetencyRequests}
        onClear={clearCompetencyRequestsFilter}
        localizationService={localizationService}
        title={localizationService.toLanguageString('grid.filterTitle')}
        uniqueKey={JSON.stringify(filter)}
        render={() => (
          <WrapLayout>
            <StackLayout width='340px'>
              <Field
                name='studyProgram'
                width='340px'
                component={ComboBox}
                label={localizationService.toLanguageString('competencyRequest.studyProgram')}
                textField='name'
                dataItemKey='id'
                loading={studyProgramsComboBoxLoading}
                data={studyProgramsComboBoxData}
                onFilter={filterStudyProgramsComboBox}
              />
              <Field
                name='resident'
                width='340px'
                component={ComboBox}
                label={localizationService.toLanguageString('competencyRequest.resident')}
                textField='name'
                dataItemKey='id'
                loading={residentsComboBoxLoading}
                data={residentsComboBoxData}
                onFilter={filterResidentsComboBox}
              />
            </StackLayout>
            <StackLayout width='340px'>
              <Field
                name='status'
                width='340px'
                component={DropDown}
                itemRender={this.statusDropdownItemRender}
                label={localizationService.toLanguageString('competencyRequest.status')}
                textField='text'
                dataItemKey='value'
                data={this.getStatuses()}
              />
            </StackLayout>
          </WrapLayout>
        )}
      />
    );
  }

  getStatuses() {
    const localizationService = provideLocalizationService(this);
    return Object.entries(STUDENT_COMPETENCY_STATUS).map(element => ({
      value: element[1],
      text: localizationService.toLanguageString(`studentCompetencyStatus.${element[1]}`)
    }));
  }

  statusDropdownItemRender(li, itemProps) {
    const itemChildren = (
      <DropdownItemContainer>
        <BadgeContainer>
          <StyledBadge
            shape='rectangle'
            size='large'
            themeColor={getStudentCompetencyStatusColor(itemProps.dataItem.value)}
            position='outside'
            align={{ vertical: 'top', horizontal: 'end' }}
          >
            {li.props.children}
          </StyledBadge>
        </BadgeContainer>
      </DropdownItemContainer>
    );
    return React.cloneElement(li, li.props, itemChildren);
  }
}

const mapStateToProps = state => ({
  filter: state.competencyRequestListPage.filter,

  studyProgramsComboBoxLoading: state.competencyRequestListPage.studyProgramsComboBoxLoading,
  studyProgramsComboBoxData: state.competencyRequestListPage.studyProgramsComboBoxData,

  residentsComboBoxLoading: state.competencyRequestListPage.residentsComboBoxLoading,
  residentsComboBoxData: state.competencyRequestListPage.residentsComboBoxData
});

const mapDispatchToProps = dispatch => ({
  filterCompetencyRequests: (payload) => dispatch(filterCompetencyRequests(payload)),
  clearCompetencyRequestsFilter: () => dispatch(clearCompetencyRequestsFilter()),

  filterStudyProgramsComboBox: (payload) => dispatch(filterStudyProgramsComboBox(payload)),

  filterResidentsComboBox: (payload) => dispatch(filterResidentsComboBox(payload))
});

registerForLocalization(CompetencyRequestListFilter);

export default connect(mapStateToProps, mapDispatchToProps)(CompetencyRequestListFilter);