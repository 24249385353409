import React from 'react';
import { connect } from 'react-redux';
import { GridToolbar } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { Grid, GridSize } from '../../shared/components';
import {
  loadCompetencyListPage,
  findCompetencies,
  openCompetencyConfirmDialog,
  closeCompetencyConfirmDialog,
  removeCompetencyConfirmDialog,
  sortCompetencies
} from '../actions/competency-list-page-actions';
import { canWriteStudyProgram } from '../../common/actions';
import { withRouter } from '../../../components/withRouter';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Text } from '../../../ui';

class CompetencyList extends React.PureComponent {
  constructor() {
    super();
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleAddClicked = this.handleAddClicked.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleConfirmDialogClose = this.handleConfirmDialogClose.bind(this);
    this.handleRemoveConfirm = this.handleRemoveConfirm.bind(this);
    this.handleSort = this.handleSort.bind(this);
  }

  async componentDidMount() {
    await this.props.loadCompetencyListPage();
  }

  render() {
    const localizationService = provideLocalizationService(this);
    return (
      <>
        <Grid
          loading={this.props.loading}
          data={this.props.competencies}
          skip={this.props.skip}
          take={this.props.take}
          total={this.props.total}
          sort={this.props.sorting}
          onSortChange={this.handleSort}
          onPageChange={this.handlePageChange}
          onRowClick={this.handleRowClick}
          columns={[
            {
              field: 'name',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('competency.name'),
              width: '450px'
            }, {
              field: 'abbreviation',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('competency.abbreviation')
            }, {
              field: 'studyProgramName',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('competency.studyProgram'),
              minGridWidth: GridSize.small,
              width: '350px'
            }
          ]}
          actions={
            this.props.canWrite && [{
              icon: 'delete',
              onClick: this.handleRemoveClick,
              disabledOn: (e) => {
                return !e.dataItem.canBeDeleted || !canWriteStudyProgram(e.dataItem.studyProgramId, this.props.app);
              }
            }]
          }
        >
          {this.props.canWrite &&
            <GridToolbar>
              <Button themeColor='primary' onClick={this.handleAddClicked}>
                {localizationService.toLanguageString('custom.add')}
              </Button>
            </GridToolbar>
          }
        </Grid>
        {this.props.isConfirmDialogVisible &&
          <Dialog title={localizationService.toLanguageString('custom.confirm')} onClose={this.handleConfirmDialogClose}>
            <Text>{localizationService.toLanguageString('competency.competencyDialogMessage')}</Text>
            <DialogActionsBar>
              <Button themeColor={'primary'} onClick={this.handleRemoveConfirm}>{localizationService.toLanguageString('buttons.delete')}</Button>
              <Button onClick={this.handleConfirmDialogClose}>{localizationService.toLanguageString('buttons.cancel')}</Button>
            </DialogActionsBar>
          </Dialog>
        }
      </>
    );
  }

  async handlePageChange(e) {
    await this.props.findCompetencies({ skip: e.page.skip, take: e.page.take });
  }

  handleRowClick(e) {
    this.props.navigate(`/competencies/${e.dataItem.id}/overview`);
  }

  handleAddClicked() {
    this.props.navigate('/competencies/create');
  }

  handleRemoveClick(row) {
    this.props.openCompetencyConfirmDialog(row.dataItem);
  }

  handleConfirmDialogClose() {
    this.props.closeCompetencyConfirmDialog();
  }

  handleRemoveConfirm() {
    this.props.removeCompetencyConfirmDialog({
      competencyId: this.props.competencyId
    });
  }

  handleSort(e) {
    this.props.sortCompetencies(e.sort);
  }
}

const mapStateToProps = state => ({
  loading: state.competencyListPage.loading,
  competencies: state.competencyListPage.competencies,
  skip: state.competencyListPage.skip,
  take: state.competencyListPage.take,
  total: state.competencyListPage.total,
  canWrite: state.competencyListPage.canWrite,
  isConfirmDialogVisible: state.competencyListPage.isConfirmDialogVisible,
  sorting: state.competencyListPage.sorting,
  app: state.app
});

const mapDispatchToProps = dispatch => ({
  loadCompetencyListPage: () => dispatch(loadCompetencyListPage()),
  findCompetencies: (payload) => dispatch(findCompetencies(payload)),
  openCompetencyConfirmDialog: (payload) => dispatch(openCompetencyConfirmDialog(payload)),
  closeCompetencyConfirmDialog: () => dispatch(closeCompetencyConfirmDialog()),
  removeCompetencyConfirmDialog: (payload) => dispatch(removeCompetencyConfirmDialog(payload)),
  sortCompetencies: (payload) => dispatch(sortCompetencies(payload))
});

registerForLocalization(CompetencyList);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CompetencyList));