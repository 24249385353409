import React from 'react';
import { connect } from 'react-redux';
import { GridToolbar } from '@progress/kendo-react-grid';
import { provideLocalizationService, registerForIntl, registerForLocalization } from '@progress/kendo-react-intl';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridSize } from '../../shared/components';
import { Text } from '../../../ui';
import { loadStudyPlanCycleList, openStudyPlanCycleConfirmDialog, closeStudyPlanCycleConfirmDialog, removeStudyPlanCycleConfirmDialog } from '../actions/study-plan-cycle-list-actions';
import { withRouter } from '../../../components/withRouter';
import styled from 'styled-components';

const CycleNameContainer = styled.span`
  font-weight: bolder;
`;

class StudyPlanCycleList extends React.Component {
  constructor(props) {
    super(props);
    this.expandChange = this.expandChange.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleConfirmDialogClose = this.handleConfirmDialogClose.bind(this);
    this.handleRemoveConfirm = this.handleRemoveConfirm.bind(this);
    this.renderName = this.renderName.bind(this);
    this.renderCycleType = this.renderCycleType.bind(this);
  }

  async componentDidMount() {
    await this.props.loadStudyPlanCycleList(this.props.studyPlanId);
  }

  render() {
    const { studyPlanCycles, loading, isConfirmDialogVisible } = this.props;
    const localizationService = provideLocalizationService(this);
    return (
      <>
        <Grid
          loading={loading}
          data={studyPlanCycles}
          isPageable={false}
          onRowClick={this.handleRowClick}
          columns={[
            {
              field: 'cycleName',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('StudyPlanCycle.name'),
              cell: this.renderName
            }, {
              field: 'cycleCode',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('StudyPlanCycle.code')
            }, {
              field: 'cycleType',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('StudyPlanCycle.type'),
              minGridWidth: GridSize.medium,
              cell: (e) => this.renderCycleType(e, localizationService)
            }, {
              field: 'cycleCredits',
              className: 'align-right',
              headerClassName: 'align-right',
              title: localizationService.toLanguageString('StudyPlanCycle.credits')
            }
          ]}
          actions={[{
            icon: 'delete',
            onClick: this.handleRemoveClick,
            disabledOn: e => !e.dataItem.canBeDeleted
          }]}
        >
          <GridToolbar>
            <Button themeColor='primary' onClick={this.handleAddClick}>
              {localizationService.toLanguageString('custom.add')}
            </Button>
          </GridToolbar>

        </Grid>
        {isConfirmDialogVisible &&
          <Dialog title={localizationService.toLanguageString('custom.confirm')} onClose={this.handleConfirmDialogClose}>
            <Text>{localizationService.toLanguageString('StudyPlanCycle.studyPlanCycleRemoveDialogMessage')}</Text>
            <DialogActionsBar>
              <Button themeColor={'primary'} onClick={this.handleRemoveConfirm}>{localizationService.toLanguageString('buttons.delete')}</Button>
              <Button onClick={this.handleConfirmDialogClose}>{localizationService.toLanguageString('buttons.cancel')}</Button>
            </DialogActionsBar>
          </Dialog>
        }
      </>
    );
  }

  renderName(e) {
    const { dataItem, className } = e;
    return (
      <td className={className}>
        {this.props.studyProgramId === dataItem.cycleStudyProgramId
          ? dataItem.cycleName
          : <CycleNameContainer onClick={() => this.handleRowClick(e)}>{`${dataItem.cycleName} (${dataItem.cycleStudyProgramName})`}</CycleNameContainer>
        }
      </td>
    );
  }

  renderCycleType(e, localizationService) {
    const { dataItem, className } = e;
    return (
      <td className={className}>
        {localizationService.toLanguageString(`cycleType.${dataItem.cycleType}`)}
      </td>
    );
  }

  expandChange(event) {
    event.dataItem.expanded = !event.dataItem.expanded;
    this.forceUpdate();
  };

  handleEditClick(row) {
    this.props.navigate(`/study_plans/${this.props.studyPlanId}/cycles/${row.dataItem.id}`);
  }

  handleRowClick(row) {
    this.props.navigate(`/study_plans/${this.props.studyPlanId}/cycles/${row.dataItem.id}/overview`);
  }

  handleAddClick() {
    this.props.navigate(`/study_plans/${this.props.studyPlanId}/cycle_select`);
  }

  handleRemoveClick(e) {
    this.props.openStudyPlanCycleConfirmDialog(e.dataItem);
  }

  handleConfirmDialogClose() {
    this.props.closeStudyPlanCycleConfirmDialog();
  }

  handleRemoveConfirm() {
    this.props.removeStudyPlanCycleConfirmDialog({
      studyPlanId: this.props.studyPlanId
    });
  }
}

const mapStateToProps = (state) => ({
  studyPlanCycles: state.studyPlanCycleList.studyPlanCycles,
  loading: state.studyPlanCycleList.loading,
  isConfirmDialogVisible: state.studyPlanCycleList.isConfirmDialogVisible
});

const mapDispatchToProps = (dispatch) => ({
  loadStudyPlanCycleList: (payload) => dispatch(loadStudyPlanCycleList(payload)),
  openStudyPlanCycleConfirmDialog: (payload) => dispatch(openStudyPlanCycleConfirmDialog(payload)),
  closeStudyPlanCycleConfirmDialog: (payload) => dispatch(closeStudyPlanCycleConfirmDialog(payload)),
  removeStudyPlanCycleConfirmDialog: (payload) => dispatch(removeStudyPlanCycleConfirmDialog(payload))
});

registerForIntl(StudyPlanCycleList);
registerForLocalization(StudyPlanCycleList);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StudyPlanCycleList));
