import { STUDY_PLAN_CYCLE_STUDENT_APPROVING_USER_STATUS } from '../resources/studyPlanCycleStudentApprovingUserStatus';
import { Colors } from '../ui';

export const getStudyPlanCycleStudentApprovingUserStatusColorCode = (status) => {
  switch (status) {
    case STUDY_PLAN_CYCLE_STUDENT_APPROVING_USER_STATUS.UNAPPROVED:
      return null;
    case STUDY_PLAN_CYCLE_STUDENT_APPROVING_USER_STATUS.SUPPLEMENTED:
      return null;
    case STUDY_PLAN_CYCLE_STUDENT_APPROVING_USER_STATUS.SUBMITTED:
      return Colors.INFO_40;
    case STUDY_PLAN_CYCLE_STUDENT_APPROVING_USER_STATUS.RETURNED:
      return Colors.ERROR_30;
    case STUDY_PLAN_CYCLE_STUDENT_APPROVING_USER_STATUS.APPROVED:
      return Colors.SUCCESS_40;
    default:
      return null;
  }
};