import { css } from 'styled-components';

export default {

  h1: css`
    font-size: 28px;
    line-height: 32px;
    letter-spacing: 0.86px;
    font-family: 'Roboto',sans-serif;
    font-weight: 900;
    @media (max-width: 768px) {
      font-weight: 400;
      font-size: 24px;
      line-height: 28px;
    }
  `,

  h2: css`
    font-size: 26px;
    line-height: 30px;
    font-family: 'Roboto',sans-serif;
    font-weight: 700;
  `,

  h3: css`
    font-size: 22px;
    line-height: 26px;
    font-family: 'Roboto',sans-serif;
    font-weight: 700;
    @media (max-width: 768px) {
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
    }
  `,

  h4: css`
    font-size: 20px;
    line-height: 24px;
    font-family: 'Roboto',sans-serif;
    font-weight: 700;
    @media (max-width: 768px) {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }
  `,

  h5: css`
    font-size: 18px;
    line-height: 22px;
    font-family: 'Roboto',sans-serif;
    font-weight: 700;
  `,

  h6: css`
    font-size: 16px;
    line-height: 20px;
    font-family: 'Roboto',sans-serif;
    font-weight: 700;
  `,

  subtitle1: css`
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.15px;
    font-family: 'Roboto',sans-serif;
    font-weight: 400;
  `,

  subtitle2: css`
    font-size: 14px;
    line-height: 20px;
    font-family: 'Roboto',sans-serif;
    font-weight: 700;
  `,

  subtitle3: css`
    font-size: 14px;
    line-height: 20px;
    font-family: 'Roboto',sans-serif;
    font-weight: 100;
  `,

  body1: css`
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.5px;
    font-family: 'Roboto',sans-serif;
    font-weight: 400;
  `,

  body2: css`
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    font-family: 'Roboto',sans-serif;
    font-weight: 400;
  `,

  button1: css`
    font-size: 14px;
    line-height: 16px;
    font-family: 'Roboto',sans-serif;
    font-weight: 700;
  `,

  button2: css`
    font-size: 12px;
    line-height: 16px;
    font-family: 'Roboto',sans-serif;
    font-weight: 700;
  `,

  caption: css`
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    font-family: 'Roboto',sans-serif;
    font-weight: 400;
  `,

  overline: css`
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 1.5px;
    font-family: 'Roboto',sans-serif;
    font-weight: 400;
    text-transform: uppercase;
  `

};