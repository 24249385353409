import * as React from 'react';
import { MultiSelect as KendoMultiSelect } from '@progress/kendo-react-dropdowns';
import { itemsFromValues, valuesFromItems } from '../../../utils/formUtils';

class MultiSelectWithRemoteData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      skip: 0,
      total: 0,
      data: [],
      pageSize: 20,
      filter: {}
    };
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  async componentDidMount() {
    const { skip, filter } = this.state;
    const { input: { value } } = this.props;
    await this.loadData(skip, filter, value);
  }

  async handlePageChange(e) {
    const { skip } = e.page;
    const { filter } = this.state;
    await this.loadData(skip, filter);
  }

  async handleFilterChange(e) {
    const filter = e.filter;
    const { skip } = this.state;
    await this.loadData(skip, filter);
  }

  async loadData(skip, filter, value) {
    const { handleData } = this.props;
    const { pageSize } = this.state;

    let total = 0;
    let initialData = [];

    this.setState({
      ...this.state,
      loading: true
    });

    const result = await handleData(skip, pageSize, filter, value);

    if (result) {
      total = result.total;
      initialData = result.data;
    }

    this.setState({
      ...this.state,
      loading: false,
      data: initialData,
      total: total,
      filter: filter,
      skip: skip
    });
  }

  render() {
    const {
      label,
      input: { value, onChange },
      textField,
      valueField,
      disabled,
      required
    } = this.props;

    const {
      data,
      loading,
      total,
      pageSize,
      skip
    } = this.state;

    const virtual = {
      total: total,
      pageSize: pageSize,
      skip: skip
    };

    return (
      <fieldset className={'k-form-fieldset'}>
        <KendoMultiSelect
          label={label}
          value={itemsFromValues(value, valueField, data)}
          onChange={e => onChange(valuesFromItems((e.value || e.target.value), valueField))}
          data={data}
          textField={textField}
          disabled={disabled}
          required={required}
          loading={loading}
          filterable={true}
          onFilterChange={this.handleFilterChange}
          virtual={virtual}
          onPageChange={this.handlePageChange}
        />
      </fieldset>
    );
  }
};

export default MultiSelectWithRemoteData;
