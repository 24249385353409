import * as React from 'react';
import { connect } from 'react-redux';
import { provideLocalizationService, registerForLocalization, provideIntlService, registerForIntl } from '@progress/kendo-react-intl';
import { Skeleton } from '@progress/kendo-react-indicators';
import { OverviewPage, StackLayout, Text, WrapLayout } from '../../../ui';
import { ValueItem, DateFormatter, InlineBadge } from '../../shared/components';
import { loadDesignationOverviewPage, cleanupDesignationOverviewPage } from '../actions/designation-overview-page-actions';
import { withRouter } from '../../../components/withRouter';
import { DatePeriodFormatter } from '../../shared/components/Formatter';
import { getStudentCycleDepartmentStudentDesignationStatusColor } from '../../../utils/studyPlanCycleDepartmentStudentDesignationStatusColors';
import colors from '../../../ui/components/shared/colors';

class DesignationOverviewPage extends React.PureComponent {

  constructor() {
    super();
    this.handleBreadcrumbItemClick = this.handleBreadcrumbItemClick.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
  }

  async componentDidMount() {
    if (this.props.params?.studyPlanCycleDepartmentStudentId) {
      this.props.loadDesignationOverviewPage({
        studyPlanId: this.props.params.studyPlanId,
        studyPlanCycleId: this.props.params.studyPlanCycleId,
        studyPlanCycleStudentId: this.props.params.studyPlanCycleStudentId,
        studyPlanCycleDepartmentStudentId: this.props.params.studyPlanCycleDepartmentStudentId
      });
    }
  }

  componentWillUnmount() {
    if (this.props.designation?.designationPdfObjectUrl) {
      this.props.cleanupDesignationOverviewPage();
    }
  }

  render() {
    const { designation, loading } = this.props;
    const localizationService = provideLocalizationService(this);
    const intlService = provideIntlService(this);
    return (
      <OverviewPage
        title={loading
          ? <Skeleton shape='text' style={{ width: 300, height: 28 }}/>
          : `${designation?.studyPlanCycleDepartmentName}`
        }
        breadcrumbItems={[
          {
            id: 'designations',
            text: localizationService.toLanguageString('designations.designations')
          },
          {
            id: 'designation',
            text: localizationService.toLanguageString('designations.designation'),
            disabled: true
          }
        ]}
        onBreadcrumbItemClick={this.handleBreadcrumbItemClick}
        onBackClick={this.handleBackClick}
        summary={
          <StackLayout rowGap='8px'>
            <ValueItem
              label={localizationService.toLanguageString('designations.date')}
              labelWidth='100px'
              loading={loading}
            >
              {designation?.dateFrom && designation?.dateTo ? DatePeriodFormatter(designation.dateFrom, designation.dateTo, intlService) : ''}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('designations.cycle')}
              labelWidth='100px'
              loading={loading}
            >
              {designation?.cycleName}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('designations.credits')}
              labelWidth='100px'
              loading={loading}
            >
              {designation?.credits}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('designations.statusHistory')}
              labelWidth='100px'
              loading={loading}
            >
              <WrapLayout orientation='vertical'>
                {designation?.designationHistory?.map((element, index) => {
                  return (
                    <div key={index}>
                      <InlineBadge themeColor={getStudentCycleDepartmentStudentDesignationStatusColor(element.status)}>
                        {localizationService.toLanguageString('studyPlanCycleDepartmentStudentStatus.' + element.status)}
                      </InlineBadge>
                      <Text variant={'body2'} textColor={colors.GRAY_80}>
                        {` ${element.createdBy} ${DateFormatter(element.date, intlService)}`}
                      </Text>
                    </div>
                  );
                })}
              </WrapLayout>
            </ValueItem>
          </StackLayout>
        }
      >
        {designation?.designationPdfObjectUrl &&
          <iframe
            src={designation?.designationPdfObjectUrl}
            title={localizationService.toLanguageString('designations.designation')}
            width={'100%'}
            height={'1000'}
          />
        }
      </OverviewPage>
    );
  }

  handleBreadcrumbItemClick(e) {
    if (e.id === 'designations') {
      this.props.navigate('/designations');
    }
  }

  handleBackClick() {
    this.props.navigate(-1);
  }
}

registerForLocalization(DesignationOverviewPage);
registerForIntl(DesignationOverviewPage);

const mapStateToProps = state => ({
  designation: state.designationOverviewPage.designation,
  loading: state.designationOverviewPage.loading
});

const mapDispatchToProps = dispatch => ({
  loadDesignationOverviewPage: (payload) => dispatch(loadDesignationOverviewPage(payload)),
  cleanupDesignationOverviewPage: () => dispatch(cleanupDesignationOverviewPage())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DesignationOverviewPage));
