import React from 'react';
import { connect } from 'react-redux';
import { provideIntlService, provideLocalizationService, registerForIntl, registerForLocalization } from '@progress/kendo-react-intl';
import { Grid, GridSize, InlineBadge } from '../../shared/components';
import { findManagedStudents, loadManagedStudentListPage, toggleManagedStudentExpand } from '../actions/managed-student-list-page-actions';
import { DateFormatter, DecimalFormatter } from '../../shared/components/Formatter';
import { Colors, Text } from '../../../ui';
import { getStudentCycleStatusColor } from '../../../utils/studyPlanCycleDepartmentStudentStatusColors';
import { withRouter } from '../../../components/withRouter';
import styled from 'styled-components';
import { Skeleton } from '@progress/kendo-react-indicators';

const ExpandCell = styled.td`
  padding-left: 16px !important;
  padding-right: 0px !important;
`;

const GridDetailsItemContainer = styled.div`
  margin: 8px 0px;
`;

class ManagedStudentList extends React.PureComponent {

  constructor(props) {
    super(props);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.renderCell = this.renderCell.bind(this);
  }

  async componentDidMount() {
    await this.props.loadManagedStudentListPage();
  }

  render() {
    const localizationService = provideLocalizationService(this);
    const intlService = provideIntlService(this);
    return (
      <Grid
        loading={this.props.loading}
        data={this.props.managedStudents}
        skip={this.props.skip}
        take={this.props.take}
        total={this.props.total}
        onPageChange={this.handlePageChange}
        detail={({ dataItem }) => this.renderDetails(dataItem, localizationService, intlService)}
        cellRender={this.renderCell}
        expandField='expanded'
        onExpandChange={(event) => this.props.toggleManagedStudentExpand({ id: event.dataItem.id })}
        onRowClick={this.handleRowClick}
        columns={[
          {
            field: 'student',
            className: 'align-left',
            headerClassName: 'align-left',
            title: localizationService.toLanguageString('managedStudents.resident'),
            cell: (e) => this.renderStudent(e, localizationService)
          }, {
            field: 'cycle',
            className: 'align-left',
            headerClassName: 'align-left',
            title: localizationService.toLanguageString('managedStudents.cycle')
          }, {
            field: 'generalMark',
            className: 'align-right',
            headerClassName: 'align-right',
            title: localizationService.toLanguageString('managedStudents.generalMark'),
            cell: (e) => this.renderGeneralMark(e),
            minGridWidth: GridSize.large
          }, {
            field: 'status',
            className: 'align-left',
            headerClassName: 'align-left',
            title: localizationService.toLanguageString('managedStudents.status'),
            cell: (e) => this.renderCycleStatus(e, localizationService),
            minGridWidth: GridSize.medium
          }
        ]}
      >
      </Grid>
    );
  }

  renderDetails(dataItem, localizationService, intlService) {
    if (dataItem.studentDepartments?.length > 0) {
      return (dataItem.studentDepartments?.map((studentDepartment, index) => (
        <GridDetailsItemContainer key={index}>
          {studentDepartment?.loading
            ? <Skeleton shape='text'/>
            : <Text variant='caption' textColor={Colors.GRAY_60}>
              {`${DateFormatter(studentDepartment.dateFrom, intlService)} - ${DateFormatter(studentDepartment.dateTo, intlService)} ${studentDepartment.credits}${localizationService.toLanguageString('managedStudents.creditsShort')} ${studentDepartment.studyPlanCycleDepartmentName}`}
            </Text>
          }
        </GridDetailsItemContainer>
      )));
    } else {
      return (
        <GridDetailsItemContainer>
          <Text variant='caption' textColor={Colors.GRAY_60}>{localizationService.toLanguageString('grid.noRecords')}</Text>
        </GridDetailsItemContainer>
      );
    }
  }

  renderCell(tdElement, cellProps) {
    if (cellProps.rowType === 'data') {
      if (cellProps.field === 'expanded') {
        return (
          <ExpandCell>
            <a
              onClick={() => this.props.toggleManagedStudentExpand({ id: cellProps.dataItem.id })}
              className={`k-icon k-i-${cellProps.dataItem.expanded ? 'collapse' : 'expand'}`}
            />
          </ExpandCell>
        );
      }
    }
    return tdElement;
  }

  renderStudent(e, localizationService) {
    const { dataItem, className } = e;
    return (
      <td className={className}>
        {`${dataItem.name} ${dataItem.surname}`}
        <br />
        <Text variant={'caption'} textColor={Colors.GRAY_60} overflowWrap={true}>
          {`${dataItem.studyProgram} ${dataItem.studyYear} ${localizationService.toLanguageString('managedStudents.year')}`}
        </Text>
      </td>
    );
  }

  renderGeneralMark(e) {
    const { dataItem, className } = e;
    return (
      <td className={className}>
        { DecimalFormatter(dataItem.generalMark, 0) }
      </td>
    );
  }

  renderCycleStatus(e, localizationService) {
    const { dataItem, className } = e;
    return (
      <td className={className}>
        <InlineBadge themeColor={getStudentCycleStatusColor(dataItem.status)}>
          {localizationService.toLanguageString('studyPlanCycleDepartmentStudentStatus.' + dataItem.status)}
        </InlineBadge>
      </td>
    );
  }

  async handlePageChange(e) {
    await this.props.findManagedStudents({ skip: e.page.skip, take: e.page.take });
  }

  handleRowClick(e) {
    this.props.navigate(`/managed_students/${e.dataItem.id}/overview`);
  }
}

const mapStateToProps = state => ({
  loading: state.managedStudentListPage.loading,
  managedStudents: state.managedStudentListPage.managedStudents,
  skip: state.managedStudentListPage.skip,
  take: state.managedStudentListPage.take,
  total: state.managedStudentListPage.total
});

const mapDispatchToProps = dispatch => ({
  loadManagedStudentListPage: () => dispatch(loadManagedStudentListPage()),
  findManagedStudents: (payload) => dispatch(findManagedStudents(payload)),
  toggleManagedStudentExpand: (payload) => dispatch(toggleManagedStudentExpand(payload))
});

registerForLocalization(ManagedStudentList);
registerForIntl(ManagedStudentList);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ManagedStudentList));