import StudentCompetencySurveyListActionTypes from '../actions/student-competency-survey-list-action-types';

const initialState = {
  loading: false,
  studentCompetencySurveys: null,
  studentCompetencySurveysCancelToken: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case StudentCompetencySurveyListActionTypes.LOAD_STUDENT_COMPETENCY_SURVEY_LIST_START:
      return {
        ...state,
        loading: true
      };
    case StudentCompetencySurveyListActionTypes.LOAD_STUDENT_COMPETENCY_SURVEY_LIST_END:
      return {
        ...state,
        studentCompetencySurveys: action.payload,
        loading: false
      };
    default:
      return state;
  }
};
