import { React, Component } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import styled from 'styled-components';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { withRouter } from '../../../components/withRouter';

const ErrorContainer = styled.div`
  margin: 16px;
`;

const MessageItem = styled.div`
  margin: 8px;
`;

export class Error extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const localizationService = provideLocalizationService(this);
    const { messageResource, buttonMessageResource, onButtonClick } = this.props;
    let message;
    if (process.env.NODE_ENV === 'development') {
      message = this.props.location?.state?.message || this.props.messageText || localizationService.toLanguageString(messageResource);
    }
    const buttonMessage = this.props.buttonMessageText || localizationService.toLanguageString(buttonMessageResource);
    return (
      <ErrorContainer>
        <h2>{localizationService.toLanguageString('common.somethingWentWrong')}</h2>
        {message &&
          <MessageItem>{message}</MessageItem>
        }
        {buttonMessage &&
          <Button onClick={onButtonClick}>{buttonMessage}</Button>
        }
      </ErrorContainer>
    );
  }
}

registerForLocalization(Error);

export default withRouter(Error);