import { CycleTheoreticalPartListActionTypes } from '..';

const initialState = {
  loading: false,
  cycleTheoreticalParts: null,
  cycleTheoreticalPartsCancelToken: null,
  isConfirmDialogVisible: false,
  selectedCycleTheoreticalPartId: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CycleTheoreticalPartListActionTypes.LOAD_CYCLE_THEORETICAL_PART_LIST_START:
      return {
        ...state,
        loading: true
      };
    case CycleTheoreticalPartListActionTypes.LOAD_CYCLE_THEORETICAL_PART_LIST_END:
      return {
        ...state,
        cycleTheoreticalParts: action.payload,
        loading: false
      };
    case CycleTheoreticalPartListActionTypes.TOGGLE_CYCLE_THEORETICAL_PART_LIST_CONFIRM_DIALOG:
      return {
        ...state,
        selectedCycleTheoreticalPartId: action.payload?.id,
        isConfirmDialogVisible: !state.isConfirmDialogVisible
      };
    default:
      return state;
  }
};