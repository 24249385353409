import { STUDENT_COMPETENCY_STATUS } from '../../../resources/studentCompetencyStatus';
import CompetencyRequestListPageActionTypes from './competency-request-list-page-action-types';
import axios from 'axios';

export const loadCompetencyRequestListPage = (payload) => async(dispatch, getState) => {
  dispatch(findCompetencyRequests());
  dispatch(filterStudyProgramsComboBox());
  dispatch(filterResidentsComboBox());
};

export const findCompetencyRequests = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: CompetencyRequestListPageActionTypes.LOAD_COMPETENCY_REQUEST_LIST_START });
  const state = getState().competencyRequestListPage;
  const query = {
    skip: payload ? payload.skip : state.skip,
    limit: payload ? payload.take : state.take,
    studyProgramId: state.filter?.studyProgram?.id,
    studentId: state.filter?.resident?.id,
    status: state.filter?.status?.value
  };
  try {
    state.competencyRequestsCancelToken?.cancel();
    state.competencyRequestsCancelToken = axios.CancelToken.source();
    const competencyRequests = await api.get(`api/competency_requests`, query, state.competencyRequestsCancelToken.token);
    dispatch({
      type: CompetencyRequestListPageActionTypes.LOAD_COMPETENCY_REQUEST_LIST_END,
      payload: {
        competencyRequests: competencyRequests?.data?.map(item => ({
          ...item,
          isSelected: false
        })),
        total: competencyRequests ? competencyRequests.total : 0,
        skip: payload ? payload.skip : state.skip,
        take: payload ? payload.take : state.take
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: CompetencyRequestListPageActionTypes.LOAD_COMPETENCY_REQUEST_LIST_END });
      throw error;
    }
  }
};

export const filterCompetencyRequests = (payload) => (dispatch) => {
  dispatch({
    type: CompetencyRequestListPageActionTypes.FILTER_COMPETENCY_REQUEST_LIST,
    payload: payload
  });
  dispatch(findCompetencyRequests());
};

export const clearCompetencyRequestsFilter = () => (dispatch) => {
  dispatch({
    type: CompetencyRequestListPageActionTypes.FILTER_COMPETENCY_REQUEST_LIST,
    payload: {}
  });
  dispatch(findCompetencyRequests());
};

export const filterStudyProgramsComboBox = payload => async(dispatch, getState, { api }) => {
  dispatch({ type: CompetencyRequestListPageActionTypes.SEARCH_STUDY_PROGRAMS_COMBOBOX_START });
  const query = {
    skip: 0,
    take: 50,
    keyword: payload?.keyword
  };
  const state = getState().competencyRequestListPage;
  try {
    state.studyProgramsComboBoxCancelToken?.cancel();
    state.studyProgramsComboBoxCancelToken = axios.CancelToken.source();
    const studyPrograms = await api.get(`api/study_programs`, query, state.studyProgramsComboBoxCancelToken.token);
    dispatch({
      type: CompetencyRequestListPageActionTypes.SEARCH_STUDY_PROGRAMS_COMBOBOX_END,
      payload: {
        studyPrograms: studyPrograms?.data ?? []
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: CompetencyRequestListPageActionTypes.SEARCH_STUDY_PROGRAMS_COMBOBOX_END });
      throw error;
    }
  }
};

export const filterResidentsComboBox = payload => async(dispatch, getState, { api }) => {
  dispatch({ type: CompetencyRequestListPageActionTypes.SEARCH_RESIDENTS_COMBOBOX_START });
  const query = {
    skip: 0,
    take: 50,
    keyword: payload?.keyword
  };
  const state = getState().competencyRequestListPage;
  try {
    state.residentsComboBoxCancelToken?.cancel();
    state.residentsComboBoxCancelToken = axios.CancelToken.source();
    const residents = await api.get(`api/students`, query, state.residentsComboBoxCancelToken.token);
    dispatch({
      type: CompetencyRequestListPageActionTypes.SEARCH_RESIDENTS_COMBOBOX_END,
      payload: {
        residents: residents?.data?.map(element => ({ id: element.id, name: element.firstName + ' ' + element.lastName })) ?? []
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: CompetencyRequestListPageActionTypes.SEARCH_RESIDENTS_COMBOBOX_END });
      throw error;
    }
  }
};

export const selectCompetencyRequestList = (payload) => (dispatch, getState) => {
  const state = getState().competencyRequestListPage;
  const selectedIds = [];
  let competencyRequests = [];
  let selectedAll = true;
  if (!state.competencyRequests) {
    selectedAll = false;
  }
  competencyRequests = state.competencyRequests.map(competencyRequest => {
    let isSelected = competencyRequest.isSelected;
    if (competencyRequest.id === payload.id) {
      isSelected = !isSelected;
    }
    if (isSelected) {
      selectedIds.push(competencyRequest.id);
    } else {
      selectedAll = false;
    }
    return {
      ...competencyRequest,
      isSelected
    };
  });
  dispatch({
    type: CompetencyRequestListPageActionTypes.SELECT_COMPETENCY_REQUEST_LIST,
    payload: {
      competencyRequests,
      selectedIds,
      selectedAll
    }
  });
};

export const selectAllCompetencyRequestList = () => (dispatch, getState) => {
  const state = getState().competencyRequestListPage;
  const selectedIds = [];
  const competencyRequests = state.competencyRequests?.map(competencyRequest => {
    const isSelected = !state.selectedAll && (competencyRequest.status === STUDENT_COMPETENCY_STATUS.TO_BE_APPROVED || competencyRequest.status === STUDENT_COMPETENCY_STATUS.TO_BE_SIGNED);
    if (isSelected) {
      selectedIds.push(competencyRequest.id);
    }
    return {
      ...competencyRequest,
      isSelected
    };
  });
  dispatch({
    type: CompetencyRequestListPageActionTypes.SELECT_ALL_COMPETENCY_REQUEST_LIST,
    payload: {
      competencyRequests,
      selectedIds
    }
  });
};

export const initMultiSign = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: CompetencyRequestListPageActionTypes.INIT_MULTI_SIGN_START });
  const state = getState().competencyRequestListPage;
  try {
    state.competencyRequestsCancelToken?.cancel();
    state.competencyRequestsCancelToken = axios.CancelToken.source();
    const signingRedirectUrl = await api.post(`api/student_competencies/init_multi_sign`, state.selectedIds, state.competencyRequestsCancelToken.token);
    dispatch({
      type: CompetencyRequestListPageActionTypes.INIT_MULTI_SIGN_END,
      payload: { signingRedirectUrl }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: CompetencyRequestListPageActionTypes.INIT_MULTI_SIGN_END });
      throw error;
    }
  }
};

export const toggleGrantConfirmDialog = () => async(dispatch) => {
  dispatch({ type: CompetencyRequestListPageActionTypes.TOGGLE_GRANT_CONFIRM_DIALOG });
};