import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import { StackLayout } from '../layouts';
import { respondTo } from '../shared';
import { SCREEN_SIZES as ScreenSizes } from '../../../resources/screenSize';

const Container = styled.div`
  display: flex;
  align-self: stretch;
  justify-content: center;
  width: 100%;
  background-color: ${props => props.theme.gray10};
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: stretch;
  max-width: 1600px;
  min-width: 320px;
  position: relative;
`;

const HeaderActionsContainer = styled(StackLayout).attrs(() => ({
  orientation: 'horizontal',
  columnGap: '8px'
}))`
  flex-grow: 0;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.white};
  border-style: solid;
  border-color: ${props => props.theme.gray20}; 
  border-width: 0 0 1px 0;
  
  ${respondTo(ScreenSizes.XS, css`
    border-width: 1px;
  `)}
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;  
  background-color: ${props => props.theme.white};

  ${props => props.minWidth == null && respondTo(ScreenSizes.XS, css`
    max-width: calc(100vw - 48px);
  `)}
`;

export default class OverviewPage extends PureComponent {

  render() {
    return (
      <Container>
        <InnerContainer>
          <StackLayout rowGap='8px'>
            <StackLayout orientation='horizontal' columnGap='16px'>
              {this.props.headerActions &&
                <HeaderActionsContainer>
                  {this.props.headerActions}
                </HeaderActionsContainer>
              }
            </StackLayout>
          </StackLayout>
          <Main>
            <FilterContainer>
              {this.props.filter}
            </FilterContainer>
            <ContentContainer minWidth={this.props.minContentWidth}>
              {this.props.children}
            </ContentContainer>
          </Main>
        </InnerContainer>
      </Container>
    );
  }

}