import React from 'react';
import { DateFormatter } from '.';
import { provideIntlService, registerForIntl } from '@progress/kendo-react-intl';

export default class GridDateRangeCell extends React.PureComponent {

  render() {
    const intlService = provideIntlService(this);
    const dateFrom = this.props.dataItem[this.props.dateFrom]
      ? DateFormatter(new Date(this.props.dataItem[this.props.dateFrom]), intlService)
      : '';
    const dateTo = this.props.dataItem[this.props.dateTo]
      ? DateFormatter(new Date(this.props.dataItem[this.props.dateTo]), intlService)
      : '';
    const value = `${dateFrom} - ${dateTo}`;
    return (
      <td className={this.props.className}>{value.replaceAll('-', '\u2011')}</td>
    );
  }

}

registerForIntl(GridDateRangeCell);