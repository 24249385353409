import CycleCaseListActionTypes from '../actions/cycle-case-list-action-types';

const initialState = {
  loading: false,
  cycleCases: null,
  cycleCasesTotalDiagnoses: 0,
  cycleCasesTotalMinRequiredNumber: 0,
  cycleCasesCancelToken: null,
  isConfirmDialogVisible: false,
  selectedCycleCaseId: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CycleCaseListActionTypes.LOAD_CYCLE_CASE_LIST_START:
      return {
        ...state,
        loading: true
      };
    case CycleCaseListActionTypes.LOAD_CYCLE_CASE_LIST_END:
      return {
        ...state,
        cycleCases: action.payload?.cycleCases,
        cycleCasesTotalDiagnoses: action.payload?.cycleCasesTotalDiagnoses,
        cycleCasesTotalMinRequiredNumber: action.payload?.cycleCasesTotalMinRequiredNumber,
        loading: false
      };
    case CycleCaseListActionTypes.TOGGLE_CYCLE_CASE_LIST_CONFIRM_DIALOG:
      return {
        ...state,
        selectedCycleCaseId: action.payload?.id,
        isConfirmDialogVisible: !state.isConfirmDialogVisible
      };
    default:
      return state;
  }
};
