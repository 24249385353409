import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../../components/withRouter';
import { StudentCompetencySurveyOverview } from '../../shared/student-competency';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';

class MyCompetencySurveyOverviewPage extends React.PureComponent {

  render() {
    const localizationService = provideLocalizationService(this);
    return (
      <StudentCompetencySurveyOverview
        studentId={this.props.studentId}
        breadcrumbItems={[
          {
            id: 'myCompetencies',
            text: localizationService.toLanguageString('studentCompetency.myTieredCompetency')
          },
          {
            id: 'myCompetency',
            text: localizationService.toLanguageString('studentCompetency.tieredCompetencyAbbr')
          },
          {
            id: 'myCompetencySurvey',
            text: localizationService.toLanguageString('studentCompetency.surveys'),
            disabled: true
          }
        ]}
      />
    );
  }
}

const mapStateToProps = state => ({
  studentId: state.app.currentStudies?.studentId
});

const mapDispatchToProps = dispatch => ({
});

registerForLocalization(MyCompetencySurveyOverviewPage);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MyCompetencySurveyOverviewPage));
