import * as React from 'react';
import { connect } from 'react-redux';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { Skeleton } from '@progress/kendo-react-indicators';
import { OverviewPage, StackLayout } from '../../../ui';
import { ValueItem } from '../../shared/components';
import { loadStudyPlanCycleOverviewPage, selectStudyPlanCycleOverviewPageTab } from '../actions';
import { StudyPlanCycleDepartmentList, StudyPlancycleStudentList, StudyPlanCycleTheoreticalPartList, StudyPlanCycleManagersList } from '.';
import { withRouter } from '../../../components/withRouter';

class StudyPlanCycleOverviewPage extends React.PureComponent {

  constructor() {
    super();
    this.handleBreadcrumbItemClick = this.handleBreadcrumbItemClick.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleTabSelect = this.handleTabSelect.bind(this);
  }

  async componentDidMount() {
    if (this.props.params?.studyPlanId) {
      this.props.loadStudyPlanCycleOverviewPage({
        studyPlanId: this.props.params.studyPlanId,
        studyPlanCycleId: this.props.params.studyPlanCycleId
      });
    }
  }

  render() {
    const { studyPlanCycle, loading } = this.props;
    const localizationService = provideLocalizationService(this);
    return (
      <OverviewPage
        title={loading
          ? <Skeleton shape='text' style={{ width: 300, height: 28 }}/>
          : studyPlanCycle?.cycleName
        }
        breadcrumbItems={[
          {
            id: 'study-plans',
            text: localizationService.toLanguageString('StudyPlanCycle.studyPlans')
          },
          {
            id: 'study-plan',
            text: localizationService.toLanguageString('StudyPlanCycle.studyPlan')
          },
          {
            id: 'study-plan-cycle',
            text: localizationService.toLanguageString('StudyPlanCycle.studyPlanCycle'),
            disabled: true
          }
        ]}
        onBreadcrumbItemClick={this.handleBreadcrumbItemClick}
        onBackClick={this.handleBackClick}
        summary={
          <StackLayout rowGap='8px'>
            <ValueItem
              label={localizationService.toLanguageString('StudyPlanCycle.studyProgram')}
              labelWidth='108px'
              loading={loading}
            >
              {studyPlanCycle?.studyProgramName}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('StudyPlanCycle.studyYear')}
              labelWidth='108px'
              loading={loading}
            >
              {studyPlanCycle?.studyPlanYear}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('StudyPlanCycle.credits')}
              labelWidth='108px'
              loading={loading}
            >
              {studyPlanCycle?.cycleCredits}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('StudyPlanCycle.type')}
              labelWidth='108px'
              loading={loading}
            >
              {studyPlanCycle?.cycleType ? localizationService.toLanguageString(`cycleType.${studyPlanCycle.cycleType}`) : null}
            </ValueItem>
          </StackLayout>
        }
        tabs={
          <TabStrip selected={this.props.selectedTab} onSelect={this.handleTabSelect}>
            <TabStripTab title={localizationService.toLanguageString('StudyPlanCycle.departments')} />
            <TabStripTab title={localizationService.toLanguageString('StudyPlanCycle.cycleManagers')} />
            <TabStripTab title={localizationService.toLanguageString('StudyPlanCycle.theoreticalParts')} />
            <TabStripTab title={localizationService.toLanguageString('StudyPlanCycle.students')} />
          </TabStrip>
        }
      >
        {this.props.selectedTab === 0 &&
          <StudyPlanCycleDepartmentList
            studyPlanId={this.props.params.studyPlanId}
            studyPlanCycleId={this.props.params.studyPlanCycleId}
          />
        }
        {this.props.selectedTab === 1 &&
          <StudyPlanCycleManagersList
            studyPlanId={this.props.params.studyPlanId}
            studyPlanCycleId={this.props.params.studyPlanCycleId}
          />
        }
        {this.props.selectedTab === 2 &&
          <StudyPlanCycleTheoreticalPartList
            studyPlanId={this.props.params.studyPlanId}
            studyPlanCycleId={this.props.params.studyPlanCycleId}
          />
        }
        {this.props.selectedTab === 3 &&
        <StudyPlancycleStudentList studyPlanId={this.props.params.studyPlanId}
          studyPlanCycleId={this.props.params.studyPlanCycleId}
        />}
      </OverviewPage>
    );
  }

  handleTabSelect(e) {
    this.props.selectStudyPlanCycleOverviewPageTab({
      selectedTab: e.selected
    });
  }

  handleBreadcrumbItemClick(e) {
    if (e.id === 'study-plans') {
      this.props.navigate('/study_plans');
    } else if (e.id === 'study-plan') {
      this.props.navigate(`/study_plans/${this.props.params.studyPlanId}/overview`);
    }
  }

  handleBackClick() {
    this.props.navigate(-1);
  }
}

registerForLocalization(StudyPlanCycleOverviewPage);

const mapStateToProps = state => ({
  studyPlanCycle: state.studyPlanCycleOverviewPage.studyPlanCycle,
  loading: state.studyPlanCycleOverviewPage.loading,
  selectedTab: state.studyPlanCycleOverviewPage.selectedTab
});

const mapDispatchToProps = dispatch => ({
  loadStudyPlanCycleOverviewPage: (payload) => dispatch(loadStudyPlanCycleOverviewPage(payload)),
  selectStudyPlanCycleOverviewPageTab: (payload) => dispatch(selectStudyPlanCycleOverviewPageTab(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StudyPlanCycleOverviewPage));
