import { StudyPlanCycleManagersListActionTypes } from '..';

const initialState = {
  loading: false,
  studyPlanId: null,
  studyPlanCycleId: null,
  studyPlanCycleManagers: [],
  selectedId: null,
  isAddDialogVisible: false,
  isDeleteConfirmDialogVisible: false,
  managersListLoading: false,
  availableManagers: [],
  studyPlanCycleManagersCancelToken: null,
  availableManagersCancelToken: null,
  validationResult: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case StudyPlanCycleManagersListActionTypes.LOAD_STUDY_PLAN_CYCLE_MANAGERS_LIST_START:
      return {
        ...state,
        loading: true
      };
    case StudyPlanCycleManagersListActionTypes.LOAD_STUDY_PLAN_CYCLE_MANAGERS_LIST_END:
      return {
        ...state,
        loading: false,
        studyPlanId: action.payload?.studyPlanId ?? state.studyPlanId,
        studyPlanCycleId: action.payload?.studyPlanCycleId ?? state.studyPlanCycleId,
        studyPlanCycleManagers: action.payload?.studyPlanCycleManagers
      };
    case StudyPlanCycleManagersListActionTypes.OPEN_STUDY_PLAN_CYCLE_MANAGERS_LIST_ADD_DIALOG:
      return {
        ...state,
        isAddDialogVisible: true
      };
    case StudyPlanCycleManagersListActionTypes.CLOSE_STUDY_PLAN_CYCLE_MANAGERS_LIST_ADD_DIALOG:
      return {
        ...state,
        isAddDialogVisible: false,
        validationResult: null
      };
    case StudyPlanCycleManagersListActionTypes.OPEN_STUDY_PLAN_CYCLE_MANAGERS_LIST_DELETE_DIALOG:
      return {
        ...state,
        isDeleteConfirmDialogVisible: true,
        selectedId: action.payload.selectedId
      };
    case StudyPlanCycleManagersListActionTypes.CLOSE_STUDY_PLAN_CYCLE_MANAGERS_LIST_DELETE_DIALOG:
      return {
        ...state,
        isDeleteConfirmDialogVisible: false
      };
    case StudyPlanCycleManagersListActionTypes.LOAD_STUDY_PLAN_CYCLE_AVAILABLE_MANAGERS_START:
      return {
        ...state,
        managersListLoading: true
      };
    case StudyPlanCycleManagersListActionTypes.LOAD_STUDY_PLAN_CYCLE_AVAILABLE_MANAGERS_END:
      return {
        ...state,
        managersListLoading: false,
        availableManagers: action.payload?.availableManagers ?? []
      };
    case StudyPlanCycleManagersListActionTypes.ADD_STUDY_PLAN_CYCLE_MANAGER_START:
      return {
        ...state,
        validationResult: null
      };
    case StudyPlanCycleManagersListActionTypes.ADD_STUDY_PLAN_CYCLE_MANAGER_END:
      return {
        ...state,
        validationResult: action.payload?.validationResult
      };
    default:
      return state;
  }
};