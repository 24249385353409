import * as React from 'react';
import { ListPage } from '../../../ui';
import { SurveyListFilter, SurveyList } from './index';

class SurveyListPage extends React.PureComponent {
  render() {
    return (
      <ListPage filter={<SurveyListFilter/>}>
        <SurveyList history={this.props.history}/>
      </ListPage>
    );
  }
}

export default SurveyListPage;