import { StudyPlanCycleStudentFormPageActionTypes } from '../actions';

const initialState = {
  loading: false,
  studyPlanCycleStudentDetails: null,
  studyPlanCycleStudentIds: null,
  studyPlanCycleStudentDepartments: null,
  departmentStudents: null,
  studyPlanId: null,
  studyPlanCycleId: null,
  studyPlanCycleStudentId: null,
  availableStudyYears: null,
  availableStatuses: null,
  availableDepartments: null,
  availableDepartmentsLoading: false,
  validationResult: null,
  studyPlanCycleStudentFormCancelToken: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case StudyPlanCycleStudentFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_STUDENT_FORM_PAGE_START:
      return {
        ...state,
        loading: true,
        validationResult: null
      };
    case StudyPlanCycleStudentFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_STUDENT_FORM_PAGE_END:
      return {
        ...state,
        loading: false,
        studyPlanId: action.payload?.studyPlanId,
        studyPlanCycleId: action.payload?.studyPlanCycleId,
        studyPlanCycleStudentId: action.payload?.studyPlanCycleStudentId,
        studyPlanCycleStudentDetails: action.payload?.studyPlanCycleStudentDetails,
        studyPlanCycleStudentIds: action.payload?.studyPlanCycleStudentIds,
        studyPlanCycleStudentDepartments: action.payload?.studyPlanCycleStudentDepartments,
        validationResult: action.payload?.validationResult
      };
    case StudyPlanCycleStudentFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_STUDENT_FORM_PAGE_AVAILABLE_STUDY_YEARS:
      return {
        ...state,
        availableStudyYears: action.payload?.availableStudyYears
      };
    case StudyPlanCycleStudentFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_STUDENT_FORM_PAGE_AVAILABLE_STATUSES:
      return {
        ...state,
        availableStatuses: action.payload?.availableStatuses
      };
    case StudyPlanCycleStudentFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_STUDENT_FORM_PAGE_AVAILABLE_DEPARTMENTS_START:
      return {
        ...state,
        availableDepartmentsLoading: true
      };
    case StudyPlanCycleStudentFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_STUDENT_FORM_PAGE_AVAILABLE_DEPARTMENTS_END:
      return {
        ...state,
        availableDepartmentsLoading: false,
        availableDepartments: action.payload?.availableDepartments
      };
    case StudyPlanCycleStudentFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_STUDENT_FORM_PAGE_DEPARTMENT_STUDENTS:
      return {
        ...state,
        departmentStudents: action.payload?.departmentStudents
      };
    case StudyPlanCycleStudentFormPageActionTypes.SAVE_STUDY_PLAN_CYCLE_STUDENT_FORM_PAGE_START:
      return {
        ...state,
        loading: true,
        validationResult: null
      };
    case StudyPlanCycleStudentFormPageActionTypes.SAVE_STUDY_PLAN_CYCLE_STUDENT_FORM_PAGE_END:
      return {
        ...state,
        loading: false,
        validationResult: action.payload?.validationResult,
        studyPlanCycleStudentDetails: action.payload?.studyPlanCycleStudentDetails
      };
    default:
      return state;
  }
};