import { CycleTheoreticalPartFormPageActiontypes } from '.';
import { THEORETICAL_PARTS } from '../../../resources/theoreticalPartType';
import axios from 'axios';
import ValidationError from '../../../utils/validation-error';

export const loadCycleTheoreticalPartFormPage = ({ cycleId, cycleTheoreticalPartId, localizationService }) => async(dispatch, getState, { api }) => {
  dispatch({ type: CycleTheoreticalPartFormPageActiontypes.LOAD_CYCLE_THEORETICAL_PART_FORM_PAGE_START });
  try {
    const cycle = await api.get(`api/cycles/${cycleId}`);
    if (cycleTheoreticalPartId > 0) {
      const cycleTheoreticalPart = await api.get(`api/cycles/${cycleId}/theoretical_parts/${cycleTheoreticalPartId}`);
      dispatch({
        type: CycleTheoreticalPartFormPageActiontypes.LOAD_CYCLE_THEORETICAL_PART_FORM_PAGE_END,
        payload: {
          studyProgramId: cycle.studyProgramId,
          cycleTheoreticalPart: {
            ...cycleTheoreticalPart,
            studyProgramTheoreticalPart: cycleTheoreticalPart?.studyProgramTheoreticalPartId ? { id: cycleTheoreticalPart.studyProgramTheoreticalPartId, name: cycleTheoreticalPart.studyProgramTheoreticalPartName } : null,
            theoreticalPartType: cycleTheoreticalPart?.type ? { id: cycleTheoreticalPart.type, name: localizationService.toLanguageString(`cycleTheoreticalPartType.${cycleTheoreticalPart.type}`) } : null,
            teachers: cycleTheoreticalPart?.teachers?.map(item => ({
              id: item.userId,
              text: item.name
            }))
          }
        }
      });
    } else if (cycleId) {
      dispatch({
        type: CycleTheoreticalPartFormPageActiontypes.LOAD_CYCLE_THEORETICAL_PART_FORM_PAGE_END,
        payload: {
          studyProgramId: cycle.studyProgramId,
          cycleTheoreticalPart: {
            cycleId: cycleId
          }
        }
      });
    }
    dispatch(loadAvailableTheoreticalParts());
  } catch (error) {
    dispatch({ type: CycleTheoreticalPartFormPageActiontypes.LOAD_CYCLE_THEORETICAL_PART_FORM_PAGE_END });
    throw error;
  }
};

export const saveCycleTheoreticalPart = (payload) => async(dispatch, getState, { api, history }) => {
  dispatch({ type: CycleTheoreticalPartFormPageActiontypes.LOAD_CYCLE_THEORETICAL_PART_FORM_PAGE_START });
  const state = getState().cycleTheoreticalPartFormPage;
  const { studyProgramTheoreticalPart, theoreticalPartType, ...rest } = payload;
  const apiModel = {
    ...rest,
    studyProgramTheoreticalPartId: studyProgramTheoreticalPart?.id,
    studyProgramTheoreticalPartName: studyProgramTheoreticalPart?.name,
    type: theoreticalPartType?.id,
    teachers: payload.teachers?.map(element => ({ userId: element.id }))
  };
  let id = payload?.id;
  try {
    if (id > 0) {
      await api.put(`api/cycles/${payload.cycleId}/theoretical_parts/${id}`, apiModel);
    } else {
      id = await api.post(`api/cycles/${payload.cycleId}/theoretical_parts`, apiModel);
    }
    dispatch({
      type: CycleTheoreticalPartFormPageActiontypes.LOAD_CYCLE_THEORETICAL_PART_FORM_PAGE_END,
      payload: {
        cycleTheoreticalPart: {
          ...state.cycleTheoreticalPart,
          id: id
        }
      }
    });
    history.back();
  } catch (error) {
    let validationResult = null;
    if (error instanceof ValidationError) {
      validationResult = {
        errorMessage: error.message,
        errors: error.errors
      };
    }
    dispatch({
      type: CycleTheoreticalPartFormPageActiontypes.LOAD_CYCLE_THEORETICAL_PART_FORM_PAGE_END,
      payload: {
        validationResult,
        cycleTheoreticalPart: state.cycleTheoreticalPart
      }
    });
  }
};

export const loadAvailableTheoreticalParts = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: CycleTheoreticalPartFormPageActiontypes.LOAD_CYCLE_THEORETICAL_PART_AVAILABLE_THEORETICAL_PARTS_START });
  const state = getState().cycleTheoreticalPartFormPage;
  try {
    state.availableTheoreticalPartsCancelToken?.cancel();
    state.availableTheoreticalPartsCancelToken = axios.CancelToken.source();
    const query = { keyword: payload?.keyword };
    const theoreticalParts = await api.get(`api/study_programs/${state.studyProgramId}/theoretical_parts`, query, state.availableTheoreticalPartsCancelToken.token);
    dispatch({
      type: CycleTheoreticalPartFormPageActiontypes.LOAD_CYCLE_THEORETICAL_PART_AVAILABLE_THEORETICAL_PARTS_END,
      payload: {
        availableTheoreticalParts: theoreticalParts?.data
      }
    });
  } catch (error) {
    dispatch({ type: CycleTheoreticalPartFormPageActiontypes.LOAD_CYCLE_THEORETICAL_PART_AVAILABLE_THEORETICAL_PARTS_END });
  }
};

export const loadAvailableTeachers = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().cycleTheoreticalPartFormPage;
  let teachers = [...state.teachers];
  teachers[payload.index] = {
    loading: true,
    availableTeachers: teachers[payload.index]?.availableTeachers ? [...teachers[payload.index].availableTeachers] : []
  };
  dispatch({
    type: CycleTheoreticalPartFormPageActiontypes.LOAD_CYCLE_THEORETICAL_PART_AVAILABLE_TEACHERS_START,
    payload: {
      teachers: teachers
    }
  });
  try {
    const cancellationTokenKey = `teachers[${payload.index}]`;
    state.cancellationTokenSources.get(cancellationTokenKey)?.cancel();
    state.cancellationTokenSources.set(cancellationTokenKey, axios.CancelToken.source());
    const users = await api.get(`api/users_search`, { keyword: payload.keyword, skip: 0, limit: 50 }, state.cancellationTokenSources.get(cancellationTokenKey).token);
    teachers = [...state.teachers];
    teachers[payload.index] = {
      loading: false,
      availableTeachers: users ? users.data?.map(user => ({ id: user.id, text: user.name + ' ' + user.surname })) : []
    };
    dispatch({
      type: CycleTheoreticalPartFormPageActiontypes.LOAD_CYCLE_THEORETICAL_PART_AVAILABLE_TEACHERS_END,
      payload: {
        teachers: teachers
      }
    });
  } catch (error) {
    dispatch({ type: CycleTheoreticalPartFormPageActiontypes.LOAD_CYCLE_THEORETICAL_PART_AVAILABLE_TEACHERS_END });
  }
};

export const loadTheoreticalPartTypes = (localizationService) => async(dispatch, getState) => {
  const types = Object.keys(THEORETICAL_PARTS)
    .map(key => ({
      id: THEORETICAL_PARTS[key],
      name: localizationService.toLanguageString('cycleTheoreticalPartType.' + THEORETICAL_PARTS[key])
    }));

  dispatch({
    type: CycleTheoreticalPartFormPageActiontypes.LOAD_CYCLE_THEORETICAL_PART_TYPES,
    payload: {
      theoreticalPartTypes: types
    }
  });
};