import StudentCompetencySurveyOverviewPageActionTypes from './student-competency-survey-overview-page-action-types';
import axios from 'axios';

export const loadStudentCompetencySurveyOverviewPage = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudentCompetencySurveyOverviewPageActionTypes.LOAD_STUDENT_COMPETENCY_SURVEY_OVERVIEW_PAGE_START });
  const states = getState();
  const state = states.studentCompetencySurveyOverview;
  try {

    state.studentCompetencySurveyCancelToken?.cancel();
    state.studentCompetencySurveyCancelToken = axios.CancelToken.source();
    const studentCompetencySurvey = await api.get(`api/competencies/${payload.competencyId}/surveys/${payload.competencySurveyId}`, state.studentCompetencySurveyCancelToken.token);

    const [competency, studentCompetency] = await Promise.all([
      api.get(`api/competencies/${payload.competencyId}`, state.studentCompetencySurveyCancelToken.token),
      api.get(`api/my_competencies/${payload.competencyId}`, { studentId: payload.studentId }, state.studentCompetencySurveyCancelToken.token)
    ]);

    dispatch({
      type: StudentCompetencySurveyOverviewPageActionTypes.LOAD_STUDENT_COMPETENCY_SURVEY_OVERVIEW_PAGE_END,
      payload: { studentCompetencySurvey: { ...studentCompetencySurvey, studentId: payload.studentId, studyProgramId: competency.studyProgramId, studentCompetencyStatus: studentCompetency.status } }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: StudentCompetencySurveyOverviewPageActionTypes.LOAD_STUDENT_COMPETENCY_SURVEY_OVERVIEW_PAGE_END });
      throw error;
    }
  }
};

export const selectStudentCompetencySurveyOverviewPageTab = (payload) => async(dispatch) => {
  dispatch({
    type: StudentCompetencySurveyOverviewPageActionTypes.SELECT_STUDENT_COMPETENCY_SURVEY_OVERVIEW_PAGE_TAB,
    payload: {
      selected: payload.selected
    }
  });
};

export const openRequestEvaluationDialog = (payload) => async(dispatch) => {
  dispatch({
    type: StudentCompetencySurveyOverviewPageActionTypes.TOGGLE_STUDENT_COMPETENCY_REQUEST_EVALUATION_DIALOG,
    payload: payload
  });
};

export const closeRequestEvaluationDialog = () => async(dispatch) => {
  dispatch({ type: StudentCompetencySurveyOverviewPageActionTypes.TOGGLE_STUDENT_COMPETENCY_REQUEST_EVALUATION_DIALOG });
};

export const clearStudentCompetencySurveyOverviewPage = () => async(dispatch) => {
  dispatch({ type: StudentCompetencySurveyOverviewPageActionTypes.CLEAR_STUDENT_COMPETENCY_SURVEY_OVERVIEW_PAGE });
};

export const openQrCodeDialog = () => async(dispatch) => {
  dispatch({
    type: StudentCompetencySurveyOverviewPageActionTypes.TOGGLE_STUDENT_COMPETENCY_SURVEY_QR_CODE_DIALOG,
    payload: {
      isQrCodeDialogVisible: true
    }
  });
};

export const closeQrCodeDialog = () => async(dispatch) => {
  dispatch({
    type: StudentCompetencySurveyOverviewPageActionTypes.TOGGLE_STUDENT_COMPETENCY_SURVEY_QR_CODE_DIALOG,
    payload: {
      isQrCodeDialogVisible: false
    }
  });
};

export const setShowQrCodeSetting = (shouldShowQrCode) => async(dispatch) => {
  dispatch({
    type: StudentCompetencySurveyOverviewPageActionTypes.SET_STUDENT_COMPETENCY_SURVEY_SHOW_QR_CODE_SETTING,
    payload: {
      shouldShowQrCode
    }
  });
};