import * as React from 'react';
import { connect } from 'react-redux';
import { provideLocalizationService, registerForLocalization, registerForIntl } from '@progress/kendo-react-intl';
import { Button } from '@progress/kendo-react-buttons';
import { Skeleton } from '@progress/kendo-react-indicators';
import { OverviewPage, StackLayout, Text } from '../../../ui';
import { EmptyFormatter, ValueItem } from '../../shared/components';
import { loadCompetencyOverviewPage, selectCompetencyOverviewPageTab } from '../actions/competency-overview-page-actions';
import { SubcompetencyList, CompetencySurveyList, CompetencyCycleList } from './';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { withRouter } from '../../../components/withRouter';

class CompetencyOverviewPage extends React.PureComponent {

  constructor() {
    super();
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleBreadcrumbItemClick = this.handleBreadcrumbItemClick.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleTabSelect = this.handleTabSelect.bind(this);
  }

  async componentDidMount() {
    if (this.props.params?.competencyId) {
      this.props.loadCompetencyOverviewPage({
        competencyId: this.props.params.competencyId
      });
    }
  }

  render() {
    const { competency, loading, canWrite } = this.props;
    const localizationService = provideLocalizationService(this);
    return (
      <OverviewPage
        title={loading
          ? <Skeleton shape='text' style={{ width: 300, height: 28 }}/>
          : competency?.abbreviation && competency?.name
            && `${competency.abbreviation} ${competency.name}`
            || EmptyFormatter(competency?.name)
        }
        headerActions={
          <>
            {canWrite &&
              <Button
                id='edit-button'
                type='button'
                themeColor='primary'
                onClick={this.handleEditClick}
              >
                {localizationService.toLanguageString('custom.edit')}
              </Button>
            }
          </>
        }
        breadcrumbItems={[
          {
            id: 'competencies',
            text: localizationService.toLanguageString('competency.tieredCompetencies')
          },
          {
            id: 'competency',
            text: localizationService.toLanguageString('competency.tieredCompetency'),
            disabled: true
          }
        ]}
        onBreadcrumbItemClick={this.handleBreadcrumbItemClick}
        onBackClick={this.handleBackClick}
        summary={
          <StackLayout rowGap='8px'>
            <ValueItem
              label={localizationService.toLanguageString('competency.description')}
              labelWidth='170px'
              loading={loading}
            >
              <Text variant={'body2'} overflowWrap={true}>
                {EmptyFormatter(competency?.description)}
              </Text>
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('competency.studyProgram')}
              labelWidth='170px'
              loading={loading}
            >
              {competency?.studyProgramName}
            </ValueItem>
          </StackLayout>
        }
        tabs={
          <TabStrip selected={this.props.selectedTab} onSelect={this.handleTabSelect}>
            <TabStripTab title={localizationService.toLanguageString('competency.subcompetencies')}/>
            <TabStripTab title={localizationService.toLanguageString('competency.surveys')}/>
            <TabStripTab title={localizationService.toLanguageString('competency.cycles')}/>
          </TabStrip>
        }
      >
        {this.props.selectedTab === 0 && <SubcompetencyList competencyId={this.props.params.competencyId} canWrite={canWrite}/>}
        {this.props.selectedTab === 1 && <CompetencySurveyList competencyId={this.props.params.competencyId} studyProgramId={competency?.studyProgramId} canWrite={canWrite}/>}
        {this.props.selectedTab === 2 && <CompetencyCycleList competencyId={this.props.params.competencyId} studyProgramId={this.props.competency?.studyProgramId} canWrite={canWrite}/>}
      </OverviewPage>
    );
  }

  handleEditClick() {
    this.props.navigate(`/competencies/${this.props.params.competencyId}`);
  }

  handleBreadcrumbItemClick(e) {
    if (e.id === 'competencies') {
      this.props.navigate('/competencies');
    }
  }

  handleBackClick() {
    this.props.navigate(-1);
  }

  handleTabSelect(e) {
    this.props.selectCompetencyOverviewPageTab({
      selected: e.selected
    });
  }
}

registerForLocalization(CompetencyOverviewPage);
registerForIntl(CompetencyOverviewPage);

const mapStateToProps = state => ({
  competency: state.competencyOverviewPage.competency,
  loading: state.competencyOverviewPage.loading,
  selectedTab: state.competencyOverviewPage.selectedTab,
  canWrite: state.competencyOverviewPage.canWrite
});

const mapDispatchToProps = dispatch => ({
  loadCompetencyOverviewPage: (payload) => dispatch(loadCompetencyOverviewPage(payload)),
  selectCompetencyOverviewPageTab: (payload) => dispatch(selectCompetencyOverviewPageTab(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CompetencyOverviewPage));