import axios from 'axios';
import { CompetencyRequestSummaryPageActionTypes } from '.';
import { COMPETENCY_SURVEY_STUDENT_STATUS } from '../../../resources/competencySurveyStudentStatus';

export const loadCompetencyRequestSummaryPage = ({ studentCompetencyId }) => async(dispatch, getState, { api }) => {
  dispatch({ type: CompetencyRequestSummaryPageActionTypes.LOAD_COMPETENCY_REQUEST_SUMMARY_START });
  const state = getState().competencyRequestSummaryPage;
  try {
    state.competencyRequestCancelToken?.cancel();
    state.competencyRequestCancelToken = axios.CancelToken.source();

    const competencyRequest = await api.get(`api/student_competencies/${studentCompetencyId}`, state.competencyRequestCancelToken.token);
    const [ studentCycles, competencyCycles, competencySurveys ] = await Promise.all([
      api.get(`api/students/${competencyRequest.studentId}/cycles`, state.competencyRequestCancelToken.token),
      api.get(`api/competencies/${competencyRequest.id}/cycles`, state.competencyRequestCancelToken.token),
      api.get(`api/competencies/${competencyRequest.id}/surveys`, state.competencyRequestCancelToken.token)
    ]);

    const studentCompetencyCycles = studentCycles?.filter(studentCycle => competencyCycles?.data?.some(competencyCycle => competencyCycle.cycleId === studentCycle.cycleId));

    const surveyPromises = await competencySurveys?.data?.map(async(competencySurvey) => {
      const [ studentCompetencySurveys, surveyQuestions ] = await Promise.all([
        api.get(`api/competencies/${competencyRequest.id}/surveys/${competencySurvey.surveyId}/students/${competencyRequest.studentId}`, state.competencyRequestCancelToken.token),
        api.get(`api/surveys/${competencySurvey.surveyId}/questions`, state.competencyRequestCancelToken.token)
      ]);

      let pendingSurveyEvaluationCount = 0;
      let completedSurveyEvaluationCount = 0;

      const surveyAnswerStudentPromises = studentCompetencySurveys?.data?.map((studentCompetencySurvey) => {
        switch (studentCompetencySurvey.status) {
          case COMPETENCY_SURVEY_STUDENT_STATUS.SUBMITTED:
            pendingSurveyEvaluationCount++;
            break;
          case COMPETENCY_SURVEY_STUDENT_STATUS.EVALUATED:
            completedSurveyEvaluationCount++;
            break;
        }

        return studentCompetencySurvey?.surveyAnswerStudentId && api.get(`api/survey_answer_students/${studentCompetencySurvey.surveyAnswerStudentId}`, state.competencyRequestCancelToken.token);
      });

      const filteredSurveyAnswerStudentPromises = surveyAnswerStudentPromises?.filter(element => element);
      const surveyAnswerStudentsData = filteredSurveyAnswerStudentPromises && await Promise.all(filteredSurveyAnswerStudentPromises);
      const surveyAnswers = surveyAnswerStudentsData?.flatMap(outterElement =>
        outterElement.surveyAnswers?.map(innerElement => ({
          ...innerElement,
          name: outterElement.participatingUserName,
          date: outterElement.evaluationDate
        }))
      );

      return {
        ...competencySurvey,
        name: competencySurvey.surveyName,
        type: competencySurvey.surveyType,
        minimumSurveyEvaluationCount: competencySurvey.minCount,
        completedSurveyEvaluationCount,
        pendingSurveyEvaluationCount,
        questions: surveyQuestions?.map(surveyQuestion => ({
          ...surveyQuestion,
          answers: surveyAnswers?.filter(surveyAnswer => surveyAnswer.surveyQuestionId === surveyQuestion.id) ?? []
        })) ?? []
      };
    }) ?? [];

    dispatch({
      type: CompetencyRequestSummaryPageActionTypes.LOAD_COMPETENCY_REQUEST_SUMMARY_END,
      payload: {
        competencyRequest: {
          ...competencyRequest,
          cycles: studentCompetencyCycles,
          surveys: await Promise.all(surveyPromises)
        }
      }
    });

  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: CompetencyRequestSummaryPageActionTypes.LOAD_COMPETENCY_REQUEST_SUMMARY_END });
      throw error;
    }
  }
};

export const expandSurveyQuestion = ({ surveyQuestionId, surveyId }) => async(dispatch, getState) => {
  const state = getState().competencyRequestSummaryPage;
  dispatch({
    type: CompetencyRequestSummaryPageActionTypes.EXPAND_COMPETENCY_REQUEST_SUMMARY_SURVEY_QUESTION,
    payload: {
      competencyRequest: {
        ...state.competencyRequest,
        surveys: state.competencyRequest.surveys.map(element => {
          if (element.id === surveyId) {
            return {
              ...element,
              questions: element.questions.map((questionElement) => {
                if (questionElement.id === surveyQuestionId) {
                  return { ...questionElement, expanded: !questionElement.expanded };
                }
                return questionElement;
              })
            };
          }
          return element;
        })
      }
    }
  });
};

export const clearCompetencyRequestSummaryPage = () => async(dispatch) => {
  dispatch({ type: CompetencyRequestSummaryPageActionTypes.CLEAR_COMPETENCY_REQUEST_SUMMARY_PAGE });
};

export const changeCompetencyStatus = (payload) => async(dispatch, getState, { api }) => {
  const { studentId, competencyId, status, studentCompetencyId, comment } = payload;
  dispatch({ type: CompetencyRequestSummaryPageActionTypes.SUBMIT_COMPETENCY_REQUEST_START });
  const state = getState().competencyRequestSummaryPage;
  try {
    state.competencyRequestCancelToken?.cancel();
    state.competencyRequestCancelToken = axios.CancelToken.source();
    await api.post(`api/students/${studentId}/competencies/${competencyId}/status/${status}`, { comment }, state.competencyRequestCancelToken.token);

    dispatch({ type: CompetencyRequestSummaryPageActionTypes.SUBMIT_COMPETENCY_REQUEST_END });
    dispatch(loadCompetencyRequestSummaryPage({ studentCompetencyId }));
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: CompetencyRequestSummaryPageActionTypes.SUBMIT_COMPETENCY_REQUEST_END });
      throw error;
    }
  }
};

export const openReturnCompetencyDialog = (payload) => async(dispatch) => {
  dispatch({
    type: CompetencyRequestSummaryPageActionTypes.TOGGLE_COMPETENCY_REQUEST_RETURN_DIALOG,
    payload: payload
  });
};

export const closeReturnCompetencyDialog = () => async(dispatch) => {
  dispatch({ type: CompetencyRequestSummaryPageActionTypes.TOGGLE_COMPETENCY_REQUEST_RETURN_DIALOG });
};

export const getCompetencyReport = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: CompetencyRequestSummaryPageActionTypes.GET_COMPETENCY_REPORT_START });
  const state = getState().competencyRequestSummaryPage;
  const formatDigit = digit => `${digit < 10 ? '0' : ''}${digit}`;
  try {
    state.competencyRequestCancelToken?.cancel();
    state.competencyRequestCancelToken = axios.CancelToken.source();
    const pdfResponse = await api.get(`api/student_competencies/${payload.studentCompetencyId}/reports`, {}, state.competencyRequestCancelToken.token, 'blob');
    const pdfBlob = new Blob([pdfResponse], { type: 'application/pdf' });
    const pdfUrl = URL.createObjectURL(pdfBlob);
    const downloadLink = document.createElement('a');
    downloadLink.href = pdfUrl;
    const date = new Date();
    const fileName = `${state.competencyRequest.student.replace(' ', '_')}_PK_${date.getFullYear()}-${formatDigit(date.getMonth() + 1)}-${formatDigit(date.getDate())}_${formatDigit(date.getHours())}-${formatDigit(date.getMinutes())}-${formatDigit(date.getSeconds())}.pdf`;
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    URL.revokeObjectURL(pdfUrl);
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: CompetencyRequestSummaryPageActionTypes.GET_COMPETENCY_REPORT_END });
      throw error;
    }
  }
};

export const closeGrantConfirmDialog = () => async(dispatch) => {
  dispatch({ type: CompetencyRequestSummaryPageActionTypes.TOGGLE_COMPETENCY_GRANT_CONFIRM_DIALOG });
};

export const openGrantConfirmDialog = () => async(dispatch) => {
  dispatch({ type: CompetencyRequestSummaryPageActionTypes.TOGGLE_COMPETENCY_GRANT_CONFIRM_DIALOG });
};

export const initSigning = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: CompetencyRequestSummaryPageActionTypes.INIT_SIGNING_START });
  const { studentId, competencyId } = payload;
  const state = getState().competencyRequestSummaryPage;
  try {
    state.competencyRequestCancelToken?.cancel();
    state.competencyRequestCancelToken = axios.CancelToken.source();
    const signingRedirectUrl = await api.post(`api/students/${studentId}/competencies/${competencyId}/init_sign`, state.competencyRequestCancelToken.token);
    dispatch({
      type: CompetencyRequestSummaryPageActionTypes.INIT_SIGNING_END,
      payload: { signingRedirectUrl }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: CompetencyRequestSummaryPageActionTypes.INIT_SIGNING_END });
      throw error;
    }
  }
};

export const downloadCertificate = (payload) => (dispatch, getState) => {
  const { key } = payload;
  window.open(`/api/student_competency_certificate/${key}/download`, '_blank');
};