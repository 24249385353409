import CompetencySurveyOverviewPageActionTypes from './competency-survey-overview-page-action-types';
import axios from 'axios';

export const loadCompetencySurveyOverviewPage = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: CompetencySurveyOverviewPageActionTypes.LOAD_COMPETENCY_SURVEY_OVERVIEW_PAGE_START });
  const state = getState().competencySurveyOverviewPage;
  try {
    state.competencySurveyCancelToken?.cancel();
    state.competencySurveyCancelToken = axios.CancelToken.source();
    let survey;
    if (payload.surveyId > 0) {
      survey = await api.get(`api/surveys/${payload.surveyId}`, null, state.competencySurveyCancelToken.token);
      survey = { ...survey, surveyType: survey.type, surveyName: survey.name };
    } else {
      survey = await api.get(`api/competencies/${payload.competencyId}/surveys/${payload.competencySurveyId}`, null, state.competencySurveyCancelToken.token);
    }
    dispatch({
      type: CompetencySurveyOverviewPageActionTypes.LOAD_COMPETENCY_SURVEY_OVERVIEW_PAGE_END,
      payload: {
        survey: survey
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: CompetencySurveyOverviewPageActionTypes.LOAD_COMPETENCY_SURVEY_OVERVIEW_PAGE_END });
      throw error;
    }
  }
};

export const selectCompetencySurveyOverviewPageTab = (payload) => async(dispatch, getState, { api }) => {
  dispatch({
    type: CompetencySurveyOverviewPageActionTypes.SELECT_COMPETENCY_SURVEY_OVERVIEW_PAGE_TAB,
    payload: {
      selected: payload.selected
    }
  });
};

export const clearCompetencySurveyOverviewPage = () => async(dispatch) => {
  dispatch({
    type: CompetencySurveyOverviewPageActionTypes.CLEAR_COMPETENCY_SURVEY_OVERVIEW_PAGE
  });
};