import * as React from 'react';
import { connect } from 'react-redux';
import { Field } from '@progress/kendo-react-form';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { FilterForm, TextBox, WrapLayout, StackLayout, DropDown, MultiSelect } from '../../../ui';
import { filterUsers, clearUsersFilter } from '../actions/user-list-page-actions';
import { Badge, BadgeContainer } from '@progress/kendo-react-indicators';
import styled from 'styled-components';
import { getUserStatusColor } from '../../../utils/userStatusColors';

const DropdownItemContainer = styled.div`
  height: 24px;
  padding-top: 8px;
`;

const StyledBadge = styled(Badge)`
  opacity: 75%;
  font-size: small;
`;

class UserListFilter extends React.PureComponent {

  constructor(props) {
    super(props);
    this.statusDropdownItemRender = this.statusDropdownItemRender.bind(this);
  }

  render() {
    const {
      filter,
      clearUsersFilter,
      filterUsers,
      userStatuses,
      userRoles
    } = this.props;
    const localizationService = provideLocalizationService(this);

    return (
      <FilterForm
        initialValues={filter}
        onSubmit={filterUsers}
        onClear={clearUsersFilter}
        localizationService={localizationService}
        title={localizationService.toLanguageString('grid.filterTitle')}
        uniqueKey={JSON.stringify(filter)}
        render={() => (
          <WrapLayout>
            <StackLayout width='340px'>
              <Field
                name='keyword'
                width='340px'
                component={TextBox}
                label={localizationService.toLanguageString('custom.search')}
              />
              <Field
                name='type'
                width='340px'
                component={MultiSelect}
                data={userRoles}
                label={localizationService.toLanguageString('User.role')}
                textField='text'
                dataItemKey='id'
              />
            </StackLayout>
            <StackLayout width='340px'>
              <Field
                name='status'
                width='340px'
                component={DropDown}
                data={userStatuses}
                textField='text'
                dataItemKey='id'
                label={localizationService.toLanguageString('User.status')}
                itemRender={this.statusDropdownItemRender}
              />
            </StackLayout>
          </WrapLayout>
        )}
      />
    );
  }

  statusDropdownItemRender(li, itemProps) {
    let color = getUserStatusColor(itemProps.dataItem.id);
    const itemChildren = (
      <DropdownItemContainer>
        <BadgeContainer>
          <StyledBadge rounded={'medium'} size={'large'} themeColor={color} position={'outside'} align={{ vertical: 'top', horizontal: 'end' }}>
            {li.props.children}
          </StyledBadge>
        </BadgeContainer>
      </DropdownItemContainer>
    );
    return React.cloneElement(li, li.props, itemChildren);
  }
}

const mapStateToProps = state => ({
  filter: state.userListPage.filter,
  userStatuses: state.userListPage.userStatuses,
  userRoles: state.userListPage.userRoles
});

const mapDispatchToProps = dispatch => ({
  filterUsers: (payload) => dispatch(filterUsers(payload)),
  clearUsersFilter: () => dispatch(clearUsersFilter())
});

registerForLocalization(UserListFilter);

export default connect(mapStateToProps, mapDispatchToProps)(UserListFilter);