import * as React from 'react';
import { ListPage } from '../../../ui';
import { StudyProgramListFilter, StudyProgramList } from './index';

class StudyProgramListPage extends React.PureComponent {
  render() {
    return (
      <ListPage filter={<StudyProgramListFilter/>}>
        <StudyProgramList/>
      </ListPage>
    );
  }
}

export default StudyProgramListPage;