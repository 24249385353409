import StudentCompetencySurveySubmitFormActionTypes from './student-competency-survey-submit-form-action-types';
import axios from 'axios';
import { COMPETENCY_SURVEY_STUDENT_STATUS } from '../../../../resources/competencySurveyStudentStatus';
import { USER_TYPES } from '../../../../resources/userTypes';
import ValidationError from '../../../../utils/validation-error';
import { EVALUATOR_ROLES } from '../../../../resources/EvaluatorRoles';
import { SURVEY_TYPES } from '../../../../resources/surveyType';

export const loadStudentCompetencySurveySubmitForm = (payload) => async(dispatch) => {
  dispatch({
    type: StudentCompetencySurveySubmitFormActionTypes.LOAD_STUDENT_COMPETENCY_SURVERY_SUBMIT_FORM_SURVEY_TYPE,
    payload: {
      surveyType: payload.surveyType
    }
  });
  dispatch(loadAvailableManagers());
  dispatch(loadAvailableDepartments());
  dispatch(loadAvailableExternalUsers());
  dispatch(loadEvaluatorRoles({ localizationService: payload.localizationService }));
};

export const loadAvailableManagers = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudentCompetencySurveySubmitFormActionTypes.LOAD_STUDENT_COMPETENCY_SURVEY_SUBMIT_FORM_AVAILABLE_MANAGERS_START });
  try {
    const state = getState().studentCompetencySurveySubmitForm;
    state.availableManagersCancelToken?.cancel();
    state.availableManagersCancelToken = axios.CancelToken.source();
    const userTypes = [ USER_TYPES.MENTOR, USER_TYPES.MANAGER ];
    if (state.surveyType === SURVEY_TYPES.SURVEY_360) {
      userTypes.push(USER_TYPES.STUDENT);
    }
    const users = await api.get(`api/users_search`, { keyword: payload?.keyword, type: userTypes }, state.availableManagersCancelToken.token);
    dispatch({
      type: StudentCompetencySurveySubmitFormActionTypes.LOAD_STUDENT_COMPETENCY_SURVEY_SUBMIT_FORM_AVAILABLE_MANAGERS_END,
      payload: {
        users: users?.data?.map(user => ({ id: user.id, text: `${user.stampNumber ? `${user.stampNumber} ` : ''}${user.name} ${user.surname}` })) ?? []
      }
    });
  } catch (error) {
    dispatch({
      type: StudentCompetencySurveySubmitFormActionTypes.LOAD_STUDENT_COMPETENCY_SURVEY_SUBMIT_FORM_AVAILABLE_MANAGERS_END
    });
    throw error;
  }
};

export const submitRequestEvaluationDialog = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().studentCompetencySurveySubmitForm;
  let apiModel = {
    studentId: payload.studentId,
    competencySurveyId: payload.competencySurveyId,
    comment: payload.comment,
    role: payload.role?.id,
    departmentId: payload.department?.id,
    status: COMPETENCY_SURVEY_STUDENT_STATUS.SUBMITTED
  };
  try {
    if (state.isExternalUserForm) {
      if (payload.externalUser?.id > 0) {
        apiModel = { ...apiModel, managerId: payload.externalUser.id };
      } else {
        const externalUserApiModel = {
          name: payload.name,
          surname: payload.surname,
          email: payload.email,
          stampNumber: payload.stampNumber,
          isExternal: true
        };
        const externalUserId = await api.post(`api/users`, externalUserApiModel);
        apiModel = { ...apiModel, managerId: externalUserId };
      }
    } else {
      apiModel = { ...apiModel, managerId: payload.manager?.id };
    }
    const surveyLocationAddress = await api.post(`api/competency_survey_student`, apiModel);
    payload.closeDialog();
    payload.afterSubmit({ surveyLocationAddress });
  } catch (error) {
    if (error instanceof ValidationError) {
      dispatch({
        type: StudentCompetencySurveySubmitFormActionTypes.SAVE_STUDENT_COMPETENCY_SURVEY_SUBMIT_FORM_REQUEST_EVALUATION,
        payload: {
          requestEvaluationDialogValidationResult: {
            errorMessage: error.message,
            errors: error.errors
          }
        }
      });
    } else {
      throw error;
    }
  }
};

export const clearStudentCompetencySurveySubmitForm = () => async(dispatch) => {
  dispatch({ type: StudentCompetencySurveySubmitFormActionTypes.CLEAR_STUDENT_COMPETENCY_SURVEY_SUBMIT_FORM });
};

export const toggleStudentCompetencySurveySubmitFormType = () => async(dispatch) => {
  dispatch({ type: StudentCompetencySurveySubmitFormActionTypes.TOGGLE_STUDENT_COMPETENCY_SURVEY_SUBMIT_FORM_TYPE });
};

export const loadEvaluatorRoles = (payload) => async(dispatch, getState) => {
  const state = getState().studentCompetencySurveySubmitForm;
  const evaluatorRoles = Object.keys(EVALUATOR_ROLES)
    .map(key => ({
      id: EVALUATOR_ROLES[key],
      name: payload.localizationService.toLanguageString(`evaluatorRoles.${EVALUATOR_ROLES[key]}`)
    }));
  dispatch({
    type: StudentCompetencySurveySubmitFormActionTypes.LOAD_STUDENT_COMPETENCY_SURVEY_SUBMIT_FORM_EVALUATOR_ROLES,
    payload: {
      evaluatorRoles: evaluatorRoles,
      studentCompetencySurveyForm: {
        ...state.studentCompetencySurveyForm,
        role: { id: EVALUATOR_ROLES.MANAGER, name: payload.localizationService.toLanguageString(`evaluatorRoles.${EVALUATOR_ROLES.MANAGER}`) }
      }
    }
  });
};

export const loadAvailableExternalUsers = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudentCompetencySurveySubmitFormActionTypes.LOAD_STUDENT_COMPETENCY_SURVEY_SUBMIT_FORM_AVAILABLE_EXTERNAL_USERS_START });
  try {
    const state = getState().studentCompetencySurveySubmitForm;
    state.availableExternalUsersCancelToken?.cancel();
    state.availableExternalUsersCancelToken = axios.CancelToken.source();
    const externalUsers = await api.get(`api/users_search`, { keyword: payload?.keyword, skip: 0, limit: 50, showExternal: true }, state.availableExternalUsersCancelToken.token);
    dispatch({
      type: StudentCompetencySurveySubmitFormActionTypes.LOAD_STUDENT_COMPETENCY_SURVEY_SUBMIT_FORM_AVAILABLE_EXTERNAL_USERS_END,
      payload: {
        externalUsers: externalUsers?.data?.map(externalUser => ({
          id: externalUser.id,
          text: `${externalUser.name} ${externalUser.surname}`,
          name: externalUser.name,
          surname: externalUser.surname,
          email: externalUser.email,
          stampNumber: externalUser.stampNumber
        })) ?? []
      }
    });
  } catch (error) {
    dispatch({ type: StudentCompetencySurveySubmitFormActionTypes.LOAD_STUDENT_COMPETENCY_SURVEY_SUBMIT_FORM_AVAILABLE_EXTERNAL_USERS_END });
    throw error;
  }
};

export const loadAvailableDepartments = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudentCompetencySurveySubmitFormActionTypes.LOAD_STUDENT_COMPETENCY_SURVEY_SUBMIT_FORM_AVAILABLE_DEPARTMENTS_START });
  try {
    const state = getState().studentCompetencySurveySubmitForm;
    state.availableDepartmentsCancelToken?.cancel();
    state.availableDepartmentsCancelToken = axios.CancelToken.source();
    const manager = state.studentCompetencySurveyForm.manager;
    const initialValue = {
      id: payload?.value?.id ?? manager?.id,
      text: payload?.value?.text ?? manager?.text
    };
    const userDepartments = payload && payload.userId ? await api.get(`api/users/${payload.userId}/departments`, state.availableDepartmentsCancelToken.token) : [];
    const departments = await api.get(`api/departments`, { keyword: payload?.keyword }, state.availableDepartmentsCancelToken.token);
    dispatch({
      type: StudentCompetencySurveySubmitFormActionTypes.LOAD_STUDENT_COMPETENCY_SURVEY_SUBMIT_FORM_AVAILABLE_DEPARTMENTS_END,
      payload: {
        departments: userDepartments?.length > 0
          ? userDepartments
          : departments?.data ?? [],
        studentCompetencySurveyForm: {
          ...state.studentCompetencySurveyForm,
          manager: initialValue.id != null
            ? { id: initialValue.id, text: initialValue.text }
            : null,
          department: userDepartments?.length === 1
            ? { id: userDepartments[0].id, name: userDepartments[0].name }
            : null
        }
      }
    });
  } catch (error) {
    dispatch({
      type: StudentCompetencySurveySubmitFormActionTypes.LOAD_STUDENT_COMPETENCY_SURVEY_SUBMIT_FORM_AVAILABLE_DEPARTMENTS_END
    });
    throw error;
  }
};

