import axios from 'axios';
import StudentCompetencySubcompetencyListActionTypes from './student-competency-subcompetency-list-action-types';

export const loadStudentCompetencySubcompetencyList = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudentCompetencySubcompetencyListActionTypes.LOAD_STUDENT_COMPETENCY_SUBCOMPETENCY_LIST_START });
  const state = getState().studentCompetencySubcompetencyList;
  try {
    state.studentCompetencySubcompetenciesCancelToken?.cancel();
    state.studentCompetencySubcompetenciesCancelToken = axios.CancelToken.source();
    const competencySubcompetencies = await api.get(`api/competencies/${payload.competencyId}/subcompetencies`, state.studentCompetencySubcompetenciesCancelToken.token);
    dispatch({
      type: StudentCompetencySubcompetencyListActionTypes.LOAD_STUDENT_COMPETENCY_SUBCOMPETENCY_LIST_END,
      payload: competencySubcompetencies ? competencySubcompetencies.data : []
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: StudentCompetencySubcompetencyListActionTypes.LOAD_STUDENT_COMPETENCY_SUBCOMPETENCY_LIST_END });
      throw error;
    }
  }
};