import { pagingSettings } from '../../../utils/GridPagingUtils';
import StudyProgramListPageActionTypes from '../actions/study-program-list-page-action-types';

const initialState = {
  loading: false,
  studyPrograms: null,
  studyProgramsCancelToken: null,
  skip: pagingSettings.paging.skip,
  take: pagingSettings.paging.take,
  total: 0,
  sorting: null,
  filter: null,
  canWrite: false,

  filterStudyProgramsLoadingComboBox: false,
  filterStudyProgramsComboBoxCancelToken: null,
  filterStudyProgramsComboBox: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case StudyProgramListPageActionTypes.LOAD_STUDY_PROGRAM_LIST_START:
      return {
        ...state,
        loading: true
      };
    case StudyProgramListPageActionTypes.LOAD_STUDY_PROGRAM_LIST_END:
      return {
        ...state,
        loading: false,
        studyPrograms: action.payload.studyPrograms,
        total: action.payload.total,
        skip: action.payload.skip,
        take: action.payload.take,
        canWrite: action.payload.canWrite
      };
    case StudyProgramListPageActionTypes.FILTER_STUDY_PROGRAM_LIST:
      return {
        ...state,
        filter: action.payload,
        skip: initialState.skip,
        take: initialState.take
      };
    case StudyProgramListPageActionTypes.SORT_STUDY_PROGRAM_LIST:
      return {
        ...state,
        sorting: action.payload
      };
    case StudyProgramListPageActionTypes.SEARCH_STUDY_PROGRAM_LIST_FILTER_STUDY_PROGRAM_START:
      return {
        ...state,
        filterStudyProgramsLoadingComboBox: true
      };
    case StudyProgramListPageActionTypes.SEARCH_STUDY_PROGRAM_LIST_FILTER_STUDY_PROGRAM_END:
      return {
        ...state,
        filterStudyProgramsLoadingComboBox: false,
        filterStudyProgramsComboBox: action.payload.studyPrograms
      };
    default:
      return state;
  }
};