import CycleCaseDiagnosisTlkDialogActionTypes from '../actions/cycle-case-diagnosis-tlk-dialog-action-types';

const initialState = {
  loading: false,
  availableCycleCaseDiagnosisTlksLoading: false,
  availableCycleCaseDiagnosisTlksCancelToken: null,
  availableCycleCaseDiagnosisTlks: null,
  tlk: null,
  onAdd: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CycleCaseDiagnosisTlkDialogActionTypes.LOAD_CYCLE_CASE_DIAGNOSIS_TLK_DIALOG_START:
      return {
        ...state,
        availableCycleCaseDiagnosisTlksLoading: true
      };
    case CycleCaseDiagnosisTlkDialogActionTypes.LOAD_CYCLE_CASE_DIAGNOSIS_TLK_DIALOG_END:
      return {
        ...state,
        availableCycleCaseDiagnosisTlksLoading: false,
        availableCycleCaseDiagnosisTlks: action.payload ? action.payload.tlks : null
      };
    case CycleCaseDiagnosisTlkDialogActionTypes.ADD_CYCLE_CASE_DIAGNOSIS_TLK_DIALOG_START:
      return {
        ...state,
        loading: true
      };
    case CycleCaseDiagnosisTlkDialogActionTypes.ADD_CYCLE_CASE_DIAGNOSIS_TLK_DIALOG_END:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};