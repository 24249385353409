import axios from 'axios';
import StudentCompetencySubmittedSurveyListActionTypes from './student-competency-submitted-survey-list-action-types';
import { sortingToStringWithSubstitutes } from '../../../../utils/objectUtils';

export const loadStudentCompetencySubmittedSurveyList = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudentCompetencySubmittedSurveyListActionTypes.LOAD_STUDENT_COMPETENCY_SUBMITTED_SURVEY_LIST_START });
  const states = getState();
  const state = states.studentCompetencySubmittedSurveyList;
  const query = {
    sorting: sortingToStringWithSubstitutes(state.sorting, [
      { originalName: 'requestDate', substituteName: 'CreatedOn' },
      { originalName: 'surveyEvaluationDate', substituteName: 'MarkDate' },
      { originalName: 'manager', substituteName: 'ManagerUser' },
      { originalName: 'surveyEvaluation', substituteName: 'GeneralMark' },
      { originalName: 'status', substituteName: 'Status' }
    ])
  };
  try {
    state.studentCompetencySubmittedSurveysCancelToken?.cancel();
    state.studentCompetencySubmittedSurveysCancelToken = axios.CancelToken.source();

    const studentCompetencySubmittedSurveys = await api.get(`api/competencies/${payload.competencyId}/surveys/${payload.surveyId}/students/${payload.studentId}`, query, state.studentCompetencySubmittedSurveysCancelToken.token);

    dispatch({
      type: StudentCompetencySubmittedSurveyListActionTypes.LOAD_STUDENT_COMPETENCY_SUBMITTED_SURVEY_LIST_END,
      payload: studentCompetencySubmittedSurveys ? studentCompetencySubmittedSurveys.data : []
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: StudentCompetencySubmittedSurveyListActionTypes.LOAD_STUDENT_COMPETENCY_SUBMITTED_SURVEY_LIST_END });
      throw error;
    }
  }
};

export const clearStudentCompetencySubmittedSurveyList = () => async(dispatch) => {
  dispatch({ type: StudentCompetencySubmittedSurveyListActionTypes.CLEAR_STUDENT_COMPETENCY_SUBMITTED_SURVEY_LIST });
};

export const sortStudentCompetencySubmittedSurveys = (payload) => (dispatch) => {
  const { sort, ...rest } = payload;
  dispatch({
    type: StudentCompetencySubmittedSurveyListActionTypes.SORT_STUDENT_COMPETENCY_SUBMITTED_SURVEY_LIST,
    payload: sort
  });
  dispatch(loadStudentCompetencySubmittedSurveyList(rest));
};