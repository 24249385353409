import CompetencyCycleSelectPageActionTypes from '../actions/competency-cycle-select-page-action-types';
import { pagingSettings } from '../../../utils/GridPagingUtils';

const initialState = {
  loading: false,
  skip: pagingSettings.paging.skip,
  take: pagingSettings.paging.take,
  total: 0,
  filter: {
    showInvalid: false
  },
  studyProgramId: null,
  assignedCycleIds: null,

  availableCyclesCancelToken: null,
  availableCycles: null,

  selectedIds: [],
  selectedAll: false,
  savingSelectedCycles: false,
  errorMessage: null,
  successMessage: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CompetencyCycleSelectPageActionTypes.LOAD_COMPETENCY_CYCLE_SELECT_PAGE_START:
      return {
        ...state,
        competencyId: action.payload?.competencyId,
        studyProgramId: action.payload?.studyProgramId,
        assignedCycleIds: action.payload?.assignedCycleIds,
        errorMessage: null,
        successMessage: null
      };
    case CompetencyCycleSelectPageActionTypes.FIND_COMPETENCY_CYCLE_SELECT_PAGE_START:
      return {
        ...state,
        loading: true
      };
    case CompetencyCycleSelectPageActionTypes.FIND_COMPETENCY_CYCLE_SELECT_PAGE_END:
      return {
        ...state,
        loading: false,
        availableCycles: action.payload?.cycles,
        total: action.payload?.total,
        skip: action.payload?.skip ?? state.skip,
        take: action.payload?.take ?? state.take
      };
    case CompetencyCycleSelectPageActionTypes.FILTER_COMPETENCY_CYCLE_SELECT_PAGE:
      return {
        ...state,
        filter: {
          ...action.payload,
          showInvalid: false
        }
      };
    case CompetencyCycleSelectPageActionTypes.SELECT_COMPETENCY_CYCLE_SELECT_PAGE:
      return {
        ...state,
        availableCycles: action.payload?.availableCycles,
        selectedIds: action.payload?.selectedIds,
        selectedAll: false
      };
    case CompetencyCycleSelectPageActionTypes.SELECT_ALL_COMPETENCY_CYCLE_SELECT_PAGE:
      return {
        ...state,
        availableCycles: action.payload?.availableCycles,
        selectedIds: action.payload?.selectedIds,
        selectedAll: !state.selectedAll
      };
    case CompetencyCycleSelectPageActionTypes.ADD_SELECTED_CYCLES_COMPETENCY_CYCLE_SELECT_PAGE_START:
      return {
        ...state,
        savingSelectedCycles: true,
        errorMessage: null,
        successMessage: null
      };
    case CompetencyCycleSelectPageActionTypes.ADD_SELECTED_CYCLES_COMPETENCY_CYCLE_SELECT_PAGE_END:
      return {
        ...state,
        savingSelectedCycles: false,
        errorMessage: action.payload?.errorMessage,
        successMessage: action.payload?.successMessage,
        assignedCycleIds: action.payload?.assignedCycleIds
      };
    case CompetencyCycleSelectPageActionTypes.CLEANUP_COMPETENCY_CYCLE_SELECT_PAGE:
      return initialState;
    default:
      return state;
  }
};