import * as React from 'react';
import styled, { css } from 'styled-components';
import { RadioGroup } from '@progress/kendo-react-inputs';
import { Error } from '@progress/kendo-react-labels';
import { StackLayout } from '@progress/kendo-react-layout';

const Container = styled.fieldset`
  ${props => props.$width && css`
    flex-grow: 0;
    flex-basis: auto;
    width: ${props.$width};
  `}
  display: flex;
  align-items: center;
`;

const Radiogroup = (props) => {
  const { valid, validationMessage, ...rest } = props;
  return (
    <Container $width={props.width} className={'k-form-fieldset'}>
      <StackLayout orientation='vertical'>
        <RadioGroup
          {...rest}
          valid={valid}
          validationMessage={validationMessage}
        />
        {!valid && <Error>{validationMessage}</Error>}
      </StackLayout>
    </Container>
  );
};

export default Radiogroup;