import CycleSkillAbilityListActionTypes from '../actions/cycle-skill-ability-list-action-types';

const initialState = {
  loading: false,
  cycleSkillAbilities: null,
  cycleSkillAbilitiesTotalMinRequiredLevelA: 0,
  cycleSkillAbilitiesTotalMinRequiredLevelB: 0,
  cycleSkillAbilitiesTotalMinRequiredLevelC: 0,
  cycleSkillAbilitiesCancelToken: null,
  isConfirmDialogVisible: false,
  selectedCycleSkillAbilityId: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CycleSkillAbilityListActionTypes.LOAD_CYCLE_SKILL_ABILITY_LIST_START:
      return {
        ...state,
        loading: true
      };
    case CycleSkillAbilityListActionTypes.LOAD_CYCLE_SKILL_ABILITY_LIST_END:
      return {
        ...state,
        cycleSkillAbilities: action.payload?.cycleSkillAbilities,
        cycleSkillAbilitiesTotalMinRequiredLevelA: action.payload?.cycleSkillAbilitiesTotalMinRequiredLevelA,
        cycleSkillAbilitiesTotalMinRequiredLevelB: action.payload?.cycleSkillAbilitiesTotalMinRequiredLevelB,
        cycleSkillAbilitiesTotalMinRequiredLevelC: action.payload?.cycleSkillAbilitiesTotalMinRequiredLevelC,
        loading: false
      };
    case CycleSkillAbilityListActionTypes.TOGGLE_CYCLE_SKILL_ABILITY_LIST_CONFIRM_DIALOG:
      return {
        ...state,
        selectedCycleSkillAbilityId: action.payload?.id,
        isConfirmDialogVisible: !state.isConfirmDialogVisible
      };
    default:
      return state;
  }
};