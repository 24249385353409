import { provideIntlService, provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import * as React from 'react';
import { DateFormatter, Grid } from '../../shared/components';
import { connect } from 'react-redux';
import { withRouter } from '../../../components/withRouter';
import { loadNotifications, sortNotifications } from '../actions';
import { GridToolbar } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { closeNotificationDeleteConfirmDialog, deleteNotification, openNotificationDeleteConfirmDialog } from '../actions/notifications-list-page-actions';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Colors, StackLayout, Text } from '../../../ui';

class NotificationsList extends React.PureComponent {

  constructor(props) {
    super(props);
    this.handleAddNotificationClick = this.handleAddNotificationClick.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleSortChange = this.handleSortChange.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  async componentDidMount() {
    await this.props.loadNotifications();
  }

  render() {
    const localizationService = provideLocalizationService(this);
    const intlService = provideIntlService(this);
    const { isDeleteConfirmDialogVisible } = this.props;
    return (
      <>
        <Grid
          loading={this.props.loading}
          data={this.props.notifications}
          skip={this.props.skip}
          take={this.props.take}
          total={this.props.total}
          sort={this.props.sorting}
          onPageChange={this.handlePageChange}
          onSortChange={this.handleSortChange}
          onRowClick={this.handleRowClick}
          columns={[
            {
              field: 'title',
              class: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('notifications.notification'),
              cell: (e) => this.renderTitle(e, localizationService)
            },
            {
              field: 'createdOn',
              class: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('notifications.creationDate'),
              cell: (e) => this.renderCreatedOn(e, intlService)
            },
            {
              field: 'visibleFrom',
              class: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('notifications.visibleFrom'),
              cell: (e) => this.renderVisibleFrom(e, intlService)
            }
          ]}
          actions={[{
            icon: 'delete',
            onClick: this.handleRemoveClick,
            disabledOn: (e) => e.dataItem.isDeleted
          }]}
        >
          <GridToolbar>
            <Button themeColor='primary' onClick={this.handleAddNotificationClick}>
              {localizationService.toLanguageString('custom.add')}
            </Button>
          </GridToolbar>
        </Grid>
        {isDeleteConfirmDialogVisible &&
          <Dialog title={localizationService.toLanguageString('notifications.deleteNotification')} onClose={this.props.closeNotificationDeleteConfirmDialog}>
            <Text>{localizationService.toLanguageString('notifications.deleteNotificationConfirmation')}</Text>
            <DialogActionsBar>
              <Button themeColor='primary' onClick={this.props.deleteNotification}>{localizationService.toLanguageString('buttons.delete')}</Button>
              <Button onClick={this.props.closeNotificationDeleteConfirmDialog}>{localizationService.toLanguageString('buttons.cancel')}</Button>
            </DialogActionsBar>
          </Dialog>
        }
      </>
    );
  }

  renderTitle(e, localizationService) {
    const { className, dataItem } = e;
    return (
      <td className={className} onClick={() => this.handleRowClick(e)}>
        <StackLayout rowGap='2px' orientation='vertical'>
          {dataItem.title}
          {dataItem.isDeleted &&
            <Text textColor={Colors.ERROR_40} variant='caption'>
              {localizationService.toLanguageString('notifications.deleted')}
            </Text>
          }
        </StackLayout>
      </td>
    );
  }

  handleAddNotificationClick() {
    this.props.navigate('/notifications/create');
  }

  handleRowClick(e) {
    if (!e.dataItem.isDeleted) {
      this.props.navigate(`/notifications/${e.dataItem.id}`);
    }
  }

  handleSortChange(e) {
    this.props.sortNotifications(e.sort);
  }

  handleRemoveClick(e) {
    this.props.openNotificationDeleteConfirmDialog({
      notificationId: e.dataItem.id
    });
  }

  async handlePageChange(e) {
    await this.props.loadNotifications({ skip: e.page.skip, take: e.page.take });
  }

  renderCreatedOn(e, intlService) {
    const { dataItem, className } = e;
    return (
      <td className={className} onClick={() => this.handleRowClick(e)}>
        {DateFormatter(new Date(dataItem.createdOn), intlService)}
      </td>
    );
  }

  renderVisibleFrom(e, intlService) {
    const { dataItem, className } = e;
    return (
      <td className={className} onClick={() => this.handleRowClick(e)}>
        {DateFormatter(new Date(dataItem.visibleFrom), intlService)}
      </td>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.notificationsListPage.loading,
  notifications: state.notificationsListPage.notifications,
  skip: state.notificationsListPage.skip,
  take: state.notificationsListPage.take,
  total: state.notificationsListPage.total,
  sorting: state.notificationsListPage.sorting,
  isDeleteConfirmDialogVisible: state.notificationsListPage.isDeleteConfirmDialogVisible
});

const mapDispatchToProps = dispatch => ({
  loadNotifications: (payload) => dispatch(loadNotifications(payload)),
  sortNotifications: (payload) => dispatch(sortNotifications(payload)),
  openNotificationDeleteConfirmDialog: (payload) => dispatch(openNotificationDeleteConfirmDialog(payload)),
  closeNotificationDeleteConfirmDialog: () => dispatch(closeNotificationDeleteConfirmDialog()),
  deleteNotification: () => dispatch(deleteNotification())
});

registerForLocalization(NotificationsList);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NotificationsList));