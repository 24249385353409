import React from 'react';
import { connect } from 'react-redux';
import { provideLocalizationService, registerForLocalization, provideIntlService } from '@progress/kendo-react-intl';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { Grid, DateFormatter } from '../../shared/components';
import { Colors, Text, WrapLayout } from '../../../ui';
import { loadUserRequestList, openUserRequestConfirmDialog, closeUserRequestConfirmDialog, removeUserRequestConfirmDialog } from '../actions/user-request-list-actions';
import { withRouter } from '../../../components/withRouter';
import { StackLayout } from '@progress/kendo-react-layout';

class UserRequestList extends React.Component {
  constructor(props) {
    super(props);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleConfirmDialogClose = this.handleConfirmDialogClose.bind(this);
    this.handleRemoveConfirm = this.handleRemoveConfirm.bind(this);
  }

  async componentDidMount() {
    await this.props.loadUserRequestList({ userId: this.props.userId });
  }

  render() {
    const { userRequests, loading, isConfirmDialogVisible } = this.props;
    const localizationService = provideLocalizationService(this);
    const intlService = provideIntlService(this);
    return (
      <>
        <Grid
          loading={loading}
          data={userRequests}
          isPageable={false}
          columns={[
            {
              field: 'student',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('userRequest.resident'),
              cell: this.renderStudent
            }, {
              field: 'surveyName',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('userRequest.survey'),
              cell: (e) => this.renderSurvey(e, localizationService, intlService)
            }, {
              field: 'role',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('userRequest.role'),
              cell: (e) =>
                <td className={e.className}>
                  {localizationService.toLanguageString(`evaluatorRoles.${e.dataItem.role}`)}
                </td>
            }
          ]}
          actions={[
            { icon: 'delete', onClick: this.handleRemoveClick }
          ]}
        />
        {isConfirmDialogVisible &&
          <Dialog title={localizationService.toLanguageString('custom.confirm')} onClose={this.handleConfirmDialogClose}>
            <Text>{localizationService.toLanguageString('userRequest.userRequestDialogMessage')}</Text>
            <DialogActionsBar>
              <Button themeColor={'primary'} onClick={this.handleRemoveConfirm}>{localizationService.toLanguageString('buttons.delete')}</Button>
              <Button onClick={this.handleConfirmDialogClose}>{localizationService.toLanguageString('buttons.cancel')}</Button>
            </DialogActionsBar>
          </Dialog>
        }
      </>
    );
  }

  renderStudent(e) {
    const { className, dataItem } = e;
    return (
      <td className={className}>
        <StackLayout rowGap='2px' orientation='vertical'>
          {dataItem.student}
          <WrapLayout>
            <Text textColor={Colors.GRAY_50} variant='caption'>
              {dataItem.department}
            </Text>
          </WrapLayout>
        </StackLayout>
      </td>
    );
  }

  renderSurvey(e, localizationService, intlService) {
    const { className, dataItem } = e;
    return (
      <td className={className}>
        <StackLayout rowGap='2px' orientation='vertical'>
          {`${dataItem.surveyCode} ` + `${dataItem.surveyName}`}
          <WrapLayout>
            <Text textColor={Colors.GRAY_50} variant='caption'>
              {`${DateFormatter(dataItem.requestDate, intlService)}` +
                (dataItem.surveyEvaluation != null ? `, ${localizationService.toLanguageString('userRequest.evaluated')}: ${dataItem.surveyEvaluation}` : ``)}
            </Text>
          </WrapLayout>
        </StackLayout>
      </td>
    );
  }

  handleRemoveClick(e) {
    this.props.openUserRequestConfirmDialog(e.dataItem);
  }

  handleConfirmDialogClose() {
    this.props.closeUserRequestConfirmDialog();
  }

  handleRemoveConfirm() {
    this.props.removeUserRequestConfirmDialog({ userId: this.props.userId });
  }
}

const mapStateToProps = (state) => ({
  userRequests: state.userRequestList.userRequests,
  loading: state.userRequestList.loading,
  isConfirmDialogVisible: state.userRequestList.isConfirmDialogVisible
});

const mapDispatchToProps = (dispatch) => ({
  loadUserRequestList: (payload) => dispatch(loadUserRequestList(payload)),
  openUserRequestConfirmDialog: (payload) => dispatch(openUserRequestConfirmDialog(payload)),
  closeUserRequestConfirmDialog: (payload) => dispatch(closeUserRequestConfirmDialog(payload)),
  removeUserRequestConfirmDialog: (payload) => dispatch(removeUserRequestConfirmDialog(payload))
});

registerForLocalization(UserRequestList);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserRequestList));
