import React from 'react';
import styled from 'styled-components';

const InlineBadgeDiv = styled.div`
  font-size: 10pt;
  display: inline-block;
  border-width: 1px;
  opacity: 75%;
  border-radius: 4px;
  padding: 4px;
  width: fit-content;
`;

const InlineBadge = (props) => {
  const colorClassName = props.themeColor ? `k-notification-${props.themeColor}` : null;
  return (
    <InlineBadgeDiv className={colorClassName}>
      {props.children}
    </InlineBadgeDiv>
  );
};

export default InlineBadge;