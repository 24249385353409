import React from 'react';
import { connect } from 'react-redux';
import { GridToolbar } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { Grid, GridSize } from '../../shared/components';
import { loadCompetencyCycleList, openCompetencyCycleConfirmDialog, closeCompetencyCycleConfirmDialog, removeCompetencyCycleConfirmDialog } from '../actions/competency-cycle-list-actions';
import { canWriteStudyProgram } from '../../common/actions';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Text } from '../../../ui';
import { withRouter } from '../../../components/withRouter';
import styled from 'styled-components';

const CycleNameContainer = styled.span`
  font-weight: bolder;
`;

class CompetencyCycleList extends React.PureComponent {

  constructor() {
    super();
    this.handleAddClicked = this.handleAddClicked.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleConfirmDialogClose = this.handleConfirmDialogClose.bind(this);
    this.handleRemoveConfirm = this.handleRemoveConfirm.bind(this);
  }

  async componentDidMount() {
    await this.props.loadCompetencyCycleList({ competencyId: this.props.competencyId });
  }

  render() {
    const { loading, competencyCycles, canWrite } = this.props;
    const localizationService = provideLocalizationService(this);
    const canDeleteRow = canWriteStudyProgram(this.props.studyProgramId, this.props.app);
    return (
      <>
        <Grid
          loading={loading}
          data={competencyCycles}
          isPageable={false}
          columns={[
            {
              field: 'cycleName',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('competency.name'),
              cell: ({ dataItem, className }) =>
                <td className={className}>
                  {this.props.studyProgramId === dataItem.cycleStudyProgramId
                    ? dataItem.cycleName
                    : <CycleNameContainer>{`${dataItem.cycleName} (${dataItem.cycleStudyProgramName})`}</CycleNameContainer>
                  }
                </td>
            }, {
              field: 'cycleType',
              className: 'align-center',
              headerClassName: 'align-center',
              title: localizationService.toLanguageString('competency.type'),
              minGridWidth: GridSize.large,
              cell: e => (
                <td className={e.className}>
                  {localizationService.toLanguageString(`cycleType.${e.dataItem?.cycleType}`)}
                </td>
              )
            }, {
              field: 'cycleCredits',
              className: 'align-right',
              headerClassName: 'align-right',
              title: localizationService.toLanguageString('competency.credits'),
              minGridWidth: GridSize.medium
            }, {
              field: 'cycleCode',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('competency.code'),
              minGridWidth: GridSize.small
            }
          ]}
          actions={
            [
              {
                icon: 'delete',
                onClick: this.handleRemoveClick,
                disabledOn: () => {
                  return !canDeleteRow;
                }
              }
            ]
          }
        >
          {canWrite &&
            <GridToolbar>
              <Button themeColor='primary' onClick={this.handleAddClicked}>
                {localizationService.toLanguageString('custom.add')}
              </Button>
            </GridToolbar>
          }
        </Grid>
        {this.props.isConfirmDialogVisible &&
          <Dialog title={localizationService.toLanguageString('custom.confirm')} onClose={this.handleConfirmDialogClose}>
            <Text>{localizationService.toLanguageString('competency.competencyCycleDialogMessage')}</Text>
            <DialogActionsBar>
              <Button themeColor={'primary'} onClick={this.handleRemoveConfirm}>{localizationService.toLanguageString('buttons.delete')}</Button>
              <Button onClick={this.handleConfirmDialogClose}>{localizationService.toLanguageString('buttons.cancel')}</Button>
            </DialogActionsBar>
          </Dialog>
        }
      </>
    );
  }

  handleRemoveClick(row) {
    this.props.openCompetencyCycleConfirmDialog(row.dataItem);
  }

  handleConfirmDialogClose() {
    this.props.closeCompetencyCycleConfirmDialog();
  }

  handleRemoveConfirm() {
    this.props.removeCompetencyCycleConfirmDialog({ competencyId: this.props.competencyId });
  }

  handleAddClicked() {
    this.props.navigate(`/competencies/${this.props.competencyId}/cycles/cycle_select`);
  }
}

const mapStateToProps = state => ({
  loading: state.competencyCycleList.loading,
  competencyCycles: state.competencyCycleList.competencyCycles,
  isConfirmDialogVisible: state.competencyCycleList.isConfirmDialogVisible,
  app: state.app
});

const mapDispatchToProps = dispatch => ({
  loadCompetencyCycleList: (payload) => dispatch(loadCompetencyCycleList(payload)),
  openCompetencyCycleConfirmDialog: (payload) => dispatch(openCompetencyCycleConfirmDialog(payload)),
  closeCompetencyCycleConfirmDialog: () => dispatch(closeCompetencyCycleConfirmDialog()),
  removeCompetencyCycleConfirmDialog: (payload) => dispatch(removeCompetencyCycleConfirmDialog(payload))
});

registerForLocalization(CompetencyCycleList);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CompetencyCycleList));