import axios from 'axios';
import StudyProgramSkillAbilityListActionTypes from './study-program-skill-ability-list-action-types';
import { ROLE_PERMISSIONS as Permissions } from '../../../resources/rolePermissions';

export const loadStudyProgramSkillAbilityList = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudyProgramSkillAbilityListActionTypes.LOAD_STUDY_PROGRAM_SKILL_ABILITY_LIST_START });
  const state = getState().studyProgramSkillAbilityList;
  try {
    state.studyProgramSkillAbilitiesCancelToken?.cancel();
    state.studyProgramSkillAbilitiesCancelToken = axios.CancelToken.source();
    const studyProgramSkillAbilities = await api.get(`api/study_programs/${payload.studyProgramId}/skill_abilities`, null, state.studyProgramSkillAbilitiesCancelToken.token);
    const canWrite = getState().app.userPermissions.includes(Permissions.STUDY_PROGRAMS_WRITE);
    dispatch({
      type: StudyProgramSkillAbilityListActionTypes.LOAD_STUDY_PROGRAM_SKILL_ABILITY_LIST_END,
      payload: {
        studyProgramSkillAbilities: studyProgramSkillAbilities ? studyProgramSkillAbilities.data : [],
        canWrite
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: StudyProgramSkillAbilityListActionTypes.LOAD_STUDY_PROGRAM_SKILL_ABILITY_LIST_END });
      throw error;
    }
  }
};

export const openStudyProgramSkillAbilityConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  dispatch({
    type: StudyProgramSkillAbilityListActionTypes.TOGGLE_STUDY_PROGRAM_SKILL_ABILITY_LIST_CONFIRM_DIALOG,
    payload: payload
  });
};

export const closeStudyProgramSkillAbilityConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  dispatch({
    type: StudyProgramSkillAbilityListActionTypes.TOGGLE_STUDY_PROGRAM_SKILL_ABILITY_LIST_CONFIRM_DIALOG
  });
};

export const removeStudyProgramSkillAbilityConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().studyProgramSkillAbilityList;
  if (state.selectedStudyProgramSkillAbilityId > 0) {
    dispatch({ type: StudyProgramSkillAbilityListActionTypes.LOAD_STUDY_PROGRAM_SKILL_ABILITY_LIST_START });
    try {
      await api.remove(`api/study_programs/${payload.studyProgramId}/skill_abilities/${state.selectedStudyProgramSkillAbilityId}`);
      dispatch(loadStudyProgramSkillAbilityList({ studyProgramId: payload.studyProgramId }));
    } catch (error) {
      dispatch({ type: StudyProgramSkillAbilityListActionTypes.LOAD_STUDY_PROGRAM_SKILL_ABILITY_LIST_END });
    }
  }
  dispatch({ type: StudyProgramSkillAbilityListActionTypes.TOGGLE_STUDY_PROGRAM_SKILL_ABILITY_LIST_CONFIRM_DIALOG });
};