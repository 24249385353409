import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import axios from 'axios';
import { reducers } from './index';
import { AppPageReducer } from '../features/common';
import { StudentListPageReducer, StudentCyclesReducer, StudentOverviewPageReducer } from '../features/students';
import {
  StudyPlanListPageReducer,
  StudyPlanOverviewPageReducer,
  StudyPlanCycleListReducer,
  StudyPlanFormPageReducer,
  StudyPlanCycleOverviewPageReducer,
  StudyPlanCycleDepartmentListReducer,
  StudyPlanCycleDepartmentFormPageReducer,
  StudyPlanCycleSelectPageReducer,
  StudyPlanCycleStudentListReducer,
  StudyPlanCycleStudentSelectPageReducer,
  StudyPlanCycleStudentFormPageReducer,
  StudyPlanCycleTheoreticalPartListReducer,
  StudyPlanCycleTheoreticalPartFormPageReducer,
  StudyPlanCycleStudentDepartmentsListReducer,
  StudyPlanCycleManagersListReducer
} from '../features/study-plans';
import {
  CycleListPageReducer,
  CycleOverviewPageReducer,
  CycleFormPageReducer,
  CycleCaseListReducer,
  CycleCaseFormPageReducer,
  CycleSkillAbilityListReducer,
  CycleSkillAbilityFormPageReducer,
  CycleTheoreticalPartListReducer,
  CycleTheoreticalPartFormPageReducer,
  CycleCaseOverviewPageReducer,
  CycleCaseDiagnosisListReducer,
  CycleCaseDiagnosisTlkDialogReducer
} from '../features/cycles';
import {
  StudyProgramListPageReducer,
  StudyProgramOverviewPageReducer,
  StudyProgramFormPageReducer,
  StudyProgramCycleListReducer,
  StudyProgramCaseFormPageReducer,
  StudyProgramCaseListReducer,
  StudyProgramSkillAbilityFormPageReducer,
  StudyProgramSkillAbilityListReducer,
  StudyProgramTheoreticalPartFormPageReducer,
  StudyProgramTheoreticalPartListReducer
} from '../features/study-programs';
import {
  UserListPageReducer,
  UserFormPageReducer,
  UserSearchDialogReducer,
  UserOverviewPageReducer
} from '../features/users';
import {
  DepartmentListPageReducer,
  DepartmentOverviewPageReducer,
  DepartmentFormPageReducer
} from '../features/departments';
import {
  DesignationOverviewPageReducer
} from '../features/designations';
import {
  TheoreticalPartListPageReducer,
  TheoreticalPartOverviewPageReducer,
  TheoreticalPartStudentListReducer
} from '../features/theoretical-parts';

import {
  MyStudiesStudentOverviewPageReducer,
  MyStudiesCycleListReducer,
  MyStudiesSurveyFormPageReducer
} from '../features/my-studies';
import {
  ManagedStudentListPageReducer,
  ManagedStudentOverviewPageReducer
} from '../features/managed-students';
import {
  CyclePlanListPageReducer
} from '../features/cycle-plans';
import {
  CompetencyListPageReducer,
  CompetencyOverviewPageReducer,
  CompetencyFormPageReducer,
  CompetencySurveyListReducer,
  CompetencySurveyOverviewPageReducer,
  CompetencySurveyQuestionListReducer,
  CompetencySurveyFormPageReducer,
  CompetencySurveyQuestionFormPageReducer,
  CompetencyCycleListReducer,
  CompetencyCycleSelectPageReducer,
  SubcompetencyListPageReducer,
  SubcompetencyFormPageReducer,
  CompetencySubcompetencySelectPageReducer,
  SurveyListPageReducer
} from '../features/competencies';
import {
  MySurveyListPageReducer,
  MySurveyFormPageReducer
} from '../features/my-surveys';
import {
  CompetencyRequestListPageReducer,
  CompetencyRequestSummaryPageReducer
} from '../features/competency-requests';
import { UserRequestListReducer
} from '../features/users';
import {
  ApprovalRequestListPageReducer
} from '../features/approval-requests';

import {
  StudentCompetencyCycleListReducer,
  StudentCompetencySubcompetencyListReducer,
  StudentCompetencyListReducer,
  StudentCompetencyOverviewReducer,
  StudentCompetencySurveyListReducer,
  StudentCompetencySurveyOverviewReducer,
  StudentCompetencySubmittedSurveyListReducer,
  StudentCompetencySurveySubmitFormReducer,
  StudentCompetencyCertificateViewReducer,
  StudentCycleOverviewReducer
} from '../features/shared';

import api from '../utils/api';
import StudyPlanCycleStudentOverviewPageReducer from '../features/study-plans/reducers/study-plan-cycle-student-overview-page-reducer';
import ForbiddenError from '../utils/forbidden-error';
import {
  NotificationFormPageReducer,
  NotificationsListPageReducer
} from '../features/notifications/reducers';
import {
  MyNotificationsListPageReducer
} from '../features/my-notifications/reducers';

export default function configureStore(history, initialState) {
  const authorizationService = window.authorizationService;
  const errorHandler = store => next => async action => {
    const handleException = async(error) => {
      if (error instanceof ForbiddenError) {
        history.push('/forbidden');
      } else {
        let message;
        if (error.status) {
          message = error.status;
        } else {
          message = error.message;
        }
        history.push('/error', { message });
      }
    };
    try {
      return await next(action);
    } catch (error) {
      if (!(error instanceof axios.Cancel)) {
        if (error instanceof Promise) {
          error.catch(handleException);
        } else {
          await handleException(error);
        }
      }
    }
  };

  const middleware = [
    errorHandler,
    thunk.withExtraArgument({ api, history, authorizationService })
  ];

  const rootReducer = combineReducers({
    ...reducers,

    app: AppPageReducer,

    studentListPage: StudentListPageReducer,
    studentOverviewPage: StudentOverviewPageReducer,
    studentCycles: StudentCyclesReducer,

    studentCompetencyList: StudentCompetencyListReducer,
    studentCompetencyOverview: StudentCompetencyOverviewReducer,
    studentCompetencyCycleList: StudentCompetencyCycleListReducer,
    studentCompetencySubcompetencyList: StudentCompetencySubcompetencyListReducer,
    studentCompetencySurveyList: StudentCompetencySurveyListReducer,
    studentCompetencySurveyOverview: StudentCompetencySurveyOverviewReducer,
    studentCompetencySurveySubmitForm: StudentCompetencySurveySubmitFormReducer,
    studentCompetencySubmittedSurveyList: StudentCompetencySubmittedSurveyListReducer,
    studentCompetencyCertificateView: StudentCompetencyCertificateViewReducer,

    studentCycleOverview: StudentCycleOverviewReducer,

    studyPlanListPage: StudyPlanListPageReducer,
    studyPlanOverviewPage: StudyPlanOverviewPageReducer,
    studyPlanCycleList: StudyPlanCycleListReducer,
    studyPlanFormPage: StudyPlanFormPageReducer,
    studyPlanCycleOverviewPage: StudyPlanCycleOverviewPageReducer,
    studyPlanCycleDepartmentList: StudyPlanCycleDepartmentListReducer,
    studyPlanCycleSelectPage: StudyPlanCycleSelectPageReducer,
    studyPlanCycleDepartmentFormPage: StudyPlanCycleDepartmentFormPageReducer,
    studyPlanCycleStudentList: StudyPlanCycleStudentListReducer,
    studyPlanCycleStudentSelectPage: StudyPlanCycleStudentSelectPageReducer,
    studyPlanCycleStudentFormPage: StudyPlanCycleStudentFormPageReducer,
    studyPlanCycleStudentOverviewPage: StudyPlanCycleStudentOverviewPageReducer,
    studyPlanCycleStudentDepartmentsList: StudyPlanCycleStudentDepartmentsListReducer,
    studyPlanCycleTheoreticalPartList: StudyPlanCycleTheoreticalPartListReducer,
    studyPlanCycleTheoreticalPartFormPage: StudyPlanCycleTheoreticalPartFormPageReducer,
    studyPlanCycleManagersList: StudyPlanCycleManagersListReducer,

    cycleListPage: CycleListPageReducer,
    cycleOverviewPage: CycleOverviewPageReducer,
    cycleFormPage: CycleFormPageReducer,
    cycleCaseList: CycleCaseListReducer,
    cycleCaseFormPage: CycleCaseFormPageReducer,
    cycleSkillAbilityList: CycleSkillAbilityListReducer,
    cycleSkillAbilityFormPage: CycleSkillAbilityFormPageReducer,
    cycleTheoreticalPartList: CycleTheoreticalPartListReducer,
    cycleTheoreticalPartFormPage: CycleTheoreticalPartFormPageReducer,
    cycleCaseOverviewPage: CycleCaseOverviewPageReducer,
    cycleCaseDiagnosisList: CycleCaseDiagnosisListReducer,
    cycleCaseDiagnosisTlkDialog: CycleCaseDiagnosisTlkDialogReducer,

    studyProgramListPage: StudyProgramListPageReducer,
    studyProgramOverviewPage: StudyProgramOverviewPageReducer,
    studyProgramFormPage: StudyProgramFormPageReducer,
    studyProgramCycleList: StudyProgramCycleListReducer,
    studyProgramCaseFormPage: StudyProgramCaseFormPageReducer,
    studyProgramCaseList: StudyProgramCaseListReducer,
    studyProgramSkillAbilityFormPage: StudyProgramSkillAbilityFormPageReducer,
    studyProgramSkillAbilityList: StudyProgramSkillAbilityListReducer,
    studyProgramTheoreticalPartFormPage: StudyProgramTheoreticalPartFormPageReducer,
    studyProgramTheoreticalPartList: StudyProgramTheoreticalPartListReducer,

    departmentListPage: DepartmentListPageReducer,
    departmentOverviewPage: DepartmentOverviewPageReducer,
    departmentFormPage: DepartmentFormPageReducer,

    designationOverviewPage: DesignationOverviewPageReducer,

    theoreticalPartListPage: TheoreticalPartListPageReducer,
    theoreticalPartOverviewPage: TheoreticalPartOverviewPageReducer,
    theoreticalPartStudentList: TheoreticalPartStudentListReducer,

    userListPage: UserListPageReducer,
    userFormPage: UserFormPageReducer,
    userSearchDialog: UserSearchDialogReducer,
    userOverviewPage: UserOverviewPageReducer,

    myStudiesStudentOverviewPage: MyStudiesStudentOverviewPageReducer,
    myStudiesCycleList: MyStudiesCycleListReducer,
    myStudiesSurveyFormPage: MyStudiesSurveyFormPageReducer,

    managedStudentListPage: ManagedStudentListPageReducer,
    managedStudentOverviewPage: ManagedStudentOverviewPageReducer,

    cyclePlanListPage: CyclePlanListPageReducer,

    competencyListPage: CompetencyListPageReducer,
    competencyOverviewPage: CompetencyOverviewPageReducer,
    competencyFormPage: CompetencyFormPageReducer,
    competencySurveyList: CompetencySurveyListReducer,
    competencySurveyOverviewPage: CompetencySurveyOverviewPageReducer,
    competencySurveyQuestionList: CompetencySurveyQuestionListReducer,
    competencySurveyFormPage: CompetencySurveyFormPageReducer,
    competencySurveyQuestionFormPage: CompetencySurveyQuestionFormPageReducer,
    competencyCycleList: CompetencyCycleListReducer,
    competencyCycleSelectPage: CompetencyCycleSelectPageReducer,
    subcompetencyListPage: SubcompetencyListPageReducer,
    subcompetencyFormPage: SubcompetencyFormPageReducer,
    competencySubcompetencySelectPage: CompetencySubcompetencySelectPageReducer,
    surveyListPage: SurveyListPageReducer,

    mySurveyListPage: MySurveyListPageReducer,
    mySurveyFormPage: MySurveyFormPageReducer,

    competencyRequestListPage: CompetencyRequestListPageReducer,
    competencyRequestSummaryPage: CompetencyRequestSummaryPageReducer,

    userRequestList: UserRequestListReducer,

    approvalRequestListPage: ApprovalRequestListPageReducer,

    notificationsListPage: NotificationsListPageReducer,
    notificationFormPage: NotificationFormPageReducer,

    myNotificationsListPage: MyNotificationsListPageReducer
  });

  const enhancers = [];
  const windowIfDefined = typeof window === 'undefined' ? null : window;
  if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
  }

  return createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  );

}
