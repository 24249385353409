import React from 'react';
import { connect } from 'react-redux';
import { provideLocalizationService, registerForIntl, registerForLocalization } from '@progress/kendo-react-intl';
import { Grid, InlineBadge, GridSize } from '../../components';
import { loadStudentCompetencyCycleList } from '../../student-competency/actions';
import { withRouter } from '../../../../components/withRouter';
import { getStudentCycleStatusColor } from '../../../../utils/studyPlanCycleDepartmentStudentStatusColors';

class StudentCompetencyCycleList extends React.Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    if (this.props.competencyId > 0 && this.props.studyProgramId > 0) {
      await this.props.loadStudentCompetencyCycleList({
        competencyId: this.props.competencyId,
        studentId: this.props.studentId,
        studyProgramId: this.props.studyProgramId
      });
    }
  }

  render() {
    const { studentCompetencyCycles, loading } = this.props;
    const localizationService = provideLocalizationService(this);
    return (
      <Grid
        loading={loading}
        data={studentCompetencyCycles}
        isPageable={false}
        columns={[
          {
            field: 'cycleName',
            className: 'align-left',
            headerClassName: 'align-left',
            title: localizationService.toLanguageString('studentCompetency.name')
          }, {
            field: 'cycleType',
            className: 'align-left',
            headerClassName: 'align-left',
            title: localizationService.toLanguageString('studentCompetency.type'),
            minGridWidth: GridSize.small,
            cell: (e) => (
              <td className={e.className}>
                {localizationService.toLanguageString(`cycleType.${e.dataItem.cycleType}`)}
              </td>
            )
          }, {
            field: 'cycleCredits',
            className: 'align-left',
            headerClassName: 'align-left',
            title: localizationService.toLanguageString('studentCompetency.credits'),
            minGridWidth: GridSize.medium
          }, {
            field: 'status',
            className: 'align-left',
            headerClassName: 'align-left',
            title: localizationService.toLanguageString('studentCompetency.cycleStatus'),
            cell: (e) => (
              <td className={e.className}>
                {e.dataItem.status &&
                  <InlineBadge themeColor={getStudentCycleStatusColor(e.dataItem.status)}>
                    {localizationService.toLanguageString(`studyPlanCycleDepartmentStudentStatus.${e.dataItem.status}`)}
                  </InlineBadge>
                }
              </td>
            )
          }
        ]}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  studentCompetencyCycles: state.studentCompetencyCycleList.studentCompetencyCycles,
  loading: state.studentCompetencyCycleList.loading
});

const mapDispatchToProps = (dispatch) => ({
  loadStudentCompetencyCycleList: (payload) => dispatch(loadStudentCompetencyCycleList(payload))
});

registerForIntl(StudentCompetencyCycleList);
registerForLocalization(StudentCompetencyCycleList);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StudentCompetencyCycleList));
