import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Breadcrumb } from '@progress/kendo-react-layout';
import { Text, ErrorMessage, SuccessMessage } from '../common';
import { StackLayout } from '../layouts';
import { elevation } from '../shared';
import { Button } from '@progress/kendo-react-buttons';

const Container = styled.div`
  display: flex;
  align-self: stretch;
  justify-content: center;
  width: 100%;
  background-color: ${props => props.theme.gray10};
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: stretch;
  max-width: 1600px;
  min-width: 320px;
  position: relative;
`;

const FilterInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

const HeaderActionsContainer = styled(StackLayout).attrs(() => ({
  orientation: 'horizontal',
  columnGap: '8px'
}))`
  flex-grow: 0;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.white};
  ${elevation(0)}
  border-style: solid; 
  border-width: 0 1px 1px 1px;
  border-color: ${props => props.theme.gray20}; 
`;

const HeaderContainer = styled.div`
  background-color: ${props => props.theme.white};
  padding: 8px 0px;
  border-style: solid; 
  border-width: 0 1px 0 1px;
  border-color: ${props => props.theme.gray20}; 
`;

const Title = styled(Text).attrs(props => ({
  variant: 'h3',
  textColor: props.theme.gray80,
  truncate: true
}))``;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
`;

const Header = styled(StackLayout).attrs(props => ({
  rowGap: '8px',
  columnGap: '16px',
  orientation: 'horizontal'
}))`
  margin: 0 16px 0 24px;
`;

const BackButton = styled(Button).attrs(props => ({
}))`
  flex: 0 0 36px;
`;

const customDelimiter = () => {
  return <span className={'k-icon k-i-divide'} />;
};

export default class SelectListPage extends PureComponent {

  render() {
    return (
      <Container id={this.props.id}>
        <InnerContainer>
          <HeaderContainer>
            <Header>
              <BackButton
                id='back-button'
                type='button'
                onClick={this.props.onBackClick}
                icon='undo'
              >
              </BackButton>
              {this.props.breadcrumbItems?.length > 0 &&
                  <Breadcrumb
                    data={this.props.breadcrumbItems}
                    onItemSelect={this.props.onBreadcrumbItemClick}
                    breadcrumbDelimiter={customDelimiter}
                  />
              }
              {this.props.headerActions &&
                  <HeaderActionsContainer>
                    {this.props.headerActions}
                  </HeaderActionsContainer>
              }
            </Header>
          </HeaderContainer>
          <Main>
            <FilterContainer>
              <FilterInnerContainer>
                <StackLayout rowGap='4px'>
                  {this.props.title && <Title>{this.props.title}</Title>}
                  {(this.props.errorMessage ||
                    this.props.successMessage) &&
                    <StackLayout margin='8px 0 0' rowGap='8px'>
                      {this.props.errorMessage && <ErrorMessage>{this.props.errorMessage}</ErrorMessage>}
                      {this.props.successMessage && <SuccessMessage>{this.props.successMessage}</SuccessMessage>}
                    </StackLayout>
                  }
                </StackLayout>
                {this.props.filter}
              </FilterInnerContainer>
            </FilterContainer>
            <ContentContainer>
              {this.props.children}
            </ContentContainer>
          </Main>
        </InnerContainer>
      </Container>
    );
  }

}