import { COMPETENCY_SURVEY_STUDENT_STATUS } from '../resources/competencySurveyStudentStatus';

export const getCompetencySurveyStudentStatusColor = (status) => {
  switch (status) {
    case COMPETENCY_SURVEY_STUDENT_STATUS.SUBMITTED:
      return 'primary';
    case COMPETENCY_SURVEY_STUDENT_STATUS.EVALUATED:
      return 'success';
    default:
      return 'inverse';
  }
};