import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { provideIntlService, provideLocalizationService, registerForIntl, registerForLocalization } from '@progress/kendo-react-intl';
import { loadStudentCycles } from '../actions/student-cycles-actions';
import { Grid, GridSize, DateFormatter, InlineBadge } from '../../shared/components';
import { StackLayout } from '../../../ui';
import { CYCLE_STUDENT_STATUS } from '../../../resources/studyPlanCycleDepartmentStudentStatus';
import { withRouter } from '../../../components/withRouter';
import { getStudentCycleStatusColor } from '../../../utils/studyPlanCycleDepartmentStudentStatusColors';

const HeaderLabel = styled.span`
  font-weight: normal;
`;

const HeaderValue = styled.span`
  font-weight: bold;
`;

class StudentCycles extends React.Component {
  constructor(props) {
    super(props);
    this.expandChange = this.expandChange.bind(this);
    this.handleAddClicked = this.handleAddClicked.bind(this);
    this.handleEditClicked = this.handleEditClicked.bind(this);
    this.renderCell = this.renderCell.bind(this);
    this.renderStatus = this.renderStatus.bind(this);
  }

  async componentDidMount() {
    await this.props.loadStudentCycles(this.props.studentId);
  }

  render() {
    const { studentCycles, loading } = this.props;
    const intlService = provideIntlService(this);
    const localizationService = provideLocalizationService(this);
    return (
      <Grid
        loading={loading}
        data={this.formatGridData(studentCycles, localizationService, intlService)}
        expandField='expanded'
        group={[{ field: 'year' }]}
        onExpandChange={this.expandChange}
        onRowClick={e => this.props.navigate(`/students/${e.dataItem.studentId}/cycles/${e.dataItem.id}/overview`)}
        cellRender={this.renderCell}
        rowRender={(tr, props) => {
          if (tr && props.rowType === 'groupHeader') {
            return React.cloneElement(tr, { className: '' });
          }
          return tr;
        }}
        isPageable={false}
        columns={[
          {
            field: 'cycle',
            className: 'align-left',
            headerClassName: 'align-left',
            title: localizationService.toLanguageString('residentcycles.cycle')
          }, {
            field: 'localizedCycleType',
            className: 'align-left',
            headerClassName: 'align-left',
            title: localizationService.toLanguageString('residentcycles.cycleType'),
            minGridWidth: GridSize.medium
          }, {
            field: 'credits',
            className: 'align-right',
            headerClassName: 'align-right',
            title: localizationService.toLanguageString('residentcycles.credits'),
            minGridWidth: GridSize.small
          }, {
            field: 'dateFromTo',
            className: 'align-center',
            headerClassName: 'align-center',
            title: localizationService.toLanguageString('residentcycles.dateFromTo'),
            minGridWidth: GridSize.medium,
            width: '110px'
          }, {
            field: 'localizedStatus',
            className: 'align-left',
            headerClassName: 'align-left',
            title: localizationService.toLanguageString('residentcycles.status'),
            minGridWidth: GridSize.large,
            cell: this.renderStatus
          }
        ]}
        actions={[
          { icon: 'edit', onClick: this.handleEditClicked }
        ]}
      />
    );
  }

  renderStatus({ className, dataItem }) {
    const localizationService = provideLocalizationService(this);
    return (
      <td className={className}>
        {dataItem.status &&
          <InlineBadge themeColor={getStudentCycleStatusColor(dataItem.status)}>
            {localizationService.toLanguageString('studyPlanCycleDepartmentStudentStatus.' + dataItem.status)}
          </InlineBadge>
        }
      </td>
    );
  }

  renderCell(td, props) {
    if (td && td.props.children && props.rowType === 'groupHeader') {
      const localizationService = provideLocalizationService(this);
      const studyYear = props.dataItem.items[0].year == 0
        ? `${localizationService.toLanguageString('residentcycles.notSpecify')}:`
        : `${props.dataItem.items[0].year} ${localizationService.toLanguageString('residentcycles.studyYear')}:`;
      const children = (
        <StackLayout orientation='horizontal' align='left' columnGap='8px' valign='center'>
          {td.props.children.props.children[0]}
          <HeaderLabel>{studyYear}</HeaderLabel>
          <HeaderLabel>{localizationService.toLanguageString('residentcycles.assignedCredits')}</HeaderLabel>
          <HeaderValue>{props.dataItem.assignedCredits}</HeaderValue>
          <HeaderLabel>{localizationService.toLanguageString('residentcycles.finishedCredits')}</HeaderLabel>
          <HeaderValue>{props.dataItem.finishedCredits}</HeaderValue>
        </StackLayout>
      );
      return React.cloneElement(td, { ...td.props, colSpan: 6 }, children);
    }
    return td;
  }

  expandChange(event) {
    event.dataItem.expanded = !event.dataItem.expanded;
    this.forceUpdate();
  };

  formatGridData = (studentCycles, localizationService, intlService) => {
    for (let i = 0; i < studentCycles?.data?.length; i++) {
      studentCycles.data[i].assignedCredits = 0;
      studentCycles.data[i].finishedCredits = 0;
      if (studentCycles.data[i].items) {
        for (let s = 0; s < studentCycles.data[i].items.length; s++) {
          studentCycles.data[i].assignedCredits += studentCycles.data[i].items[s].cycleCredits;
          studentCycles.data[i].finishedCredits += studentCycles.data[i].items[s].status === CYCLE_STUDENT_STATUS.FINISHED ? studentCycles.data[i].items[s].cycleCredits : 0;
          studentCycles.data[i].items[s].localizedCycleType = localizationService.toLanguageString(`cycleType.${studentCycles.data[i].items[s].cycleType}`);
          studentCycles.data[i].items[s].dateFromTo = `
          ${
            studentCycles.data[i].items[s].dateFrom
              ? DateFormatter(new Date(studentCycles.data[i].items[s].dateFrom), intlService)
              : '-'
          }
          ${
            studentCycles.data[i].items[s].dateFrom && studentCycles.data[i].items[s].dateTo
              ? '\n'
              : '/'
          }
          ${
            studentCycles.data[i].items[s].dateTo
              ? DateFormatter(new Date(studentCycles.data[i].items[s].dateTo), intlService)
              : '-'}
          `;
        }
      }
    }
    return studentCycles;
  };

  handleEditClicked(row) {
    this.props.navigate(`/study_plans/${row.dataItem.studyPlanId}/cycles/${row.dataItem.studyPlanCycleId}/students/${row.dataItem.id}`);
  }

  handleAddClicked() {
    this.props.navigate(`/students/${this.props.studentId}/cycles/new`);
  }
}

const mapStateToProps = (state) => ({
  studentCycles: state.studentCycles.studentCycles,
  loading: state.studentCycles.loading
});

const mapDispatchToProps = (dispatch) => ({
  loadStudentCycles: (payload) => dispatch(loadStudentCycles(payload))
});

registerForIntl(StudentCycles);
registerForLocalization(StudentCycles);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StudentCycles));
