import * as React from 'react';
import { connect } from 'react-redux';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { Skeleton } from '@progress/kendo-react-indicators';
import { ComboBox, OverviewPage, StackLayout, Text } from '../../../../ui';
import { ValueItem, InlineBadge, EmptyFormatter } from '../../components';
import {
  loadStudentCompetencyOverviewPage,
  selectStudentCompetencyOverviewPageTab,
  openRequestEvaluationDialog,
  closeRequestEvaluationDialog,
  submitStudentCompetency,
  openSubmitConfirmDialog,
  closeSubmitConfirmDialog,
  togglePageSummaryExpanded,
  loadAvailableCoordinators,
  openQrCodeDialog,
  closeQrCodeDialog,
  setShowQrCodeSetting,
  getCompetencyReport
} from '../../student-competency/actions/student-competency-overview-actions';
import { withRouter } from '../../../../components/withRouter';
import StudentCompetencySubcompetencyList from './student-competency-subcompetency-list';
import StudentCompetencySurveyList from './student-competency-survey-list';
import StudentCompetencySurveySubmitForm from './student-competency-survey-submit-form';
import StudentCompetencyCertificateView from './student-competency-certificate-view';
import StudentCompetencyCycleList from './student-competency-cycle-list';
import { loadStudentCompetencySurveyList } from '../../student-competency/actions/student-competency-survey-list-actions';
import { getStudentCompetencyStatusColor } from '../../../../utils/studentCompetencyStatusColors';
import { Button, FloatingActionButton } from '@progress/kendo-react-buttons';
import { SCREEN_SIZES as ScreenSizes } from '../../../../resources/screenSize';
import { ReactComponent as CyclesIcon } from '../../../../assets/cycles.svg';
import { ReactComponent as SurveysIcon } from '../../../../assets/my-residents.svg';
import { ReactComponent as SubcompetenciesIcon } from '../../../../assets/competencies.svg';
import { STUDENT_COMPETENCY_STATUS } from '../../../../resources/studentCompetencyStatus';
import styled from 'styled-components';
import FormDialog from '../../../../ui/components/application/form-dialog';
import { Field } from '@progress/kendo-react-form';
import { QRCode } from '@progress/kendo-react-barcodes';

const TabStripContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
`;

const TabStripItem = styled.div`
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  position: relative;

  &:hover {
    color: rgba(0, 0, 0, 0.87);
    background-color: rgba(0, 0, 0, 0.04);
  }  
`;

const TabStripBox = styled(StackLayout).attrs(props => ({
  orientation: 'vertical',
  align: 'center',
  rowGap: '0'
}))`
  cursor: pointer;
  ${(props) => props.selected ? ` 
    &:after{
      border-bottom-width: 2px;
      border-width: 2px;
      border-color: #0D84C6;
      display: block;
      content: "";
      border-style: solid;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0; }` : ''
};
`;

const TabStripText = styled(Text).attrs(props => ({
  variant: 'caption',
  textColor: props.theme.gray80,
  truncate: true
}))`
  font-size: 10px;
  text-transform: uppercase;
`;

const TabStripIcon = styled.div`
  position: relative;
`;

const SmallCyclesIcon = styled(CyclesIcon)`
  height: 24px;
  width: 24px;
`;

const SmallSurveysIcon = styled(SurveysIcon)`
  height: 24px;
  width: 24px;
`;

const SmallSubcompetenciesIcon = styled(SubcompetenciesIcon)`
  height: 24px;
  width: 24px;
`;

const SmallCertificateIcon = styled.span.attrs(props => ({
  className: 'k-icon k-font-icon k-i-file-pdf'
}))`
  font-size: 24px;
`;

const QrCodeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ActionButton = styled(Button)`
  width: 128px;
`;

const HeaderButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  row-gap: 8px;
  column-gap: 8px;
`;

class StudentCompetencyOverview extends React.PureComponent {

  constructor(props) {
    super(props);
    this.handleBreadcrumbItemClick = this.handleBreadcrumbItemClick.bind(this);
    this.handleTabSelect = this.handleTabSelect.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleRequestEvaluationClick = this.handleRequestEvaluationClick.bind(this);
    this.validateRequestEvaluationDialog = this.validateRequestEvaluationDialog.bind(this);
    this.handleSubmitConfirm = this.handleSubmitConfirm.bind(this);
    this.handleSubmitDialogOpen = this.handleSubmitDialogOpen.bind(this);
    this.handleSubmitDialogClose = this.handleSubmitDialogClose.bind(this);
    this.validateSubmitDialog = this.validateSubmitDialog.bind(this);
    this.handleGetCompetencyReportClick = this.handleGetCompetencyReportClick.bind(this);
    this.state = {
      surveyLocationAddress: null
    };
  }

  componentDidMount() {
    if (this.props.params?.competencyId && this.props.studentId) {
      this.props.loadStudentCompetencyOverviewPage({
        competencyId: this.props.params.competencyId,
        studentId: this.props.studentId
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.studentId !== this.props.studentId) {
      this.props.navigate('/my_competencies');
    }
  }

  render() {
    const { studentCompetency, loading } = this.props;
    const localizationService = provideLocalizationService(this);
    const isSmallerScreen = window.innerWidth < ScreenSizes.XS;
    const position = isSmallerScreen ? 'fixed' : 'absolute';
    const y = isSmallerScreen ? 68 : 8;
    return (
      <OverviewPage
        title={loading
          ? <Skeleton shape='text' style={{ width: 300, height: 28 }}/>
          : studentCompetency?.abbreviation && studentCompetency?.name
            && `${studentCompetency.abbreviation} ${studentCompetency.name}`
            || EmptyFormatter(studentCompetency?.name)
        }
        breadcrumbItems={this.props.breadcrumbItems}
        headerActions={
          <>
            {this.props.showReportButton &&
              (studentCompetency?.status === STUDENT_COMPETENCY_STATUS.STARTED
                || studentCompetency?.status === STUDENT_COMPETENCY_STATUS.TO_BE_APPROVED
                || studentCompetency?.status === STUDENT_COMPETENCY_STATUS.NOT_APPROVED
                || studentCompetency?.status === STUDENT_COMPETENCY_STATUS.TO_BE_SIGNED
                || studentCompetency?.status === STUDENT_COMPETENCY_STATUS.RETURNED
                || studentCompetency?.status === STUDENT_COMPETENCY_STATUS.ACQUIRED) &&
              <HeaderButtonContainer>
                <ActionButton
                  type='button'
                  themeColor='base'
                  onClick={() => this.handleGetCompetencyReportClick()}
                >
                  {localizationService.toLanguageString('studentCompetency.getReport')}
                </ActionButton>
              </HeaderButtonContainer>
            }
          </>
        }
        onBreadcrumbItemClick={this.handleBreadcrumbItemClick}
        onBackClick={this.handleBackClick}
        expandableSummary={true}
        summaryExpanded={this.props.isSummaryExpanded}
        onToggleExpandSummary={this.props.togglePageSummaryExpanded}
        summary={
          <>
            <StackLayout rowGap='8px'>
              <ValueItem
                label={`${localizationService.toLanguageString('studentCompetency.cycles')}`}
                labelWidth='150px'
                loading={loading}
              >
                {`${EmptyFormatter(studentCompetency?.completedCyclesCount, 0)} ${localizationService.toLanguageString('conjunctions.outOf')} ${EmptyFormatter(studentCompetency?.cycleCount, 0)}`}
              </ValueItem>
              <ValueItem
                label={localizationService.toLanguageString('studentCompetency.evaluations')}
                labelWidth='150px'
                loading={loading}
              >
                {`${studentCompetency?.completedSurveyEvaluationCount} ${localizationService.toLanguageString('conjunctions.outOf')} ${studentCompetency?.minimumSurveyEvaluationCount}`}
              </ValueItem>
              <ValueItem
                label={localizationService.toLanguageString('studentCompetency.submittedForEvaluation')}
                labelWidth='150px'
                loading={loading}
              >
                {studentCompetency?.pendingSurveyEvaluationCount}
              </ValueItem>
            </StackLayout>
          </>
        }
        details={
          <>
            {!this.props.preview &&
              (studentCompetency?.status === STUDENT_COMPETENCY_STATUS.STARTED
              || studentCompetency?.status === STUDENT_COMPETENCY_STATUS.RETURNED) &&
              <FloatingActionButton
                hidden={this.props.isRequestEvaluationDialogVisible || this.props.isSubmitDialogVisible}
                disabled={studentCompetency?.completedSurveyEvaluationCount < 1}
                rounded='large'
                size='small'
                shape='rectangle'
                text={localizationService.toLanguageString('studentCompetency.submitForEvaluation')}
                themeColor='primary'
                positionMode={position}
                alignOffset={{
                  x: 8,
                  y: y
                }}
                align={{
                  vertical: 'top',
                  horizontal: 'end'
                }}
                onClick={this.handleSubmitDialogOpen}
              />
            }
            <ValueItem
              label={localizationService.toLanguageString('studentCompetency.status')}
              labelWidth='150px'
              loading={loading}
            >
              {studentCompetency?.status
                ?
                <InlineBadge themeColor={getStudentCompetencyStatusColor(studentCompetency.status)}>
                  {localizationService.toLanguageString(`studentCompetencyStatus.${studentCompetency.status}`)}
                </InlineBadge>
                :
                <InlineBadge themeColor={getStudentCompetencyStatusColor(STUDENT_COMPETENCY_STATUS.NOT_STARTED)}>
                  {localizationService.toLanguageString(`studentCompetencyStatus.${STUDENT_COMPETENCY_STATUS.NOT_STARTED}`)}
                </InlineBadge>
              }
            </ValueItem>
            {studentCompetency?.comment &&
                <ValueItem
                  label={localizationService.toLanguageString('studentCompetency.comment')}
                  labelWidth='150px'
                  loading={loading}
                  truncateValue={false}
                >
                  {studentCompetency.comment}
                </ValueItem>
            }
          </>
        }
        tabs={
          <>
            <TabStripContainer>
              <TabStripItem selected={this.props.selectedTab === 0} onClick={() => this.handleTabSelect({ selected: 0 })}>
                <TabStripBox selected={this.props.selectedTab === 0}>
                  <TabStripIcon>
                    <SmallSurveysIcon />
                  </TabStripIcon>
                  <TabStripText>{localizationService.toLanguageString('studentCompetency.tabstripSurveysTitle')}</TabStripText>
                </TabStripBox>
              </TabStripItem>
              <TabStripItem selected={this.props.selectedTab === 1} onClick={() => this.handleTabSelect({ selected: 1 })}>
                <TabStripBox selected={this.props.selectedTab === 1}>
                  <TabStripIcon>
                    <SmallCyclesIcon/>
                  </TabStripIcon>
                  <TabStripText>{localizationService.toLanguageString('studentCompetency.tabstripCyclesTitle')}</TabStripText>
                </TabStripBox>
              </TabStripItem>
              <TabStripItem selected={this.props.selectedTab === 2} onClick={() => this.handleTabSelect({ selected: 2 })}>
                <TabStripBox selected={this.props.selectedTab === 2}>
                  <TabStripIcon>
                    <SmallSubcompetenciesIcon />
                  </TabStripIcon>
                  <TabStripText>{localizationService.toLanguageString('studentCompetency.tabstripSubcompetenciesTitle')}</TabStripText>
                </TabStripBox>
              </TabStripItem>
              {studentCompetency?.studentCompetencyCertificateKey && studentCompetency?.status === STUDENT_COMPETENCY_STATUS.ACQUIRED &&
                <TabStripItem selected={this.props.selectedTab === 3} onClick={() => this.handleTabSelect({ selected: 3 })}>
                  <TabStripBox selected={this.props.selectedTab === 3}>
                    <TabStripIcon>
                      <SmallCertificateIcon />
                    </TabStripIcon>
                    <TabStripText>{localizationService.toLanguageString('studentCompetency.tabstripCertificateTitle')}</TabStripText>
                  </TabStripBox>
                </TabStripItem>
              }
            </TabStripContainer>
          </>
        }
      >
        {this.props.selectedTab === 0 && studentCompetency?.studyProgramId > 0 &&
          <StudentCompetencySurveyList
            preview={this.props.preview}
            studentId={this.props.studentId}
            competencyId={this.props.params.competencyId}
            studyProgramId={studentCompetency.studyProgramId}
            handleRequestEvaluationClick={this.handleRequestEvaluationClick}
            competencyStatus={studentCompetency.status}
            onRowClick={this.props.studentCompetencySurveyListOnRowClick}
          />
        }
        {this.props.selectedTab === 1 && studentCompetency?.studyProgramId > 0 &&
          <StudentCompetencyCycleList
            competencyId={this.props.params.competencyId}
            studyProgramId={studentCompetency.studyProgramId}
            studentId={this.props.studentId}
          />
        }
        {this.props.selectedTab === 2 &&
          <StudentCompetencySubcompetencyList
            competencyId={this.props.params.competencyId}
          />
        }
        {this.props.selectedTab === 3 && studentCompetency?.studentCompetencyCertificateKey &&
          <StudentCompetencyCertificateView
            studentCompetencyCertificateKey={studentCompetency?.studentCompetencyCertificateKey}
          />
        }
        {this.props.isRequestEvaluationDialogVisible &&
          <StudentCompetencySurveySubmitForm
            onCancel={this.props.closeRequestEvaluationDialog}
            studentId={this.props.studentId}
            competencySurvey={this.props.selectedCompetencySurvey}
            setShowQrCodeSetting={this.props.setShowQrCodeSetting}
            shouldShowQrCode={this.props.shouldShowQrCode}
            afterSubmit={(payload) => {
              this.setState({ surveyLocationAddress: payload.surveyLocationAddress });
              if (payload.surveyLocationAddress) {
                this.props.openQrCodeDialog();
              }

              this.props.loadStudentCompetencyOverviewPage({ competencyId: this.props.params.competencyId, studentId: this.props.studentId });
              this.props.loadStudentCompetencySurveyList({ competencyId: studentCompetency.competencyId, studyProgramId: studentCompetency.studyProgramId, studentId: this.props.studentId });
            }}
          />
        }
        {this.props.isQrCodeDialogVisible && this.state.surveyLocationAddress && this.props.shouldShowQrCode &&
          <FormDialog
            title={localizationService.toLanguageString('studentCompetency.qrCode')}
            confirmButtonText={localizationService.toLanguageString('buttons.close')}
            onSubmit={this.props.closeQrCodeDialog}
            onCancel={this.props.closeQrCodeDialog}
            hideCloseButton={true}
            width={'300px'}
            render={() => (
              <QrCodeContainer>
                <QRCode
                  value={this.state.surveyLocationAddress}
                  errorCorrection='M'
                  renderAs={'svg'}
                  size={'250px'}
                />
              </QrCodeContainer>
            )}
          />
        }
        {this.props.isSubmitDialogVisible &&
          <FormDialog
            title={localizationService.toLanguageString('studentCompetency.submitForEvaluation')}
            width={'325px'}
            onSubmit={this.handleSubmitConfirm}
            onCancel={this.handleSubmitDialogClose}
            validator={(values) => this.validateSubmitDialog(values, localizationService)}
            validationResult={this.props.submitDialogValidationResult}
            errorMessage={this.props.submitDialogErrorMessage}
            confirmButtonText={localizationService.toLanguageString('buttons.submit')}
            closeButtonText={localizationService.toLanguageString('buttons.close')}
            render={() => (
              <StackLayout>
                <Field
                  name='coordinator'
                  component={ComboBox}
                  label={localizationService.toLanguageString('studentCompetency.coordinator')}
                  data={this.props.availableCoordinators}
                  onFilter={this.props.loadAvailableCoordinators}
                  loading={this.props.availableCoordinatorsLoading}
                  textField='name'
                  valueField='userId'
                />
              </StackLayout>
            )}
          />
        }
      </OverviewPage>
    );
  }

  validateSubmitDialog(values, localizationService) {
    const errors = {};
    if (!values.coordinator) {
      errors.coordinator = localizationService.toLanguageString('validation.required');
    }
    return errors;
  }

  handleSubmitDialogOpen() {
    this.props.openSubmitConfirmDialog();
  }

  handleSubmitDialogClose() {
    this.props.closeSubmitConfirmDialog();
  }

  handleSubmitConfirm(values) {
    this.props.submitStudentCompetency({ ...values, studentId: this.props.studentId });
  }

  validateRequestEvaluationDialog(values) {
    const errors = {};
    const localizationService = provideLocalizationService(this);
    if (!values.manager) {
      errors.manager = localizationService.toLanguageString('validation.required');
    }
    if (!values.comment) {
      errors.comment = localizationService.toLanguageString('validation.required');
    }
    return errors;
  }

  handleRequestEvaluationClick(e) {
    this.props.openRequestEvaluationDialog({ ...e.dataItem });
  }

  handleTabSelect(e) {
    this.props.selectStudentCompetencyOverviewPageTab({
      selected: e.selected
    });
  }

  handleBreadcrumbItemClick(e) {
    if (e.id === 'myCompetencies') {
      this.props.navigate('/my_competencies');
    }
    if (e.id === 'students') {
      this.props.navigate('/students');
    }
    if (e.id === 'student') {
      this.props.navigate(`/students/${this.props.studentId}/overview`);
    }
  }

  handleBackClick() {
    this.props.navigate(-1);
  }

  handleGetCompetencyReportClick() {
    this.props.getCompetencyReport({
      student: this.props.studentCompetency.student,
      studentCompetencyId: this.props.studentCompetency.id
    });
  }
}

registerForLocalization(StudentCompetencyOverview);

const mapStateToProps = state => ({
  studentCompetency: state.studentCompetencyOverview.studentCompetency,
  loading: state.studentCompetencyOverview.loading,
  selectedTab: state.studentCompetencyOverview.selectedTab,
  isRequestEvaluationDialogVisible: state.studentCompetencyOverview.isRequestEvaluationDialogVisible,
  availableManagers: state.studentCompetencyOverview.availableManagers,
  availableManagersLoading: state.studentCompetencyOverview.availableManagersLoading,
  selectedCompetencySurvey: state.studentCompetencyOverview.selectedCompetencySurvey,
  isSubmitDialogVisible: state.studentCompetencyOverview.isSubmitDialogVisible,
  isSummaryExpanded: state.studentCompetencyOverview.isSummaryExpanded,
  availableCoordinators: state.studentCompetencyOverview.availableCoordinators,
  availableCoordinatorsLoading: state.studentCompetencyOverview.availableCoordinatorsLoading,
  submitDialogValidationResult: state.studentCompetencyOverview.submitDialogValidationResult,
  submitDialogErrorMessage: state.studentCompetencyOverview.submitDialogErrorMessage,
  isQrCodeDialogVisible: state.studentCompetencyOverview.isQrCodeDialogVisible,
  shouldShowQrCode: state.studentCompetencyOverview.shouldShowQrCode
});

const mapDispatchToProps = dispatch => ({
  loadStudentCompetencyOverviewPage: (payload) => dispatch(loadStudentCompetencyOverviewPage(payload)),
  selectStudentCompetencyOverviewPageTab: (payload) => dispatch(selectStudentCompetencyOverviewPageTab(payload)),
  openRequestEvaluationDialog: (payload) => dispatch(openRequestEvaluationDialog(payload)),
  closeRequestEvaluationDialog: () => dispatch(closeRequestEvaluationDialog()),
  loadStudentCompetencySurveyList: (payload) => dispatch(loadStudentCompetencySurveyList(payload)),
  submitStudentCompetency: (payload) => dispatch(submitStudentCompetency(payload)),
  openSubmitConfirmDialog: () => dispatch(openSubmitConfirmDialog()),
  closeSubmitConfirmDialog: () => dispatch(closeSubmitConfirmDialog()),
  togglePageSummaryExpanded: () => dispatch(togglePageSummaryExpanded()),
  loadAvailableCoordinators: (payload) => dispatch(loadAvailableCoordinators(payload)),
  openQrCodeDialog: () => dispatch(openQrCodeDialog()),
  closeQrCodeDialog: () => dispatch(closeQrCodeDialog()),
  setShowQrCodeSetting: (payload) => dispatch(setShowQrCodeSetting(payload)),
  getCompetencyReport: (payload) => dispatch(getCompetencyReport(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StudentCompetencyOverview));
