import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import { Breadcrumb } from '@progress/kendo-react-layout';
import { Text, ErrorMessage, WarningMessage, SuccessMessage } from '../common';
import { StackLayout, WrapLayout } from '../layouts';
import { elevation, respondTo } from '../shared';
import { SCREEN_SIZES as ScreenSizes } from '../../../resources/screenSize';
import { Button } from '@progress/kendo-react-buttons';
import { LoadingPanel } from '.';

const Container = styled.div`
  display: flex;
  align-self: stretch;
  justify-content: center;
  width: 100%;
  background-color: ${props => props.theme.gray10};
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: stretch;
  max-width: 1600px;
  min-width: 320px;
  position: relative;
`;

const SummaryInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  row-gap: 16px;
  
  ${respondTo(ScreenSizes.XS, css`
    padding: 8px 24px;
  `)}
`;

const HeaderActionsContainer = styled(StackLayout).attrs(() => ({
  orientation: 'horizontal',
  columnGap: '8px'
}))`
  flex-grow: 0;
  min-width: fit-content;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.white};
  ${elevation(0)}
  border-style: solid;
  border-width: 0 0 1px 0;
  border-color: ${props => props.theme.gray20};

  ${respondTo(ScreenSizes.XS, css`
    border-width: 0 1px 1px 1px;
  `)}
`;

const HeaderContainer = styled.div`
  background-color: ${props => props.theme.white};
  padding: 8px 0px;
  border: none;

  ${respondTo(ScreenSizes.XS, css`
    border-style: solid;
    border-width: 0 1px 0 1px;
    border-color: ${props => props.theme.gray20};
  `)}
`;

const Title = styled(Text).attrs(props => ({
  variant: 'h3',
  textColor: props.theme.gray80
}))`
  width: fit-content;
  cursor: ${props => props.expandableSummary ? 'pointer' : 'auto'}
`;

const Subtitle = styled(Text).attrs(props => ({
  variant: 'subtitle3',
  textColor: props.theme.gray70
}))``;

const TabsContainer = styled(StackLayout)`
  border-top: 1px solid ${props => props.theme.gray20};
  padding: 0 8px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
`;

const Header = styled(WrapLayout).attrs(props => ({
  rowGap: '8px',
  columnGap: '16px',
  orientation: 'horizontal'
}))`
  display: flex;
  justify-content: flex-end;
  margin: 0 16px 0 16px;

  ${respondTo(ScreenSizes.XS, css`
    margin: 0 16px 0 24px;
  `)}
`;

const BackButton = styled(Button).attrs(props => ({
}))`
  flex: 0 0 36px;
  width: 38px;
  height: 38px;
`;

const customDelimiter = () => {
  return <span className={'k-icon k-i-divide'} />;
};

const NavigationControlsPanel = styled(StackLayout).attrs(() => ({
  orientation: 'horizontal'
}))`
  display: flex;
  align-items: center;
  min-width: 325px;
`;

export default class OverviewPage extends PureComponent {
  render() {
    return (
      <Container id={this.props.id}>
        <InnerContainer>
          <HeaderContainer>
            <Header>
              <NavigationControlsPanel>
                {!this.props.hideBackButton &&
                  <BackButton
                    id='back-button'
                    type='button'
                    primary='false'
                    onClick={this.props.onBackClick}
                    icon='undo'
                  />
                }
                {this.props.breadcrumbItems?.length > 0 &&
                  <Breadcrumb
                    data={this.props.breadcrumbItems}
                    onItemSelect={this.props.onBreadcrumbItemClick}
                    breadcrumbDelimiter={customDelimiter}
                  />
                }
              </NavigationControlsPanel>
              {this.props.headerActions &&
                  <HeaderActionsContainer>
                    {this.props.headerActions}
                  </HeaderActionsContainer>
              }
            </Header>
          </HeaderContainer>
          <Main>
            <SummaryContainer>
              <SummaryInnerContainer>
                <StackLayout rowGap='4px'>
                  {this.props.title &&
                    <Title expandableSummary={this.props.expandableSummary} onClick={this.props.onToggleExpandSummary}>
                      {this.props.expandableSummary &&
                        <span className={this.props.summaryExpanded ? 'k-icon k-i-arrow-60-down' : 'k-icon k-i-arrow-60-right'}></span>
                      }
                      {this.props.title}
                    </Title>
                  }
                  {this.props.subtitle &&
                    <Subtitle>{this.props.subtitle}</Subtitle>
                  }
                  {(this.props.errorMessage ||
                    this.props.warningMessage ||
                    this.props.successMessage) &&
                    <StackLayout margin='8px 0 0' rowGap='8px'>
                      {this.props.errorMessage && <ErrorMessage>{this.props.errorMessage}</ErrorMessage>}
                      {this.props.warningMessage && <WarningMessage>{this.props.warningMessage}</WarningMessage>}
                      {this.props.successMessage && <SuccessMessage>{this.props.successMessage}</SuccessMessage>}
                    </StackLayout>
                  }
                </StackLayout>
                <StackLayout>
                  {((this.props.expandableSummary && this.props.summaryExpanded) || !this.props.expandableSummary) && this.props.summary}
                  {this.props.details &&
                    this.props.details
                  }
                </StackLayout>
              </SummaryInnerContainer>
              {this.props.tabs && <TabsContainer>{this.props.tabs}</TabsContainer>}
            </SummaryContainer>
            <ContentContainer>
              {this.props.children}
            </ContentContainer>
            {this.props.loading && <LoadingPanel/>}
          </Main>
        </InnerContainer>
      </Container>
    );
  }
}