import axios from 'axios';
import StudyProgramFormPageActionTypes from './study-program-form-page-action-types';
import ValidationError from '../../../utils/validation-error';
import { StudyProgramFieldArrayNames } from '.';
import studyProgramFieldArrayNames from '../actions/study-program-field-array-names';

export const loadStudyProgramFormPage = ({ studyProgramId }) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudyProgramFormPageActionTypes.LOAD_STUDY_PROGRAM_FORM_PAGE_START });
  const dataConsumers = [];
  const dataArrayHandler = (array, fieldArrayName) => array.map((item, index) => {
    dataConsumers.push({ index, fieldArrayName });
    return {
      id: item.userId,
      text: item.name
    };
  });
  try {
    if (studyProgramId > 0) {
      const studyProgram = await api.get(`api/study_programs/${studyProgramId}`);
      let formattedCoordinators;

      if (studyProgram.coordinators?.length > 0) {
        formattedCoordinators = dataArrayHandler(studyProgram.coordinators, StudyProgramFieldArrayNames.COORDINATORS);
      } else {
        dataConsumers.push({ index: 0, fieldArrayName: StudyProgramFieldArrayNames.COORDINATORS });
        formattedCoordinators = [{}];
      }

      dispatch({
        type: StudyProgramFormPageActionTypes.LOAD_STUDY_PROGRAM_FORM_PAGE_END,
        payload: {
          studyProgram: {
            ...studyProgram,
            validFrom: studyProgram.validFrom ? new Date(studyProgram.validFrom) : null,
            validTo: studyProgram.validTo ? new Date(studyProgram.validTo) : null,
            coordinators: formattedCoordinators,
            administrators: studyProgram.administrators && dataArrayHandler(studyProgram.administrators, StudyProgramFieldArrayNames.ADMINISTRATORS),
            committeeMembers: studyProgram.committeeMembers && dataArrayHandler(studyProgram.committeeMembers, StudyProgramFieldArrayNames.COMMITTEE_MEMBERS),
            division: studyProgram.divisionId ? { id: studyProgram.divisionId, name: studyProgram.divisionName } : null,
            faculty: studyProgram.facultyDivisionId ? { id: studyProgram.facultyDivisionId, name: studyProgram.facultyDivisionName } : null
          }
        }
      });
    } else {
      dataConsumers.push({ index: 0, fieldArrayName: StudyProgramFieldArrayNames.COORDINATORS });
      dispatch({
        type: StudyProgramFormPageActionTypes.LOAD_STUDY_PROGRAM_FORM_PAGE_END,
        payload: {
          studyProgram: {
            coordinators: [{}]
          }
        }
      });
    }
    dispatch(loadInputData({ dataConsumers }));
  } catch (error) {
    dispatch({ type: StudyProgramFormPageActionTypes.LOAD_STUDY_PROGRAM_FORM_PAGE_END });
    throw error;
  }
};

export const loadAvailableCoordinators = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().studyProgramFormPage;
  let coordinators = [...state.coordinators];
  coordinators[payload.index] = {
    loading: true,
    availableCoordinators: coordinators[payload.index]?.availableCoordinators ?? []
  };
  dispatch({
    type: StudyProgramFormPageActionTypes.LOAD_STUDY_PROGRAM_AVAILABLE_COORDINATORS_START,
    payload: { coordinators }
  });
  try {
    const cancellationTokenKey = `${studyProgramFieldArrayNames.COORDINATORS}[${payload.index}]`;
    state.cancellationTokenSources.get(cancellationTokenKey)?.cancel();
    state.cancellationTokenSources.set(cancellationTokenKey, axios.CancelToken.source());
    const users = await api.get(`api/users_search`, {
      keyword: payload.keyword,
      showOnlyEmployees: true,
      showOnlyActive: true
    }, state.cancellationTokenSources.get(cancellationTokenKey).token);
    coordinators = [...getState().studyProgramFormPage.coordinators];
    coordinators[payload.index] = {
      loading: false,
      availableCoordinators: users?.data ? users.data.map(user => ({ id: user.id, text: `${user.name} ${user.surname}` })) : []
    };
    dispatch({
      type: StudyProgramFormPageActionTypes.LOAD_STUDY_PROGRAM_AVAILABLE_COORDINATORS_END,
      payload: { coordinators }
    });
  } catch (error) {
    dispatch({ type: StudyProgramFormPageActionTypes.LOAD_STUDY_PROGRAM_AVAILABLE_COORDINATORS_END });
  }
};

export const loadAvailableAdministrators = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().studyProgramFormPage;
  let administrators = [...state.administrators];
  administrators[payload.index] = {
    loading: true,
    availableAdministrators: administrators[payload.index]?.availableAdministrators ?? []
  };
  dispatch({
    type: StudyProgramFormPageActionTypes.LOAD_STUDY_PROGRAM_AVAILABLE_ADMINISTRATORS_START,
    payload: { administrators }
  });
  try {
    const cancellationTokenKey = `${studyProgramFieldArrayNames.ADMINISTRATORS}[${payload.index}]`;
    state.cancellationTokenSources.get(cancellationTokenKey)?.cancel();
    state.cancellationTokenSources.set(cancellationTokenKey, axios.CancelToken.source());
    const users = await api.get(`api/users_search`, {
      keyword: payload.keyword,
      showOnlyEmployees: true,
      showOnlyActive: true
    }, state.cancellationTokenSources.get(cancellationTokenKey).token);
    administrators = [...getState().studyProgramFormPage.administrators];
    administrators[payload.index] = {
      loading: false,
      availableAdministrators: users ? users.data?.map(user => ({ id: user.id, text: `${user.name} ${user.surname}` })) : []
    };
    dispatch({
      type: StudyProgramFormPageActionTypes.LOAD_STUDY_PROGRAM_AVAILABLE_ADMINISTRATORS_END,
      payload: { administrators }
    });
  } catch (error) {
    dispatch({ type: StudyProgramFormPageActionTypes.LOAD_STUDY_PROGRAM_AVAILABLE_ADMINISTRATORS_END });
  }
};

export const loadAvailableCommitteeMembers = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().studyProgramFormPage;
  let committeeMembers = [...state.committeeMembers];
  committeeMembers[payload.index] = {
    loading: true,
    availableCommitteeMembers: committeeMembers[payload.index]?.availableCommitteeMembers ?? []
  };
  dispatch({
    type: StudyProgramFormPageActionTypes.LOAD_STUDY_PROGRAM_AVAILABLE_COMMITTEE_MEMBERS_START,
    payload: { committeeMembers }
  });
  try {
    const cancellationTokenKey = `${studyProgramFieldArrayNames.COMMITTEE_MEMBERS}[${payload.index}]`;
    state.cancellationTokenSources.get(cancellationTokenKey)?.cancel();
    state.cancellationTokenSources.set(cancellationTokenKey, axios.CancelToken.source());
    const users = await api.get(`api/users_search`, {
      keyword: payload.keyword,
      showOnlyActive: true,
      type: ['Mentor', 'Manager']
    }, state.cancellationTokenSources.get(cancellationTokenKey).token);
    committeeMembers = [...getState().studyProgramFormPage.committeeMembers];
    committeeMembers[payload.index] = {
      loading: false,
      availableCommitteeMembers: users ? users.data?.map(user => ({ id: user.id, text: `${user.name} ${user.surname}` })) : []
    };
    dispatch({
      type: StudyProgramFormPageActionTypes.LOAD_STUDY_PROGRAM_AVAILABLE_COMMITTEE_MEMBERS_END,
      payload: { committeeMembers }
    });
  } catch (error) {
    dispatch({ type: StudyProgramFormPageActionTypes.LOAD_STUDY_PROGRAM_AVAILABLE_COMMITTEE_MEMBERS_END });
  }
};

export const saveStudyProgram = (payload) => async(dispatch, getState, { api, history }) => {
  dispatch({ type: StudyProgramFormPageActionTypes.LOAD_STUDY_PROGRAM_FORM_PAGE_START });
  const state = getState().studyProgramFormPage;
  const apiDataArrayHandler = array => array?.filter(element => element.id > 0).map(element => ({ userId: element.id }));
  const apiModel = {
    ...payload,
    divisionId: payload.division?.id,
    facultyDivisionId: payload.faculty?.id,
    coordinators: apiDataArrayHandler(payload.coordinators),
    administrators: apiDataArrayHandler(payload.administrators),
    committeeMembers: apiDataArrayHandler(payload.committeeMembers)
  };
  let id = payload?.id;
  try {
    if (id > 0) {
      await api.put(`api/study_programs/${id}`, apiModel);
    } else {
      id = await api.post('api/study_programs', apiModel);
    }
    dispatch({
      type: StudyProgramFormPageActionTypes.LOAD_STUDY_PROGRAM_FORM_PAGE_END,
      payload: {
        studyProgram: {
          ...state.studyProgram,
          id: id
        }
      }
    });
    history.back();
  } catch (error) {
    let validationResult = null;
    if (error instanceof ValidationError) {
      validationResult = {
        errorMessage: error.message,
        errors: error.errors
      };
    }
    dispatch({
      type: StudyProgramFormPageActionTypes.LOAD_STUDY_PROGRAM_FORM_PAGE_END,
      payload: {
        validationResult,
        studyProgram: state.studyProgram
      }
    });
  }
};

export const cancelStudyProgramFormPage = () => async(dispatch, getState) => {
  const state = getState().studyProgramFormPage;
  state.availableCoordinatorsCancelToken?.cancel();
  state.cancellationTokenSources?.forEach(token => {
    token?.cancel();
  });
};

export const loadInputData = (payload) => async(dispatch) => {
  dispatch(loadAvailableDivisions());
  dispatch(loadAvailableFaculties());
  if (payload?.dataConsumers?.length > 0) {
    payload.dataConsumers.forEach(element => {
      const { index, fieldArrayName } = element;
      if (index >= 0) {
        switch (fieldArrayName) {
          case StudyProgramFieldArrayNames.COORDINATORS:
            dispatch(loadAvailableCoordinators({ index }));
            break;
          case StudyProgramFieldArrayNames.ADMINISTRATORS:
            dispatch(loadAvailableAdministrators({ index }));
            break;
          case StudyProgramFieldArrayNames.COMMITTEE_MEMBERS:
            dispatch(loadAvailableCommitteeMembers({ index }));
            break;
          default:
            break;
        }
      }
    });
  }
};

export const loadAvailableDivisions = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudyProgramFormPageActionTypes.LOAD_STUDY_PROGRAM_AVAILABLE_DIVISIONS_START });
  try {
    const state = getState().studyProgramFormPage;
    state.availableDivisionsCancelToken?.cancel();
    state.availableDivisionsCancelToken = axios.CancelToken.source();
    const query = {
      skip: 0,
      limit: 50,
      keyword: payload?.keyword
    };
    const divisions = await api.get(`api/divisions`, query, state.availableDivisionsCancelToken.token);
    dispatch({
      type: StudyProgramFormPageActionTypes.LOAD_STUDY_PROGRAM_AVAILABLE_DIVISIONS_END,
      payload: { divisions }
    });
  } catch (error) {
    dispatch({
      type: StudyProgramFormPageActionTypes.LOAD_STUDY_PROGRAM_AVAILABLE_DIVISIONS_END
    });
    throw error;
  }
};

export const loadAvailableFaculties = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudyProgramFormPageActionTypes.LOAD_STUDY_PROGRAM_AVAILABLE_FACULTIES_START });
  try {
    const state = getState().studyProgramFormPage;
    state.availableFacultiesCancelToken?.cancel();
    state.availableFacultiesCancelToken = axios.CancelToken.source();
    const query = {
      skip: 0,
      limit: 50,
      keyword: payload?.keyword,
      showFacultiesOnly: true
    };
    const faculties = await api.get(`api/divisions`, query, state.availableFacultiesCancelToken.token);
    dispatch({
      type: StudyProgramFormPageActionTypes.LOAD_STUDY_PROGRAM_AVAILABLE_FACULTIES_END,
      payload: { faculties: faculties ?? [] }
    });
  } catch (error) {
    dispatch({
      type: StudyProgramFormPageActionTypes.LOAD_STUDY_PROGRAM_AVAILABLE_FACULTIES_END
    });
    throw error;
  }
};