import ComptetencySurveyListActionTypes from '../actions/competency-survey-list-action-types';

const initialState = {
  loading: false,
  competencySurveys: null,
  competencySurveysCancelToken: null,

  isConfirmDialogVisible: false,
  selectedCompetencySurveyId: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ComptetencySurveyListActionTypes.LOAD_COMPETENCY_SURVEY_LIST_START:
      return {
        ...state,
        loading: true
      };
    case ComptetencySurveyListActionTypes.LOAD_COMPETENCY_SURVEY_LIST_END:
      return {
        ...state,
        loading: false,
        competencySurveys: action.payload.competencySurveys
      };
    case ComptetencySurveyListActionTypes.TOGGLE_COMPETENCY_SURVEY_LIST_CONFIRM_DIALOG:
      return {
        ...state,
        selectedCompetencySurveyId: action.payload?.id,
        isConfirmDialogVisible: !state.isConfirmDialogVisible
      };
    default:
      return state;
  }
};