import UserOverviewPageActionTypes from '../actions/user-overview-page-action-types';

const initialState = {
  loading: false,
  user: null,
  userCancelToken: null,
  selectedTab: 0,
  isConfirmDialogVisible: false,
  isConfirmImpersonateDialogVisible: false,
  selectedUserId: null,
  selectedImpersonateUserId: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UserOverviewPageActionTypes.LOAD_USER_OVERVIEW_START:
      return {
        ...state,
        loading: true
      };
    case UserOverviewPageActionTypes.LOAD_USER_OVERVIEW_END:
      return {
        ...state,
        loading: false,
        user: action.payload?.user
      };
    case UserOverviewPageActionTypes.SELECT_USER_OVERVIEW_PAGE_TAB:
      return {
        ...state,
        selectedTab: action.payload?.selected
      };
    case UserOverviewPageActionTypes.CHANGE_USER_STATUS_START:
      return state;
    case UserOverviewPageActionTypes.CHANGE_USER_STATUS_END:
      return state;
    case UserOverviewPageActionTypes.TOGGLE_USER_OVERVIEW_CONFIRM_DIALOG:
      return {
        ...state,
        selectedUserId: action.payload,
        isConfirmDialogVisible: !state.isConfirmDialogVisible
      };
    case UserOverviewPageActionTypes.OPEN_USER_IMPERSONATE_CONFIRM_DIALOG:
      return {
        ...state,
        selectedImpersonateUserId: action.payload?.userId,
        isConfirmImpersonateDialogVisible: true
      };
    case UserOverviewPageActionTypes.CLOSE_USER_IMPERSONATE_CONFIRM_DIALOG:
      return {
        ...state,
        isConfirmImpersonateDialogVisible: false
      };
    case UserOverviewPageActionTypes.CLEANUP_USER_OVERVIEW:
      return initialState;
    default:
      return state;
  }
};