export const config = {
  appId: '55d285ff-2785-459b-a1e0-565623ddb0b6',
  redirectUri: getRedirectUri(),
  scopes: [
    'https://rp.lsmu.lt/Read'
  ],
  authority: 'https://login.microsoftonline.com/0d432dbb-deaf-42f5-afde-82d6878fdfff'
};

function getRedirectUri() {
  return `${location.protocol}//${location.host}`;
};