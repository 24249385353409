import * as React from 'react';
import { connect } from 'react-redux';
import { Field } from '@progress/kendo-react-form';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { FilterForm, TextBox, WrapLayout, StackLayout } from '../../../ui';
import { filterStudentCompetencies, clearStudentCompetenciesFilter } from '../../shared';

class MyCompetencyListFilter extends React.PureComponent {

  render() {
    const {
      filter,
      clearStudentCompetenciesFilter,
      filterStudentCompetencies
    } = this.props;
    const localizationService = provideLocalizationService(this);
    return (
      <FilterForm
        initialValues={filter}
        onSubmit={(payload) => filterStudentCompetencies(payload, { studentId: this.props.currentStudies.studentId })}
        onClear={() => clearStudentCompetenciesFilter({ studentId: this.props.currentStudies.studentId })}
        localizationService={localizationService}
        title={localizationService.toLanguageString('grid.filterTitle')}
        uniqueKey={JSON.stringify(filter)}
        render={() => (
          <WrapLayout>
            <StackLayout width='340px'>
              <Field
                name='keyword'
                width='340px'
                component={TextBox}
                label={localizationService.toLanguageString('custom.search')}
              />
            </StackLayout>
          </WrapLayout>
        )}
      />
    );
  }
}

const mapStateToProps = state => ({
  filter: state.studentCompetencyList.filter,
  currentStudies: state.app.currentStudies
});

const mapDispatchToProps = dispatch => ({
  filterStudentCompetencies: (payload, params) => dispatch(filterStudentCompetencies(payload, params)),
  clearStudentCompetenciesFilter: (params) => dispatch(clearStudentCompetenciesFilter(params))
});

registerForLocalization(MyCompetencyListFilter);

export default connect(mapStateToProps, mapDispatchToProps)(MyCompetencyListFilter);