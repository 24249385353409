import { provideIntlService, provideLocalizationService, registerForIntl, registerForLocalization } from '@progress/kendo-react-intl';
import * as React from 'react';
import styled from 'styled-components';
import { Button } from '@progress/kendo-react-buttons';
import { Badge, BadgeContainer } from '@progress/kendo-react-indicators';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Colors, TextStyles } from '../../../../ui';
import { STUDY_PLAN_CYCLE_STUDENT_APPROVING_USER_STATUS } from '../../../../resources/studyPlanCycleStudentApprovingUserStatus';
import { getStudyPlanCycleStudentApprovingUserStatusColorCode } from '../../../../utils/studyPlanCycleStudentApprovingUserStatusColorCodes';
import { DateFormatter } from '../../components';

const CaseContainer = styled.div`
  background-color: ${Colors.WHITE};
`;

const CaseTitleContainer = styled.div`
  display: flex;
  text-align: left;
  padding: 8px;
  border: 1px solid ${Colors.GRAY_20};
  border-bottom-width: ${props => props.showBottomBorder ? '1px' : '0'};
`;

const CaseTitleTextContainer = styled.div`
  width: 100%;
  padding-top: 8px;
`;

const CaseButtons = styled.div`
  width: 140px;
  min-width: 140px;
  text-align: right;
`;

const CaseNumbers = styled.span`
  padding-right: 8px;
  vertical-align: middle;
`;

const CompletedCaseNumbers = styled.span`
  color: ${props => props.isCompleted ? Colors.BLACK : Colors.ERROR_40}
`;

const DiagnosisContainer = styled.div`
  border: 1px solid ${Colors.GRAY_20};
  border-top-width: 0;
  padding: 8px;
`;

const CaseDiagnosisText = styled.span`
  ${TextStyles.caption}
  color: ${Colors.GRAY_50};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const CaseHistoryContainer = styled.div`
  width: 100%;
`;

const CaseHistoryRow = styled.div`
  text-align: left;
  border: 1px solid ${Colors.GRAY_20};
  border-top-width: ${props => props.showTopBorder ? '1px' : '0'};
  display: flex;
`;

const CaseHistoryTextContainer = styled.div`
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const CenteredTextContainer = styled(CaseHistoryTextContainer)`
  text-align: center;
`;

const CaseHistoryText = styled.span`
  ${TextStyles.caption}
  color: ${Colors.GRAY_50};
  padding-left: 8px;
`;

const CaseHistoryButtonGroup = styled.div`
  width: 88px;
  min-width: 76px;
  height: 36px;
`;

const AddButton = styled(Button)`
  width: 32px;
  height: 32px;
`;

const RemoveButton = styled(AddButton)`
  margin-left: 8px;
`;

const CommandButton = styled(Button).attrs({
  fillMode: 'solid'
})`
  margin: 2px;
  box-shadow: none;
  width: 32px;
  height: 32px;
  background-color: ${props => props.theme.gray15};
`;

const AdditionalCaseText = styled.p`
  ${TextStyles.caption}
  color: ${Colors.GRAY_50};
  padding: 4px 0 0 20px;
  margin: 0;
`;

const BadgeIndicator = styled(Badge).attrs({
  size: 'small'
})`
  z-index: 0;
`;

const EllipsisContainer = styled.div`
  cursor: pointer;
`;

const StatusDisplay = styled.div`
  margin-right: 6px;
  width: 6px;
  background: ${props => props.statusColor};
`;

const DiagnosisCode = styled.span`
  margin: 0 2px;
`;

export default class MyStudiesStudentCycleCaseItem extends React.PureComponent {

  constructor() {
    super();
    this.handleExpandChange = this.handleExpandChange.bind(this);
    this.getColor = this.getColor.bind(this);
    this.getTitle = this.getTitle.bind(this);
    this.state = {
      isTruncated: true
    };
  }

  render() {
    const localizationService = provideLocalizationService(this);
    const intlService = provideIntlService(this);
    const caseCountForApproval = this.props.history?.reduce((accumulator, historyCase) => historyCase.managerUserId === this.props.userId && historyCase.approvalStatus === STUDY_PLAN_CYCLE_STUDENT_APPROVING_USER_STATUS.SUBMITTED ? ++accumulator : accumulator, 0) ?? 0;

    return (
      <>
        <CaseContainer>
          <CaseTitleContainer onClick={this.props.onCaseExpand} showBottomBorder={!this.props.diagnoses && !this.props.isExpanded}>
            <CaseTitleTextContainer>
              <div>
                <span className={this.props.isExpanded ? 'k-icon k-i-arrow-60-down' : 'k-icon k-i-arrow-60-right'}></span> {this.props.name}
              </div>
              {this.props.isAdditional &&
                <AdditionalCaseText>{this.props.cycleName}</AdditionalCaseText>
              }
            </CaseTitleTextContainer>
            <CaseButtons>
              <BadgeContainer>
                <CaseNumbers>
                  <CompletedCaseNumbers isCompleted={!this.props.readOnly || this.props.studentCases + this.props.newCases >= this.props.required}>{this.props.studentCases + this.props.newCases}</CompletedCaseNumbers>/{this.props.required}
                  {this.props.readOnly && caseCountForApproval > 0 && <BadgeIndicator>{caseCountForApproval}</BadgeIndicator>}
                </CaseNumbers>
              </BadgeContainer>
              {!this.props.readOnly &&
                <AddButton onClick={(e) => { e.stopPropagation(); this.props.onIncrease(); }}>
                  <span className='k-icon k-i-plus'></span>
                  {this.props.newCases > 0 && <BadgeIndicator>+{this.props.newCases}</BadgeIndicator>}
                </AddButton>
              }
              {!this.props.readOnly && this.props.isAdditional &&
                <RemoveButton onClick={(e) => { e.stopPropagation(); this.props.onCaseDelete(); }}>
                  <span className='k-icon k-i-trash'></span>
                </RemoveButton>
              }
            </CaseButtons>
          </CaseTitleContainer>
          <CaseHistoryContainer hidden={!this.props.isExpanded}>
            {(!this.props.history || this.props.history.length === 0) &&
              <CaseHistoryRow showTopBorder={true}>
                <CenteredTextContainer>
                  <CaseHistoryText>
                    {localizationService.toLanguageString('grid.noRecords')}
                  </CaseHistoryText>
                </CenteredTextContainer>
              </CaseHistoryRow>
            }
            {this.props.history?.map((historyCase, historyCaseIndex) => (
              <CaseHistoryRow key={historyCase.id} showTopBorder={historyCaseIndex === 0}>
                <StatusDisplay statusColor={this.getColor(historyCase)} title={this.getTitle(historyCase)} />
                <CaseHistoryTextContainer>
                  <CaseHistoryText>
                    {historyCase.managerUserFullName} {DateFormatter(historyCase.date, intlService)} ({historyCase.medicalHistoryNumber})
                  </CaseHistoryText>
                </CaseHistoryTextContainer>
                {!this.props.readOnly &&
                  <CaseHistoryButtonGroup>
                    <CommandButton onClick={() => this.props.onEditDialogOpen(historyCaseIndex)}>
                      <span className='k-icon k-i-pencil'></span>
                    </CommandButton>
                    <CommandButton onClick={() => this.props.onDeleteDialogOpen(historyCaseIndex)}>
                      <span className='k-icon k-i-trash'></span>
                    </CommandButton>
                  </CaseHistoryButtonGroup>
                }
              </CaseHistoryRow>
            ))}
          </CaseHistoryContainer>
          <DiagnosisContainer ref={this.textContainerRef} isTruncated={this.state.isTruncated}>
            <CaseDiagnosisText>
              {this.props.diagnosesArray?.slice(0, this.state.isTruncated ? 20 : undefined).map((diagnosis, index) => (
                <div key={index}>
                  <Tooltip anchorElement='pointer' position='top'>
                    <DiagnosisCode title={diagnosis.name}>
                      {diagnosis.code}
                      {index < this.props.diagnosesArray.length - 1 && ','}
                    </DiagnosisCode>
                  </Tooltip>
                </div>
              ))}
              {this.props.diagnosesArray?.length > 20 &&
                <EllipsisContainer onClick={() => this.setState({ isTruncated: !this.state.isTruncated })}>...</EllipsisContainer>
              }
            </CaseDiagnosisText>
          </DiagnosisContainer>
        </CaseContainer>
      </>
    );
  }
  handleExpandChange() {
    this.setState({ isTruncated: !this.state.isTruncated });
  }

  getColor(cycleCase) {
    if (this.props.cycleManagerOverview || this.props.userId === cycleCase.managerUserId) {

      if (cycleCase.approvedOn) {
        return getStudyPlanCycleStudentApprovingUserStatusColorCode(STUDY_PLAN_CYCLE_STUDENT_APPROVING_USER_STATUS.APPROVED);
      }

      return getStudyPlanCycleStudentApprovingUserStatusColorCode(cycleCase.approvalStatus);
    }

    return null;
  }

  getTitle(cycleCase) {
    if (this.props.cycleManagerOverview || this.props.userId === cycleCase.managerUserId) {
      const localizationService = provideLocalizationService(this);
      if (cycleCase.approvedOn) {
        return localizationService.toLanguageString(`studyPlanCycleStudentApprovingUserStatus.${STUDY_PLAN_CYCLE_STUDENT_APPROVING_USER_STATUS.APPROVED}`);
      }
      if (cycleCase.approvalStatus) {
        return localizationService.toLanguageString(`studyPlanCycleStudentApprovingUserStatus.${cycleCase.approvalStatus}`);
      }
    }

    return null;
  }
}

registerForLocalization(MyStudiesStudentCycleCaseItem);
registerForIntl(MyStudiesStudentCycleCaseItem);