import * as React from 'react';
import { connect } from 'react-redux';
import { provideLocalizationService, registerForLocalization, provideIntlService, registerForIntl } from '@progress/kendo-react-intl';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { Skeleton } from '@progress/kendo-react-indicators';
import { OverviewPage, StackLayout } from '../../../ui';
import { ValueItem, DateFormatter, TimeFormatter } from '../../shared/components';
import { loadTheoreticalPartOverviewPage, selectTheoreticalPartOverviewPageTab, clearTheoreticalPartOverviewPage } from '../actions/theoretical-part-overview-page-actions';
import { TheoreticalPartStudentList } from '.';
import { withRouter } from '../../../components/withRouter';
import styled from 'styled-components';

const Link = styled.a`
  overflow-wrap: anywhere;
`;

class TheoreticalPartOverviewPage extends React.PureComponent {

  constructor() {
    super();
    this.handleBreadcrumbItemClick = this.handleBreadcrumbItemClick.bind(this);
    this.handleTabSelect = this.handleTabSelect.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
  }

  async componentDidMount() {
    if (this.props.params?.theoreticalPartId) {
      await this.props.loadTheoreticalPartOverviewPage({
        theoreticalPartId: this.props.params.theoreticalPartId
      });
    }
  }

  componentWillUnmount() {
    this.props.clearTheoreticalPartOverviewPage();
  }

  render() {
    const { theoreticalPart, loading } = this.props;
    const localizationService = provideLocalizationService(this);
    const intlService = provideIntlService(this);
    return (
      <OverviewPage
        title={loading
          ? <Skeleton shape='text' style={{ width: 300, height: 28 }}/>
          : `${theoreticalPart?.studyProgramTheoreticalPartName}`
        }
        breadcrumbItems={[
          {
            id: 'theoretical-parts',
            text: localizationService.toLanguageString('theoreticalPart.theoreticalParts')
          },
          {
            id: 'theoretical-part',
            text: localizationService.toLanguageString('theoreticalPart.theoreticalPart'),
            disabled: true
          }
        ]}
        onBreadcrumbItemClick={this.handleBreadcrumbItemClick}
        onBackClick={this.handleBackClick}
        summary={
          <StackLayout rowGap='8px'>
            <ValueItem
              label={localizationService.toLanguageString('theoreticalPart.plannedDate')}
              labelWidth='170px'
              loading={loading}
            >
              {DateFormatter(theoreticalPart?.plannedDate, intlService)}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('theoreticalPart.plannedTime')}
              labelWidth='170px'
              loading={loading}
            >
              {TimeFormatter(theoreticalPart?.plannedTime, intlService)}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('theoreticalPart.lecturer')}
              labelWidth='170px'
              loading={loading}
            >
              {theoreticalPart?.fullName}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('theoreticalPart.link')}
              labelWidth='170px'
              loading={loading}
            >
              <Link href={theoreticalPart?.link} target='_blank' rel='noreferrer'>{theoreticalPart?.link}</Link>
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('theoreticalPart.location')}
              labelWidth='170px'
              loading={loading}
            >
              {theoreticalPart?.location}
            </ValueItem>
          </StackLayout>
        }
        tabs={
          <TabStrip selected={this.props.selectedTab} onSelect={this.handleTabSelect}>
            <TabStripTab title={localizationService.toLanguageString('theoreticalPart.tabstripStudentsTitle')}/>
          </TabStrip>
        }
      >
        {this.props.selectedTab === 0 && this.props.theoreticalPart &&
          <TheoreticalPartStudentList
            theoreticalPartId={this.props.theoreticalPart?.id}
            studyPlanId={this.props.theoreticalPart?.studyPlanId}
            studyPlanCycleId={this.props.theoreticalPart?.studyPlanCycleId}
          />
        }
      </OverviewPage>
    );
  }

  handleTabSelect(e) {
    this.props.selectTheoreticalPartOverviewPageTab({
      selected: e.selected
    });
  }

  handleBreadcrumbItemClick(e) {
    if (e.id === 'theoretical-parts') {
      this.props.navigate(`/theoretical_parts`);
    }
  }

  handleBackClick() {
    this.props.navigate(-1);
  }
}

registerForLocalization(TheoreticalPartOverviewPage);
registerForIntl(TheoreticalPartOverviewPage);

const mapStateToProps = state => ({
  theoreticalPart: state.theoreticalPartOverviewPage.theoreticalPart,
  loading: state.theoreticalPartOverviewPage.loading,
  selectedTab: state.theoreticalPartOverviewPage.selectedTab
});

const mapDispatchToProps = dispatch => ({
  loadTheoreticalPartOverviewPage: (payload) => dispatch(loadTheoreticalPartOverviewPage(payload)),
  selectTheoreticalPartOverviewPageTab: (payload) => dispatch(selectTheoreticalPartOverviewPageTab(payload)),
  clearTheoreticalPartOverviewPage: () => dispatch(clearTheoreticalPartOverviewPage())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TheoreticalPartOverviewPage));
