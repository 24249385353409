import { USER_STATUS } from '../resources/userStatus';

export const getUserStatusColor = (status) => {
  switch (status) {
    case USER_STATUS.ACTIVE:
      return 'success';
    case USER_STATUS.DEACTIVATED:
      return 'error';
    case USER_STATUS.UNAPPROVED:
      return 'warning';
    default:
      return 'none';
  }
};