import React from 'react';
import styled from 'styled-components';

const DisplayItem = styled.div`
  padding-bottom: 8px;
  display: block;
  width:100%;
`;

const DisplayLabel = styled.span`
  &:after {
    content: ':';
  }
`;

const DisplayValue = styled.span`
  padding-left: 8px;
  text-align: left;
  font-weight: 700;
`;

const DisplayField = ({ label, value, input }) => {
  return (
    <fieldset className={'k-form-fieldset'}>
      <DisplayItem>
        <DisplayLabel>{label}</DisplayLabel>
        <DisplayValue>{value ?? input.value}</DisplayValue>
      </DisplayItem>
    </fieldset>
  );
};

export default DisplayField;
