import * as React from 'react';
import { StudentCompetencySurveyOverview } from '../../shared/student-competency';
import { withRouter } from '../../../components/withRouter';
import { connect } from 'react-redux';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';

class StudentCompetencySurveyOverviewPage extends React.PureComponent {
  render() {
    const localizationService = provideLocalizationService(this);
    return (
      <StudentCompetencySurveyOverview
        preview
        studentId={this.props.params?.studentId}
        breadcrumbItems={[
          {
            id: 'students',
            text: localizationService.toLanguageString('student.residents')
          },
          {
            id: 'student',
            text: localizationService.toLanguageString('student.resident')
          },
          {
            id: 'studentCompetency',
            text: localizationService.toLanguageString('studentCompetency.tieredCompetency')
          },
          {
            id: 'studentCompetencySurvey',
            text: localizationService.toLanguageString('studentCompetency.surveys'),
            disabled: true
          }
        ]}
      />
    );
  }
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
});

registerForLocalization(StudentCompetencySurveyOverviewPage);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StudentCompetencySurveyOverviewPage));