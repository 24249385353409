import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../../components/withRouter';
import { StudentCompetencyCycleList } from '../../shared/student-competency';

class MyCompetencyCycleList extends React.Component {

  render() {
    return (
      <StudentCompetencyCycleList/>
    );
  }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MyCompetencyCycleList));
