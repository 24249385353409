import { StudyPlanCycleStudentListActionTypes } from '..';

const initialState = {
  loading: false,
  studyPlanCycleStudents: [],
  studyPlanCycleManagers: [],
  studyPlanCycleStudentsCancelToken: null,
  isConfirmDialogVisible: false,
  isCycleManagerDialogVisible: false,
  selectedStudyPlanCycleStudentId: null,
  selectedAll: false,
  selectedIds: null,
  selectedStudents: null,
  validationResult: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case StudyPlanCycleStudentListActionTypes.LOAD_STUDY_PLAN_CYCLE_STUDENT_LIST_START:
      return {
        ...state,
        loading: true,
        validationResult: null
      };
    case StudyPlanCycleStudentListActionTypes.LOAD_STUDY_PLAN_CYCLE_STUDENT_LIST_END:
      return {
        ...state,
        studyPlanCycleStudents: action.payload?.studyPlanCycleStudents ?? [],
        studyPlanCycleManagers: action.payload?.studyPlanCycleManagers ?? [],
        selectedIds: null,
        selectedStudents: null,
        selectedAll: false,
        loading: false
      };
    case StudyPlanCycleStudentListActionTypes.TOGGLE_STUDY_PLAN_CYCLE_STUDENT_LIST_CONFIRM_DIALOG:
      return {
        ...state,
        selectedStudyPlanCycleStudentId: action.payload?.id,
        isConfirmDialogVisible: !state.isConfirmDialogVisible
      };
    case StudyPlanCycleStudentListActionTypes.SELECT_STUDY_PLAN_CYCLE_STUDENT_LIST:
      return {
        ...state,
        studyPlanCycleStudents: action.payload?.studyPlanCycleStudents,
        selectedIds: action.payload?.selectedIds,
        selectedStudents: action.payload?.selectedStudents,
        selectedAll: action.payload?.selectedAll
      };
    case StudyPlanCycleStudentListActionTypes.SELECT_ALL_STUDY_PLAN_CYCLE_STUDENT_LIST:
      return {
        ...state,
        studyPlanCycleStudents: action.payload?.studyPlanCycleStudents,
        selectedIds: action.payload?.selectedIds,
        selectedStudents: action.payload?.selectedStudents,
        selectedAll: !state.selectedAll
      };
    case StudyPlanCycleStudentListActionTypes.OPEN_STUDY_PLAN_CYCLE_STUDENT_LIST_CHANGE_CYCLE_MANAGER_DIALOG:
      return {
        ...state,
        isCycleManagerDialogVisible: true
      };
    case StudyPlanCycleStudentListActionTypes.CLOSE_STUDY_PLAN_CYCLE_STUDENT_LIST_CHANGE_CYCLE_MANAGER_DIALOG:
      return {
        ...state,
        isCycleManagerDialogVisible: false
      };
    case StudyPlanCycleStudentListActionTypes.SAVE_STUDY_PLAN_CYCLE_STUDENT_LIST_CYCLE_MANAGER_START:
      return {
        ...state,
        loading: true
      };
    case StudyPlanCycleStudentListActionTypes.SAVE_STUDY_PLAN_CYCLE_STUDENT_LIST_CYCLE_MANAGER_END:
      return {
        ...state,
        loading: false,
        validationResult: action.payload?.validationResult,
        isCycleManagerDialogVisible: false
      };
    default:
      return state;
  }
};