import { pagingSettings } from '../../../../utils/GridPagingUtils';
import StudentCompetencyListActionTypes from '../actions/student-competency-list-action-types';

const initialState = {
  loading: false,
  studentCompetencies: null,
  studentCompetenciesCancelToken: null,
  skip: pagingSettings.paging.skip,
  take: pagingSettings.paging.take,
  total: 0,
  sorting: null,
  filter: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case StudentCompetencyListActionTypes.LOAD_STUDENT_COMPETENCIES_LIST_START:
      return {
        ...state,
        loading: true
      };
    case StudentCompetencyListActionTypes.LOAD_STUDENT_COMPETENCIES_LIST_END:
      return {
        ...state,
        loading: false,
        studentCompetencies: action.payload.studentCompetencies,
        total: action.payload.total,
        skip: action.payload.skip,
        take: action.payload.take
      };
    case StudentCompetencyListActionTypes.FILTER_STUDENT_COMPETENCIES_LIST:
      return {
        ...state,
        filter: action.payload,
        skip: initialState.skip,
        take: initialState.take
      };
    case StudentCompetencyListActionTypes.SORT_STUDENT_COMPETENCIES_LIST:
      return {
        ...state,
        sorting: action.payload
      };
    case StudentCompetencyListActionTypes.LOAD_STUDENT_COMPETENCIES_LIST_DETAILS:
      return {
        ...state,
        studentCompetencies: action.payload.studentCompetencies
      };
    case StudentCompetencyListActionTypes.CHANGE_STUDENT_COMPETENCIES_LIST_EXPAND_STATE:
      return {
        ...state,
        studentCompetencies: action.payload.studentCompetencies
      };
    default:
      return state;
  }
};