import { PublicClientApplication } from '@azure/msal-browser';
import { config } from './msalConfig';
import { loadFromSessionStorage, loadFromStorage, removeFromSessionStorage, removeFromStorage, saveToSessionStorage, saveToStorage } from './storageUtils';
import * as st from './storageTypes';
import api from './api';

const ACCESS_TOKEN_REFRESH_TIMEOUT = 30000;

export class AuthorizationServiceAzureAd {
  constructor() {
    this.publicClientApplication = new PublicClientApplication({
      auth: {
        clientId: config.appId,
        redirectUri: config.redirectUri,
        authority: config.authority
      },
      cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false
      }
    });
  }

  async redirect() {
    await this.publicClientApplication.loginRedirect({
      scopes: config.scopes
    });
  }

  async authorize() {
    const authenticationResult = await this.publicClientApplication.handleRedirectPromise();
    if (authenticationResult?.accessToken) {
      api.updateBearerAccessToken(authenticationResult.accessToken);
      saveToStorage(st.ACCESS_TOKEN_EXPIRES_ON, authenticationResult.expiresOn);
      saveToSessionStorage(st.ACCOUNT, authenticationResult.account);
    } else {
      await this.redirect();
    }
  }

  isAuthorized() {
    let isAuthorized = false;
    const expiresOn = loadFromStorage(st.ACCESS_TOKEN_EXPIRES_ON);

    if (expiresOn != null) {
      isAuthorized = this.getTimeStamp() < new Date(expiresOn).getTime();
    }

    return isAuthorized;
  }

  async logout() {
    removeFromStorage(st.ACCESS_TOKEN_EXPIRES_ON);
    const account = loadFromSessionStorage(st.ACCOUNT);
    await this.publicClientApplication.logoutRedirect({ account });
  }

  async refreshAccessToken() {
    const concurrencyErrorMessage = 'Another process started refreshing access token';
    if (this.isRefreshingAccessToken()) {
      await this.waitFor(() => !this.isRefreshingAccessToken());
      return;
    }
    const refreshStartedOn = this.getTimeStamp();
    saveToSessionStorage(st.ACCESS_TOKEN_REFRESH_STARTED_ON, refreshStartedOn);
    if (loadFromSessionStorage(st.ACCESS_TOKEN_REFRESH_STARTED_ON) !== refreshStartedOn) {
      throw new Error(concurrencyErrorMessage);
    }
    try {
      const account = loadFromSessionStorage(st.ACCOUNT);
      const tokenResponse = await this.publicClientApplication.acquireTokenSilent({ account, forceRefresh: true });
      api.updateBearerAccessToken(tokenResponse.accessToken);
      saveToStorage(st.ACCESS_TOKEN_EXPIRES_ON, tokenResponse.expiresOn);
    } finally {
      if (loadFromSessionStorage(st.ACCESS_TOKEN_REFRESH_STARTED_ON) === refreshStartedOn) {
        removeFromSessionStorage(st.ACCESS_TOKEN_REFRESH_STARTED_ON);
      }
    }
  }

  getTimeStamp() {
    return new Date().getTime();
  }

  isRefreshingAccessToken() {
    let isRefreshing = false;
    const refreshStartedOn = loadFromSessionStorage(st.ACCESS_TOKEN_REFRESH_STARTED_ON);
    if (refreshStartedOn) {
      const elapsed = this.getTimeStamp() - refreshStartedOn;
      isRefreshing = elapsed < ACCESS_TOKEN_REFRESH_TIMEOUT;
    }
    return isRefreshing;
  }

  async waitFor(condition) {
    while (!condition()) {
      await this.wait(100);
    }
  }

  wait(duration) {
    return new Promise(resolve => {
      setTimeout(resolve, duration);
    });
  }
}

export default AuthorizationServiceAzureAd;