
import { STUDY_PLAN_CYCLE_STUDENT_APPROVING_USER_STATUS } from '../resources/studyPlanCycleStudentApprovingUserStatus';

export const getStudyPlanCycleStudentApprovingUserStatusColor = (status) => {
  switch (status) {
    case STUDY_PLAN_CYCLE_STUDENT_APPROVING_USER_STATUS.UNAPPROVED:
      return 'inverse';
    case STUDY_PLAN_CYCLE_STUDENT_APPROVING_USER_STATUS.SUPPLEMENTED:
      return 'inverse';
    case STUDY_PLAN_CYCLE_STUDENT_APPROVING_USER_STATUS.SUBMITTED:
      return 'primary';
    case STUDY_PLAN_CYCLE_STUDENT_APPROVING_USER_STATUS.RETURNED:
      return 'secondary';
    case STUDY_PLAN_CYCLE_STUDENT_APPROVING_USER_STATUS.APPROVED:
      return 'success';
    default:
      return 'inverse';
  }
};