import MyStudiesStudentOverviewPageActionTypes from '../actions/my-studies-student-overview-page-action-types';

const initialState = {
  loading: false,
  student: null,
  studentCancelToken: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MyStudiesStudentOverviewPageActionTypes.LOAD_MY_STUDIES_STUDENT_OVERVIEW_PAGE_START:
      return {
        ...state,
        loading: true
      };
    case MyStudiesStudentOverviewPageActionTypes.LOAD_MY_STUDIES_STUDENT_OVERVIEW_PAGE_END:
      return {
        ...state,
        loading: false,
        student: action.payload
      };
    default:
      return state;
  }
};