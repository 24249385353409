import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../../components/withRouter';
import MyNotificationsListPageItem from '../components/my-notifications-list-page-item';
import { loadMyNotificationsListPage, loadMyNotifications, updateNotificationRead } from '../actions';
import { Colors, ListviewPage } from '../../../ui';
import styled from 'styled-components';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';

const NoRecordsInfo = styled.div`
  padding: 16px;
`;

class MyNotificationsListPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleItemViewed = this.handleItemViewed.bind(this);
  }

  async componentDidMount() {
    await this.props.loadMyNotificationsListPage({ skip: 0, limit: 15 });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.props.location.key !== prevProps.location.key && this.props.unreadNotificationsCount > 0) {
      await this.props.loadMyNotificationsListPage({ skip: 0, limit: 15 });
    }
  }

  render() {
    const localizationService = provideLocalizationService(this);
    return this.props.notifications?.length > 0
      ? (
        <ListviewPage
          onScroll={this.handleScroll}
          data={this.props.notifications}
          item={(props) => <MyNotificationsListPageItem {...props} onItemViewed={this.handleItemViewed} />}
          loading={this.props.loading}
          style={{ backgroundColor: Colors.GRAY_10 }}
        />
      ) : (
        <NoRecordsInfo>
          {localizationService.toLanguageString('myNotifications.noRecords')}
        </NoRecordsInfo>
      );
  }

  handleScroll(e) {
    if (this.props.notifications?.length !== this.props.totalNotificationsCount && !this.props.loading) {
      const target = e.nativeEvent.target;
      if (target.scrollTop + 20 >= target.scrollHeight - target.clientHeight) {
        this.props.loadMyNotifications({ skip: this.props.notifications?.length ?? 0, limit: 15 });
      }
    }
  }

  handleItemViewed(payload) {
    this.props.updateNotificationRead(payload);
  }
}

const mapStateToProps = state => ({
  loading: state.myNotificationsListPage.loading,
  notifications: state.myNotificationsListPage.notifications,
  totalNotificationsCount: state.myNotificationsListPage.totalNotificationsCount,
  unreadNotificationsCount: state.app.requestsHubData?.unreadNotificationsCount
});

const mapDispatchToProps = dispatch => ({
  loadMyNotificationsListPage: (payload) => dispatch(loadMyNotificationsListPage(payload)),
  loadMyNotifications: (payload) => dispatch(loadMyNotifications(payload)),
  updateNotificationRead: (payload) => (dispatch(updateNotificationRead(payload)))
});

registerForLocalization(MyNotificationsListPage);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MyNotificationsListPage));