import { Skeleton } from '@progress/kendo-react-indicators';
import { registerForLocalization, provideLocalizationService, registerForIntl, provideIntlService } from '@progress/kendo-react-intl';
import { ExpansionPanel, ExpansionPanelContent } from '@progress/kendo-react-layout';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../../components/withRouter';
import { OverviewPage, StackLayout, Text } from '../../../ui';
import { getStudentCompetencyStatusColor } from '../../../utils/studentCompetencyStatusColors';
import { DateFormatter, EmptyFormatter, InlineBadge, ValueItem } from '../../shared/components';
import {
  loadCompetencyRequestSummaryPage,
  expandSurveyQuestion,
  clearCompetencyRequestSummaryPage,
  changeCompetencyStatus,
  openReturnCompetencyDialog,
  closeReturnCompetencyDialog,
  getCompetencyReport,
  openGrantConfirmDialog,
  closeGrantConfirmDialog,
  initSigning,
  downloadCertificate
} from '../actions';
import { Reveal } from '@progress/kendo-react-animation';
import { ListView } from '@progress/kendo-react-listview';
import colors from '../../../ui/components/shared/colors';
import styled from 'styled-components';
import { getStudentCycleStatusColor } from '../../../utils/studyPlanCycleDepartmentStudentStatusColors';
import { Button, SplitButton } from '@progress/kendo-react-buttons';
import { STUDENT_COMPETENCY_STATUS } from '../../../resources/studentCompetencyStatus';
import CompetencyRequestReturnForm from './competency-request-return-form';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

const SubListContainer = styled.div`
  margin-left: 16px;
`;

const Container = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: flex-end;
`;

const ListItem = styled.li`
  margin-bottom: 8px;
`;

const SurveyQuestionTitleContainer = styled.span`
  cursor: pointer;
`;

const OrderedList = styled.ol`
  margin: 0;
`;

const TableRow = styled.tr`
  line-height: normal;
`;

const Seperator = styled.div`
  height: 16px;
`;

const EvaluationText = styled(Text)`
  font-weight: 600;
`;

const GreyCaption = styled(Text).attrs({
  variant: 'caption',
  textColor: colors.GRAY_60
})``;

const HeaderButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  row-gap: 8px;
  column-gap: 8px;
`;

const ActionButton = styled(Button)`
  width: 128px;
`;

const BUTTON_IDS = {
  DENY: 'deny-button',
  RETURN: 'return-button'
};

class CompetencyRequestSummaryPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleBreadcrumbItemClick = this.handleBreadcrumbItemClick.bind(this);
    this.renderListItem = this.renderListItem.bind(this);
    this.renderSubListItem = this.renderSubListItem.bind(this);
    this.handleSurveyQuestionClick = this.handleSurveyQuestionClick.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleReturnCompetencyClick = this.handleReturnCompetencyClick.bind(this);
    this.handleGetCompetencyReportClick = this.handleGetCompetencyReportClick.bind(this);
    this.handleSplitButtonItemClick = this.handleSplitButtonItemClick.bind(this);
    this.handleDownloadCertificateClick = this.handleDownloadCertificateClick.bind(this);
    this.state = {
      expanded: null
    };
  }

  async componentDidMount() {
    if (this.props.params?.studentCompetencyId) {
      await this.props.loadCompetencyRequestSummaryPage({
        studentCompetencyId: this.props.params.studentCompetencyId
      });
    }
  }

  componentWillUnmount() {
    this.props.clearCompetencyRequestSummaryPage();
  }

  render() {
    const { loading, competencyRequest } = this.props;
    const localizationService = provideLocalizationService(this);
    return (
      <OverviewPage
        title={loading
          ? <Skeleton shape='text' style={{ width: 300, height: 28 }}/>
          : `${competencyRequest?.name}`
        }
        breadcrumbItems={[
          {
            id: 'competencyRequests',
            text: localizationService.toLanguageString('competencyRequest.competencyRequests')
          },
          {
            id: 'competencyRequest',
            text: localizationService.toLanguageString('competencyRequest.competencyRequest'),
            disabled: true
          }
        ]}
        headerActions={
          <>
            <HeaderButtonContainer>
              {(competencyRequest?.status === STUDENT_COMPETENCY_STATUS.TO_BE_APPROVED || competencyRequest?.status === STUDENT_COMPETENCY_STATUS.TO_BE_SIGNED) &&
                <SplitButton
                  themeColor={'primary'}
                  text={localizationService.toLanguageString('competencyRequest.sign')}
                  items={[
                    {
                      id: BUTTON_IDS.DENY,
                      text: localizationService.toLanguageString('competencyRequest.deny'),
                      disabled: competencyRequest?.status === STUDENT_COMPETENCY_STATUS.TO_BE_SIGNED
                    }, {
                      id: BUTTON_IDS.RETURN,
                      text: localizationService.toLanguageString('competencyRequest.return'),
                      disabled: competencyRequest?.status === STUDENT_COMPETENCY_STATUS.TO_BE_SIGNED
                    }
                  ]}
                  onButtonClick={this.props.openGrantConfirmDialog}
                  onItemClick={this.handleSplitButtonItemClick}
                />
              }
              {competencyRequest?.isSigned && competencyRequest?.studentCompetencyCertificateKey &&
                <ActionButton
                  type='button'
                  themeColor='primary'
                  onClick={this.handleDownloadCertificateClick}>
                  {localizationService.toLanguageString('buttons.download')}
                </ActionButton>
              }
              <ActionButton
                type='button'
                themeColor='base'
                onClick={() => this.handleGetCompetencyReportClick()}
              >
                {localizationService.toLanguageString('competencyRequest.getReport')}
              </ActionButton>
            </HeaderButtonContainer>
          </>
        }
        onBreadcrumbItemClick={this.handleBreadcrumbItemClick}
        onBackClick={this.handleCancel}
        summary={
          <StackLayout rowGap='8px'>
            <ValueItem
              label={localizationService.toLanguageString('competencyRequest.resident')}
              labelWidth='130px'
              loading={this.props.loading}
            >
              {competencyRequest?.student}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('competencyRequest.studyProgram')}
              labelWidth='130px'
              loading={this.props.loading}
            >
              {competencyRequest?.studyProgramName}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('competencyRequest.code')}
              labelWidth='130px'
              loading={this.props.loading}
            >
              {competencyRequest?.abbreviation}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('competencyRequest.status')}
              labelWidth='130px'
              loading={this.props.loading}
            >
              {competencyRequest?.status
                ?
                <InlineBadge themeColor={getStudentCompetencyStatusColor(competencyRequest.status)}>
                  {localizationService.toLanguageString(`studentCompetencyStatus.${competencyRequest.status}`)}
                </InlineBadge>
                : '-'
              }
            </ValueItem>
            <Seperator/>
            <Container>
              <Text variant={'h4'}>
                {localizationService.toLanguageString('competencyRequest.cycles')}
              </Text>
              <Text variant={'body2'} textColor={colors.GRAY_70}>
                {`${localizationService.toLanguageString('competencyRequest.completed')}: ${EmptyFormatter(competencyRequest?.completedCyclesCount)}
                  | ${localizationService.toLanguageString('competencyRequest.required')}: ${EmptyFormatter(competencyRequest?.cycleCount)}`}
              </Text>
            </Container>
            <OrderedList>
              {competencyRequest?.cycles?.map((element, index) => (
                <ListItem key={index}>
                  <Container>
                    {element.status &&
                      <InlineBadge themeColor={getStudentCycleStatusColor(element.status)}>{localizationService.toLanguageString('studyPlanCycleDepartmentStudentStatus.' + element.status)}</InlineBadge>
                    }
                    {element.cycle}
                    <GreyCaption>{localizationService.toLanguageString('cycleType.' + element.cycleType)}</GreyCaption>
                  </Container>
                </ListItem>
              ))}
            </OrderedList>
            <Container>
              <Text variant={'h4'}>
                {localizationService.toLanguageString('competencyRequest.evaluations')}
              </Text>
              <Text variant={'body2'} textColor={colors.GRAY_70}>
                {this.generateSurveyStatsLabels(competencyRequest).join(' | ')}
              </Text>
            </Container>
            <div>
              {competencyRequest?.surveys?.map((element, index) => {
                return (
                  <ExpansionPanel
                    title={element.name}
                    subtitle={localizationService.toLanguageString('surveyTypeAbbreviation.' + element.type) + ' | ' + this.generateSurveyStatsLabels(element).join(' | ')}
                    expanded={this.state.expanded === element.id}
                    tabIndex={0}
                    key={index}
                    onAction={e => {
                      this.setState({ ...this.state, expanded: e.expanded ? '' : element.id });
                    }}
                  >
                    <Reveal>
                      {this.state.expanded === element.id &&
                        <ExpansionPanelContent>
                          <ListView
                            data={element.questions}
                            item={(e) => this.renderListItem({ ...e, surveyId: element.id })}
                          />
                        </ExpansionPanelContent>
                      }
                    </Reveal>
                  </ExpansionPanel>
                );
              })}
            </div>
          </StackLayout>
        }
      >
        {this.props.isReturnDialogVisible &&
          <CompetencyRequestReturnForm
            onCancel={this.props.closeReturnCompetencyDialog}
            onSubmit={(e) => {
              this.handleStatusChange({ ...e, status: STUDENT_COMPETENCY_STATUS.RETURNED });
              this.props.closeReturnCompetencyDialog();
            }}
          />
        }
        {this.props.isGrantConfirmDialogVisible &&
          <Dialog title={localizationService.toLanguageString('custom.confirm')} onClose={this.props.closeGrantConfirmDialog}>
            <Text>{localizationService.toLanguageString('competencyRequest.competencyGrantMessage')}</Text>
            <DialogActionsBar>
              <Button themeColor={'primary'} onClick={() => this.handleSignClick()}>
                {localizationService.toLanguageString('buttons.yes')}
              </Button>
              <Button onClick={this.props.closeGrantConfirmDialog}>{localizationService.toLanguageString('buttons.no')}</Button>
            </DialogActionsBar>
          </Dialog>
        }
      </OverviewPage>
    );
  }

  handleSplitButtonItemClick(e) {
    if (e.item.id === BUTTON_IDS.DENY) {
      this.handleStatusChange({ status: STUDENT_COMPETENCY_STATUS.NOT_APPROVED });
    }
    if (e.item.id === BUTTON_IDS.RETURN) {
      this.handleReturnCompetencyClick();
    }
  }

  async handleSignClick() {
    const { studentId, id: competencyId } = this.props.competencyRequest;
    await this.props.initSigning({ studentId, competencyId });
    if (this.props.signingRedirectUrl) {
      this.props.closeGrantConfirmDialog();
      window.open(this.props.signingRedirectUrl, '_self');
    }
  }

  handleGetCompetencyReportClick() {
    this.props.getCompetencyReport({ studentCompetencyId: this.props.params.studentCompetencyId });
  }

  handleReturnCompetencyClick() {
    this.props.openReturnCompetencyDialog();
  }

  handleStatusChange(e) {
    const { competencyRequest, params } = this.props;
    this.props.changeCompetencyStatus({
      ...e,
      studentCompetencyId: params.studentCompetencyId,
      studentId: competencyRequest.studentId,
      competencyId: competencyRequest.id
    });
  }

  generateSurveyStatsLabels(e) {
    const localizationService = provideLocalizationService(this);
    const surveyStats = [
      `${localizationService.toLanguageString('competencyRequest.submitted')}: ${EmptyFormatter(e?.pendingSurveyEvaluationCount)}`,
      `${localizationService.toLanguageString('competencyRequest.evaluated')}: ${EmptyFormatter(e?.completedSurveyEvaluationCount)}`,
      `${localizationService.toLanguageString('competencyRequest.required')}: ${EmptyFormatter(e?.minimumSurveyEvaluationCount)}`
    ];
    return surveyStats;
  }

  handleSurveyQuestionClick(surveyQuestionId, surveyId) {
    this.props.expandSurveyQuestion({ surveyQuestionId, surveyId });
  }

  renderListItem(e) {
    const { dataItem } = e;
    const localizationService = provideLocalizationService(this);
    const arrowDir = dataItem.expanded ? 'k-i-arrow-chevron-down' : 'k-i-arrow-chevron-right';
    return (
      <div>
        <SurveyQuestionTitleContainer onClick={() => this.handleSurveyQuestionClick(dataItem.id, e.surveyId)}>
          <Text variant={'body2'}>
            {`${dataItem.number}. ${dataItem.questionText}`}
          </Text>
          <span className={'k-icon ' + arrowDir}/>
        </SurveyQuestionTitleContainer>
        {dataItem.expanded &&
          <SubListContainer>
            {dataItem?.answers?.length > 0
              ?
              <table>
                <tbody>
                  {dataItem?.answers.map((item, index) => this.renderSubListItem(item, index))}
                </tbody>
              </table>
              : <GreyCaption>{localizationService.toLanguageString('grid.noRecords')}</GreyCaption>
            }
          </SubListContainer>
        }
      </div>
    );
  }

  renderSubListItem(dataItem, index) {
    const intlService = provideIntlService(this);
    const localizationService = provideLocalizationService(this);
    return (
      <TableRow key={index}>
        <td>
          <GreyCaption>{`${dataItem.name} ${localizationService.toLanguageString('competencyRequest.evaluatedPastTense')}`}</GreyCaption>
        </td>
        <td>
          <EvaluationText variant={'caption'} textColor={colors.GRAY_60}>{dataItem.answer}</EvaluationText>
        </td>
        <td>
          <Text variant={'caption'} textColor={colors.GRAY_40}>{DateFormatter(dataItem.date, intlService)}</Text>
        </td>
      </TableRow>
    );
  }

  handleBreadcrumbItemClick(e) {
    if (e.id === 'competencyRequests') {
      this.props.navigate('/competency_requests');
    }
  }

  handleDownloadCertificateClick() {
    this.props.downloadCertificate({ key: this.props.competencyRequest.studentCompetencyCertificateKey });
  }

  handleCancel() {
    this.props.navigate(-1);
  }
}

const mapStateToProps = state => ({
  loading: state.competencyRequestSummaryPage.loading,
  competencyRequest: state.competencyRequestSummaryPage.competencyRequest,
  isReturnDialogVisible: state.competencyRequestSummaryPage.isReturnDialogVisible,
  isGrantConfirmDialogVisible: state.competencyRequestSummaryPage.isGrantConfirmDialogVisible,
  signingRedirectUrl: state.competencyRequestSummaryPage.signingRedirectUrl
});

const mapDispatchToProps = dispatch => ({
  loadCompetencyRequestSummaryPage: (payload) => dispatch(loadCompetencyRequestSummaryPage(payload)),
  expandSurveyQuestion: (payload) => dispatch(expandSurveyQuestion(payload)),
  clearCompetencyRequestSummaryPage: () => dispatch(clearCompetencyRequestSummaryPage()),
  changeCompetencyStatus: (payload) => dispatch(changeCompetencyStatus(payload)),
  openReturnCompetencyDialog: () => dispatch(openReturnCompetencyDialog()),
  closeReturnCompetencyDialog: () => dispatch(closeReturnCompetencyDialog()),
  getCompetencyReport: (payload) => dispatch(getCompetencyReport(payload)),
  openGrantConfirmDialog: () => dispatch(openGrantConfirmDialog()),
  closeGrantConfirmDialog: () => dispatch(closeGrantConfirmDialog()),
  initSigning: (payload) => dispatch(initSigning(payload)),
  downloadCertificate: (payload) => dispatch(downloadCertificate(payload))
});

registerForLocalization(CompetencyRequestSummaryPage);
registerForIntl(CompetencyRequestSummaryPage);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CompetencyRequestSummaryPage));