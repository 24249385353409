import StudentCompetencyCertificateViewActionTypes from '../actions/student-competency-certificate-view-action-types';

const initialState = {
  loading: false,
  certificatePdfObjectUrl: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case StudentCompetencyCertificateViewActionTypes.LOAD_STUDENT_COMPETENCY_CERTIFICATE_VIEW_START:
      return {
        ...state,
        loading: true
      };
    case StudentCompetencyCertificateViewActionTypes.LOAD_STUDENT_COMPETENCY_CERTIFICATE_VIEW_END:
      return {
        ...state,
        certificatePdfObjectUrl: action.payload.certificatePdfObjectUrl,
        loading: false
      };
    default:
      return state;
  }
};
