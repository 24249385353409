import ValidationError from '../../../utils/validation-error';
import CycleSkillAbilityFormPageActionTypes from './cycle-skill-ability-form-page-action-types';
import axios from 'axios';

export const loadCycleSkillAbilityFormPage = ({ cycleId, cycleSkillAbilityId }) => async(dispatch, getState, { api }) => {
  dispatch({ type: CycleSkillAbilityFormPageActionTypes.LOAD_CYCLE_SKILL_ABILITY_FORM_PAGE_START });
  try {
    const cycle = await api.get(`api/cycles/${cycleId}`);
    if (cycleSkillAbilityId > 0) {
      const cycleSkillAbility = await api.get(`api/cycles/${cycleId}/skill_abilities/${cycleSkillAbilityId}`);
      dispatch({
        type: CycleSkillAbilityFormPageActionTypes.LOAD_CYCLE_SKILL_ABILITY_FORM_PAGE_END,
        payload: {
          studyProgramId: cycle.studyProgramId,
          cycleSkillAbility: {
            ...cycleSkillAbility,
            studyProgramSkillAbility: cycleSkillAbility?.studyProgramSkillAbilityId ? { id: cycleSkillAbility.studyProgramSkillAbilityId, name: cycleSkillAbility.studyProgramSkillAbilityName } : null
          }
        }
      });
    } else if (cycleId) {
      dispatch({
        type: CycleSkillAbilityFormPageActionTypes.LOAD_CYCLE_SKILL_ABILITY_FORM_PAGE_END,
        payload: {
          studyProgramId: cycle.studyProgramId,
          cycleSkillAbility: {
            cycleId: cycleId
          }
        }
      });
    }
    dispatch(loadAvailableSkillAbilities());
  } catch (error) {
    dispatch({ type: CycleSkillAbilityFormPageActionTypes.LOAD_CYCLE_SKILL_ABILITY_FORM_PAGE_END });
    throw error;
  }
};

export const saveCycleSkillAbility = (payload) => async(dispatch, getState, { api, history }) => {
  dispatch({ type: CycleSkillAbilityFormPageActionTypes.LOAD_CYCLE_SKILL_ABILITY_FORM_PAGE_START });
  const state = getState().cycleSkillAbilityFormPage;
  const { studyProgramSkillAbility, ...rest } = payload;
  const apiModel = {
    ...rest,
    studyProgramSkillAbilityId: studyProgramSkillAbility?.id,
    studyProgramSkillAbilityName: studyProgramSkillAbility?.name
  };
  let id = payload?.id;
  try {
    if (id > 0) {
      await api.put(`api/cycles/${payload.cycleId}/skill_abilities/${id}`, apiModel);
    } else {
      id = await api.post(`api/cycles/${payload.cycleId}/skill_abilities`, apiModel);
    }
    dispatch({
      type: CycleSkillAbilityFormPageActionTypes.LOAD_CYCLE_SKILL_ABILITY_FORM_PAGE_END,
      payload: {
        cycleSkillAbility: {
          ...state.cycleSkillAbility,
          id: id
        }
      }
    });
    history.back();
  } catch (error) {
    let validationResult = null;
    if (error instanceof ValidationError) {
      validationResult = {
        errorMessage: error.message,
        errors: error.errors
      };
    }
    dispatch({
      type: CycleSkillAbilityFormPageActionTypes.LOAD_CYCLE_SKILL_ABILITY_FORM_PAGE_END,
      payload: {
        validationResult,
        cycleSkillAbility: state.cycleSkillAbility
      }
    });
  }
};

export const loadAvailableSkillAbilities = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: CycleSkillAbilityFormPageActionTypes.LOAD_CYCLE_SKILL_ABILITY_AVAILABLE_SKILL_ABILITIES_START });
  const state = getState().cycleSkillAbilityFormPage;
  try {
    state.availableCycleSkillAbilitiesCancelToken?.cancel();
    state.availableCycleSkillAbilitiesCancelToken = axios.CancelToken.source();
    const query = { keyword: payload?.keyword };
    const studyProgramSkillAbilities = await api.get(`api/study_programs/${state.studyProgramId}/skill_abilities`, query, state.availableCycleSkillAbilitiesCancelToken.token);
    dispatch({
      type: CycleSkillAbilityFormPageActionTypes.LOAD_CYCLE_SKILL_ABILITY_AVAILABLE_SKILL_ABILITIES_END,
      payload: {
        availableCycleSkillAbilities: studyProgramSkillAbilities?.data
      }
    });
  } catch (error) {
    dispatch({ type: CycleSkillAbilityFormPageActionTypes.LOAD_CYCLE_SKILL_ABILITY_AVAILABLE_SKILL_ABILITIES_END });
  }
};