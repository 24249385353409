import ComptetencySurveyListActionTypes from './competency-survey-list-action-types';
import axios from 'axios';

export const loadCompetencySurveyList = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: ComptetencySurveyListActionTypes.LOAD_COMPETENCY_SURVEY_LIST_START });
  const state = getState().competencySurveyList;
  try {
    state.competencySurveysCancelToken?.cancel();
    state.competencySurveysCancelToken = axios.CancelToken.source();
    const competencySurveys = await api.get(`api/competencies/${payload.competencyId}/surveys`, null, state.competencySurveysCancelToken.token);
    dispatch({
      type: ComptetencySurveyListActionTypes.LOAD_COMPETENCY_SURVEY_LIST_END,
      payload: {
        competencySurveys: competencySurveys ? competencySurveys.data : []
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: ComptetencySurveyListActionTypes.LOAD_COMPETENCY_SURVEY_LIST_END });
      throw error;
    }
  }
};

export const openCompetencySurveyConfirmDialog = (payload) => async(dispatch) => {
  dispatch({
    type: ComptetencySurveyListActionTypes.TOGGLE_COMPETENCY_SURVEY_LIST_CONFIRM_DIALOG,
    payload: payload
  });
};

export const closeCompetencySurveyConfirmDialog = () => async(dispatch) => {
  dispatch({ type: ComptetencySurveyListActionTypes.TOGGLE_COMPETENCY_SURVEY_LIST_CONFIRM_DIALOG });
};

export const removeCompetencySurveyConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().competencySurveyList;
  if (state.selectedCompetencySurveyId > 0) {
    dispatch({ type: ComptetencySurveyListActionTypes.LOAD_COMPETENCY_SURVEY_LIST_START });
    try {
      await api.remove(`api/competencies/${payload.competencyId}/surveys/${state.selectedCompetencySurveyId}`);
      dispatch(loadCompetencySurveyList({ competencyId: payload.competencyId }));
    } catch (error) {
      dispatch({ type: ComptetencySurveyListActionTypes.LOAD_COMPETENCY_SURVEY_LIST_END });
    }
  }
  dispatch({ type: ComptetencySurveyListActionTypes.TOGGLE_COMPETENCY_SURVEY_LIST_CONFIRM_DIALOG });
};