import CyclePlanListPageActionTypes from '../actions/cycle-plan-list-page-action-types';

const initialState = {
  loading: false,
  year: null,
  cyclePlans: null,
  cyclePlansCancelToken: null,
  filter: null,

  filterDepartmentsLoading: false,
  filterDepartmentsCancelToken: null,
  filterDepartments: null,

  filterResidentsLoading: false,
  filterResidentsCancelToken: null,
  filterResidents: null,

  filterStudyProgramsLoading: false,
  filterStudyProgramsCancelToken: null,
  filterStudyPrograms: null,

  filterCyclesLoading: false,
  filterCyclesCancelToken: null,
  filterCycles: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CyclePlanListPageActionTypes.LOAD_CYCLE_PLAN_LIST_START:
      return {
        ...state,
        loading: true
      };
    case CyclePlanListPageActionTypes.LOAD_CYCLE_PLAN_LIST_END:
      return {
        ...state,
        loading: false,
        cyclePlans: action.payload.cyclePlans,
        total: action.payload.total,
        skip: action.payload.skip,
        take: action.payload.take,
        year: action.payload.year
      };
    case CyclePlanListPageActionTypes.FILTER_CYCLE_PLAN_LIST:
      return {
        ...state,
        filter: action.payload
      };
    case CyclePlanListPageActionTypes.SEARCH_CYCLE_PLAN_LIST_FILTER_DEPARTMENT_START:
      return {
        ...state,
        filterDepartmentsLoading: true
      };
    case CyclePlanListPageActionTypes.SEARCH_CYCLE_PLAN_LIST_FILTER_DEPARTMENT_END:
      return {
        ...state,
        filterDepartmentsLoading: false,
        filterDepartments: action.payload.departments
      };
    case CyclePlanListPageActionTypes.SEARCH_CYCLE_PLAN_LIST_FILTER_RESIDENT_START:
      return {
        ...state,
        filterResidentsLoading: true
      };
    case CyclePlanListPageActionTypes.SEARCH_CYCLE_PLAN_LIST_FILTER_RESIDENT_END:
      return {
        ...state,
        filterResidentsLoading: false,
        filterResidents: action.payload.residents
      };
    case CyclePlanListPageActionTypes.SEARCH_CYCLE_PLAN_LIST_FILTER_STUDY_PROGRAM_START:
      return {
        ...state,
        filterStudyProgramsLoading: true
      };
    case CyclePlanListPageActionTypes.SEARCH_CYCLE_PLAN_LIST_FILTER_STUDY_PROGRAM_END:
      return {
        ...state,
        filterStudyProgramsLoading: false,
        filterStudyPrograms: action.payload.studyPrograms
      };
    case CyclePlanListPageActionTypes.SEARCH_CYCLE_PLAN_LIST_FILTER_CYCLE_START:
      return {
        ...state,
        filterCyclesLoading: true
      };
    case CyclePlanListPageActionTypes.SEARCH_CYCLE_PLAN_LIST_FILTER_CYCLE_END:
      return {
        ...state,
        filterCyclesLoading: false,
        filterCycles: action.payload.cycles
      };
    default:
      return state;
  }
};