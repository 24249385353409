import ManagedStudentOverviewPageActionTypes from '../actions/managed-student-overview-page-action-types';

const initialState = {
  loading: false,
  managedStudent: null,
  skills: null,
  additionalSkills: null,
  cases: null,
  additionalCases: null,
  theoreticalParts: null,
  managedStudentCancelToken: null,
  selectedTab: 0,
  studentSkillsLoading: false,
  studentSkillsLoaded: false,
  studentCasesLoading: false,
  studentCasesLoaded: false,
  studentTheoreticalPartsLoading: false,
  studentTheoreticalPartsLoaded: false,
  isReturnDialogVisible: false,
  isApproveDialogVisible: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ManagedStudentOverviewPageActionTypes.LOAD_MANAGED_STUDENTS_SKILLS_TAB_START:
      return {
        ...state,
        studentSkillsLoading: true
      };
    case ManagedStudentOverviewPageActionTypes.LOAD_MANAGED_STUDENTS_SKILLS_TAB_END:
      return {
        ...state,
        studentSkillsLoading: false,
        studentSkillsLoaded: action.payload?.studentSkillsLoaded,
        skills: action.payload?.skills,
        additionalSkills: action.payload?.additionalSkills,
        managedStudent: action.payload?.managedStudent ?? state.managedStudent
      };
    case ManagedStudentOverviewPageActionTypes.LOAD_MANAGED_STUDENTS_CASES_TAB_START:
      return {
        ...state,
        studentCasesLoading: true
      };
    case ManagedStudentOverviewPageActionTypes.LOAD_MANAGED_STUDENTS_CASES_TAB_END:
      return {
        ...state,
        studentCasesLoading: false,
        studentCasesLoaded: action.payload?.studentCasesLoaded,
        cases: action.payload?.cases,
        additionalCases: action.payload?.additionalCases,
        managedStudent: action.payload?.managedStudent ?? state.managedStudent
      };
    case ManagedStudentOverviewPageActionTypes.LOAD_MANAGED_STUDENTS_THEORETICAL_PARTS_TAB_START:
      return {
        ...state,
        studentTheoreticalPartsLoading: true
      };
    case ManagedStudentOverviewPageActionTypes.LOAD_MANAGED_STUDENTS_THEORETICAL_PARTS_TAB_END:
      return {
        ...state,
        studentTheoreticalPartsLoading: false,
        studentTheoreticalPartsLoaded: action.payload?.studentTheoreticalPartsLoaded,
        theoreticalParts: action.payload?.theoreticalParts,
        managedStudent: action.payload?.managedStudent ?? state.managedStudent
      };
    case ManagedStudentOverviewPageActionTypes.SELECT_MANAGED_STUDENTS_OVERVIEW_PAGE_TAB:
      return {
        ...state,
        selectedTab: action.payload?.selected
      };
    case ManagedStudentOverviewPageActionTypes.UPDATE_MANAGED_STUDENTS_OVERVIEW_PAGE_SKILLS:
      return {
        ...state,
        skills: action.payload?.skills ?? state.skills,
        additionalSkills: action.payload?.additionalSkills ?? state.additionalSkills
      };
    case ManagedStudentOverviewPageActionTypes.UPDATE_MANAGED_STUDENTS_OVERVIEW_PAGE_CASES:
      return {
        ...state,
        cases: action.payload?.cases ?? state.cases,
        additionalCases: action.payload?.additionalCases ?? state.additionalCases
      };
    case ManagedStudentOverviewPageActionTypes.OPEN_MANAGED_STUDENTS_OVERVIEW_PAGE_RETURN_DIALOG:
      return {
        ...state,
        submitDialogFunction: action?.payload?.submitDialogFunction,
        isReturnDialogVisible: true
      };
    case ManagedStudentOverviewPageActionTypes.CLOSE_MANAGED_STUDENTS_OVERVIEW_PAGE_RETURN_DIALOG:
      return {
        ...state,
        isReturnDialogVisible: false,
        managedStudent: action.payload?.managedStudent ?? state.managedStudent
      };
    case ManagedStudentOverviewPageActionTypes.OPEN_MANAGED_STUDENTS_OVERVIEW_PAGE_APPROVE_DIALOG:
      return {
        ...state,
        isApproveDialogVisible: true
      };
    case ManagedStudentOverviewPageActionTypes.CLOSE_MANAGED_STUDENTS_OVERVIEW_PAGE_APPROVE_DIALOG:
      return {
        ...state,
        isApproveDialogVisible: false
      };
    case ManagedStudentOverviewPageActionTypes.CHANGE_STUDY_PLAN_CYCLE_STUDENT_PART_STATUS_START:
      return {
        ...state,
        loading: true
      };
    case ManagedStudentOverviewPageActionTypes.CHANGE_STUDY_PLAN_CYCLE_STUDENT_PART_STATUS_END:
      return {
        ...state,
        loading: false,
        managedStudent: action.payload?.managedStudent ?? state.managedStudent
      };
    case ManagedStudentOverviewPageActionTypes.CLEAR_MANAGED_STUDENTS_OVERVIEW_PAGE:
      return initialState;
    default:
      return state;
  }
};