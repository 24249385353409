import axios from 'axios';
import CycleFormPageActionTypes from './cycle-form-page-action-types';
import ValidationError from '../../../utils/validation-error';
import FIELD_NAMES from './cycle-form-field-names';

export const loadCycleFormPage = ({ cycleId, targetCycleId, localizationService }) => async(dispatch, getState, { api }) => {
  dispatch({ type: CycleFormPageActionTypes.LOAD_CYCLE_FORM_PAGE_START });
  try {
    if (targetCycleId > 0 || cycleId > 0) {
      const cycle = await api.get(`api/cycles/${targetCycleId ?? cycleId}`);
      dispatch({
        type: CycleFormPageActionTypes.LOAD_CYCLE_FORM_PAGE_END,
        targetCycleId: targetCycleId,
        payload: {
          cycle: {
            ...cycle,
            id: cycleId,
            code: targetCycleId
              ? ''
              : cycle.code,
            partialCycleCode: targetCycleId
              ? ''
              : cycle.partialCycleCode,
            studyProgram: targetCycleId
              ? null
              : {
                id: cycle.studyProgramId,
                name: cycle.divisionName ? `${cycle.studyProgramName} (${cycle.divisionName})` : cycle.studyProgramName
              },
            studyProgramCode: targetCycleId
              ? ''
              : cycle.studyProgramCode,
            administrator: cycle.administratorUserId
              ? { id: cycle.administratorUserId, name: cycle.administratorName }
              : null,
            cycleChildren: cycle.studyPrograms?.map(element => ({
              studyProgram: {
                id: element.studyProgramId,
                name: element.studyProgramName
              },
              studyProgramCode: element.studyProgramCode,
              partialCycleCode: targetCycleId ? null : element.partialCycleCode
            })),
            cycleType: cycle.type
              ? {
                value: cycle.type,
                text: localizationService.toLanguageString(`cycleType.${cycle.type}`)
              }
              : null,
            validFrom: cycle.validFrom ? new Date(cycle.validFrom) : null,
            validTo: cycle.validTo ? new Date(cycle.validTo) : null,
            teachingType: cycle.teachingType
              ? {
                value: cycle.teachingType,
                text: localizationService.toLanguageString(`cycleTeachingType.${cycle.teachingType}`)
              } : null,
            version: targetCycleId
              ? 1
              : cycle.version
          }
        }
      });
    } else {
      dispatch({ type: CycleFormPageActionTypes.LOAD_CYCLE_FORM_PAGE_END });
    }
  } catch (error) {
    dispatch({ type: CycleFormPageActionTypes.LOAD_CYCLE_FORM_PAGE_END });
    throw error;
  }
  dispatch(searchAvailableStudyPrograms());
};

export const searchAvailableStudyPrograms = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: CycleFormPageActionTypes.SEARCH_CYCLE_FORM_PAGE_STUDY_PROGRAMS_START });
  try {
    const state = getState().cycleFormPage;
    const query = {
      skip: 0,
      limit: 50,
      keyword: payload?.keyword
    };
    state.availableStudyProgramsCancelToken?.cancel();
    state.availableStudyProgramsCancelToken = axios.CancelToken.source();
    const studyPrograms = await api.get(`api/study_programs`, query, state.availableStudyProgramsCancelToken.token);
    dispatch({
      type: CycleFormPageActionTypes.SEARCH_CYCLE_FORM_PAGE_STUDY_PROGRAMS_END,
      payload: { studyPrograms: studyPrograms ? studyPrograms.data.map(element => ({ id: element.id, name: `${element.name}${element.divisionName ? ` (${element.divisionName})` : ``}`, code: element.code })) : [] }
    });
  } catch (error) {
    dispatch({ type: CycleFormPageActionTypes.SEARCH_CYCLE_FORM_PAGE_STUDY_PROGRAMS_END });
    throw error;
  }
};

export const saveCycle = (payload) => async(dispatch, getState, { api, history }) => {
  dispatch({ type: CycleFormPageActionTypes.LOAD_CYCLE_FORM_PAGE_START });
  const state = getState().cycleFormPage;
  const apiModel = {
    ...payload,
    type: payload.cycleType?.value,
    studyProgramId: payload.studyProgram?.id,
    code: `${payload.studyProgramCode}-${payload.partialCycleCode}`,
    administratorUserId: payload.administrator?.id,
    teachingType: payload.teachingType?.value,
    studyPrograms: payload.cycleChildren?.map(element => ({
      ...element,
      studyProgramId: element.studyProgram?.id,
      cycleId: payload.id,
      code: `${element.studyProgramCode}-${element.partialCycleCode}`
    })) ?? []
  };
  let id = payload?.id;
  try {
    let navigate;
    if (id > 0) {
      await api.put(`api/cycles/${id}`, apiModel);
      navigate = () => history.push(`/cycles/${id}/overview`);
    } else {
      id = payload.targetId ? await api.post(`api/cycles/${payload.targetId}`, apiModel) : await api.post('api/cycles', apiModel);
      navigate = () => history.replace(`/cycles/${id}/overview`);
    }
    dispatch({
      type: CycleFormPageActionTypes.LOAD_CYCLE_FORM_PAGE_END,
      payload: {
        cycle: {
          ...state.cycle,
          id: id
        }
      }
    });
    navigate();
  } catch (error) {
    let validationResult = null;
    if (error instanceof ValidationError) {
      validationResult = {
        errorMessage: error.message,
        errors: error.errors
      };
    }
    dispatch({
      type: CycleFormPageActionTypes.LOAD_CYCLE_FORM_PAGE_END,
      payload: {
        validationResult,
        cycle: state.cycle
      }
    });
  }
};

export const loadInputData = () => async(dispatch, getState, { api }) => {
  dispatch(searchAvailableStudyPrograms());
};

export const loadCycleChildrenAvailableStudyPrograms = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().cycleFormPage;
  const query = {
    skip: 0,
    limit: 50,
    keyword: payload?.keyword,
    includeUnaffiliated: true
  };
  let cycleChildren = [...state.cycleChildren];
  cycleChildren[payload.index] = {
    loading: true,
    availableStudyPrograms: cycleChildren[payload.index]?.availableCoordinators ?? []
  };
  dispatch({
    type: CycleFormPageActionTypes.LOAD_CYCLE_CHILDREN_AVAILABLE_STUDY_PROGRAM_START,
    payload: { cycleChildren }
  });
  try {
    const cancellationTokenKey = `${FIELD_NAMES.CYCLE_CHILDREN}[${payload.index}]`;
    state.cancellationTokenSources.get(cancellationTokenKey)?.cancel();
    state.cancellationTokenSources.set(cancellationTokenKey, axios.CancelToken.source());
    const studyPrograms = await api.get(`api/study_programs`, query, state.cancellationTokenSources.get(cancellationTokenKey).token);
    cycleChildren = [...getState().cycleFormPage.cycleChildren];
    cycleChildren[payload.index] = {
      loading: false,
      availableStudyPrograms: studyPrograms?.data ?? []
    };
    dispatch({
      type: CycleFormPageActionTypes.LOAD_CYCLE_CHILDREN_AVAILABLE_STUDY_PROGRAM_END,
      payload: { cycleChildren }
    });
  } catch (error) {
    dispatch({ type: CycleFormPageActionTypes.LOAD_CYCLE_CHILDREN_AVAILABLE_STUDY_PROGRAM_END });
  }
};

export const searchAvailableAdministrators = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: CycleFormPageActionTypes.SEARCH_CYCLE_FORM_PAGE_ADMINISTRATORS_START });
  try {
    const state = getState().cycleFormPage;
    const query = {
      skip: 0,
      limit: 50,
      keyword: payload?.keyword,
      showOnlyEmployees: true
    };
    state.availableAdministratorsCancelToken?.cancel();
    state.availableAdministratorsCancelToken = axios.CancelToken.source();
    const availableAdministrators = await api.get(`api/users_search`, query, state.availableAdministratorsCancelToken.token);
    dispatch({
      type: CycleFormPageActionTypes.SEARCH_CYCLE_FORM_PAGE_ADMINISTRATORS_END,
      payload: { availableAdministrators: availableAdministrators ? availableAdministrators.data.map(element => ({ id: element.id, name: `${element.name} ${element.surname}` })) : [] }
    });
  } catch (error) {
    dispatch({ type: CycleFormPageActionTypes.SEARCH_CYCLE_FORM_PAGE_ADMINISTRATORS_END });
    throw error;
  }
};