import { STUDY_PLAN_CYCLE_DEPARTMENT_STUDENT_DESIGNATION_STATUS } from '../resources/studyPlanCycleDepartmentStudentDesignationStatus';

export const getStudentCycleDepartmentStudentDesignationStatusColor = (status) => {
  switch (status) {
    case STUDY_PLAN_CYCLE_DEPARTMENT_STUDENT_DESIGNATION_STATUS.NOT_SUBMITTED:
      return 'inverse';
    case STUDY_PLAN_CYCLE_DEPARTMENT_STUDENT_DESIGNATION_STATUS.SUBMITTED:
      return 'primary';
    case STUDY_PLAN_CYCLE_DEPARTMENT_STUDENT_DESIGNATION_STATUS.APPROVED:
      return 'info';
    case STUDY_PLAN_CYCLE_DEPARTMENT_STUDENT_DESIGNATION_STATUS.SIGNED:
      return 'success';
    default:
      return 'inverse';
  }
};