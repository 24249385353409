import * as React from 'react';
import { useLocalization } from '@progress/kendo-react-intl';

const Profile = () => {
  const localizationService = useLocalization();

  return (
    <div id='Profile' className='main-content'>
      <div className='card-container grid'>
        <h3 className='card-title'>
          {localizationService.toLanguageString('custom.profile')}
        </h3>
      </div>
    </div>
  );
};
export default Profile;
