import axios from 'axios';
import { USER_TYPES } from '../../../resources/userTypes';
import UserSearchDialogActionTypes from './user-search-dialog-action-types';
import ValidationError from '../../../utils/validation-error';

export const loadUsers = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: UserSearchDialogActionTypes.LOAD_USER_SEARCH_DIALOG_USERS_START });
  try {
    const state = getState().userSearchDialog;
    state.availableUsersCancelToken?.cancel();
    state.availableUsersCancelToken = axios.CancelToken.source();
    const users = await api.get(`api/users_search`, { keyword: payload.keyword, type: USER_TYPES.LSMUSIS }, state.availableUsersCancelToken.token);
    dispatch({
      type: UserSearchDialogActionTypes.LOAD_USER_SEARCH_DIALOG_USERS_END,
      payload: {
        users: users ? users.data?.map(user => ({
          ...user,
          text: `${user.name} ${user.surname}${user.details && ` (${user.details.split(';').join(', ')})`}`
        })) : []
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      throw error;
    }
  }
};

export const openUserSearchDialog = (payload) => async(dispatch, getState, { api }) => {
  dispatch({
    type: UserSearchDialogActionTypes.OPEN_USER_SEARCH_DIALOG,
    payload: payload
  });
};

export const closeUserSearchDialog = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: UserSearchDialogActionTypes.CLOSE_USER_SEARCH_DIALOG });
};

export const addUser = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: UserSearchDialogActionTypes.ADD_USER_SEARCH_DIALOG_USER_START });
  try {
    const state = getState().userSearchDialog;
    const apiModel = {
      ...payload.user
    };
    const userId = await api.post(`api/users/`, apiModel);
    if (state.onAdd && payload.user) {
      state.onAdd({
        ...payload.user,
        id: userId,
        text: payload.user.name + ' ' + payload.user.surname
      });
    }
    dispatch({
      type: UserSearchDialogActionTypes.CLOSE_USER_SEARCH_DIALOG
    });
  } catch (error) {
    if (error instanceof ValidationError) {
      dispatch({
        type: UserSearchDialogActionTypes.ADD_USER_SEARCH_DIALOG_USER_END,
        payload: {
          userSearchDialogValidationResult: {
            errorMessage: error.message,
            errors: error.errors
          }
        }
      });
    } else {
      dispatch({ type: UserSearchDialogActionTypes.ADD_USER_SEARCH_DIALOG_USER_END });
    }
  }
};