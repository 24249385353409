export const isObject = arg => {
  return Object.prototype.toString.call(arg).indexOf('Object') !== -1;
};

export const sortingToString = sorting => {
  let sort = '';
  if (sorting && sorting.length) {
    sorting.forEach(item => {
      let prefix = sort.length === 0 ? '' : '_';
      sort = sort + `${prefix}${item.field}-${item.dir}`;
    });
  }
  return sort;
};

export const sortingToStringWithSubstitutes = (sorting, substitutes) => {
  let sort = '';
  if (sorting && sorting.length) {
    sorting.forEach(item => {
      let prefix = sort.length === 0 ? '' : '_';
      let fieldName = item.field;
      if (substitutes && substitutes.length) {
        const substitute = substitutes.find(element => element.originalName === fieldName);
        if (substitute) {
          fieldName = substitute.substituteName;
        }
      }
      sort = sort + `${prefix}${fieldName}-${item.dir}`;
    });
  }
  return sort;
};