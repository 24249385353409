
export const EmptyFormatter = (value, emptyValue) => {
  if (emptyValue === null || emptyValue === undefined) {
    emptyValue = '-';
  }
  return value === null || value === undefined ? emptyValue : value;
};

export const TimeFormatter = (value, intlService) => {
  return value === null || value === undefined ? null : intlService.formatDate(new Date(value), 'HH:mm', intlService.locale);
};

export const DateFormatter = (value, intlService) => {
  return value === null || value === undefined ? null : intlService.formatDate(new Date(value), 'd');
};

export const DatePeriodFormatter = (from, to, intlService) => {
  return from === null || from === undefined || to === null || to === undefined ? null : `${intlService.formatDate(new Date(from), 'd')} - ${intlService.formatDate(new Date(to), 'd')}`;
};

export const DateTimeFormatter = (value, intlService) => {
  return value === null || value === undefined ? null : intlService.formatDate(new Date(value), 'yyyy-MM-dd HH:mm', intlService.locale);
};

export const DecimalFormatter = (value, precision) => {
  return value === null || value === undefined ? null : value.toFixed(precision ?? 2).replace('.', ',');
};