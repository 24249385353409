import StudentCompetencyOverviewPageActionTypes from './student-competency-overview-action-types';
import axios from 'axios';
import { STUDENT_COMPETENCY_STATUS } from '../../../../resources/studentCompetencyStatus';
import { userFilter } from '../../../../utils/dataUtils';

export const loadStudentCompetencyOverviewPage = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudentCompetencyOverviewPageActionTypes.LOAD_STUDENT_COMPETENCY_OVERVIEW_PAGE_START });
  const states = getState();
  const state = states.studentCompetencyOverview;
  try {
    state.studentCompetencyCancelToken?.cancel();
    state.studentCompetencyCancelToken = axios.CancelToken.source();
    const competency = await api.get(`api/my_competencies/${payload.competencyId}`, { studentId: payload.studentId }, state.studentCompetencyCancelToken.token);
    const studentCompetency = { ...competency, competencyId: competency.id, id: competency.studentCompetencyId ?? null };
    competency.studentCompetencyId && delete studentCompetency.studentCompetencyId;
    dispatch({
      type: StudentCompetencyOverviewPageActionTypes.LOAD_STUDENT_COMPETENCY_OVERVIEW_PAGE_END,
      payload: { studentCompetency }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: StudentCompetencyOverviewPageActionTypes.LOAD_STUDENT_COMPETENCY_OVERVIEW_PAGE_END });
      throw error;
    }
  }
  dispatch(loadAvailableCoordinators());
};

export const loadAvailableCoordinators = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().studentCompetencyOverview;
  dispatch({ type: StudentCompetencyOverviewPageActionTypes.LOAD_STUDENT_COMPETENCY_AVAILABLE_COORDINATORS_START });
  try {
    state.availableCoordinatorsCancelToken?.cancel();
    state.availableCoordinatorsCancelToken = axios.CancelToken.source();
    const studyProgram = await api.get(`api/study_programs/${state.studentCompetency.studyProgramId}`, state.availableCoordinatorsCancelToken.token);
    const availableCoordinators = payload?.keyword
      ? studyProgram?.coordinators?.filter(coordinator => userFilter(coordinator.name, payload.keyword))
      : studyProgram?.coordinators;
    dispatch({
      type: StudentCompetencyOverviewPageActionTypes.LOAD_STUDENT_COMPETENCY_AVAILABLE_COORDINATORS_END,
      payload: { availableCoordinators }
    });
  } catch (error) {
    dispatch({ type: StudentCompetencyOverviewPageActionTypes.LOAD_STUDENT_COMPETENCY_AVAILABLE_COORDINATORS_END });
  }
};

export const selectStudentCompetencyOverviewPageTab = (payload) => async(dispatch) => {
  dispatch({
    type: StudentCompetencyOverviewPageActionTypes.SELECT_STUDENT_COMPETENCY_OVERVIEW_PAGE_TAB,
    payload: {
      selected: payload.selected
    }
  });
};

export const openRequestEvaluationDialog = (payload) => async(dispatch) => {
  dispatch({
    type: StudentCompetencyOverviewPageActionTypes.TOGGLE_STUDENT_COMPETENCY_REQUEST_EVALUATION_DIALOG,
    payload: payload
  });
};

export const closeRequestEvaluationDialog = () => async(dispatch) => {
  dispatch({ type: StudentCompetencyOverviewPageActionTypes.TOGGLE_STUDENT_COMPETENCY_REQUEST_EVALUATION_DIALOG });
};

export const submitStudentCompetency = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudentCompetencyOverviewPageActionTypes.SUBMIT_STUDENT_COMPETENCY_START });
  const state = getState().studentCompetencyOverview;
  const apiModel = {
    approvingUserId: payload?.coordinator?.userId
  };
  try {
    state.studentCompetencyCancelToken?.cancel();
    state.studentCompetencyCancelToken = axios.CancelToken.source();
    await api.post(`api/students/${state?.studentCompetency?.studentId}/competencies/${state?.studentCompetency?.competencyId}/status/${STUDENT_COMPETENCY_STATUS.TO_BE_APPROVED}`, apiModel, state.studentCompetencyCancelToken.token);

    dispatch({ type: StudentCompetencyOverviewPageActionTypes.SUBMIT_STUDENT_COMPETENCY_END });
    dispatch(closeSubmitConfirmDialog());
    dispatch(loadStudentCompetencyOverviewPage({ competencyId: state?.studentCompetency?.competencyId, studentId: payload.studentId }));
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: StudentCompetencyOverviewPageActionTypes.SUBMIT_STUDENT_COMPETENCY_END });
      throw error;
    }
  }
};

export const togglePageSummaryExpanded = () => (dispatch) => {
  dispatch({ type: StudentCompetencyOverviewPageActionTypes.TOGGLE_STUDENT_COMPETENCY_OVERVIEW_PAGE_SUMMARY_EXPANDED });
};

export const openSubmitConfirmDialog = (payload) => async(dispatch) => {
  dispatch({
    type: StudentCompetencyOverviewPageActionTypes.TOGGLE_STUDENT_COMPETENCY_SUBMIT_DIALOG,
    payload: payload
  });
};

export const closeSubmitConfirmDialog = () => async(dispatch) => {
  dispatch({ type: StudentCompetencyOverviewPageActionTypes.TOGGLE_STUDENT_COMPETENCY_SUBMIT_DIALOG });
};

export const openQrCodeDialog = () => async(dispatch) => {
  dispatch({
    type: StudentCompetencyOverviewPageActionTypes.TOGGLE_STUDENT_COMPETENCY_QR_CODE_DIALOG,
    payload: {
      isQrCodeDialogVisible: true
    }
  });
};

export const closeQrCodeDialog = () => async(dispatch) => {
  dispatch({
    type: StudentCompetencyOverviewPageActionTypes.TOGGLE_STUDENT_COMPETENCY_QR_CODE_DIALOG,
    payload: {
      isQrCodeDialogVisible: false
    }
  });
};

export const setShowQrCodeSetting = (shouldShowQrCode) => async(dispatch) => {
  dispatch({
    type: StudentCompetencyOverviewPageActionTypes.SET_STUDENT_COMPETENCY_SHOW_QR_CODE_SETTING,
    payload: {
      shouldShowQrCode
    }
  });
};

export const getCompetencyReport = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudentCompetencyOverviewPageActionTypes.GET_COMPETENCY_REPORT_START });
  const state = getState().competencyRequestSummaryPage;
  try {
    state.competencyRequestCancelToken?.cancel();
    state.competencyRequestCancelToken = axios.CancelToken.source();
    const pdfResponse = await api.get(`api/student_competencies/${payload.studentCompetencyId}/reports`, {}, state.competencyRequestCancelToken.token, 'blob');
    const pdfBlob = new Blob([pdfResponse], { type: 'application/pdf' });
    const pdfUrl = URL.createObjectURL(pdfBlob);
    const downloadLink = document.createElement('a');
    downloadLink.href = pdfUrl;
    const date = new Date();
    const fileName = `${payload.student.replace(' ', '_')}_PK_${date.toISOString().slice(0, 10)}.pdf`;
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    URL.revokeObjectURL(pdfUrl);
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: StudentCompetencyOverviewPageActionTypes.GET_COMPETENCY_REPORT_END });
      throw error;
    }
  }
};