import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../../../components/withRouter';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { loadStudentCompetencyCertificateView } from '../../student-competency/actions/student-competency-certificate-view-actions';

class StudentCompetencyCertificateView extends React.Component {

  componentDidMount() {
    const { studentCompetencyCertificateKey, loadStudentCompetencyCertificateView } = this.props;
    loadStudentCompetencyCertificateView({ studentCompetencyCertificateKey });
  }

  componentWillUnmount() {
    if (this.props.certificatePdfObjectUrl) {
      URL.revokeObjectURL(this.props.certificatePdfObjectUrl);
    }
  }

  render() {
    const localizationService = provideLocalizationService(this);
    return this.props.certificatePdfObjectUrl
      ?
      <iframe
        src={this.props.certificatePdfObjectUrl}
        title={localizationService.toLanguageString('studentCompetency.competencyCertificate')}
        width={'100%'}
        height={'500'}
      />
      : <div>{localizationService.toLanguageString('studentCompetency.noCertificate')}</div>;
  }
}

registerForLocalization(StudentCompetencyCertificateView);

const mapStateToProps = (state) => ({
  certificatePdfObjectUrl: state.studentCompetencyCertificateView.certificatePdfObjectUrl,
  loading: state.studentCompetencyCertificateView.loading
});

const mapDispatchToProps = (dispatch) => ({
  loadStudentCompetencyCertificateView: (payload) => dispatch(loadStudentCompetencyCertificateView(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StudentCompetencyCertificateView));