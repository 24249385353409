import StudyProgramListPageActionTypes from './study-program-list-page-action-types';
import axios from 'axios';
import { sortingToString } from '../../../utils/objectUtils';
import { ROLE_PERMISSIONS as Permissions } from '../../../resources/rolePermissions';

export const loadStudyProgramListPage = (payload) => async(dispatch, getState) => {
  const state = getState().studyProgramListPage;
  dispatch(findStudyPrograms());
  if (!state.filterStudyProgramsComboBox) {
    dispatch(searchStudyProgramsFilterStudyPrograms());
  }
};

export const findStudyPrograms = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudyProgramListPageActionTypes.LOAD_STUDY_PROGRAM_LIST_START });
  const state = getState().studyProgramListPage;
  const query = {
    skip: payload ? payload.skip : state.skip,
    limit: payload ? payload.take : state.take,
    sorting: sortingToString(state.sorting),
    keyword: state.filter?.keyword,
    studyProgramId: state.filter?.studyProgram?.id,
    extendedSearch: true
  };
  try {
    state.studyProgramsCancelToken?.cancel();
    state.studyProgramsCancelToken = axios.CancelToken.source();
    const studyPrograms = await api.get(`api/study_programs`, query, state.studyProgramsCancelToken.token);
    const canWrite = getState().app.userPermissions.includes(Permissions.STUDY_PROGRAMS_WRITE);
    dispatch({
      type: StudyProgramListPageActionTypes.LOAD_STUDY_PROGRAM_LIST_END,
      payload: {
        studyPrograms: studyPrograms ? studyPrograms.data : [],
        total: studyPrograms ? studyPrograms.total : 0,
        skip: payload ? payload.skip : state.skip,
        take: payload ? payload.take : state.take,
        canWrite
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: StudyProgramListPageActionTypes.LOAD_STUDY_PROGRAM_LIST_END });
      throw error;
    }
  }
};

export const filterStudyPrograms = (payload) => (dispatch) => {
  dispatch({
    type: StudyProgramListPageActionTypes.FILTER_STUDY_PROGRAM_LIST,
    payload: payload
  });
  dispatch(findStudyPrograms());
};

export const sortStudyPrograms = (payload) => (dispatch) => {
  dispatch({
    type: StudyProgramListPageActionTypes.SORT_STUDY_PROGRAM_LIST,
    payload: payload
  });
  dispatch(findStudyPrograms());
};

export const clearStudyProgramsFilter = () => (dispatch) => {
  dispatch({
    type: StudyProgramListPageActionTypes.FILTER_STUDY_PROGRAM_LIST,
    payload: {}
  });
  dispatch(findStudyPrograms());
};

export const searchStudyProgramsFilterStudyPrograms = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudyProgramListPageActionTypes.SEARCH_STUDY_PROGRAM_LIST_FILTER_STUDY_PROGRAM_START });
  const query = {
    skip: 0,
    limit: 50,
    keyword: payload?.keyword
  };
  const state = getState().studyProgramListPage;
  try {
    state.filterStudyProgramsComboBoxCancelToken?.cancel();
    state.filterStudyProgramsComboBoxCancelToken = axios.CancelToken.source();
    const studyPrograms = await api.get('api/study_programs', query, state.filterStudyProgramsComboBoxCancelToken.token);
    dispatch({
      type: StudyProgramListPageActionTypes.SEARCH_STUDY_PROGRAM_LIST_FILTER_STUDY_PROGRAM_END,
      payload: {
        studyPrograms: studyPrograms ? studyPrograms.data : []
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: StudyProgramListPageActionTypes.SEARCH_STUDY_PROGRAM_LIST_FILTER_STUDY_PROGRAM_END });
      throw error;
    }
  }
};