import { pagingSettings } from '../../../utils/GridPagingUtils';
import NotificationsListPageActionTypes from '../actions/notifications-list-page-action-types';

const initialState = {
  loading: false,
  notifications: null,
  skip: pagingSettings.paging.skip,
  take: pagingSettings.paging.take,
  total: 0,
  sorting: null,
  filter: null,
  selectedNotificationId: null,
  isDeleteConfirmDialogVisible: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case NotificationsListPageActionTypes.LOAD_NOTIFICATIONS_LIST_START:
      return {
        ...state,
        loading: true
      };
    case NotificationsListPageActionTypes.LOAD_NOTIFICATIONS_LIST_END:
      return {
        ...state,
        loading: false,
        notifications: action.payload.notifications,
        total: action.payload.total,
        skip: action.payload.skip,
        take: action.payload.take
      };
    case NotificationsListPageActionTypes.FILTER_NOTIFICATIONS_LIST:
      return {
        ...state,
        filter: action.payload,
        skip: initialState.skip,
        take: initialState.take
      };
    case NotificationsListPageActionTypes.SORT_NOTIFICATIONS_LIST:
      return {
        ...state,
        sorting: action.payload
      };
    case NotificationsListPageActionTypes.OPEN_NOTIFICATIONS_DELETE_CONFIRM_DIALOG:
      return {
        ...state,
        selectedNotificationId: action.payload.notificationId,
        isDeleteConfirmDialogVisible: true
      };
    case NotificationsListPageActionTypes.CLOSE_NOTIFICATIONS_DELETE_CONFIRM_DIALOG:
      return {
        ...state,
        selectedNotificationId: null,
        isDeleteConfirmDialogVisible: false
      };
    default:
      return state;
  }
};