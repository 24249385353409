import axios from 'axios';
import StudentCompetencyCertificateViewActionTypes from './student-competency-certificate-view-action-types';

export const loadStudentCompetencyCertificateView = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudentCompetencyCertificateViewActionTypes.LOAD_STUDENT_COMPETENCY_CERTIFICATE_VIEW_START });
  const states = getState();
  const state = states.studentCompetencyCertificateView;
  try {
    state.studentCompetencyCertificateCancelToken?.cancel();
    state.studentCompetencyCertificateCancelToken = axios.CancelToken.source();
    const pdfResponse = payload.studentCompetencyCertificateKey != null
      ? await api.get(`api/student_competency_certificate/${payload.studentCompetencyCertificateKey}/download`, {}, state.studentCompetencyCertificateCancelToken.token, 'blob')
      : null;

    dispatch({
      type: StudentCompetencyCertificateViewActionTypes.LOAD_STUDENT_COMPETENCY_CERTIFICATE_VIEW_END,
      payload: { certificatePdfObjectUrl: pdfResponse ? URL.createObjectURL(pdfResponse) : null }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: StudentCompetencyCertificateViewActionTypes.LOAD_STUDENT_COMPETENCY_CERTIFICATE_VIEW_END });
      throw error;
    }
  }
};