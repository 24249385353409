import ComptetencyCycleListActionTypes from '../actions/competency-cycle-list-action-types';

const initialState = {
  loading: false,
  competencyCycles: null,
  competencyCyclesCancelToken: null,

  isConfirmDialogVisible: false,
  selectedCompetencyCycleId: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ComptetencyCycleListActionTypes.LOAD_COMPETENCY_CYCLE_LIST_START:
      return {
        ...state,
        loading: true
      };
    case ComptetencyCycleListActionTypes.LOAD_COMPETENCY_CYCLE_LIST_END:
      return {
        ...state,
        loading: false,
        competencyCycles: action.payload.competencyCycles
      };
    case ComptetencyCycleListActionTypes.TOGGLE_COMPETENCY_CYCLE_LIST_CONFIRM_DIALOG:
      return {
        ...state,
        selectedCompetencyCycleId: action.payload?.id,
        isConfirmDialogVisible: !state.isConfirmDialogVisible
      };
    default:
      return state;
  }
};