import CycleListPageActionTypes from './cycle-list-page-action-types';
import axios from 'axios';
import { sortingToString } from '../../../utils/objectUtils';

export const loadCycleListPage = (payload) => async(dispatch, getState) => {
  const state = getState().cycleListPage;
  dispatch(findCycles());
  if (!state.filterStudyPrograms) {
    dispatch(searchCyclesFilterStudyPrograms());
  }
};

export const findCycles = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: CycleListPageActionTypes.LOAD_CYCLE_LIST_START });
  const state = getState().cycleListPage;
  const query = {
    skip: payload ? payload.skip : state.skip,
    limit: payload ? payload.take : state.take,
    sorting: sortingToString(state.sorting),
    keyword: state.filter?.keyword,
    year: state.filter?.year?.id,
    studyProgramId: state.filter?.studyProgram?.id,
    showInvalid: state.filter?.showInvalid,
    extendedSearch: true,
    includeUnaffiliated: true,
    includeExternalyTaught: true
  };
  try {
    state.cyclesCancelToken?.cancel();
    state.cyclesCancelToken = axios.CancelToken.source();
    const cycles = await api.get(`api/cycles`, query, state.cyclesCancelToken.token);
    const usersStudyPrograms = await api.get(`api/study_programs`, state.cyclesCancelToken.token);
    dispatch({
      type: CycleListPageActionTypes.LOAD_CYCLE_LIST_END,
      payload: {
        cycles: cycles ? cycles.data : [],
        total: cycles ? cycles.total : 0,
        skip: payload ? payload.skip : state.skip,
        take: payload ? payload.take : state.take,
        usersStudyPrograms: usersStudyPrograms ? usersStudyPrograms.data : []
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: CycleListPageActionTypes.LOAD_CYCLE_LIST_END });
      throw error;
    }
  }
};

export const filterCycles = (payload) => (dispatch) => {
  dispatch({
    type: CycleListPageActionTypes.FILTER_CYCLE_LIST,
    payload: payload
  });
  dispatch(findCycles());
};

export const sortCycles = (payload) => (dispatch) => {
  dispatch({
    type: CycleListPageActionTypes.SORT_CYCLE_LIST,
    payload: payload
  });
  dispatch(findCycles());
};

export const clearCyclesFilter = () => (dispatch) => {
  dispatch({
    type: CycleListPageActionTypes.FILTER_CYCLE_LIST,
    payload: { showInvalid: false }
  });
  dispatch(findCycles());
};

export const searchCyclesFilterStudyPrograms = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: CycleListPageActionTypes.SEARCH_CYCLE_LIST_FILTER_STUDY_PROGRAM_START });
  const query = {
    skip: 0,
    limit: 50,
    keyword: payload?.keyword,
    includeUnaffiliated: true
  };
  const state = getState().cycleListPage;
  try {
    state.filterStudyProgramsCancelToken?.cancel();
    state.filterStudyProgramsCancelToken = axios.CancelToken.source();
    const studyPrograms = await api.get('api/study_programs', query, state.filterStudyProgramsCancelToken.token);
    dispatch({
      type: CycleListPageActionTypes.SEARCH_CYCLE_LIST_FILTER_STUDY_PROGRAM_END,
      payload: {
        studyPrograms: studyPrograms ? studyPrograms.data : []
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: CycleListPageActionTypes.SEARCH_CYCLE_LIST_FILTER_STUDY_PROGRAM_END });
      throw error;
    }
  }
};

export const openCycleConfirmDialog = (payload) => async(dispatch) => {
  dispatch({
    type: CycleListPageActionTypes.TOGGLE_CYCLE_LIST_CONFIRM_DIALOG,
    payload: payload
  });
};

export const closeCycleConfirmDialog = () => async(dispatch) => {
  dispatch({ type: CycleListPageActionTypes.TOGGLE_CYCLE_LIST_CONFIRM_DIALOG });
};

export const removeCycleConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().cycleListPage;
  if (state.selectedCycleId > 0) {
    dispatch({ type: CycleListPageActionTypes.LOAD_CYCLE_LIST_START });
    try {
      await api.remove(`api/cycles/${state.selectedCycleId}`);
      dispatch(loadCycleListPage({ cycleId: payload.cycleId }));
    } catch (error) {
      dispatch({ type: CycleListPageActionTypes.LOAD_CYCLE_LIST_END });
      throw error;
    }
  }
  dispatch({ type: CycleListPageActionTypes.TOGGLE_CYCLE_LIST_CONFIRM_DIALOG });
};