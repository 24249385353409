import React from 'react';
import { Component } from 'react';
import { IntlProvider, LocalizationProvider, load, loadMessages } from '@progress/kendo-react-intl';
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';
import usNumbers from 'cldr-numbers-full/main/en/numbers.json';
import usLocalCurrency from 'cldr-numbers-full/main/en/currencies.json';
import usCaGregorian from 'cldr-dates-full/main/en/ca-gregorian.json';
import usDateFields from 'cldr-dates-full/main/en/dateFields.json';
import ltNumbers from 'cldr-numbers-full/main/lt/numbers.json';
import ltLocalCurrency from 'cldr-numbers-full/main/lt/currencies.json';
import ltCaGregorian from 'cldr-dates-full/main/lt/ca-gregorian.json';
import ltDateFields from 'cldr-dates-full/main/lt/dateFields.json';
import { enMessages } from '../messages/en';
import { ltMessages } from '../messages/lt';

const LOCALES = { lt: 'lt', en: 'en' };

export default class LocalizationWrapper extends Component {

  constructor(props) {
    super(props);
    this.handleLocaleChange = this.handleLocaleChange.bind(this);
    this.state = {
      localeId: process.env.REACT_APP_LOCALE_ID ?? window.ENV.LANGUAGE
    };
  }

  componentDidMount() {
    load(likelySubtags, currencyData, weekData, usNumbers, usLocalCurrency, usCaGregorian, usDateFields, ltNumbers, ltLocalCurrency, ltCaGregorian, ltDateFields);
    loadMessages(enMessages, LOCALES.en);
    loadMessages(ltMessages, LOCALES.lt);
  }

  handleLocaleChange() {
    if (this.state.localeId === LOCALES.lt) {
      this.setState({ localeId: LOCALES.en });
    } else {
      this.setState({ localeId: LOCALES.lt });
    }
  }

  render() {
    return (
      <LocalizationProvider language={this.state.localeId}>
        <IntlProvider locale={this.state.localeId}>
          {this.props.children &&
            React.Children.map(this.props.children, child => React.cloneElement(child, { onLocaleChange: this.handleLocaleChange }))
          }
        </IntlProvider>
      </LocalizationProvider>
    );
  }
}