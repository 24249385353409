import StudyPlanListPageActionTypes from './study-plan-list-page-action-types';
import axios from 'axios';
import { sortingToString } from '../../../utils/objectUtils';

export const loadStudyPlanListPage = (payload) => async(dispatch, getState) => {
  const state = getState().studyPlanListPage;
  dispatch(findStudyPlans());
  if (!state.filterStudyPrograms) {
    dispatch(searchStudyPlansFilterStudyPrograms());
  }
};

export const findStudyPlans = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudyPlanListPageActionTypes.LOAD_STUDY_PLAN_LIST_START });
  const state = getState().studyPlanListPage;
  const query = {
    skip: payload ? payload.skip : state.skip,
    limit: payload ? payload.take : state.take,
    sorting: sortingToString(state.sorting),
    keyword: state.filter?.keyword,
    year: state.filter?.year,
    studyProgramId: state.filter?.studyProgram?.id,
    extendedSearch: true
  };
  try {
    state.studyPlansCancelToken?.cancel();
    state.studyPlansCancelToken = axios.CancelToken.source();
    const studyPlans = await api.get(`api/study_plans`, query, state.studyPlansCancelToken.token);
    dispatch({
      type: StudyPlanListPageActionTypes.LOAD_STUDY_PLAN_LIST_END,
      payload: {
        studyPlans: studyPlans ? studyPlans.data : [],
        total: studyPlans ? studyPlans.total : 0,
        skip: payload ? payload.skip : state.skip,
        take: payload ? payload.take : state.take
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: StudyPlanListPageActionTypes.LOAD_STUDY_PLAN_LIST_END });
      throw error;
    }
  }
};

export const filterStudyPlans = (payload) => (dispatch) => {
  dispatch({
    type: StudyPlanListPageActionTypes.FILTER_STUDY_PLAN_LIST,
    payload: payload
  });
  dispatch(findStudyPlans());
};

export const sortStudyPlans = (payload) => (dispatch) => {
  dispatch({
    type: StudyPlanListPageActionTypes.SORT_STUDY_PLAN_LIST,
    payload: payload
  });
  dispatch(findStudyPlans());
};

export const clearStudyPlansFilter = () => (dispatch) => {
  dispatch({
    type: StudyPlanListPageActionTypes.FILTER_STUDY_PLAN_LIST,
    payload: {}
  });
  dispatch(findStudyPlans());
};

export const searchStudyPlansFilterStudyPrograms = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudyPlanListPageActionTypes.SEARCH_STUDY_PLAN_LIST_FILTER_STUDY_PROGRAM_START });
  const query = {
    skip: 0,
    limit: 50,
    keyword: payload?.keyword
  };
  const state = getState().studyPlanListPage;
  try {
    state.filterStudyProgramsCancelToken?.cancel();
    state.filterStudyProgramsCancelToken = axios.CancelToken.source();
    const studyPrograms = await api.get('api/study_programs', query, state.filterStudyProgramsCancelToken.token);
    dispatch({
      type: StudyPlanListPageActionTypes.SEARCH_STUDY_PLAN_LIST_FILTER_STUDY_PROGRAM_END,
      payload: {
        studyPrograms: studyPrograms ? studyPrograms.data : []
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: StudyPlanListPageActionTypes.SEARCH_STUDY_PLAN_LIST_FILTER_STUDY_PROGRAM_END });
      throw error;
    }
  }
};