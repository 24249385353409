import React, { PureComponent } from 'react';

export default class PaperAndPencilIcon extends PureComponent {
  render() {
    return (
      <svg
        height='24'
        width='24'
        viewBox='0 0 400 400'
        style={this.props.style}
      >
        <g>
          <path d='M393.674,131.298l-29.509-29.51c-0.004-0.004-0.006-0.008-0.01-0.011s-0.008-0.006-0.011-0.009l-29.51-29.511
		c-2.343-2.344-5.522-3.661-8.839-3.661s-6.495,1.317-8.839,3.661l-14.59,14.59V12.5c0-6.903-5.598-12.5-12.5-12.5H14.835
		c-6.903,0-12.5,5.597-12.5,12.5v374.67c0,6.903,5.597,12.5,12.5,12.5h275.032c6.902,0,12.5-5.597,12.5-12.5V240.279l91.307-91.304
		C398.556,144.094,398.556,136.18,393.674,131.298z M277.367,374.67H27.335V25h250.032v86.847L174.073,215.139
		c-1.726,1.728-2.908,3.922-3.402,6.312l-15.359,74.4c-0.851,4.121,0.429,8.393,3.403,11.366c2.368,2.368,5.558,3.661,8.839,3.661
		c0.841,0,1.688-0.085,2.527-0.258l74.396-15.362c2.393-0.493,4.586-1.677,6.312-3.403l26.577-26.576V374.67z M190.468,249.21
		l26.252,26.253l-33.082,6.831L190.468,249.21z M241.951,265.339l-41.36-41.36L325.796,98.774l11.842,11.843l-76.36,76.362
		c-4.883,4.882-4.883,12.796,0,17.678c2.439,2.439,5.64,3.661,8.839,3.661s6.397-1.222,8.839-3.661l76.361-76.361l11.842,11.842
		L241.951,265.339z'/>
        </g>
      </svg>
    );
  }
}