import { ListView } from '@progress/kendo-react-listview';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import LoadingPanel from './loading-panel';
import { HEADER_HEIGHT } from '../../../constants';

const KendoListView = styled(ListView)`
  height: ${window.innerHeight - 60}px;
`;

export default class ListviewPage extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      listViewHeight: 0
    };
    this.updateListViewSize = this.updateListViewSize.bind(this);
  }

  componentDidMount() {
    this.updateListViewSize();
    window.addEventListener('resize', this.updateListViewSize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateListViewSize);
  }

  render() {
    return (
      <>
        <KendoListView
          onScroll={this.props.onScroll}
          data={this.props.data}
          item={this.props.item}
          style={{
            ...this.props.style,
            height: this.state.listViewHeight
          }}
        />
        {this.props.loading && <LoadingPanel/>}
      </>
    );
  }

  updateListViewSize() {
    this.setState({ listViewHeight: window.innerHeight - HEADER_HEIGHT });
  }
}