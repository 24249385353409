import StudyPlanCycleDepartmentListActionTypes from '../actions/study-plan-cycle-department-list-action-types';

const initialState = {
  loading: false,
  studyPlanCycleDepartments: null,
  studyPlanCycleDepartmentsCancelToken: null,
  isConfirmDialogVisible: false,
  selectedStudyPlanCycleDepartmentId: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case StudyPlanCycleDepartmentListActionTypes.LOAD_STUDY_PLAN_CYCLE_DEPARTMENT_LIST_START:
      return {
        ...state,
        loading: true
      };
    case StudyPlanCycleDepartmentListActionTypes.LOAD_STUDY_PLAN_CYCLE_DEPARTMENT_LIST_END:
      return {
        ...state,
        studyPlanCycleDepartments: action.payload,
        loading: false
      };
    case StudyPlanCycleDepartmentListActionTypes.TOGGLE_STUDY_PLAN_CYCLE_DEPARTMENT_CONFIRM_DIALOG:
      return {
        ...state,
        selectedStudyPlanCycleDepartmentId: action.payload?.id,
        isConfirmDialogVisible: !state.isConfirmDialogVisible
      };
    default:
      return state;
  }
};
