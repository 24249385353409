import * as React from 'react';
import { DatePicker as KendoDatePicker } from '@progress/kendo-react-dateinputs';
import { Error } from '@progress/kendo-react-labels';
import styled, { css } from 'styled-components';

const Container = styled.fieldset`
  ${props => props.$width && css`
    flex-grow: 0;
    flex-basis: auto;
    width: ${props.$width};
  `}
`;

const DatePicker = (props) => {
  const { value, valid, validationMessage, label, ...rest } = props;
  let normalizedValue = value;
  if (value === '' || value === undefined) {
    normalizedValue = null;
  }
  return (
    <Container $width={props.width} className={'k-form-fieldset'}>
      <KendoDatePicker
        label={label}
        {...rest}
        formatPlaceholder={'short'}
        value={normalizedValue}
        valid={valid}
      />
      {!valid && <Error>{validationMessage}</Error>}
    </Container>
  );
};

export default DatePicker;