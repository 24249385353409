import { STUDENT_COMPETENCY_STATUS } from '../resources/studentCompetencyStatus';

export const getStudentCompetencyStatusColor = (status) => {
  switch (status) {
    case STUDENT_COMPETENCY_STATUS.NOT_STARTED:
      return 'inverse';
    case STUDENT_COMPETENCY_STATUS.STARTED:
      return 'primary';
    case STUDENT_COMPETENCY_STATUS.TO_BE_APPROVED:
      return 'info';
    case STUDENT_COMPETENCY_STATUS.NOT_APPROVED:
      return 'warning';
    case STUDENT_COMPETENCY_STATUS.RETURNED:
      return 'error';
    case STUDENT_COMPETENCY_STATUS.TO_BE_SIGNED:
      return 'tertiary';
    case STUDENT_COMPETENCY_STATUS.ACQUIRED:
      return 'success';
    default:
      return 'inverse';
  }
};