import * as React from 'react';
import { ListPage } from '../../../ui';
import { UserListFilter, UserList } from '../../users/components/index';

class UserListPage extends React.PureComponent {
  render() {
    return (
      <ListPage filter={<UserListFilter/>}>
        <UserList/>
      </ListPage>
    );
  }
}

export default UserListPage;