import { pagingSettings } from '../../../utils/GridPagingUtils';
import DepartmentListPageActionTypes from '../actions/department-list-page-action-types';

const initialState = {
  loading: false,
  departments: null,
  departmentsCancelToken: null,
  skip: pagingSettings.paging.skip,
  take: pagingSettings.paging.take,
  total: 0,
  sorting: null,
  filter: null,
  canWrite: false,

  isConfirmDialogVisible: false,
  selectedDepartmentId: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DepartmentListPageActionTypes.LOAD_DEPARTMENT_LIST_START:
      return {
        ...state,
        loading: true
      };
    case DepartmentListPageActionTypes.LOAD_DEPARTMENT_LIST_END:
      return {
        ...state,
        loading: false,
        departments: action.payload.departments,
        total: action.payload.total,
        skip: action.payload.skip,
        take: action.payload.take,
        canWrite: action.payload.canWrite
      };
    case DepartmentListPageActionTypes.FILTER_DEPARTMENT_LIST:
      return {
        ...state,
        filter: action.payload,
        skip: initialState.skip,
        take: initialState.take
      };
    case DepartmentListPageActionTypes.SORT_DEPARTMENT_LIST:
      return {
        ...state,
        sorting: action.payload
      };
    case DepartmentListPageActionTypes.TOGGLE_DEPARTMENT_LIST_CONFIRM_DIALOG:
      return {
        ...state,
        selectedDepartmentId: action.payload?.id,
        isConfirmDialogVisible: !state.isConfirmDialogVisible
      };
    default:
      return state;
  }
};