import * as React from 'react';
import { ListPage } from '../../../ui';
import { StudyPlanListFilter, StudyPlanList } from './index';

class StudyPlanListPage extends React.PureComponent {
  render() {
    return (
      <ListPage filter={< StudyPlanListFilter/>}>
        < StudyPlanList/>
      </ListPage>
    );
  }
}

export default StudyPlanListPage;
