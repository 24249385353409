import React from 'react';
import { connect } from 'react-redux';
import { GridToolbar } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { GridSize, Grid, InlineBadge } from '../../shared/components';
import { loadStudyPlanListPage, findStudyPlans, sortStudyPlans } from '../actions/study-plan-list-page-actions';
import { withRouter } from '../../../components/withRouter';
import { getStudyPlanStatusColor } from '../../../utils/studyPlanStatusColors';

class StudyPlanList extends React.PureComponent {

  constructor() {
    super();
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleAddClicked = this.handleAddClicked.bind(this);
  }

  async componentDidMount() {
    await this.props.loadStudyPlanListPage();
  }

  render() {
    const localizationService = provideLocalizationService(this);
    return (
      <Grid
        loading={this.props.loading}
        data={this.props.studyPlans}
        skip={this.props.skip}
        take={this.props.take}
        total={this.props.total}
        sort={this.props.sorting}
        onPageChange={this.handlePageChange}
        onSortChange={this.handleSort}
        onRowClick={this.handleRowClick}
        columns={[
          {
            field: 'studyProgram',
            className: 'align-left',
            headerClassName: 'align-left',
            title: localizationService.toLanguageString('StudyPlan.studyProgram')
          }, {
            field: 'studyPlanYear',
            className: 'align-left',
            headerClassName: 'align-left',
            title: localizationService.toLanguageString('StudyPlan.studyPlan'),
            cell: (e) => this.renderYear(e, localizationService)
          }, {
            field: 'isApproved',
            headerClassName: 'align-left',
            title: localizationService.toLanguageString('StudyPlan.isApproved'),
            cell: (e) => this.renderApproved(e, localizationService),
            minGridWidth: GridSize.medium
          }, {
            field: 'credits',
            className: 'align-right',
            headerClassName: 'align-right',
            title: localizationService.toLanguageString('StudyPlan.credits'),
            cell: this.renderCredits,
            minGridWidth: GridSize.small,
            sortable: false
          }
        ]}
      >
        <GridToolbar>
          <Button themeColor='primary' onClick={this.handleAddClicked}>
            {localizationService.toLanguageString('custom.add')}
          </Button>
        </GridToolbar>
      </Grid>
    );
  }

  renderYear(e, localizationService) {
    const { dataItem, className } = e;
    return (
      <td className={className}>
        {`${dataItem.studyPlanYear}${localizationService.toLanguageString('StudyPlan.shorthandYear')}/${dataItem.studyPlanYear + 1}${localizationService.toLanguageString('StudyPlan.shorthandYear')}`}
      </td>
    );
  }

  renderApproved(e, localizationService) {
    const { dataItem, className } = e;
    return (
      <td className={className}>
        <InlineBadge themeColor={getStudyPlanStatusColor(dataItem.isApproved)}>
          {dataItem.isApproved ? localizationService.toLanguageString('StudyPlan.confirmed') : localizationService.toLanguageString('StudyPlan.notConfirmed')}
        </InlineBadge>
      </td>
    );
  }

  renderCredits(e) {
    const { dataItem, className } = e;
    return (
      <td className={className}>
        {`${dataItem.studyPlansCredits ?? 0}/${dataItem.studyProgramCredits ?? 0}`}
      </td>
    );
  }

  handleSort(e) {
    this.props.sortStudyPlans(e.sort);
  }

  async handlePageChange(e) {
    await this.props.findStudyPlans({ skip: e.page.skip, take: e.page.take });
  }

  handleRowClick(e) {
    this.props.navigate(`/study_plans/${e.dataItem.id}/overview`);
  }

  handleAddClicked() {
    this.props.navigate('/study_plans/new');
  }
}

const mapStateToProps = state => ({
  loading: state.studyPlanListPage.loading,
  studyPlans: state.studyPlanListPage.studyPlans,
  skip: state.studyPlanListPage.skip,
  take: state.studyPlanListPage.take,
  total: state.studyPlanListPage.total,
  sorting: state.studyPlanListPage.sorting
});

const mapDispatchToProps = dispatch => ({
  loadStudyPlanListPage: () => dispatch(loadStudyPlanListPage()),
  findStudyPlans: (payload) => dispatch(findStudyPlans(payload)),
  sortStudyPlans: (payload) => dispatch(sortStudyPlans(payload))
});

registerForLocalization(StudyPlanList);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StudyPlanList));
