import axios from 'axios';
import StudyPlanCycleTheoreticalPartListActionTypes from './study-plan-cycle-theoretical-part-list-action-types';

export const loadStudyPlanCycleTheoreticalPartList = ({ studyPlanId, studyPlanCycleId }) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudyPlanCycleTheoreticalPartListActionTypes.LOAD_STUDY_PLAN_CYCLE_THEORETICAL_PART_LIST_START });
  const state = getState().studyPlanCycleTheoreticalPartList;
  try {
    state.studyPlanCycleTheoreticalPartsCancelToken?.cancel();
    state.studyPlanCycleTheoreticalPartsCancelToken = axios.CancelToken.source();
    const studyPlanCycleTheoreticalParts = await api.get(`api/study_plans/${studyPlanId}/cycles/${studyPlanCycleId}/theoretical_parts`, null, state.studyPlanCycleTheoreticalPartsCancelToken.token);
    dispatch({
      type: StudyPlanCycleTheoreticalPartListActionTypes.LOAD_STUDY_PLAN_CYCLE_THEORETICAL_PART_LIST_END,
      payload: studyPlanCycleTheoreticalParts?.data
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: StudyPlanCycleTheoreticalPartListActionTypes.LOAD_STUDY_PLAN_CYCLE_THEORETICAL_PART_LIST_END });
      throw error;
    }
  }
};

export const openStudyPlanCycleTheoreticalPartConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  dispatch({
    type: StudyPlanCycleTheoreticalPartListActionTypes.TOGGLE_STUDY_PLAN_CYCLE_THEORETICAL_PART_CONFIRM_DIALOG,
    payload
  });
};

export const closeStudyPlanCycleTheoreticalPartConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudyPlanCycleTheoreticalPartListActionTypes.TOGGLE_STUDY_PLAN_CYCLE_THEORETICAL_PART_CONFIRM_DIALOG });
};

export const removeStudyPlanCycleTheoreticalPartConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().studyPlanCycleTheoreticalPartList;
  if (state.selectedStudyPlanCycleTheoreticalPartId > 0) {
    dispatch({ type: StudyPlanCycleTheoreticalPartListActionTypes.LOAD_STUDY_PLAN_CYCLE_THEORETICAL_PART_LIST_START });
    try {
      await api.remove(`api/study_plans/${payload.studyPlanId}/cycles/${payload.studyPlanCycleId}/theoretical_parts/${state.selectedStudyPlanCycleTheoreticalPartId}`);
      dispatch(loadStudyPlanCycleTheoreticalPartList({ studyPlanId: payload.studyPlanId, studyPlanCycleId: payload.studyPlanCycleId }));
    } catch (error) {
      dispatch({ type: StudyPlanCycleTheoreticalPartListActionTypes.LOAD_STUDY_PLAN_CYCLE_THEORETICAL_PART_LIST_END });
      throw error;
    }
  }
  dispatch({ type: StudyPlanCycleTheoreticalPartListActionTypes.TOGGLE_STUDY_PLAN_CYCLE_THEORETICAL_PART_CONFIRM_DIALOG });
};
