import StudentCompetencySurveySubmitFormActionTypes from '../actions/student-competency-survey-submit-form-action-types';

const initialState = {
  studentCompetencySurveySubmitFormCancelToken: null,
  requestEvaluationDialogValidationResult: null,
  isExternalUserForm: false,
  studentCompetencySurveyForm: {},
  surveyType: null,

  evaluatorRoles: null,

  availableManagersLoading: false,
  availableManagersCancelToken: null,
  availableManagers: [],

  availableDepartmentsLoading: false,
  availableDepartmentsCancelToken: null,
  availableDepartments: [],

  availableExternalUsersLoading: false,
  availableExternalUsersCancelToken: null,
  availableExternalUsers: [],

  availableRolesCancelToken: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case StudentCompetencySurveySubmitFormActionTypes.LOAD_STUDENT_COMPETENCY_SURVEY_SUBMIT_FORM_AVAILABLE_MANAGERS_START:
      return {
        ...state,
        availableManagersLoading: true
      };
    case StudentCompetencySurveySubmitFormActionTypes.LOAD_STUDENT_COMPETENCY_SURVEY_SUBMIT_FORM_AVAILABLE_MANAGERS_END:
      return {
        ...state,
        availableManagersLoading: false,
        availableManagers: action?.payload?.users
      };
    case StudentCompetencySurveySubmitFormActionTypes.SAVE_STUDENT_COMPETENCY_SURVEY_SUBMIT_FORM_REQUEST_EVALUATION:
      return {
        ...state,
        requestEvaluationDialogValidationResult: action.payload?.requestEvaluationDialogValidationResult
      };
    case StudentCompetencySurveySubmitFormActionTypes.TOGGLE_STUDENT_COMPETENCY_SURVEY_SUBMIT_FORM_TYPE:
      return {
        ...state,
        isExternalUserForm: !state.isExternalUserForm
      };
    case StudentCompetencySurveySubmitFormActionTypes.LOAD_STUDENT_COMPETENCY_SURVEY_SUBMIT_FORM_EVALUATOR_ROLES:
      return {
        ...state,
        evaluatorRoles: action.payload?.evaluatorRoles,
        studentCompetencySurveyForm: {
          ...action.payload?.studentCompetencySurveyForm,
          role: action.payload?.studentCompetencySurveyForm?.role ?? state.studentCompetencySurveyForm?.role
        }
      };
    case StudentCompetencySurveySubmitFormActionTypes.LOAD_STUDENT_COMPETENCY_SURVEY_SUBMIT_FORM_AVAILABLE_DEPARTMENTS_START:
      return {
        ...state,
        availableDepartmentsLoading: true
      };
    case StudentCompetencySurveySubmitFormActionTypes.LOAD_STUDENT_COMPETENCY_SURVEY_SUBMIT_FORM_AVAILABLE_DEPARTMENTS_END:
      return {
        ...state,
        availableDepartmentsLoading: false,
        availableDepartments: action?.payload?.departments,
        studentCompetencySurveyForm: {
          ...action.payload?.studentCompetencySurveyForm,
          role: action.payload?.studentCompetencySurveyForm?.role ?? state.studentCompetencySurveyForm?.role
        }
      };
    case StudentCompetencySurveySubmitFormActionTypes.LOAD_STUDENT_COMPETENCY_SURVEY_SUBMIT_FORM_AVAILABLE_EXTERNAL_USERS_START:
      return {
        ...state,
        availableExternalUsersLoading: true
      };
    case StudentCompetencySurveySubmitFormActionTypes.LOAD_STUDENT_COMPETENCY_SURVEY_SUBMIT_FORM_AVAILABLE_EXTERNAL_USERS_END:
      return {
        ...state,
        availableExternalUsersLoading: false,
        availableExternalUsers: action?.payload?.externalUsers
      };
    case StudentCompetencySurveySubmitFormActionTypes.LOAD_STUDENT_COMPETENCY_SURVERY_SUBMIT_FORM_SURVEY_TYPE:
      return {
        ...state,
        surveyType: action.payload?.surveyType
      };
    case StudentCompetencySurveySubmitFormActionTypes.CLEAR_STUDENT_COMPETENCY_SURVEY_SUBMIT_FORM:
      return initialState;
    default:
      return state;
  }
};