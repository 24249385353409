import { GridToolbar } from '@progress/kendo-react-grid';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid, GridDateRangeCell, GridSize, InlineBadge } from '../../shared/components';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { ComboBox, StackLayout, Text } from '../../../ui';
import { loadStudyPlanCycleStudentList,
  openStudyPlanCycleStudentListConfirmDialog,
  closeStudyPlanCycleStudentListConfirmDialog,
  removeStudyPlanCycleStudentListConfirmDialog,
  selectStudyPlanCycleStudentList,
  selectAllStudyPlanCycleStudentList,
  openStudyPlanCycleStudentListChangeManagerDialog,
  closeStudyPlanCycleStudentListChangeManagerDialog,
  saveStudyPlanCycleStudentListCycleManager
} from '../actions';
import { Checkbox } from '@progress/kendo-react-inputs';
import { withRouter } from '../../../components/withRouter';
import FormDialog from '../../../ui/components/application/form-dialog';
import { Field } from '@progress/kendo-react-form';
import styled from 'styled-components';
import { getStudentCycleStatusColor } from '../../../utils/studyPlanCycleDepartmentStudentStatusColors';

const ErrorMessageContainer = styled.div.attrs({
  className: 'k-messagebox k-messagebox-error'
})`
  white-space: pre-line;
  width: 100%;
`;

class StudyPlanCycleStudentList extends React.PureComponent {
  constructor() {
    super();
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleRemoveConfirm = this.handleRemoveConfirm.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
  }

  async componentDidMount() {
    await this.props.loadStudyPlanCycleStudentList({
      studyPlanId: this.props.studyPlanId,
      studyPlanCycleId: this.props.studyPlanCycleId
    });
  }

  render() {
    const { studyPlanCycleStudents, loading, isConfirmDialogVisible, isCycleManagerDialogVisible } = this.props;
    const localizationService = provideLocalizationService(this);
    return (
      <>
        <Grid
          loading={loading}
          data={studyPlanCycleStudents}
          isPageable={false}
          selectable={{
            enabled: true,
            drag: false,
            cell: false,
            mode: 'multiple'
          }}
          dataItemKey='id'
          selectedField='isSelected'
          onSelectionChange={this.handleSelect}
          onRowClick={this.handleEditClick}
          columns={[
            {
              field: 'isSelected',
              width: '50px',
              cell: this.renderSelected,
              headerCell: () => (
                <span>
                  <Checkbox checked={this.props.selectedAll} onClick={this.handleSelectAll}/>
                </span>
              )
            },
            {
              field: 'studentFullName',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('StudyPlanCycleResident.resident')
            }, {
              field: 'department',
              className: 'align-left',
              headerClassName: 'align-left',
              minGridWidth: GridSize.small,
              title: localizationService.toLanguageString('StudyPlanCycleResident.department'),
              cell: (e) => this.renderDepartmentCell(e, localizationService)
            }, {
              field: 'credits',
              className: 'align-right',
              headerClassName: 'align-right',
              minGridWidth: GridSize.small,
              title: localizationService.toLanguageString('StudyPlanCycleResident.credits'),
              cell: this.renderCredits
            }, {
              field: 'dateRange',
              className: 'align-center',
              headerClassName: 'align-center',
              title: localizationService.toLanguageString('StudyPlanCycleResident.dateRange'),
              cell: (props) => <GridDateRangeCell {...props} dateFrom='dateFrom' dateTo='dateTo'/>,
              minGridWidth: GridSize.medium
            }, {
              field: 'status',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('StudyPlanCycleResident.status'),
              cell: (e) => this.renderStatusCell(e, localizationService)
            }, {
              field: 'cycleManagerFullName',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('StudyPlanCycleResident.cycleManager'),
              minGridWidth: GridSize.medium
            }
          ]}
          actions={[
            { icon: 'delete', onClick: this.handleRemoveClick }
          ]}
        >
          <GridToolbar>
            <Button themeColor='primary' onClick={this.handleAddClick}>
              {localizationService.toLanguageString('custom.add')}
            </Button>
            <Button primary='false' disabled={!this.props.selectedIds || this.props.selectedIds.length == 0}>
              <Link
                to={`/study_plans/${this.props.studyPlanId}/cycles/${this.props.studyPlanCycleId}/students`}
                state={{ ids: this.props.selectedStudents }}>
                {localizationService.toLanguageString('StudyPlanCycleResident.assign')}
              </Link>
            </Button>
            <Button primary='false' disabled={!this.props.selectedIds || this.props.selectedIds.length == 0} onClick={this.props.openStudyPlanCycleStudentListConfirmDialog}>
              {localizationService.toLanguageString('custom.delete')}
            </Button>
            <Button primary='false' disabled={!this.props.selectedIds || this.props.selectedIds.length == 0} onClick={this.props.openStudyPlanCycleStudentListChangeManagerDialog}>
              {localizationService.toLanguageString('StudyPlanCycleResident.changeCycleManager')}
            </Button>
            <br/>
            {this.props.validationResult?.errorMessage && <ErrorMessageContainer>{this.props.validationResult.errorMessage}</ErrorMessageContainer>}
          </GridToolbar>
        </Grid>
        {isConfirmDialogVisible &&
          <Dialog title={localizationService.toLanguageString('custom.confirm')} onClose={this.props.closeStudyPlanCycleStudentListConfirmDialog}>
            <Text>{localizationService.toLanguageString('StudyPlanCycleResident.dialogConfirmDeleteStudent')}</Text>
            <DialogActionsBar>
              <Button onClick={this.handleRemoveConfirm} themeColor='primary'>{localizationService.toLanguageString('buttons.delete')}</Button>
              <Button onClick={this.props.closeStudyPlanCycleStudentListConfirmDialog}>{localizationService.toLanguageString('buttons.cancel')}</Button>
            </DialogActionsBar>
          </Dialog>
        }
        {isCycleManagerDialogVisible &&
          <FormDialog
            title={localizationService.toLanguageString('StudyPlanCycleResident.changeCycleManager')}
            width='300px'
            onSubmit={(values) => this.props.saveStudyPlanCycleStudentListCycleManager({
              dialogFormData: values,
              studyPlanId: this.props.params.studyPlanId,
              studyPlanCycleId: this.props.params.studyPlanCycleId,
              selectedIds: this.props.selectedIds,
              localizationService
            })}
            onCancel={this.props.closeStudyPlanCycleStudentListChangeManagerDialog}
            initialValues={{ cycleManager: null }}
            confirmButtonText={localizationService.toLanguageString('StudyPlanCycleResident.change')}
            closeButttonText={localizationService.toLanguageString('buttons.cancel')}
            render={() => (
              <StackLayout>
                <Field
                  name='cycleManager'
                  component={ComboBox}
                  label={localizationService.toLanguageString('StudyPlanCycleResident.cycleManager')}
                  data={this.props.studyPlanCycleManagers}
                  textField='userFullName'
                  valueField='id'
                />
              </StackLayout>
            )}
          />
        }
      </>
    );
  }

  renderStatusCell({ dataItem, className }, localizationService) {
    return (
      <td className={className}>
        <InlineBadge themeColor={getStudentCycleStatusColor(dataItem.status)}>
          {localizationService.toLanguageString(`studyPlanCycleDepartmentStudentStatus.${dataItem.status}`)}
        </InlineBadge>
      </td>
    );
  }

  renderDepartmentCell({ dataItem, className }, localizationService) {
    return (
      <td className={className}>
        <InlineBadge themeColor={dataItem.studentDepartmentsCount ? 'success' : 'inverse'}>
          {dataItem.studentDepartmentsCount ?
            localizationService.toLanguageString('StudyPlanCycleResident.assigned') :
            localizationService.toLanguageString('StudyPlanCycleResident.notAssigned') }
        </InlineBadge>
      </td>
    );
  }

  renderCredits(e) {
    return (
      <td className='align-right' role='gridcell'>
        {e.dataItem.credits && `${e.dataItem.credits} (${e.dataItem.studentDepartmentsCount})`}
      </td>
    );
  }

  renderSelected(e) {
    return (
      <td role='gridcell' onClick={(e) => e.stopPropagation()}>
        <Checkbox checked={e.dataItem.isSelected} onChange={() => e.selectionChange(e)}/>
      </td>
    );
  }

  handleSelect(e) {
    if (e.dataItem) {
      this.props.selectStudyPlanCycleStudentList(e.dataItem);
    }
  }

  handleSelectAll() {
    this.props.selectAllStudyPlanCycleStudentList();
  }

  handleAddClick() {
    this.props.navigate(`/study_plans/${this.props.studyPlanId}/cycles/${this.props.studyPlanCycleId}/student_select`);
  }

  handleEditClick(row) {
    this.props.navigate(`/study_plans/${this.props.studyPlanId}/cycles/${this.props.studyPlanCycleId}/students/${row.dataItem.id}`);
  }

  handleRemoveClick(e) {
    this.props.openStudyPlanCycleStudentListConfirmDialog(e.dataItem);
  }

  handleRemoveConfirm() {
    this.props.removeStudyPlanCycleStudentListConfirmDialog({
      studyPlanId: this.props.studyPlanId,
      studyPlanCycleId: this.props.studyPlanCycleId
    });
  }
}

const mapStateToProps = (state) => ({
  studyPlanCycleStudents: state.studyPlanCycleStudentList.studyPlanCycleStudents,
  studyPlanCycleManagers: state.studyPlanCycleStudentList.studyPlanCycleManagers,
  loading: state.studyPlanCycleStudentList.loading,
  isConfirmDialogVisible: state.studyPlanCycleStudentList.isConfirmDialogVisible,
  isCycleManagerDialogVisible: state.studyPlanCycleStudentList.isCycleManagerDialogVisible,
  selectedAll: state.studyPlanCycleStudentList.selectedAll,
  selectedIds: state.studyPlanCycleStudentList.selectedIds,
  selectedStudents: state.studyPlanCycleStudentList.selectedStudents,
  validationResult: state.studyPlanCycleStudentList.validationResult
});

const mapDispatchToProps = (dispatch) => ({
  loadStudyPlanCycleStudentList: (payload) => dispatch(loadStudyPlanCycleStudentList(payload)),
  openStudyPlanCycleStudentListConfirmDialog: (payload) => dispatch(openStudyPlanCycleStudentListConfirmDialog(payload)),
  closeStudyPlanCycleStudentListConfirmDialog: (payload) => dispatch(closeStudyPlanCycleStudentListConfirmDialog(payload)),
  removeStudyPlanCycleStudentListConfirmDialog: (payload) => dispatch(removeStudyPlanCycleStudentListConfirmDialog(payload)),
  openStudyPlanCycleStudentListChangeManagerDialog: () => dispatch(openStudyPlanCycleStudentListChangeManagerDialog()),
  closeStudyPlanCycleStudentListChangeManagerDialog: () => dispatch(closeStudyPlanCycleStudentListChangeManagerDialog()),
  saveStudyPlanCycleStudentListCycleManager: (payload) => dispatch(saveStudyPlanCycleStudentListCycleManager(payload)),
  selectStudyPlanCycleStudentList: (payload) => dispatch(selectStudyPlanCycleStudentList(payload)),
  selectAllStudyPlanCycleStudentList: () => dispatch(selectAllStudyPlanCycleStudentList())
});

registerForLocalization(StudyPlanCycleStudentList);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StudyPlanCycleStudentList));