import MyStudiesStudentOverviewPageActionTypes from './my-studies-student-overview-page-action-types';
import axios from 'axios';

export const loadMyStudiesStudentOverviewPage = ({ studentId }) => async(dispatch, getState, { api }) => {
  dispatch({ type: MyStudiesStudentOverviewPageActionTypes.LOAD_MY_STUDIES_STUDENT_OVERVIEW_PAGE_START });
  const state = getState().myStudiesStudentOverviewPage;
  try {
    state.studentCancelToken?.cancel();
    state.studentCancelToken = axios.CancelToken.source();
    const student = await api.get(`api/students/${studentId}`, null, state.studentCancelToken.token);
    dispatch({
      type: MyStudiesStudentOverviewPageActionTypes.LOAD_MY_STUDIES_STUDENT_OVERVIEW_PAGE_END,
      payload: student
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: MyStudiesStudentOverviewPageActionTypes.LOAD_MY_STUDIES_STUDENT_OVERVIEW_PAGE_END });
      throw error;
    }
  }
};