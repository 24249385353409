import * as React from 'react';
import { ListPage } from '../../../ui';
import { MyCompetencyListFilter } from './index';
import { connect } from 'react-redux';
import { withRouter } from '../../../components/withRouter';
import { StudentCompetencyList } from '../../shared/student-competency';

class MyCompetencyListPage extends React.PureComponent {
  render() {
    return (
      <ListPage filter={<MyCompetencyListFilter/>}>
        <StudentCompetencyList studentId={this.props.studentId} onRowClick={(e) => this.props.navigate(`/my_competencies/${e.dataItem.id}/overview`)}/>
      </ListPage>
    );
  }
}

const mapStateToProps = state => ({
  studentId: state.app.currentStudies?.studentId
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MyCompetencyListPage));