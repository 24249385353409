import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../../components/withRouter';
import { StudentCompetencyOverview } from '../../shared/student-competency';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';

class MyCompetencyOverviewPage extends React.PureComponent {
  render() {
    const localizationService = provideLocalizationService(this);
    return (
      <StudentCompetencyOverview
        studentId={this.props.studentId}
        studentCompetencySurveyListOnRowClick={e => this.props.navigate(`/my_competencies/${this.props.params?.competencyId}/surveys/${e.dataItem.id}/overview`)}
        breadcrumbItems={[
          {
            id: 'myCompetencies',
            text: localizationService.toLanguageString('studentCompetency.myTieredCompetency')
          },
          {
            id: 'myCompetency',
            text: localizationService.toLanguageString('studentCompetency.tieredCompetency'),
            disabled: true
          }
        ]}
      />
    );
  }
}

const mapStateToProps = state => ({
  studentId: state.app.currentStudies?.studentId
});

const mapDispatchToProps = dispatch => ({});

registerForLocalization(MyCompetencyOverviewPage);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MyCompetencyOverviewPage));