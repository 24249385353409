import TheoreticalPartListPageActionTypes from './theoretical-part-list-page-action-types';
import axios from 'axios';
import { sortingToString } from '../../../utils/objectUtils';
import { THEORETICAL_PARTS } from '../../../resources/theoreticalPartType';

export const loadTheoreticalPartListPage = (payload) => async(dispatch, getState) => {
  const state = getState().theoreticalPartListPage;
  dispatch(findTheoreticalParts());
  if (!state.filterCycles) {
    dispatch(searchCyclesFilterCycles());
  }
  if (!state.filterUsers) {
    dispatch(searchUsersFilterUsers());
  }
  if (!state.filterStudyPrograms) {
    dispatch(searchTheoreticalPartFilterStudyPrograms());
  }
};

export const findTheoreticalParts = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: TheoreticalPartListPageActionTypes.LOAD_THEORETICAL_PART_LIST_START });
  const state = getState().theoreticalPartListPage;
  if (!state.filter?.studyProgram?.id) {
    dispatch({ type: TheoreticalPartListPageActionTypes.DEACTIVATE_THEORETICAL_PART_LIST_FILTER_CYCLE_LIST });
  }
  const query = {
    skip: payload ? payload.skip : state.skip,
    limit: payload ? payload.take : state.take,
    sorting: sortingToString(state.sorting),
    keyword: state.filter?.keyword,
    cycleId: state.filter?.cycle?.id,
    userId: state.filter?.user?.id,
    type: state.filter?.theoreticalPartType?.id,
    studyProgramId: state.filter?.studyProgram?.id,
    extendedSearch: true
  };
  if (state.filter?.dateFrom) {
    query.from = state.filter?.dateFrom;
  }
  if (state.filter?.dateTo) {
    query.to = state.filter?.dateTo;
  }
  try {
    state.theoreticalPartsCancelToken?.cancel();
    state.theoreticalPartsCancelToken = axios.CancelToken.source();
    const theoreticalParts = await api.get(`api/theoretical_parts`, query, state.theoreticalPartsCancelToken.token);
    dispatch({
      type: TheoreticalPartListPageActionTypes.LOAD_THEORETICAL_PART_LIST_END,
      payload: {
        theoreticalParts: theoreticalParts ? theoreticalParts.data.map(element => ({ ...element, expanded: false })) : [],
        total: theoreticalParts ? theoreticalParts.total : 0,
        skip: payload ? payload.skip : state.skip,
        take: payload ? payload.take : state.take
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: TheoreticalPartListPageActionTypes.LOAD_THEORETICAL_PART_LIST_END });
      throw error;
    }
  }
};

export const filterTheoreticalParts = (payload) => (dispatch) => {
  dispatch({
    type: TheoreticalPartListPageActionTypes.FILTER_THEORETICAL_PART_LIST,
    payload: payload
  });
  dispatch(findTheoreticalParts());
};

export const sortTheoreticalParts = (payload) => (dispatch) => {
  dispatch({
    type: TheoreticalPartListPageActionTypes.SORT_THEORETICAL_PART_LIST,
    payload: payload
  });
  dispatch(findTheoreticalParts());
};

export const clearTheoreticalPartsFilter = () => (dispatch) => {
  dispatch({
    type: TheoreticalPartListPageActionTypes.FILTER_THEORETICAL_PART_LIST,
    payload: {}
  });
  dispatch(findTheoreticalParts());
  dispatch(searchCyclesFilterCycles());
};

export const searchCyclesFilterCycles = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: TheoreticalPartListPageActionTypes.SEARCH_THEORETICAL_PART_LIST_FILTER_CYCLE_START });
  const query = {
    skip: 0,
    limit: 50,
    extendedSearch: true,
    keyword: payload?.keyword,
    studyProgramId: payload?.studyProgramId
  };
  if (query?.studyProgramId || query?.keyword) {
    dispatch({ type: TheoreticalPartListPageActionTypes.ACTIVATE_THEORETICAL_PART_LIST_FILTER_CYCLE_LIST });
  } else {
    dispatch({ type: TheoreticalPartListPageActionTypes.DEACTIVATE_THEORETICAL_PART_LIST_FILTER_CYCLE_LIST });
  }
  const state = getState().theoreticalPartListPage;
  try {
    state.filterCyclesCancelToken?.cancel();
    state.filterCyclesCancelToken = axios.CancelToken.source();
    const [ cycles, assignedCycles ] = payload?.studyProgramId ? await Promise.all([
      api.get('api/cycles', query, state.filterCyclesCancelToken.token),
      query.studyProgramId ? api.get(`api/study_programs/${query.studyProgramId}/assigned_cycles`, query, state.filterCyclesCancelToken.token) : []
    ]) : [];
    const allCycles = [];
    if (cycles?.data) {
      allCycles.push(...cycles.data);
    }
    if (assignedCycles?.data) {
      allCycles.push(...assignedCycles.data);
    }
    dispatch({
      type: TheoreticalPartListPageActionTypes.SEARCH_THEORETICAL_PART_LIST_FILTER_CYCLE_END,
      payload: {
        cycles: allCycles?.map(element => ({
          id: element.id,
          name: element.code + ' ' + element.name
        }))
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: TheoreticalPartListPageActionTypes.SEARCH_THEORETICAL_PART_LIST_FILTER_CYCLE_END });
      throw error;
    }
  }
};

export const searchUsersFilterUsers = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: TheoreticalPartListPageActionTypes.SEARCH_THEORETICAL_PART_LIST_FILTER_USER_START });
  const query = {
    skip: 0,
    limit: 50,
    keyword: payload?.keyword
  };
  const state = getState().theoreticalPartListPage;
  try {
    state.filterUsersCancelToken?.cancel();
    state.filterUsersCancelToken = axios.CancelToken.source();
    const users = await api.get('api/users_search', query, state.filterUsersCancelToken.token);
    dispatch({
      type: TheoreticalPartListPageActionTypes.SEARCH_THEORETICAL_PART_LIST_FILTER_USER_END,
      payload: {
        users: users?.data?.map(element => (
          {
            id: element.id,
            fullName: element.name + ' ' + element.surname
          }
        ))
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: TheoreticalPartListPageActionTypes.SEARCH_THEORETICAL_PART_LIST_FILTER_USER_END });
      throw error;
    }
  }
};

export const loadTheoreticalPartTypes = (localizationService) => async(dispatch, getState) => {
  const types = Object.keys(THEORETICAL_PARTS)
    .map(key => ({
      id: THEORETICAL_PARTS[key],
      name: localizationService.toLanguageString('cycleTheoreticalPartType.' + THEORETICAL_PARTS[key])
    }));
  dispatch({
    type: TheoreticalPartListPageActionTypes.LOAD_THEORETICAL_PART_LIST_THEORETICAL_PART_TYPES,
    payload: {
      theoreticalPartTypes: types
    }
  });
};

export const searchTheoreticalPartFilterStudyPrograms = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: TheoreticalPartListPageActionTypes.SEARCH_THEORETICAL_PART_LIST_FILTER_STUDY_PROGRAM_START });
  const query = {
    skip: 0,
    limit: 50,
    keyword: payload?.keyword
  };
  const state = getState().theoreticalPartListPage;
  try {
    state.filterStudyProgramsCancelToken?.cancel();
    state.filterStudyProgramsCancelToken = axios.CancelToken.source();
    const studyPrograms = await api.get('api/study_programs', query, state.filterStudyProgramsCancelToken.token);
    dispatch({
      type: TheoreticalPartListPageActionTypes.SEARCH_THEORETICAL_PART_LIST_FILTER_STUDY_PROGRAM_END,
      payload: {
        studyPrograms: studyPrograms ? studyPrograms.data : []
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: TheoreticalPartListPageActionTypes.SEARCH_THEORETICAL_PART_LIST_FILTER_STUDY_PROGRAM_END });
      throw error;
    }
  }
};

export const changeExpandState = (dataItem) => (dispatch, getState) => {
  const theoreticalParts = getState().theoreticalPartListPage.theoreticalParts;
  const updateTheoreticalParts = theoreticalParts.map(element => {
    if (element.id === dataItem.id && element.studyProgramName === dataItem.studyProgramName) {
      element.expanded = !dataItem.expanded;
    }
    return element;
  });
  dispatch({
    type: TheoreticalPartListPageActionTypes.CHANGE_THEORETICAL_PART_LIST_EXPAND_STATE,
    payload: { theoreticalParts: updateTheoreticalParts }
  });
};