import { CycleTheoreticalPartFormPageActiontypes } from '../actions';

const initialState = {
  loading: false,
  studyProgramId: null,
  availableTheoreticalPartsLoading: false,
  availableTheoreticalPartsCancelToken: null,
  availableTheoreticalParts: [],
  theoreticalPartTypes: [],
  cycleTheoreticalPart: {},
  saveSuccess: false,
  validationResult: null,
  teachers: [],
  cancellationTokenSources: new Map()
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CycleTheoreticalPartFormPageActiontypes.LOAD_CYCLE_THEORETICAL_PART_FORM_PAGE_START:
      return {
        ...state,
        loading: true,
        validationResult: null
      };
    case CycleTheoreticalPartFormPageActiontypes.LOAD_CYCLE_THEORETICAL_PART_FORM_PAGE_END:
      return {
        ...state,
        loading: false,
        studyProgramId: action.payload?.studyProgramId,
        cycleTheoreticalPart: action.payload ? action.payload.cycleTheoreticalPart : null,
        validationResult: action.payload ? action.payload.validationResult : null
      };
    case CycleTheoreticalPartFormPageActiontypes.LOAD_CYCLE_THEORETICAL_PART_TYPES:
      return {
        ...state,
        theoreticalPartTypes: action.payload ? action.payload.theoreticalPartTypes : []
      };
    case CycleTheoreticalPartFormPageActiontypes.LOAD_CYCLE_THEORETICAL_PART_AVAILABLE_THEORETICAL_PARTS_START:
      return {
        ...state,
        availableTheoreticalPartsLoading: true
      };
    case CycleTheoreticalPartFormPageActiontypes.LOAD_CYCLE_THEORETICAL_PART_AVAILABLE_THEORETICAL_PARTS_END:
      return {
        ...state,
        availableTheoreticalPartsLoading: false,
        availableTheoreticalParts: action.payload ? action.payload.availableTheoreticalParts : []
      };
    case CycleTheoreticalPartFormPageActiontypes.LOAD_CYCLE_THEORETICAL_PART_AVAILABLE_TEACHERS_START:
      return {
        ...state,
        teachers: action.payload?.teachers ? action.payload?.teachers : []
      };
    case CycleTheoreticalPartFormPageActiontypes.LOAD_CYCLE_THEORETICAL_PART_AVAILABLE_TEACHERS_END:
      return {
        ...state,
        teachers: action.payload?.teachers ? action.payload?.teachers : []
      };
    default:
      return state;
  }
};