export default {
  LOAD_STUDY_PLAN_CYCLE_STUDENT_LIST_START: 'LoadStudyPlanCycleStudentListStart',
  LOAD_STUDY_PLAN_CYCLE_STUDENT_LIST_END: 'LoadStudyPlanCycleStudentListEnd',
  TOGGLE_STUDY_PLAN_CYCLE_STUDENT_LIST_CONFIRM_DIALOG: 'ToggleStudyPlanCycleStudentListConfirmDialog',
  SELECT_STUDY_PLAN_CYCLE_STUDENT_LIST: 'SelectStudyPlanCycleStudentList',
  SELECT_ALL_STUDY_PLAN_CYCLE_STUDENT_LIST: 'SelectAllStudyPlanCycleStudentList',
  OPEN_STUDY_PLAN_CYCLE_STUDENT_LIST_CHANGE_CYCLE_MANAGER_DIALOG: 'OpenStudyPlanCycleStudentListChangeCycleManagerDialog',
  CLOSE_STUDY_PLAN_CYCLE_STUDENT_LIST_CHANGE_CYCLE_MANAGER_DIALOG: 'CloseStudyPlanCycleStudentListChangeCycleManagerDialog',
  SAVE_STUDY_PLAN_CYCLE_STUDENT_LIST_CYCLE_MANAGER_START: 'SaveStudyPlanCycleStudentListChangeCycleManagerStart',
  SAVE_STUDY_PLAN_CYCLE_STUDENT_LIST_CYCLE_MANAGER_END: 'SaveStudyPlanCycleStudentListChangeCycleManagerEnd'
};