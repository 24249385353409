import { pagingSettings } from '../../../utils/GridPagingUtils';
import TheoreticalPartListPageActionTypes from '../actions/theoretical-part-list-page-action-types';

const initialState = {
  loading: false,
  theoreticalParts: null,
  theoreticalPartsCancelToken: null,
  skip: pagingSettings.paging.skip,
  take: pagingSettings.paging.take,
  total: 0,
  sorting: null,
  filter: null,

  filterCyclesLoading: false,
  filterCyclesCancelToken: null,
  filterCycles: null,

  filterUsersLoading: false,
  filterUsersCancelToken: null,
  filterUsers: null,

  theoreticalPartTypes: [],

  filterStudyProgramsCancelToken: null,
  filterStudyProgramsLoading: false,
  filterStudyPrograms: null,

  cycleListActive: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TheoreticalPartListPageActionTypes.LOAD_THEORETICAL_PART_LIST_START:
      return {
        ...state,
        loading: true
      };
    case TheoreticalPartListPageActionTypes.LOAD_THEORETICAL_PART_LIST_END:
      return {
        ...state,
        loading: false,
        theoreticalParts: action.payload.theoreticalParts,
        total: action.payload.total,
        skip: action.payload.skip,
        take: action.payload.take
      };
    case TheoreticalPartListPageActionTypes.FILTER_THEORETICAL_PART_LIST:
      return {
        ...state,
        filter: action.payload,
        skip: initialState.skip,
        take: initialState.take
      };
    case TheoreticalPartListPageActionTypes.SORT_THEORETICAL_PART_LIST:
      return {
        ...state,
        sorting: action.payload
      };
    case TheoreticalPartListPageActionTypes.SEARCH_THEORETICAL_PART_LIST_FILTER_CYCLE_START:
      return {
        ...state,
        filterCyclesLoading: true
      };
    case TheoreticalPartListPageActionTypes.SEARCH_THEORETICAL_PART_LIST_FILTER_CYCLE_END:
      return {
        ...state,
        filterCyclesLoading: false,
        filterCycles: action.payload.cycles
      };
    case TheoreticalPartListPageActionTypes.SEARCH_THEORETICAL_PART_LIST_FILTER_USER_START:
      return {
        ...state,
        filterUsersLoading: true
      };
    case TheoreticalPartListPageActionTypes.SEARCH_THEORETICAL_PART_LIST_FILTER_USER_END:
      return {
        ...state,
        filterUsersLoading: false,
        filterUsers: action.payload.users
      };
    case TheoreticalPartListPageActionTypes.LOAD_THEORETICAL_PART_LIST_THEORETICAL_PART_TYPES:
      return {
        ...state,
        theoreticalPartTypes: action.payload ? action.payload.theoreticalPartTypes : []
      };
    case TheoreticalPartListPageActionTypes.SEARCH_THEORETICAL_PART_LIST_FILTER_STUDY_PROGRAM_START:
      return {
        ...state,
        filterStudyProgramsLoading: true,
        cycleListActive: false,
        filterCycles: []
      };
    case TheoreticalPartListPageActionTypes.SEARCH_THEORETICAL_PART_LIST_FILTER_STUDY_PROGRAM_END:
      return {
        ...state,
        filterStudyProgramsLoading: false,
        filterStudyPrograms: action.payload.studyPrograms
      };
    case TheoreticalPartListPageActionTypes.ACTIVATE_THEORETICAL_PART_LIST_FILTER_CYCLE_LIST:
      return {
        ...state,
        cycleListActive: true
      };
    case TheoreticalPartListPageActionTypes.DEACTIVATE_THEORETICAL_PART_LIST_FILTER_CYCLE_LIST:
      return {
        ...state,
        cycleListActive: false
      };
    case TheoreticalPartListPageActionTypes.CHANGE_THEORETICAL_PART_LIST_EXPAND_STATE:
      return {
        ...state,
        theoreticalParts: action.payload.theoreticalParts
      };
    default:
      return state;
  }
};