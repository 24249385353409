import React from 'react';
import { connect } from 'react-redux';
import { provideLocalizationService, registerForIntl, registerForLocalization } from '@progress/kendo-react-intl';
import { Grid, GridSize } from '../../components';
import { loadStudentCompetencySubcompetencyList } from '../../student-competency/actions/student-competency-subcompetency-list-actions';
import { withRouter } from '../../../../components/withRouter';

class StudentCompetencySubcompetencyList extends React.Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    if (this.props.competencyId > 0) {
      await this.props.loadStudentCompetencySubcompetencyList({
        competencyId: this.props.competencyId
      });
    }
  }

  render() {
    const { studentCompetencySubcompetencies, loading } = this.props;
    const localizationService = provideLocalizationService(this);
    return (
      <Grid
        loading={loading}
        data={studentCompetencySubcompetencies}
        isPageable={false}
        columns={[
          {
            field: 'code',
            className: 'align-left',
            headerClassName: 'align-left',
            title: localizationService.toLanguageString('studentCompetency.code')
          }, {
            field: 'name',
            className: 'align-left',
            headerClassName: 'align-left',
            title: localizationService.toLanguageString('studentCompetency.name')
          }, {
            field: 'group',
            className: 'align-left',
            headerClassName: 'align-left',
            title: localizationService.toLanguageString('studentCompetency.group'),
            minGridWidth: GridSize.medium,
            cell: ({ className, dataItem }) => (
              <td className={className}>
                {`${dataItem.groupName} (${dataItem.groupAbbreviation})`}
              </td>
            )
          }
        ]}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  studentCompetencySubcompetencies: state.studentCompetencySubcompetencyList.studentCompetencySubcompetencies,
  loading: state.studentCompetencySubcompetencyList.loading
});

const mapDispatchToProps = (dispatch) => ({
  loadStudentCompetencySubcompetencyList: (payload) => dispatch(loadStudentCompetencySubcompetencyList(payload))
});

registerForIntl(StudentCompetencySubcompetencyList);
registerForLocalization(StudentCompetencySubcompetencyList);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StudentCompetencySubcompetencyList));
