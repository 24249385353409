import * as React from 'react';
import { withRouter } from '../../../../components/withRouter';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { ComboBox, StackLayout, TextArea, TextBox } from '../../../../ui';
import { connect } from 'react-redux';
import FormDialog from '../../../../ui/components/application/form-dialog';
import { Field } from '@progress/kendo-react-form';
import {
  loadAvailableManagers,
  submitRequestEvaluationDialog,
  clearStudentCompetencySurveySubmitForm,
  toggleStudentCompetencySurveySubmitFormType,
  loadAvailableExternalUsers,
  loadAvailableDepartments,
  loadStudentCompetencySurveySubmitForm,
  loadEvaluatorRoles
} from '../../student-competency/actions/student-competency-survey-submit-form-actions';
import { Button } from '@progress/kendo-react-buttons';
import styled from 'styled-components';
import { Switch } from '@progress/kendo-react-inputs';
import { ValueItem } from '../../components';
import { SURVEY_TYPES } from '../../../../resources/surveyType';
import { validateEmail } from '../../../../utils/emailValidationHelper';
import { validateStampNumber } from '../../../../utils/stampNumberValidationHelper';
import { Hint } from '@progress/kendo-react-labels';

const FIELD_NAMES = {
  externalUser: 'externalUser',
  name: 'name',
  surname: 'surname',
  email: 'email',
  manager: 'manager',
  stampNumber: 'stampNumber'
};

const FormToggleButtonContainer = styled.div`
  display: contents;
`;

class StudentCompetencySurveySubmitForm extends React.PureComponent {

  constructor(props) {
    super(props);
    this.handleSubmitRequestEvaluationClick = this.handleSubmitRequestEvaluationClick.bind(this);
    this.validateRequestEvaluationDialog = this.validateRequestEvaluationDialog.bind(this);
    this.handleToggleFormTypeClick = this.handleToggleFormTypeClick.bind(this);
    this.handleStudentCompetencySurveySubmitChange = this.handleStudentCompetencySurveySubmitChange.bind(this);
    this.state = {
      shouldShowQrCode: this.props.shouldShowQrCode
    };
  }

  componentDidMount() {
    const localizationService = provideLocalizationService(this);
    this.props.loadStudentCompetencySurveySubmitForm({ localizationService, surveyType: this.props.competencySurvey?.surveyType });
  }

  componentWillUnmount() {
    this.props.clearStudentCompetencySurveySubmitForm();
  }

  render() {
    const localizationService = provideLocalizationService(this);
    return (
      <FormDialog
        title={localizationService.toLanguageString('studentCompetency.requestEvaluationDialogTitle')}
        onSubmit={this.handleSubmitRequestEvaluationClick}
        onCancel={this.props.onCancel}
        validator={this.validateRequestEvaluationDialog}
        validationResult={this.props.requestEvaluationDialogValidationResult}
        confirmButtonText={localizationService.toLanguageString('buttons.request')}
        closeButtonText={localizationService.toLanguageString('buttons.close')}
        minWidth={320}
        initialValues={this.props.studentCompetencySurveyForm}
        render={(formRenderProps) => {
          const externalUserHasValue = formRenderProps.valueGetter(FIELD_NAMES.externalUser)?.id > 0;
          return (
            <StackLayout>
              <FormToggleButtonContainer>
                <Button type='button' onClick={e => this.handleToggleFormTypeClick(e, formRenderProps)}>
                  {localizationService.toLanguageString(`studentCompetency.${this.props.isExternalUserForm ? 'requestInternalUser' : 'requestExternalUser'}`)}
                </Button>
              </FormToggleButtonContainer>
              {this.props.isExternalUserForm
                ?
                <>
                  <Field
                    name={FIELD_NAMES.externalUser}
                    label={localizationService.toLanguageString('studentCompetency.evaluator')}
                    component={ComboBox}
                    loading={this.props.availableExternalUsersLoading}
                    data={this.props.availableExternalUsers}
                    onFilter={this.props.loadAvailableExternalUsers}
                    textField='text'
                    valueField='id'
                    onChange={(e) => {
                      if (e.value?.id > 0) {
                        formRenderProps.onChange(FIELD_NAMES.stampNumber, { value: e.value.stampNumber });
                        formRenderProps.onChange(FIELD_NAMES.name, { value: e.value.name });
                        formRenderProps.onChange(FIELD_NAMES.surname, { value: e.value.surname });
                        formRenderProps.onChange(FIELD_NAMES.email, { value: e.value.email });
                      }
                      if (!e.value) {
                        formRenderProps.onChange(FIELD_NAMES.stampNumber, { value: '' });
                        formRenderProps.onChange(FIELD_NAMES.name, { value: '' });
                        formRenderProps.onChange(FIELD_NAMES.surname, { value: '' });
                        formRenderProps.onChange(FIELD_NAMES.email, { value: '' });
                      }
                    }}
                  />
                  <Field
                    name={FIELD_NAMES.stampNumber}
                    label={localizationService.toLanguageString('studentCompetency.stampNumber')}
                    component={TextBox}
                    disabled={externalUserHasValue}
                  />
                  <Field
                    name={FIELD_NAMES.name}
                    label={localizationService.toLanguageString('studentCompetency.firstName')}
                    component={TextBox}
                    disabled={externalUserHasValue}
                  />
                  <Field
                    name={FIELD_NAMES.surname}
                    label={localizationService.toLanguageString('studentCompetency.surname')}
                    component={TextBox}
                    disabled={externalUserHasValue}
                  />
                  <Field
                    name={FIELD_NAMES.email}
                    label={localizationService.toLanguageString('studentCompetency.email')}
                    component={TextBox}
                    disabled={externalUserHasValue}
                  />
                </>
                :
                <>
                  <Field
                    name={FIELD_NAMES.manager}
                    label={localizationService.toLanguageString('studentCompetency.evaluator')}
                    component={ComboBox}
                    loading={this.props.availableManagersLoading}
                    data={this.props.availableManagers}
                    onFilter={this.debounce(this.props.loadAvailableManagers, 100)}
                    onChange={e => this.handleStudentCompetencySurveySubmitChange(e, localizationService)}
                    textField='text'
                    valueField='id'
                  />
                </>
              }
              <Field
                name='department'
                label={localizationService.toLanguageString('studentCompetency.department')}
                component={ComboBox}
                loading={this.props.availableDepartmentsLoading}
                data={this.props.availableDepartments}
                onFilter={this.debounce(this.props.loadAvailableDepartments, 100)}
                textField='name'
                valueField='id'
              />
              <Hint>{localizationService.toLanguageString('studentCompetency.parentDepartmentWarning')}</Hint>
              {(this.props.isExternalUserForm || this.props.competencySurvey.surveyType == SURVEY_TYPES.SURVEY_360)
                &&
                <Field
                  name='role'
                  label={localizationService.toLanguageString('studentCompetency.role')}
                  component={ComboBox}
                  data={this.props.evaluatorRoles}
                  textField='name'
                  valueField='id'
                />
              }
              <Field
                name='comment'
                label={localizationService.toLanguageString('studentCompetency.comment')}
                component={TextArea}
              />
              {!this.props.isExternalUserForm &&
                <ValueItem
                  label={`${localizationService.toLanguageString('studentCompetency.shoqQrCode')}`}
                  labelWidth='150px'
                >
                  <Switch
                    checked={this.state.shouldShowQrCode}
                    valid={true}
                    onChange={() => {
                      const newState = !this.state.shouldShowQrCode;
                      this.props.setShowQrCodeSetting(newState);
                      this.setState({ shouldShowQrCode: newState });
                    }}
                  />
                </ValueItem>
              }
            </StackLayout>
          );
        }}
      />
    );
  }

  handleToggleFormTypeClick(e, formRenderProps) {
    this.props.toggleStudentCompetencySurveySubmitFormType(e);
    if (this.props.isExternalUserForm) {
      formRenderProps.onChange(FIELD_NAMES.manager, { value: null });
    } else {
      formRenderProps.onChange(FIELD_NAMES.externalUser, { value: null });
      formRenderProps.onChange(FIELD_NAMES.stampNumber, { value: '' });
      formRenderProps.onChange(FIELD_NAMES.name, { value: '' });
      formRenderProps.onChange(FIELD_NAMES.surname, { value: '' });
      formRenderProps.onChange(FIELD_NAMES.email, { value: '' });
      this.props.loadAvailableDepartments();
    }
  }

  handleSubmitRequestEvaluationClick(e) {
    this.props.submitRequestEvaluationDialog({
      ...e,
      studentId: this.props.studentId,
      competencySurveyId: this.props.competencySurvey.id,
      closeDialog: this.props.onCancel,
      afterSubmit: this.props.afterSubmit
    });
  }

  handleStudentCompetencySurveySubmitChange(e, localizationService) {
    this.props.loadAvailableDepartments({ userId: e.value?.id, ...e });
    this.props.loadEvaluatorRoles({ localizationService: localizationService });
  }

  debounce(func, delay) {
    let timer;
    return function(...args) {
      const context = this;
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(context, args), delay);
    };
  }

  validateRequestEvaluationDialog(values, localizationService, validationResult, modified) {
    const errors = {};
    if (!values.department) {
      errors.department = localizationService.toLanguageString('validation.required');
    }
    if (!values.role) {
      errors.role = localizationService.toLanguageString('validation.required');
    }
    if (values.comment && values.comment.length > 300) {
      errors.comment = localizationService.toLanguageString('studentCompetency.commentFieldLengthExceeds');
    }

    if (this.props.isExternalUserForm) {
      if (!values.externalUser) {
        if (!values.name && !values.surname && !values.email && !values.stampNumber) {
          errors.externalUser = localizationService.toLanguageString('validation.selectOrFillBelow');
        } else {
          if (!values.name) {
            errors.name = localizationService.toLanguageString('validation.required');
          }
          if (!values.surname) {
            errors.surname = localizationService.toLanguageString('validation.required');
          }
          if (!values.email) {
            errors.email = localizationService.toLanguageString('validation.required');
          } else if (!validateEmail(values.email)) {
            errors.email = localizationService.toLanguageString('validation.invalidEmailFormat');
          }
          if (this.props.competencySurvey.surveyType != SURVEY_TYPES.SURVEY_360 && !values.stampNumber) {
            errors.stampNumber = localizationService.toLanguageString('validation.required');
          }
          if (!validateStampNumber(values.stampNumber)) {
            errors.stampNumber = localizationService.toLanguageString('studentCompetency.invalidStampNumber');
          }
        }
      }
    } else {
      if (!values.manager) {
        errors.manager = localizationService.toLanguageString('validation.required');
      }
    }

    if (!modified.department && !modified.role && !modified.externalUser && !modified.name && !modified.surname && !modified.email && !modified.manager && !modified.comment && !modified.stampNumber) {
      if (validationResult?.errors?.departmentId) {
        errors.department = validationResult?.errors?.departmentId;
      }
      if (validationResult?.errors?.role) {
        errors.role = validationResult?.errors?.role;
      }
      if (validationResult?.errors?.externalUserId) {
        errors.externalUser = validationResult?.errors?.externalUserId;
      }
      if (validationResult?.errors?.name) {
        errors.name = validationResult?.errors?.name;
      }
      if (validationResult?.errors?.surname) {
        errors.surname = validationResult?.errors?.surname;
      }
      if (validationResult?.errors?.email) {
        errors.email = validationResult?.errors?.email;
      }
      if (validationResult?.errors?.manager) {
        errors.manager = validationResult?.errors?.manager;
      }
      if (validationResult?.errors?.comment) {
        errors.comment = validationResult?.errors?.comment;
      }
      if (validationResult?.errors.stampNumber) {
        errors.stampNumber = validationResult?.errors.stampNumber;
      }
    }
    return errors;
  }
}

registerForLocalization(StudentCompetencySurveySubmitForm);

const mapStateToProps = state => ({
  availableManagers: state.studentCompetencySurveySubmitForm.availableManagers,
  availableManagersLoading: state.studentCompetencySurveySubmitForm.availableManagersLoading,
  requestEvaluationDialogValidationResult: state.studentCompetencySurveySubmitForm.requestEvaluationDialogValidationResult,
  isExternalUserForm: state.studentCompetencySurveySubmitForm.isExternalUserForm,
  evaluatorRoles: state.studentCompetencySurveySubmitForm.evaluatorRoles,
  availableDepartments: state.studentCompetencySurveySubmitForm.availableDepartments,
  availableDepartmentsLoading: state.studentCompetencySurveySubmitForm.availableDepartmentsLoading,
  availableExternalUsers: state.studentCompetencySurveySubmitForm.availableExternalUsers,
  availableExternalUsersLoading: state.studentCompetencySurveySubmitForm.availableExternalUsersLoading,
  studentCompetencySurveyForm: state.studentCompetencySurveySubmitForm.studentCompetencySurveyForm
});

const mapDispatchToProps = dispatch => ({
  loadAvailableManagers: (payload) => dispatch(loadAvailableManagers(payload)),
  submitRequestEvaluationDialog: (payload) => dispatch(submitRequestEvaluationDialog(payload)),
  clearStudentCompetencySurveySubmitForm: () => dispatch(clearStudentCompetencySurveySubmitForm()),
  toggleStudentCompetencySurveySubmitFormType: () => dispatch(toggleStudentCompetencySurveySubmitFormType()),
  loadAvailableExternalUsers: (payload) => dispatch(loadAvailableExternalUsers(payload)),
  loadAvailableDepartments: (payload) => dispatch(loadAvailableDepartments(payload)),
  loadStudentCompetencySurveySubmitForm: (payload) => dispatch(loadStudentCompetencySurveySubmitForm(payload)),
  loadEvaluatorRoles: (payload) => dispatch(loadEvaluatorRoles(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StudentCompetencySurveySubmitForm));