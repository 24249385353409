import axios from 'axios';
import StudyProgramTheoreticalPartListActionTypes from './study-program-theoretical-part-list-action-types';
import { ROLE_PERMISSIONS as Permissions } from '../../../resources/rolePermissions';

export const loadStudyProgramTheoreticalPartList = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudyProgramTheoreticalPartListActionTypes.LOAD_STUDY_PROGRAM_THEORETICAL_PART_LIST_START });
  const state = getState().studyProgramTheoreticalPartList;
  try {
    state.studyProgramTheoreticalPartsCancelToken?.cancel();
    state.studyProgramTheoreticalPartsCancelToken = axios.CancelToken.source();
    const studyProgramTheoreticalParts = await api.get(`api/study_programs/${payload.studyProgramId}/theoretical_parts`, null, state.studyProgramTheoreticalPartsCancelToken.token);
    const canWrite = getState().app.userPermissions.includes(Permissions.STUDY_PROGRAMS_WRITE);
    dispatch({
      type: StudyProgramTheoreticalPartListActionTypes.LOAD_STUDY_PROGRAM_THEORETICAL_PART_LIST_END,
      payload: {
        studyProgramTheoreticalParts: studyProgramTheoreticalParts ? studyProgramTheoreticalParts.data : [],
        canWrite
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: StudyProgramTheoreticalPartListActionTypes.LOAD_STUDY_PROGRAM_THEORETICAL_PART_LIST_END });
      throw error;
    }
  }
};

export const openStudyProgramTheoreticalPartConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  dispatch({
    type: StudyProgramTheoreticalPartListActionTypes.TOGGLE_STUDY_PROGRAM_THEORETICAL_PART_LIST_CONFIRM_DIALOG,
    payload: payload
  });
};

export const closeStudyProgramTheoreticalPartConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  dispatch({
    type: StudyProgramTheoreticalPartListActionTypes.TOGGLE_STUDY_PROGRAM_THEORETICAL_PART_LIST_CONFIRM_DIALOG
  });
};

export const removeStudyProgramTheoreticalPartConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().studyProgramTheoreticalPartList;
  if (state.selectedStudyProgramTheoreticalPartId > 0) {
    dispatch({ type: StudyProgramTheoreticalPartListActionTypes.LOAD_STUDY_PROGRAM_THEORETICAL_PART_LIST_START });
    try {
      await api.remove(`api/study_programs/${payload.studyProgramId}/theoretical_parts/${state.selectedStudyProgramTheoreticalPartId}`);
      dispatch(loadStudyProgramTheoreticalPartList({ studyProgramId: payload.studyProgramId }));
    } catch (error) {
      dispatch({ type: StudyProgramTheoreticalPartListActionTypes.LOAD_STUDY_PROGRAM_THEORETICAL_PART_LIST_END });
    }
  }
  dispatch({ type: StudyProgramTheoreticalPartListActionTypes.TOGGLE_STUDY_PROGRAM_THEORETICAL_PART_LIST_CONFIRM_DIALOG });
};